import React from 'react'

import Icon from 'components/Icons/Icon'

import cikmaBuradaLayoutLogo from '../../../assets/Icons/cikmaBuradaLayoutLogo.svg'
import { LogoContainer, LogoLink } from './Logo.styled'
import { LogoProps } from './types'

const Logo: React.FunctionComponent<LogoProps> = ({ children }) => {
    return (
        <LogoContainer>
            <LogoLink to="/">
                <Icon icon={cikmaBuradaLayoutLogo} />
                {children}
            </LogoLink>
        </LogoContainer>
    )
}

export default Logo
