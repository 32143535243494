import { Box, styled, TextField } from '@mui/material'
import { ptr } from '../../utils/helpers'
import { colors } from '../../styles/color'

export const FilterSection = styled(Box)({
    boxShadow: ' 0px 2px 10px rgba(76, 78, 100, 0.22)',
    marginBottom: ptr(24),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-around',
    height: ptr(144),
    gap: ptr(16),
    padding: ptr(20),
    background: colors.white.default,
})

export const FilterGroup = styled(Box)({
    display: 'flex',
    gap: ptr(24),
    width: '100%',
})

export const OfferTableSection = styled(Box)({
    boxShadow: ' 0px 2px 10px rgba(76, 78, 100, 0.22)',
    marginBottom: ptr(24),
})

export const OfferTableHead = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    textAlign: 'center',
    alignItems: 'center',
    background: colors.white.default,
    padding: `${ptr(21)}}`,
})

export const OfferTableFilterSection = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    width: '50%',
    justifyContent: 'right',
    gap: ptr(24),
    textAlign: 'center',
    alignItems: 'flex-start',
})

export const OfferSearchInput = styled(TextField)({
    height: '40px',
    '& > div': {
        height: '40px',
        fontWeight: '300',
        paddingRight: '12px',
        fontSize: '16px',
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: colors.primary.primaryMain,
        },
    },
})

export const OrderStatus = styled(Box)({
    borderRadius: ptr(16),
    padding: `${ptr(3)} ${ptr(10)}`,
    fontSize: ptr(13),
    height: 'fit-content',
    minWidth: 'fit-content',
    '&.canceled': {
        backgroundColor: colors.error.errorOutlinedHoverBg,
        color: colors.error.errorMain,
    },
    '&.delivered': {
        backgroundColor: colors.secondary.secondaryOutlinedHoverBg,
        color: colors.secondary.secondaryMain,
    },
    '&.in_preparatio': {
        backgroundColor: colors.info.infoOutlinedHoverBg,
        color: colors.info.infoMain,
    },
    '&.shipping': {
        backgroundColor: colors.success.successOutlinedHoverBg,
        color: colors.success.successMain,
    },
    '&.pending': {
        backgroundColor: colors.warning.warningOutlinedHoverBg,
        color: colors.warning.warningMain,
    },
})

export const OfferUpdateModal = styled(Box)({
    padding: ptr(16),
})
