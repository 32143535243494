import {
    ChatContainer,
    ChatContent,
    ChatFooter,
    ChatFooterTitle,
    ChatLeft,
    ChatRight,
    ChatRightHeader,
    ChatRightHeaderInformation,
    ChatRightHeaderInformationSubtitle,
    ChatRightHeaderInformationTitle,
    ChatSearchInput,
    ChatTitle,
    Circle,
    PaddingWrapper,
    SearchWrapper,
} from './index.styled'
import { Box, InputAdornment } from '@mui/material'
import ChatUserSection from './chatUserSection'
import Avatar from '../../../../components/Avatar/Avatar'
import { ChatBubbleOutline, Search as SearchIcon } from '@mui/icons-material'
import ChatMessage from './chatMessage'
import { EDirection } from './chatMessage/chatMessage.enum'
import { useGetProfileChatDetail, useGetProfileChats, useGetUserProfile } from '../../../../utils/hooks/queries/Users'
import { useParams, useSearchParams } from 'react-router-dom'
import { UserProfileChat, UserProfileChatDetail } from '../../../../services/be-api/users/types'
import { FC, useEffect, useRef, useState } from 'react'
import { handleFilter, uniqueObjectArray } from '../../../../utils/helpers'
import Typography from 'components/Typography/Typography'
import { colors } from 'styles/color'
import { CustomBadge } from 'pages/mainPage/index.styled'
import { ptr } from 'utils/helpers'
import ChatBg from '../../../../assets/Images/ChatBG/Chat.png'

interface ChatDetailProps {
    conversationId: number
    user: {
        fullName: string
        companyName: string
        avatar: string
        id: number
    }
    companyName: string
    page: number
    status: number
    increasePage: () => void
}

interface ChatContentWrapperProps {
    handleScroll: (event: React.UIEvent<HTMLDivElement, UIEvent>) => void
    sellerId: number
    buyerData: { fullName: string; avatar: string }
    sellerData: { fullName: string; avatar: string }
    conversationId: number
    page: number
}

const ChatContentWrapper: FC<ChatContentWrapperProps> = ({ handleScroll, sellerId, buyerData, sellerData, conversationId, page }) => {
    const [chatDetails, setChatDetails] = useState<UserProfileChatDetail[]>([])
    const [uniqueChatMessages, setUniqueChatMessages] = useState<UserProfileChatDetail[]>([])
    const [chatInitialized, setChatInitialized] = useState(false)
    const { data: chatDetailData } = useGetProfileChatDetail({
        queryKeys: {
            conversationId,
            SkipCount: page * 20,
            MaxResultCount: 20,
        },
    })

    useEffect(() => {
        if (chatDetailData?.data.result) {
            const _chatDetails = chatDetailData?.data.result.filter((chat) => Number(chat.messageType) !== -1)
            setChatDetails((prevState) => [...prevState, ..._chatDetails])
        }
    }, [chatDetailData])

    useEffect(() => {
        if (chatDetails.length) {
            setChatInitialized(true)
        }
        setUniqueChatMessages(uniqueObjectArray(chatDetails.reverse()))
    }, [chatDetails])

    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (ref.current && chatInitialized) {
            ref.current.scrollTop = ref.current.scrollHeight
        }
    }, [chatInitialized])

    return (
        <>
            <ChatContent>
                <Box ref={ref} sx={{ height: '100%', overflowY: 'scroll' }} onScrollCapture={(e) => uniqueChatMessages.length % 20 === 0 && handleScroll(e)}>
                    {uniqueChatMessages.map((chatDetail, index: number) => (
                        <ChatMessage
                            key={index}
                            direction={sellerId === chatDetail.senderUserId ? EDirection.RIGHT : EDirection.LEFT}
                            user={sellerId === chatDetail.senderUserId ? sellerData : buyerData}
                            isRead={chatDetail.isMessageRead}
                            sendAt={chatDetail.sentAt}
                            type={chatDetail.messageType}
                            message={chatDetail.messageData}
                        />
                    ))}
                </Box>
            </ChatContent>
        </>
    )
}

const ChatDetail: FC<ChatDetailProps> = ({ conversationId, user, companyName, page, increasePage, status }) => {
    const { id } = useParams()
    const [profile, setProfile] = useState<any>({})
    const { data: profileData } = useGetUserProfile({ queryKeys: { id: Number(id) } })

    useEffect(() => {
        if (profileData?.data.result) setProfile(profileData.data.result)
    }, [profileData])

    const handleScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
        const { scrollTop } = event.currentTarget
        if (scrollTop === 0) {
            increasePage()
        }
    }

    return (
        <>
            <ChatRightHeader>
                <Avatar name={user.fullName} height={40} width={40} src={user.avatar} />
                <ChatRightHeaderInformation>
                    <ChatRightHeaderInformationTitle>{user.fullName}</ChatRightHeaderInformationTitle>
                    <ChatRightHeaderInformationSubtitle>{companyName}</ChatRightHeaderInformationSubtitle>
                </ChatRightHeaderInformation>
            </ChatRightHeader>
            {id && conversationId && (
                <ChatContentWrapper
                    handleScroll={handleScroll}
                    sellerId={Number(id)}
                    buyerData={{ fullName: user.fullName, avatar: user.avatar }}
                    sellerData={{ fullName: profile.fullName, avatar: profile.avatar }}
                    conversationId={conversationId}
                    page={page}
                />
            )}
            <ChatFooter>
                <Circle />
                <ChatFooterTitle>Bu Sohbet Hala Aktif {status === 23 && 'Değildir'}</ChatFooterTitle>
            </ChatFooter>
        </>
    )
}

const Chat = () => {
    const { id } = useParams()
    const [chats, setChats] = useState<UserProfileChat[]>([])
    const [conversationId, setConversationId] = useState<number | null>(null)
    const [user, setUser] = useState<any>()
    const [companyName, setCompanyName] = useState<string>()
    const [filterText, setFilterText] = useState<string>('')
    const [page, setPage] = useState<number>(0)
    const [status, setStatus] = useState<number>(0)
    const { data: chatsData } = useGetProfileChats({ queryKeys: { id, Keyword: filterText || null } })
    const [searchParams] = useSearchParams()

    useEffect(() => {
        const conversationId = searchParams.get('conversationId')
        const chat = chats.length && conversationId && chats.find((chat) => chat.conversationId === Number(conversationId))
        if (chat) {
            setPage(0)
            setConversationId(chat.conversationId)
            setUser(chat.user)
            setCompanyName(chat.user.companyName)
            setStatus(chat.status)
        }
    }, [chats, searchParams])

    useEffect(() => {
        if (chatsData?.data.result) setChats(chatsData.data.result)
    }, [chatsData])

    const increasePage = () => setPage((prevState) => prevState + 1)

    return (
        <ChatContainer>
            <ChatLeft>
                <SearchWrapper>
                    <ChatSearchInput
                        inputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        onChange={(e) => handleFilter(e, setFilterText)}
                        placeholder="Ara"
                        defaultValue={filterText}
                    />
                </SearchWrapper>
                <PaddingWrapper>
                    <ChatTitle>Sohbetler</ChatTitle>
                    {chats.length > 0 ? (
                        chats.map((chat) => (
                            <ChatUserSection
                                {...chat}
                                onClick={() => {
                                    if (chat?.conversationId === conversationId) return
                                    setPage(0)
                                    setConversationId(null)
                                    setTimeout(() => {
                                        setConversationId(chat?.conversationId)
                                    }, 100)
                                    setUser(chat.user)
                                    setCompanyName(chat.user.companyName)
                                    setStatus(chat.status)
                                }}
                                active={chat.conversationId === conversationId}
                            />
                        ))
                    ) : (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                                marginTop: '50%',
                                gap: ptr(12),
                            }}
                        >
                            <Typography color={colors.text.disabledTextLight} variant="body-normal-default">
                                Sohbet Bulunamadı
                            </Typography>
                            <CustomBadge style={{ width: ptr(100), height: ptr(100), background: colors.primary.primaryLightBg, borderRadius: '50%' }}>
                                <ChatBubbleOutline sx={{ color: colors.primary.primaryMain }} fontSize="large" />
                            </CustomBadge>
                        </Box>
                    )}
                </PaddingWrapper>
            </ChatLeft>
            <ChatRight>
                {conversationId && user && companyName && chats.length > 0 ? (
                    <ChatDetail status={status} conversationId={conversationId} user={user} companyName={companyName} page={page} increasePage={increasePage} />
                ) : (
                    chats.length < 1 && (
                        <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={ChatBg} width="100%" height="100%" alt="noConversation" />
                        </Box>
                    )
                )}
            </ChatRight>
        </ChatContainer>
    )
}

export default Chat
