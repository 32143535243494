import styled from '@emotion/styled'
import { ptr } from 'utils/helpers'
import { Box, Paper } from '@mui/material'
import { colors } from 'styles/color'

export const CustomCardPaper = styled(Paper)({
    display: 'flex',
    alignItems: 'center',
    padding: '14px 20px',
    justifyContent: 'flex-start',
    textAlign: 'center',
    width: '100%',
    height: ptr(100),
    background: colors.white.default,
    boxShadow: '0px 2px 10px rgba(76, 78, 100, 0.22)',
    borderRadius: '4px',
    gap: ptr(16),
})

export const CustomBoxColumn = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
})
