import { PagedResultDto } from '../../types'
import { ECampaingStatus, ESortingStatus } from '../../../pages/products/index.interface'
import { BaseCompany } from '../../../models/base/Company'

export interface SpecialOfferFilter {
    Filter?: string
    CompanyId?: number
    Status?: typeof ECampaingStatus
    OrderType?: typeof ESortingStatus
    SkipCount?: number
    MaxResultCount?: number
}

export interface SpecialOfferResponse extends PagedResultDto<SpecialOffer> {}

export interface SpecialOffer {
    productCode: string
    productName: string
    productDescription: string
    productPhotos: string
    productPrice: number
    productDiscountedPrice: number
    productStock: number
    isActive: boolean
    ownerIban: string
    id: number
    company: Pick<BaseCompany, 'id' | 'name' | 'city'>
    requestCount: number
}

export interface CreateSpecialOffer {
    companyId: number
    productName: string
    productDescription: string
    productPhotos: string
    productPrice: number
    productDiscountedPrice: number
    productStock: number
    vehicleType: string
    vehicleMake: string
    workCategory: string
    restrictedCompanies: {
        companyId: number
    }[]
    ownerIban: string
    ownerFullName: string
    ownerBankName: string
}

export interface UpdateSpecialOfferData {
    companyId?: number
    productName?: string
    productDescription?: string
    productPhotos?: string
    productPrice?: number
    productDiscountedPrice?: number
    productStock?: number
    ownerIban?: string
    ownerFullName?: string
    ownerBankName?: string
    vehicleType?: string
    vehicleMake?: string
    workCategory?: string
    restrictedCompanies?: { companyId: number }[]
}

export interface UpdateSpecialOffer {
    id: number
    data: UpdateSpecialOfferData
}

export enum UpdateSpecialOfferStatus {
    ACTIVE = 'active',
    DEACTIVE = 'de-active',
}

export interface UpdateSpecialOfferVariables extends Pick<SpecialOffer, 'id'> {
    status: UpdateSpecialOfferStatus
}

export interface GetSpecialOfferOrders extends Pick<SpecialOffer, 'id'> {}
export interface SpecialOfferDetailVariables extends Pick<SpecialOffer, 'id'> {}
