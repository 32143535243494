import React from 'react'

import { Box } from '@mui/material'
import { ptr } from 'utils/helpers'

import { CustomBadge } from '../index.styled'
import { CustomDemandDiv } from './index.styled'
import { colors } from 'styles/color'
import Typography from 'components/Typography/Typography'

export interface UserCountProps {
    name: string
    icon: React.ReactNode
    userCount?: number
}

const UserDemands: React.FunctionComponent<UserCountProps> = ({ name, icon, userCount }) => {
    return (
        <CustomDemandDiv>
            <Box
                sx={{
                    textAlign: 'start',
                    display: 'flex',
                    width: ptr(44),
                    height: ptr(44),
                }}
            >
                <CustomBadge style={{ background: colors.white.greyLight }}>{icon}</CustomBadge>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    textAlign: 'start',
                    flexDirection: 'column',
                }}
            >
                <Typography variant="h5-default">{userCount}</Typography>
                <Typography variant="body-smaller-default">{name}</Typography>
            </Box>
        </CustomDemandDiv>
    )
}

export default UserDemands
