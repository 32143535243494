import { FC } from 'react'
import { PriceTextProps } from './types'

const PriceText: FC<PriceTextProps> = ({ price }) => {
    const formattedOutput = new Intl.NumberFormat('tr-TR', {
        style: 'currency',
        currency: 'TRY',
        minimumFractionDigits: 2,
    })
    return <>{formattedOutput.format(Number(price)).replace('₺', '')} TL</>
}

export default PriceText
