import { FC, useEffect, useState } from 'react'

import { Box } from '@mui/material'
import Button from 'components/Buttons/Button/Button'
import dayjs from 'dayjs'
import { CustomTimeSelectionContainer } from './index.styled'
import { ptr } from 'utils/helpers'
import DatePicker from 'components/DateRangePicker/DateRangePicker'
import { dateFormat } from 'models/common/selectDate'

interface TimeSelectionProps {
    onSelectDate: (param: SelectDateProps) => void
    startDate: string
    endDate: string
    navigateStartDate: string
    navigateEndDate: string
    reset: () => void
}

export interface SelectDateProps {
    startDate: string
    endDate: string
}

const TimeSelection: FC<TimeSelectionProps> = ({ reset, navigateStartDate, navigateEndDate, onSelectDate, startDate, endDate }) => {
    const [selectedSpecificDate, setSelectedSpecificDate] = useState<boolean>()
    const [selected, setSelected] = useState<number>(navigateEndDate && navigateStartDate ? 9 : 1)

    useEffect(() => {
        if (navigateEndDate && navigateStartDate) {
            onSelectDate({
                startDate: navigateStartDate,
                endDate: navigateEndDate,
            })
        }
    }, [navigateEndDate, navigateStartDate, onSelectDate])

    return (
        <CustomTimeSelectionContainer>
            <Box>
                <Button
                    backgroundColor={selected === 1 ? '#F3F1F1' : '#fff'}
                    width={ptr(114)}
                    variant="outlined"
                    onClick={() => {
                        setSelected(1)
                        onSelectDate({
                            startDate: dayjs().format(dateFormat),
                            endDate: dayjs().format(dateFormat),
                        })
                        setSelectedSpecificDate(false)
                        reset()
                    }}
                >
                    Bugün
                </Button>
            </Box>
            <Box>
                <Button
                    backgroundColor={selected === 2 ? '#F3F1F1' : '#fff'}
                    width={ptr(114)}
                    variant="outlined"
                    onClick={() => {
                        setSelected(2)
                        onSelectDate({
                            startDate: dayjs().subtract(1, 'day').format(dateFormat),
                            endDate: dayjs().subtract(1, 'day').format(dateFormat),
                        })
                        setSelectedSpecificDate(false)
                        reset()
                    }}
                >
                    Dün
                </Button>
            </Box>
            <Box>
                <Button
                    backgroundColor={selected === 3 ? '#F3F1F1' : '#fff'}
                    width={ptr(114)}
                    variant="outlined"
                    onClick={() => {
                        setSelected(3)
                        onSelectDate({
                            startDate: dayjs().startOf('week').format(dateFormat),
                            endDate: dayjs().endOf('week').format(dateFormat),
                        })
                        setSelectedSpecificDate(false)
                        reset()
                    }}
                >
                    Bu Hafta
                </Button>
            </Box>
            <Box>
                <Button
                    backgroundColor={selected === 4 ? '#F3F1F1' : '#fff'}
                    width={ptr(114)}
                    variant="outlined"
                    onClick={() => {
                        setSelected(4)
                        onSelectDate({
                            startDate: dayjs().subtract(1, 'weeks').startOf('week').format(dateFormat),
                            endDate: dayjs().subtract(1, 'weeks').endOf('week').format(dateFormat),
                        })
                        setSelectedSpecificDate(false)
                        reset()
                    }}
                >
                    Geçen Hafta
                </Button>
            </Box>
            <Box>
                <Button
                    backgroundColor={selected === 5 ? '#F3F1F1' : '#fff'}
                    width={ptr(114)}
                    variant="outlined"
                    onClick={() => {
                        setSelected(5)
                        onSelectDate({
                            startDate: dayjs().startOf('month').format(dateFormat),
                            endDate: dayjs().endOf('month').format(dateFormat),
                        })
                        setSelectedSpecificDate(false)
                        reset()
                    }}
                >
                    Bu Ay
                </Button>
            </Box>
            <Box>
                <Button
                    backgroundColor={selected === 6 ? '#F3F1F1' : '#fff'}
                    width={ptr(114)}
                    variant="outlined"
                    onClick={() => {
                        setSelected(6)
                        onSelectDate({
                            startDate: dayjs().subtract(1, 'month').startOf('month').format(dateFormat),
                            endDate: dayjs().subtract(1, 'month').endOf('month').format(dateFormat),
                        })
                        setSelectedSpecificDate(false)
                        reset()
                    }}
                >
                    Geçen Ay
                </Button>
            </Box>
            <Box>
                <Button
                    backgroundColor={selected === 7 ? '#F3F1F1' : '#fff'}
                    width={ptr(114)}
                    variant="outlined"
                    onClick={() => {
                        setSelected(7)
                        onSelectDate({
                            startDate: dayjs().startOf('year').format(dateFormat),
                            endDate: dayjs().endOf('year').format(dateFormat),
                        })
                        setSelectedSpecificDate(false)
                        reset()
                    }}
                >
                    Bu Yıl
                </Button>
            </Box>
            <Box>
                <Button
                    backgroundColor={selected === 8 ? '#F3F1F1' : '#fff'}
                    width={ptr(114)}
                    variant="outlined"
                    onClick={() => {
                        setSelected(8)
                        onSelectDate({
                            startDate: dayjs().subtract(1, 'year').startOf('year').format(dateFormat),
                            endDate: dayjs().subtract(1, 'year').endOf('year').format(dateFormat),
                        })
                        setSelectedSpecificDate(false)
                        reset()
                    }}
                >
                    Geçen Yıl
                </Button>
            </Box>
            <Box>
                {!selectedSpecificDate && (
                    <Button
                        backgroundColor={selected === 9 ? '#F3F1F1' : '#fff'}
                        width={ptr(114)}
                        variant="outlined"
                        onClick={() => {
                            setSelected(9)
                            setSelectedSpecificDate(true)
                            reset()
                        }}
                    >
                        Özel Tarih
                    </Button>
                )}
                {selectedSpecificDate && (
                    <DatePicker
                        size
                        height={ptr(32)}
                        startDate={startDate}
                        setEndDate={() => setSelectedSpecificDate(false)}
                        setStartDate={() => setSelectedSpecificDate(false)}
                        endDate={endDate}
                        onSelectDate={onSelectDate}
                        placeholder={''}
                    />
                )}
            </Box>
        </CustomTimeSelectionContainer>
    )
}

export default TimeSelection
