import { RequestParams, Service } from 'services/types'
import { Request } from 'services/_base'
import {
    GET_ALL_SPECIAL_OFFER_CHART,
    GET_ALL_TOP_BUYER_DEMAND,
    GET_ALL_TOP_BUYER_SPECIAL_OFFER,
    GET_ALL_TOP_CATEGORY_DEMAND,
    GET_ALL_TOP_CATEGORY_SPECIAL_OFFER,
    GET_ALL_TOP_SELLER_DEMAND,
    GET_ALL_TOP_SPECIAL_OFFER,
    GET_ALL_USER_CHART,
    GET_TOP_CATEGORY_DEMAND_OFFER,
    GET_TOP_CATEGORY_SPECIAL_OFFER,
} from './constants'
import {
    SpecialOfferChartVariables,
    TopBuyerDemandFilter,
    TopBuyerSpecialOfferVariables,
    TopCategoryDemandFilter,
    TopCategorySpecialOffer,
    TopSellerDemandFilter,
    TopSpecialOfferFilter,
    UserChartVariables,
} from './types'

export const getAllTopSpecialOffer: Service<RequestParams<TopSpecialOfferFilter>> = ({ queryKey }) => {
    const { params } = queryKey[1]
    return Request.get(GET_ALL_TOP_SPECIAL_OFFER, {
        filter: params.filter,
        maxResultCount: params.maxResultCount,
        skipCount: params.skipCount,
        workCategoryName: params.workCategoryName,
        workSubCategoryName: params.workSubCategoryName,
    })
}
export const getAllTopCategoryDemand: Service<RequestParams<TopCategoryDemandFilter>> = ({ queryKey }) => {
    const { params } = queryKey[1]
    return Request.get(GET_ALL_TOP_CATEGORY_DEMAND, {
        startDate: params.startDate,
        endDate: params.endDate,
        maxResultCount: params.maxResultCount,
        skipCount: params.skipCount,
        workCategoryName: params.workCategoryName,
        vehicleTypeName: params.vehicleTypeName,
    })
}
export const getAllTopSellerDemand: Service<RequestParams<TopSellerDemandFilter>> = ({ queryKey }) => {
    const { params } = queryKey[1]
    return Request.get(GET_ALL_TOP_SELLER_DEMAND, {
        startDate: params.startDate,
        endDate: params.endDate,
        filter: params.filter,
        maxResultCount: params.maxResultCount,
        skipCount: params.skipCount,
    })
}
export const getAllTopBuyerDemand: Service<RequestParams<TopBuyerDemandFilter>> = ({ queryKey }) => {
    const { params } = queryKey[1]
    return Request.get(GET_ALL_TOP_BUYER_DEMAND, {
        startDate: params.startDate,
        endDate: params.endDate,
        filter: params.filter,
        maxResultCount: params.maxResultCount,
        skipCount: params.skipCount,
    })
}

export const getAllTopBuyerSpecialOffer: Service<RequestParams<TopBuyerSpecialOfferVariables>> = ({ queryKey }) => {
    const { params } = queryKey[1]
    return Request.get(GET_ALL_TOP_BUYER_SPECIAL_OFFER, {
        startDate: params.startDate,
        endDate: params.endDate,
        filter: params.filter,
        maxResultCount: params.maxResultCount,
        skipCount: params.skipCount,
    })
}

export const getAllTopCategorySpecialOffer: Service<RequestParams<TopCategorySpecialOffer>> = ({ queryKey }) => {
    const { params } = queryKey[1]
    return Request.get(GET_ALL_TOP_CATEGORY_SPECIAL_OFFER, {
        startDate: params.startDate,
        endDate: params.endDate,
        maxResultCount: params.maxResultCount,
        skipCount: params.skipCount,
        workCategoryName: params.workCategoryName,
        workSubCategoryName: params.workSubCategoryName,
    })
}

export const getAllSpecialOfferChart: Service<RequestParams<SpecialOfferChartVariables>> = ({ queryKey }) => {
    const { params } = queryKey[1]
    return Request.get(GET_ALL_SPECIAL_OFFER_CHART, {
        startDate: params.startDate,
        endDate: params.endDate,
    })
}

export const getAllUserChart: Service<RequestParams<UserChartVariables>> = ({ queryKey }) => {
    const { params } = queryKey[1]
    return Request.get(GET_ALL_USER_CHART, {
        startDate: params.startDate,
        endDate: params.endDate,
    })
}

export const getTopDemandOfferChart: Service<RequestParams<UserChartVariables>> = ({ queryKey }) => {
    const { params } = queryKey[1]
    return Request.get(GET_TOP_CATEGORY_DEMAND_OFFER, {
        startDate: params.startDate,
        endDate: params.endDate,
    })
}

export const getTopSpecialOfferChart: Service<RequestParams<UserChartVariables>> = ({ queryKey }) => {
    const { params } = queryKey[1]
    return Request.get(GET_TOP_CATEGORY_SPECIAL_OFFER, {
        startDate: params.startDate,
        endDate: params.endDate,
    })
}
