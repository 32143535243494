import React, { ReactElement } from 'react'
import { TabPanelProps, TabsProps } from './types'
import { Box, Typography } from '@mui/material'
import { CustomTab, CustomTabs } from './Tabs.styled'

const TabPanel: React.FunctionComponent<TabPanelProps> = ({ value, children, index, ...other }) => {
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box sx={{ paddingY: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

const Tabs: React.FunctionComponent<TabsProps> = ({ details, onChange, value, withIcon, iconPosition }) => {
    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <CustomTabs value={value} onChange={onChange} aria-label="basic tabs example">
                    {details?.map((child, index: number) => (
                        <CustomTab label={child.name} iconPosition={iconPosition} icon={withIcon ? (child?.icon as ReactElement) : undefined} key={index} />
                    ))}
                </CustomTabs>
            </Box>
            {details.map((child, index) => (
                <TabPanel value={value} index={index} key={index}>
                    {child.children}
                </TabPanel>
            ))}
        </Box>
    )
}

export default Tabs
