import { styled } from '@mui/material'
import { ErrorMessage as CustomErrorMessage } from 'formik'
import { colors } from 'styles/color'
import BaseCurrencyInput from 'react-currency-input-field'
import { ptr } from '../../../utils/helpers'

export const StepperContainer = styled('div')(() => ({
    backgroundColor: colors.white.default,
    display: 'flex',
    padding: '24px',
    margin: '24px',
    boxShadow: '0px 2px 10px rgba(76, 78, 100, 0.22)',
    gap: '1.5rem',
}))
export const StepperForm = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
}))
export const StepperContent = styled('div')(() => ({}))

export const FormContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
}))
export const CoInputContent = styled('div')(() => ({
    display: 'flex',
    alignItems: 'flex-start',
    gap: '20px',
}))
export const ErrorMessageControler = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
}))
export const ButtonsSide = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    maxWidth: '100%',
    marginTop: '1rem',
}))

export const ErrorMessage = styled(CustomErrorMessage)(() => ({
    color: colors.text.secondaryTextLight,
    fontSize: '14px',
}))

export const CurrencyInput = styled(BaseCurrencyInput)({
    height: ptr(55),
    padding: `${ptr(0)} ${ptr(8)}`,
    border: `1px solid ${colors.other.outlinedBorder}`,
    outline: 'none',
    borderRadius: ptr(4),
    '&:focus': {
        border: `2px solid ${colors.primary.primaryMain}`,
    },
    '&::placeholder': {
        color: '#3A354161',
    },
    '&::-ms-input-placeholder': {
        color: '#3A354161',
    },
})
