import { Service } from 'services/types'
import { Request } from 'services/_base'
import { GET_USER_RATES, PUT_USER_RATE_ACCEPT, PUT_USER_RATE_REJECT, USER_RATE_DELETE } from './contants'
import { GetUserRatesVariables, UserRatesVariables } from './types'

export const getAllUserRates: Service<GetUserRatesVariables> = ({ queryKey }) => {
    const { params } = queryKey[1]
    return Request.get(GET_USER_RATES(), {
        maxResultCount: params.maxResultCount,
        skipCount: params.skipCount,
        filter: params.filter,
        status: params.status,
        startDate: params.startDate,
        endDate: params.endDate,
    })
}

export const acceptUserRate: Service<UserRatesVariables> = ({ id }) => {
    return Request.put(PUT_USER_RATE_ACCEPT(id), { id }, {})
}

export const rejectUserRate: Service<UserRatesVariables> = ({ id }) => {
    return Request.put(PUT_USER_RATE_REJECT(id), { id }, {})
}

export const deleteUserRate: Service<UserRatesVariables> = ({ id }) => {
    return Request.delete(USER_RATE_DELETE(id), {})
}
