import DashboardLayout from 'layouts/Dashboard/DashboardLayout'
import React, { useEffect, useState } from 'react'
import {
    CustomBadge,
    CustomExportBox,
    CustomExportBoxDiv,
    CustomFilteredBox,
    CustomHeadBox,
    CustomHeadDiv,
    CustomMenuBox,
    CustomMenuBoxTypo,
    CustomTableDiv,
    CustomUserRateContainer,
    CustomUserRateTypography,
} from './index.styled'
import Typography from 'components/Typography/Typography'
import { Box } from '@mui/material'
import AutocompleteContainer from 'components/Input/AutoComplete/Autocomplete'
import DatePicker from 'components/DateRangePicker/DateRangePicker'
import Button from 'components/Buttons/Button/Button'
import ExportDropdown from 'components/ExportDropdown'
import Spinner from 'components/Spinner'
import Table from 'components/Table'
import { CheckCircle, Delete, IosShare, RemoveCircle, RemoveCircleOutline, RemoveRedEyeOutlined, WatchLater } from '@mui/icons-material'
import { endDateConverter, ptr, startDateConverter } from 'utils/helpers'
import { useAcceptRate, useDeleteRate, useGetAllUserRates, useRejectRate } from 'utils/hooks/queries/UserRates'
import { UserRateModel } from 'services/be-api/user-rate/types'
import { useLocation, useNavigate } from 'react-router-dom'
import { TableBodyRowData } from 'components/Table/types'
import { GridColDef } from '@mui/x-data-grid'
import dayjs from 'dayjs'
import { colors } from 'styles/color'
import Modal from 'components/Modal/Modal'
import SearchInput from '../../components/Input/SearchInput/SearchInput'

export const userRatesHead: GridColDef[] = [
    { field: 'userName', headerName: 'YORUMLAYAN', width: 160 },
    { field: 'seller', headerName: 'SATICI', width: 160 },
    { field: 'cargo', headerName: 'KARGO', width: 20 },
    { field: 'quality', headerName: 'PARÇA KALİTESİ', width: 20 },
    { field: 'communication', headerName: 'İLETİŞİM', width: 20 },
    { field: 'total', headerName: 'TOTAL', width: 20 },
    { field: 'comment', headerName: 'YORUM', width: 500 },
    { field: 'creationTime', headerName: 'TARİH', width: 80 },
]

export const statusOption = [
    {
        value: 'Beklemede',
        label: 'Beklemede',
    },
    {
        value: 'Onaylandi',
        label: 'Onaylandı',
    },
    {
        value: 'Reddedildi',
        label: 'Reddedildi',
    },
]

export const exportOptions = [
    {
        key: 'YORUMLAYAN',
        label: 'YORUMLAYAN',
        onRender: (item: string, element: UserRateModel) => {
            return `${element?.revievedUser.name} ${element?.revievedUser.surname}`
        },
    },
    {
        key: 'SATICI',
        label: 'SATICI',
        onRender: (item: string, element: UserRateModel) => {
            return `${element?.user?.name} ${element?.user?.surname}`
        },
    },
    {
        key: 'KARGO',
        label: 'KARGO',
        onRender: (item: string, element: UserRateModel) => {
            return `${element?.shipment}`
        },
    },
    {
        key: 'PARÇA KALITESI',
        label: 'PARÇA KALITESI',
        onRender: (item: string, element: UserRateModel) => {
            return `${element?.quality}`
        },
    },
    {
        key: 'ILETISIM',
        label: 'ILETISIM',
        onRender: (item: string, element: UserRateModel) => {
            return `${element?.communication}`
        },
    },
    {
        key: 'TOTAL',
        label: 'TOTAL',
        onRender: (item: string, element: UserRateModel) => {
            return `${element?.reviewAvg}`
        },
    },
    {
        key: 'YORUM',
        label: 'YORUM',
        onRender: (item: string, element: UserRateModel) => {
            return `${element?.comment}`
        },
    },
    {
        key: 'TARIH',
        label: 'TARIH',
        onRender: (item: string, element: UserRateModel) => {
            return `${dayjs(element?.creationTime).format('DD.MM.YYYY')}`
        },
    },
]
const UserRate = () => {
    const location = useLocation()
    const selectedDate = location.state
    const [userRates, setUserRates] = useState<Array<UserRateModel>>([])
    const [exportArray, setExportArray] = useState<Array<UserRateModel>>([])
    const [acceptModalOpen, setAcceptModalOpen] = useState(false)
    const [rejectModalOpen, setRejectModalOpen] = useState(false)
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [acceptId, setAcceptId] = useState(0)
    const [rejectId, setRejectId] = useState(0)
    const [deleteId, setDeleteId] = useState(0)
    const navigate = useNavigate()
    const [statusOptions, setStatusOptions] = useState<{ value: string | number; name: string | number }[]>([])
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [exportToggle, setExportToggle] = useState(false)
    const [filterText, setFilterText] = useState('')
    const [currentPage, setCurrentPage] = useState(0)
    const [rowPerPage, setRowPerPage] = useState(10)
    const {
        data: userRatesData,
        isSuccess: userRatesDataSuccess,
        isLoading: userRatesDataLoading,
        refetch,
    } = useGetAllUserRates({
        queryKeys: {
            maxResultCount: rowPerPage,
            skipCount: currentPage * rowPerPage || null,
            filter: filterText || null,
            status: statusOptions || null,
            startDate: startDate.length ? startDateConverter(startDate) : null,
            endDate: endDate.length ? endDateConverter(endDate) : null,
        },
    })

    const {
        data: userRateDataExport,
        isSuccess: userRateDataSuccessExport,
        refetch: userRateExportRefetch,
    } = useGetAllUserRates({
        queryKeys: {
            maxResultCount: 1000,
            skipCount: 0,
            filter: filterText,
            status: statusOptions,
            startDate: startDate.length ? startDateConverter(startDate) : null,
            endDate: endDate.length ? endDateConverter(endDate) : null,
        },
        enabled: false,
    })

    const { mutate: acceptRate, isSuccess: acceptRateSuccess } = useAcceptRate()
    const { mutate: rejectRate, isSuccess: rejectRateSuccess } = useRejectRate()
    const { mutate: deleteRate, isSuccess: deleteRateSuccess } = useDeleteRate()

    const handleAcceptModalConfirm = (id: number) => {
        if (userRatesData?.data.result.items) {
            acceptRate({ id: id })
        }
        setAcceptModalOpen(false)
    }

    const handleRejectModalConfirm = (id: number) => {
        if (userRatesData?.data.result.items) {
            rejectRate({ id: id })
        }
        setRejectModalOpen(false)
    }

    const handleDeleteModalConfirm = (id: number) => {
        if (userRatesData?.data.result.items) {
            deleteRate({ id: id })
        }
        setDeleteModalOpen(false)
    }

    useEffect(() => {
        if (selectedDate) {
            setStartDate(selectedDate.startDate)
            setEndDate(selectedDate.endDate)
        }
    }, [selectedDate])

    const badgeColor = (value: number) => {
        if (value >= 0 && value <= 2) {
            return colors.badge.score5
        } else if (value > 2 && value <= 4) {
            return colors.badge.score4
        } else if (value > 4 && value <= 6) {
            return colors.badge.score3
        } else if (value > 6 && value <= 8) {
            return colors.badge.score2
        } else {
            return colors.badge.score1
        }
    }

    useEffect(() => {
        if (userRateDataSuccessExport && userRateDataExport?.data.result) {
            setExportArray(userRateDataExport.data.result.items)
        }
    }, [userRateDataSuccessExport, userRateDataExport])

    useEffect(() => {
        if (userRatesDataSuccess && userRatesData?.data.result) {
            refetch()
            setUserRates(userRatesData?.data.result.items)
        }
    }, [refetch, userRatesData?.data.result, userRatesDataSuccess, acceptRateSuccess, rejectRateSuccess, deleteRateSuccess])

    const handleTo = (id: number, role: string) => {
        navigate(`/${role}/${id}`)
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowPerPage(parseInt(event.target.value, 10))
        setCurrentPage(0)
    }

    const onPageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setCurrentPage(newPage)
    }

    const handleSelectDate = (sDate: string, eDate: string) => {
        setStartDate(sDate)
        setEndDate(eDate)
    }

    const handleExport = (exportToggle: boolean, set: React.Dispatch<React.SetStateAction<boolean>>) => {
        set(!exportToggle)
        userRateExportRefetch()
    }

    const rowData: TableBodyRowData[] = userRates.map((p) => {
        return {
            userName: (
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: ptr(20) }}>
                    <Box>
                        {p.status === 'Beklemede' && <WatchLater style={{ backgroundColor: '#ffffff', color: '#FFBB3380' }} />}
                        {p.status === 'Onaylandı' && <CheckCircle style={{ backgroundColor: '#ffffff', color: '#72E12880' }} />}
                        {p.status === 'Reddedildi' && <RemoveCircle style={{ backgroundColor: '#ffffff', color: '#FF4D4980' }} />}
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="body-normal-semibold" onClick={() => handleTo(p.revievedUser.id, 'users')}>
                            {p.revievedUser.name} {p.revievedUser.surname}
                        </Typography>
                        <Typography variant="body-smaller-default">{p.revievedUser.company.name}</Typography>
                    </Box>
                </Box>
            ),

            seller: (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="body-normal-semibold" onClick={() => handleTo(p.user.id, 'users')}>
                        {p.user.name} {p.user.surname}
                    </Typography>
                    <Typography variant="body-smaller-default">{p.user.company.name}</Typography>
                </Box>
            ),
            cargo: (
                <CustomHeadBox>
                    <Typography variant="body-small-default">{p.shipment}</Typography>
                </CustomHeadBox>
            ),
            quality: (
                <CustomHeadBox>
                    <Typography width={'100%'} variant="body-small-default">
                        {p.quality}
                    </Typography>
                </CustomHeadBox>
            ),
            communication: (
                <CustomHeadBox>
                    <Typography width={'100%'} variant="body-small-default">
                        {p.communication}
                    </Typography>
                </CustomHeadBox>
            ),
            total: (
                <CustomBadge style={{ backgroundColor: badgeColor(Number(p.reviewAvg.replace(',', '.'))) }}>
                    <Typography color={colors.white.default} width={'100%'} variant="body-small-medium">
                        {p.reviewAvg}
                    </Typography>
                </CustomBadge>
            ),
            comment: (
                <Box sx={{ wordBreak: 'break-word' }}>
                    <Typography width={'100%'} variant="body-small-default">
                        {p.comment}
                    </Typography>
                </Box>
            ),
            creationTime: (
                <Box>
                    <Typography width={'100%'} variant="body-small-default">
                        {dayjs(p.creationTime).format('DD.MM.YYYY HH:mm')}
                    </Typography>
                </Box>
            ),
            menu: (
                <CustomMenuBox>
                    <CustomUserRateTypography>
                        <CustomMenuBoxTypo>
                            <RemoveRedEyeOutlined style={{ backgroundColor: '#ffffff', color: colors.action.actionActiveLight }} />
                            <Typography onClick={() => navigate(`/requests/${p.demandId}`)} variant="body-small-default">
                                Talebi Görüntüle
                            </Typography>
                        </CustomMenuBoxTypo>
                        {p.isPublished ? (
                            <CustomMenuBoxTypo>
                                <RemoveCircle style={{ backgroundColor: '#ffffff', color: '#FF4D49' }} />
                                <Typography
                                    onClick={() => {
                                        setRejectModalOpen(true)
                                        setRejectId(p.id)
                                    }}
                                    variant="body-small-default"
                                >
                                    Yayından Kaldır
                                </Typography>
                            </CustomMenuBoxTypo>
                        ) : (
                            <CustomMenuBoxTypo>
                                <CheckCircle style={{ backgroundColor: '#ffffff', color: '#72E128' }} />
                                <Typography
                                    onClick={() => {
                                        setAcceptModalOpen(true)
                                        setAcceptId(p.id)
                                    }}
                                    variant="body-small-default"
                                >
                                    Yorumu Yayınla
                                </Typography>
                            </CustomMenuBoxTypo>
                        )}
                        {p.isPublished === null && (
                            <CustomMenuBoxTypo>
                                <RemoveCircleOutline style={{ backgroundColor: '#ffffff', color: colors.error.errorMain }} />
                                <Typography
                                    onClick={() => {
                                        setRejectModalOpen(true)
                                        setRejectId(p.id)
                                    }}
                                    variant="body-small-default"
                                >
                                    Yayınlama
                                </Typography>
                            </CustomMenuBoxTypo>
                        )}
                        <CustomMenuBoxTypo>
                            <Delete style={{ backgroundColor: '#ffffff', color: '#FF4D49' }} />
                            <Typography
                                onClick={() => {
                                    setDeleteModalOpen(true)
                                    setDeleteId(p.id)
                                }}
                                variant="body-small-default"
                            >
                                Sil
                            </Typography>
                        </CustomMenuBoxTypo>
                    </CustomUserRateTypography>
                </CustomMenuBox>
            ),
        }
    })
    return (
        <DashboardLayout>
            <CustomUserRateContainer>
                <CustomTableDiv>
                    <CustomHeadDiv style={{ height: ptr(81) }}>
                        <CustomExportBox>
                            <CustomExportBoxDiv>
                                <Button
                                    startIcon={<IosShare />}
                                    onClick={() => handleExport(exportToggle, setExportToggle)}
                                    variant="outlined"
                                    colorsx={colors.secondary.secondaryAlternativeLight}
                                    borderColor={colors.secondary.secondaryAlternativeLight}
                                    padding={ptr(16)}
                                >
                                    <ExportDropdown<UserRateModel>
                                        array={exportArray}
                                        fileName={'export'}
                                        title={''}
                                        sheetName={''}
                                        headers={exportOptions}
                                        open={exportToggle}
                                        buttonHeader={'Export'}
                                        isSuccess={userRateDataSuccessExport}
                                    />
                                </Button>
                                <Typography variant="body-smaller-default">Maksimum 1000 veri</Typography>
                            </CustomExportBoxDiv>
                        </CustomExportBox>
                        <CustomFilteredBox>
                            <Box sx={{ width: '30%' }}>
                                <SearchInput
                                    fullHeight
                                    fullWidth
                                    value={filterText}
                                    onChange={(val) => {
                                        setFilterText(val)
                                        if (currentPage !== 0) setCurrentPage(0)
                                    }}
                                    placeholder="Kullanıcı Yorumu Ara"
                                />
                            </Box>
                            <Box sx={{ width: '30%' }}>
                                <AutocompleteContainer width={'100%'} label="Durum" onChange={(e) => setStatusOptions(e as any)} options={statusOption} />
                            </Box>
                            <Box sx={{ width: '30%' }}>
                                <DatePicker
                                    setEndDate={setEndDate}
                                    setStartDate={setStartDate}
                                    startDate={startDate}
                                    endDate={endDate}
                                    value={`${startDate} - ${endDate}`}
                                    width={'100%'}
                                    height={ptr(53)}
                                    placeholder={'Tarihe Göre Filtrele'}
                                    onSelectDate={({ startDate, endDate }) => handleSelectDate(startDate, endDate)}
                                />
                            </Box>
                        </CustomFilteredBox>
                    </CustomHeadDiv>

                    <Spinner open={userRatesDataLoading} />
                    <Table
                        count={userRatesData?.data.result.totalCount}
                        isLoading={userRatesDataLoading}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        onPageChange={onPageChange}
                        head={userRatesHead}
                        result={rowPerPage}
                        rowsData={rowData}
                        isClickable={false}
                        page={currentPage}
                        menu={true}
                    ></Table>
                </CustomTableDiv>
            </CustomUserRateContainer>
            <Modal
                open={acceptModalOpen}
                onClose={() => setAcceptModalOpen(false)}
                onConfirm={() => {
                    handleAcceptModalConfirm(acceptId)
                }}
                header="Yorumu Yayınla"
                subheader={'Yorumu yayınlamak istediğinize emin misiniz?'}
                type="publish"
            />
            <Modal
                open={rejectModalOpen}
                onClose={() => setRejectModalOpen(false)}
                onConfirm={() => {
                    handleRejectModalConfirm(rejectId)
                }}
                header="Yorumu Yayından Kaldır"
                subheader={'Yorumu yayından kaldırmak istediğinize emin misiniz?'}
                type="remove"
            />
            <Modal
                open={deleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                onConfirm={() => {
                    handleDeleteModalConfirm(deleteId)
                }}
                header="Yorumu Sil"
                subheader={'Yorumu silmek istediğinize emin misiniz?'}
                type="delete"
            />
        </DashboardLayout>
    )
}

export default UserRate
