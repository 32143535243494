import React, { FC, useEffect } from 'react'

import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'

import { CardsContainer, CardContainer, CardIcon, CustomRadioGroup } from './index.styled'
import Typography from 'components/Typography/Typography'
import { colors } from 'styles/color'
import Radio from 'components/Buttons/Radio/Radio'
import { CardType } from './type'

interface Props {
    handleChange: any
    val: string
    setRadioValue: React.Dispatch<React.SetStateAction<string>>
    radioValue: string
}

const CheckboxCard: FC<Props> = ({ setRadioValue, radioValue, handleChange, val }) => {
    useEffect(() => {
        setRadioValue(val)
    }, [val, setRadioValue])

    const userType: CardType[] = [
        { title: 'ALICI (USTA)', icon: <ShoppingBasketIcon />, name: 'role', value: 'alıcı', text: 'Uygulama içerisinde sadece talep oluşturma yetkisi vardır.' },
        { title: 'Satıcı (Çıkmacı)', icon: <LocalOfferIcon />, name: 'role', value: 'satıcı', text: 'Uygulama içerisinde oluşturulan taleplere sadece teklif verme yetkisi vardır.' },
    ]
    const radioValueHandler = (e: string) => {
        setRadioValue(e)
    }
    return (
        <CustomRadioGroup>
            <CardsContainer>
                {userType.map((card) => (
                    <CardContainer key={card.title} sx={{ borderColor: radioValue === card.value ? colors.primary.primaryMain : '' }}>
                        <CardIcon sx={{ color: radioValue === card.value ? colors.primary.primaryMain : '' }}>{card.icon}</CardIcon>
                        <Typography color={colors.text.primaryTextLight} variant="body-normal-semibold">
                            {card.title}
                        </Typography>
                        <Typography color={colors.text.secondaryTextLight} variant="body-small-default">
                            {card.text}
                        </Typography>
                        <Radio
                            onChange={(e, changeEvent) => {
                                radioValueHandler(e)
                                handleChange(changeEvent)
                            }}
                            name={card.name}
                            value={card.value}
                            checked={card.value === val}
                        />
                    </CardContainer>
                ))}
            </CardsContainer>
        </CustomRadioGroup>
    )
}

export default CheckboxCard
