import { Chart } from 'components/Chart/Chart'
import { EChartsOption } from 'echarts'
import { OfferStatusProps } from 'pages/mainPage/cardWithIcon/userAct/types'
import React from 'react'

const OfferChart: React.FunctionComponent<OfferStatusProps> = ({ offerStatus }) => {
    const Option: EChartsOption = {
        tooltip: {
            trigger: 'item',
        },
        legend: {
            top: ' 85%',
            left: 'center',

            textStyle: {
                color: 'black',
                fontSize: '12px',
                width: '100%',
                padding: 10,
            },
        },
        series: [
            {
                name: 'Teklif Durumu Dağılımları',
                type: 'pie',
                radius: ['40%', '60%'],
                width: '100%',
                top: '-70',
                left: 'center',
                height: '100%',
                labelLine: {
                    show: false,
                },
                data: [
                    { value: Number(offerStatus?.acceptedPercent.replace(',', '.')), name: 'Kabul Edilen' },
                    { value: Number(offerStatus?.badOfferPercent.replace(',', '.')), name: 'Hatalı' },
                    { value: Number(offerStatus?.canceledByBuyerPercent.replace(',', '.')), name: 'İptal Edilen (Alıcı)' },
                    { value: Number(offerStatus?.canceledBySellerPercent.replace(',', '.')), name: 'İptal Edilen (Satıcı)' },
                    { value: Number(offerStatus?.cancelledByAdminPercent.replace(',', '.')), name: 'İptal Edilen (Yönetici)' },
                    { value: Number(offerStatus?.expiredShopPercent.replace(',', '.')), name: 'Süresi Dolanlar' },
                    { value: Number(offerStatus?.inNegotiationPercent.replace(',', '.')), name: 'Görüşmede' },
                    { value: Number(offerStatus?.notAnsweredPercent.replace(',', '.')), name: 'Yanıtlanmayan' },
                    { value: Number(offerStatus?.notInterestedPercent.replace(',', '.')), name: 'İlginilmeyen' },
                    { value: Number(offerStatus?.rejectedPercent.replace(',', '.')), name: 'Reddedilen' },
                    { value: Number(offerStatus?.revokeOfferPercent.replace(',', '.')), name: 'Teklifi iptal et' },
                ],
                label: {
                    show: true,
                    formatter: function (params) {
                        if (params.value !== 0) {
                            return params.name
                        } else {
                            return ''
                        }
                    },
                },
            },
        ],
    }
    return <Chart option={Option} />
}

export default OfferChart
