import React, { useEffect } from 'react'

import { useGetUser } from '../../utils/hooks/queries/Users'
import useUserStore from 'context/user-store'
import useAuthStore from 'context/auth-store'

import { AuthProviderProps } from './types'
import { useNavigate } from 'react-router-dom'

const Auth: React.FunctionComponent<AuthProviderProps> = ({ children }) => {
    const setUser = useUserStore((state) => state.setUser)
    const accessToken = useAuthStore((state) => state.accessToken)
    const { refetch } = useGetUser({ queryKeys: {}, enabled: false })
    const navigate = useNavigate()

    const authorization = () => {
        refetch()
            .then((res: any) => {
                const user = res.data?.data.result?.user
                if (user) {
                    setUser(user)
                } else navigate('/login')
            })
            .catch(() => '')
    }

    useEffect(() => {
        if (accessToken) {
            authorization()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessToken])

    return <div>{children}</div>
}

export default Auth
