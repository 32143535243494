import React, { useEffect, useState } from 'react'

import { useGetAllTopSellerDemand } from 'utils/hooks/queries/Dashboard/useDashboard'

import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs'
import DatePicker from 'components/DateRangePicker/DateRangePicker'
import Button from 'components/Buttons/Button/Button'
import DashboardLayout from 'layouts/Dashboard/DashboardLayout'
import ExportDropdown from 'components/ExportDropdown'
import Spinner from 'components/Spinner'
import Typography from 'components/Typography/Typography'
import Table from 'components/Table/Table'
import { TableBodyRowData } from 'components/Table/types'
import { endDateConverter, ptr, startDateConverter } from 'utils/helpers'
import { CustomExportBox, CustomFilterDiv, CustomHeadDiv, CustomTableDiv, NameCompanynameDiv, PopularityContainer } from '../index.styled'

import { Box } from '@mui/material'
import { breadcrumbLinks, topSellerExportOptions, topSellerHead } from './constants'
import { SellerDemand } from 'services/be-api/dashboard/types'
import { IosShare } from '@mui/icons-material'
import { colors } from 'styles/color'
import { useLocation } from 'react-router-dom'
import SearchInput from '../../../components/Input/SearchInput/SearchInput'

const TopSeller = () => {
    const location = useLocation()
    const selectedDate = location.state
    const [topSellers, setTopSellers] = useState<SellerDemand[]>([])
    const [exportArray, setExportArray] = useState<SellerDemand[]>([])

    const [exportToggle, setExportToggle] = useState(false)
    //filtre
    const [currentPage, setCurrentPage] = useState(0)
    const [rowPerPage, setRowPerPage] = useState(10)
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [filterText, setFilterText] = useState('')

    const {
        data: topSellerData,
        isSuccess: isTopSellerSuccess,
        isLoading: isTopSellerLoading,
    } = useGetAllTopSellerDemand({
        queryKeys: {
            startDate: startDate.length ? startDateConverter(startDate) : null,
            endDate: endDate.length ? endDateConverter(endDate) : null,
            skipCount: currentPage * rowPerPage || null,
            maxResultCount: rowPerPage,
            filter: filterText,
        },
    })
    const {
        data: topSellerDataExport,
        isSuccess: isTopSellerSuccessExport,
        refetch: isTopSellerRefetchExport,
    } = useGetAllTopSellerDemand({
        queryKeys: {
            maxResultCount: 1000,
            startDate: startDate.length ? startDateConverter(startDate) : null,
            endDate: endDate.length ? endDateConverter(endDate) : null,
            skipCount: currentPage * rowPerPage || null,
            filter: filterText,
        },
        enabled: false,
    })

    useEffect(() => {
        if (selectedDate) {
            setStartDate(selectedDate.startDate)
            setEndDate(selectedDate.endDate)
        }
    }, [selectedDate])

    useEffect(() => {
        if (isTopSellerSuccessExport && topSellerDataExport?.data.result) {
            setExportArray(topSellerDataExport.data.result.items)
        }
    }, [topSellerDataExport, isTopSellerSuccessExport])

    useEffect(() => {
        if (isTopSellerSuccess && topSellerData?.data) {
            setTopSellers(topSellerData?.data.result.items)
        }
    }, [isTopSellerSuccess, topSellerData])

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowPerPage(parseInt(event.target.value, 10))
        setCurrentPage(0)
    }
    const onPageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setCurrentPage(newPage)
    }

    const handleSelectDate = (sDate: string, eDate: string) => {
        setStartDate(sDate)
        setEndDate(eDate)
    }

    const handleExport = (exportToggle: boolean, set: React.Dispatch<React.SetStateAction<boolean>>) => {
        set(!exportToggle)
        isTopSellerRefetchExport()
    }

    const rowData: TableBodyRowData[] = topSellers.map((data) => {
        return {
            seller: (
                <NameCompanynameDiv>
                    <Typography variant="body-normal-semibold"> {data.fullName}</Typography>
                    <Typography variant="body-small-default"> {data.companyName}</Typography>
                </NameCompanynameDiv>
            ),
            totalSales: <Typography> {data.totalRequestCount}</Typography>,
            totalSalesAmount: <Typography> {`${data.totalPurchasePrice} TL`}</Typography>,
        }
    })
    return (
        <DashboardLayout>
            <PopularityContainer>
                <Breadcrumbs links={breadcrumbLinks} />

                <CustomTableDiv>
                    <CustomHeadDiv style={{ height: ptr(81) }}>
                        <CustomExportBox>
                            <Button
                                startIcon={<IosShare />}
                                onClick={() => handleExport(exportToggle, setExportToggle)}
                                variant="outlined"
                                colorsx={colors.secondary.secondaryAlternativeLight}
                                borderColor={colors.secondary.secondaryAlternativeLight}
                                padding={ptr(16)}
                            >
                                <ExportDropdown<SellerDemand>
                                    array={exportArray}
                                    fileName={'Top Satıcı Detayı Tablo'}
                                    title={''}
                                    sheetName={''}
                                    headers={topSellerExportOptions}
                                    open={exportToggle}
                                    buttonHeader={'Export'}
                                    isSuccess={isTopSellerSuccessExport}
                                />
                            </Button>
                            <Typography variant="body-smaller-default">Maksimum 1000 veri</Typography>
                        </CustomExportBox>
                        <CustomFilterDiv style={{ gap: '20px' }}>
                            <SearchInput
                                height={ptr(50)}
                                value={filterText}
                                onChange={(val) => {
                                    setFilterText(val)
                                    if (currentPage !== 0) setCurrentPage(0)
                                }}
                                placeholder="Firma veya Kullanıcı Ara"
                            />
                            <Box width={'30%'}>
                                <DatePicker
                                    setEndDate={setEndDate}
                                    setStartDate={setStartDate}
                                    startDate={startDate}
                                    endDate={endDate}
                                    value={`${startDate} - ${endDate}`}
                                    width={'100%'}
                                    placeholder={'Tarihe Göre Filtrele'}
                                    onSelectDate={({ startDate, endDate }) => {
                                        handleSelectDate(startDate, endDate)
                                        if (currentPage !== 0) setCurrentPage(0)
                                    }}
                                />
                            </Box>
                        </CustomFilterDiv>
                    </CustomHeadDiv>

                    <div>
                        <Spinner open={isTopSellerLoading} />
                        <Table
                            count={topSellerData?.data.result.totalCount}
                            isLoading={isTopSellerLoading}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            onPageChange={onPageChange}
                            head={topSellerHead}
                            result={rowPerPage}
                            rowsData={rowData}
                            isClickable={false}
                            page={currentPage}
                        ></Table>
                    </div>
                </CustomTableDiv>
            </PopularityContainer>
        </DashboardLayout>
    )
}

export default TopSeller
