import { Box, Dialog, DialogTitle, Divider, IconButton } from '@mui/material'
import { CancelPresentation, ChangeCircle, CheckCircle, Close } from '@mui/icons-material'
import { getKey, ptr } from '../../../utils/helpers'
import { colors } from '../../../styles/color'
import Typography from '../../../components/Typography/Typography'
import React, { FC, useEffect, useState } from 'react'
import { CancelUpdateFormValues, LastStepInformationProps, ModalProps, StepProps, UpdateModalFormValues, UpdateModalProps } from './types'
import * as yup from 'yup'
import {
    apperance,
    ButtonGroup,
    Content,
    ContentRow,
    ErrorMessage,
    Header,
    HeaderTitle,
    LastStepDivider,
    LastStepInformationContainer,
    LastStepInformationItem,
    LastStepInformationRow,
    ModalBody,
    StepContainer,
} from './index.styled'
import { OrderHistory, OrderStatusFilter } from '../../../services/be-api/orders/types'
import Button from '../../../components/Buttons/Button/Button'
import { useGetOrderHistory } from '../../../utils/hooks/queries/Orders'
import { Form, Formik } from 'formik'
import TextField from '../../../components/Input/TextField/TextField'
import Status from './Status'

const StepStatus: FC<ModalProps> = ({ status }) => {
    switch (status) {
        case getKey(OrderStatusFilter, OrderStatusFilter.PENDING):
            return (
                <Typography size={14} weight={500}>
                    Gönderildi
                </Typography>
            )
        case getKey(OrderStatusFilter, OrderStatusFilter.SHIPPING):
            return (
                <Typography size={14} weight={500}>
                    Teslim Edildi
                </Typography>
            )
        case getKey(OrderStatusFilter, OrderStatusFilter.INPREPARATIO):
            return null
        default:
            return <></>
    }
}

const Step: FC<StepProps & ModalProps> = ({ title, datetime, isShow, status }) => {
    return (
        <StepContainer>
            <CheckCircle sx={{ color: colors.success.successMain }} />
            <Typography size={14}>{title}</Typography>
            <Typography size={14} color={colors.secondary.secondaryMain}>
                {datetime}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: ptr(10) }}>
                <Divider orientation="vertical" color={colors.success.successMain} sx={{ width: ptr(3), height: ptr(100), border: 'none' }} />
                {!isShow && (
                    <>
                        <Box
                            sx={{
                                height: ptr(20),
                                width: ptr(20),
                                borderRadius: '50%',
                                border: `3px solid ${colors.success.successMain}`,
                            }}
                        />
                        <StepStatus status={status} />
                    </>
                )}
            </Box>
        </StepContainer>
    )
}

const Stepper: FC<ModalProps> = ({ status }) => {
    switch (status) {
        case getKey(OrderStatusFilter, OrderStatusFilter.PENDING):
            return null
        case getKey(OrderStatusFilter, OrderStatusFilter.INPREPARATIO):
            return (
                <StepContainer>
                    <Step title="Hazırlanıyor." datetime="14/04/2023, 20:43" status={status} />
                </StepContainer>
            )
        case OrderStatusFilter.SHIPPING:
            return (
                <StepContainer>
                    <Step title="Hazırlanıyor." datetime="14/04/2023, 20:43" isShow status={status} />
                    <Step title="Gönderildi." datetime="14/04/2023, 20:43" status={status} />
                </StepContainer>
            )
        default:
            return <></>
    }
}

const LastStepInformation: FC<LastStepInformationProps> = ({ orderId, closeAction, updateOrderStatus }) => {
    const [shippedData, setShippedData] = useState<OrderHistory>()
    const { data: orderHistoriesData } = useGetOrderHistory({ queryKeys: { id: orderId } })
    useEffect(() => {
        if (orderHistoriesData?.data.result) {
            const data = orderHistoriesData?.data.result.find((orderHistory) => orderHistory.status === OrderStatusFilter.SHIPPING)
            data && setShippedData(data)
        }
    }, [orderHistoriesData])

    const action = () => {
        if (orderId) {
            updateOrderStatus({
                id: orderId,
                status: OrderStatusFilter.DELIVERED,
            })
        }
        closeAction()
    }
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
            }}
        >
            <LastStepInformationContainer>
                <LastStepDivider />
                <LastStepInformationRow>
                    <LastStepInformationItem>
                        <Typography size={14}>Firma Adı</Typography>
                        <Typography weight={600}>{shippedData?.shippedVia}</Typography>
                    </LastStepInformationItem>
                    <LastStepInformationItem>
                        <Typography size={14}>Paket Takip Num.</Typography>
                        <Typography weight={600}>{shippedData?.shippingTrackingNumber}</Typography>
                    </LastStepInformationItem>
                </LastStepInformationRow>
                <LastStepInformationRow>
                    <LastStepInformationItem>
                        <Typography size={14}>Açıklama</Typography>
                        <Typography isLineClamp={true}>{shippedData?.shippingNotes}</Typography>
                    </LastStepInformationItem>
                </LastStepInformationRow>
                <LastStepDivider />
            </LastStepInformationContainer>
            <ButtonGroup sx={{ marginTop: 'auto' }}>
                <Button color="success" onClick={action} {...apperance}>
                    TESLİM EDİLDİ
                </Button>
                <Button variant="outlined" onClick={closeAction} {...apperance}>
                    VAZGEÇ
                </Button>
            </ButtonGroup>
        </Box>
    )
}

const UpdateModal: FC<UpdateModalProps> = ({ isModalOpen, closeAction, orderId, status, updateOrderStatus }) => {
    const [formValues] = useState<UpdateModalFormValues>({} as UpdateModalFormValues)
    const [cancelFormValues] = useState<CancelUpdateFormValues>({} as CancelUpdateFormValues)

    const validationSchema = yup.object().shape({
        companyName: yup.string().required('Firma adı alanı zorunludur').max(64, 'Firma adı en fazla 64 karakter olabilir'),
        trackingNumber: yup.string().required('Paket takip numarası alanı zorunludur').max(12, 'Paket takip numarası en fazla 12 karakter olabilir'),
        information: yup.string().required('Açıklama alanı zorunludur').max(255, 'Açıklama kısmı en fazla 255 karakter olabilir'),
    })

    const cancelValidationSchema = yup.object().shape({
        rejectReason: yup.string().required('Siparişi iptal etme nedeninizi girmelisiniz').max(255, 'Açıklama kısmı en fazla 255 karakter olabilir'),
    })

    const onSubmitAction = (val: UpdateModalFormValues) => {
        if (orderId) {
            updateOrderStatus({
                id: orderId,
                status: OrderStatusFilter.SHIPPING,
                data: {
                    shippingTrackingNumber: val.trackingNumber,
                    shippedVia: val.companyName,
                    shippingNotes: val.information,
                },
            })
        }
        closeAction()
    }

    return (
        <Dialog open={isModalOpen} maxWidth="md" fullWidth={true}>
            <DialogTitle>
                <IconButton aria-label="close" onClick={closeAction} sx={{ position: 'absolute', right: 8, top: 8 }}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <ModalBody>
                <Header>
                    {status === OrderStatusFilter.CANCELED ? (
                        <>
                            <CancelPresentation sx={{ fontSize: ptr(60), color: colors.error.errorMain }} />
                            <Typography variant="h5-default">Sipariş Durumu Güncelleme</Typography>
                        </>
                    ) : (
                        <>
                            <ChangeCircle sx={{ fontSize: ptr(60), color: colors.success.successMain }} />
                            <Typography variant="h5-default">Sipariş Durumu Güncelleme</Typography>
                        </>
                    )}
                    <HeaderTitle>
                        <Status status={status} />
                    </HeaderTitle>
                </Header>
                <Content>
                    <ContentRow>
                        <Stepper status={status} />
                        {status === OrderStatusFilter.PENDING && (
                            <ButtonGroup>
                                <Button
                                    color="success"
                                    onClick={() => {
                                        if (orderId) {
                                            updateOrderStatus({ id: orderId, status: OrderStatusFilter.INPREPARATIO })
                                        }
                                        closeAction()
                                    }}
                                >
                                    ONAYLA
                                </Button>
                                <Button variant="outlined" onClick={closeAction}>
                                    VAZGEÇ
                                </Button>
                            </ButtonGroup>
                        )}
                        {status === OrderStatusFilter.INPREPARATIO && (
                            <Box sx={{ width: '100%' }}>
                                <Formik initialValues={formValues} validationSchema={validationSchema} onSubmit={onSubmitAction} enableReinitialize validateOnBlur validateOnMount>
                                    {(props) => {
                                        const { values, handleSubmit, handleBlur, handleChange, isValid } = props
                                        return (
                                            <Form id="form">
                                                <Divider orientation="horizontal" sx={{ height: '', width: '100%', margin: `${ptr(20)} 0` }} />
                                                <Box
                                                    sx={{
                                                        width: '100%',
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        gap: ptr(20),
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            width: '100%',
                                                            gap: ptr(10),
                                                        }}
                                                    >
                                                        <Box sx={{ height: ptr(75) }}>
                                                            <TextField
                                                                name="companyName"
                                                                placeholder="Firma Adı"
                                                                onChange={(e, changeEvent) => handleChange(changeEvent)}
                                                                value={values.companyName}
                                                                onBlur={handleBlur}
                                                                expand
                                                                width="100%"
                                                            />
                                                            <ErrorMessage name="companyName" component={'div'} />
                                                        </Box>
                                                        <Box sx={{ height: ptr(75) }}>
                                                            <TextField
                                                                name="trackingNumber"
                                                                placeholder="Paket Takip Numarası"
                                                                onChange={(e, changeEvent) => handleChange(changeEvent)}
                                                                value={values.trackingNumber}
                                                                onBlur={handleBlur}
                                                                expand
                                                                width="100%"
                                                            />
                                                            <ErrorMessage name="trackingNumber" component={'div'} />
                                                        </Box>
                                                    </Box>

                                                    <Box sx={{ width: '100%', height: 'fit-content' }}>
                                                        <TextField
                                                            multiline
                                                            rows={4.7}
                                                            name="information"
                                                            type="text"
                                                            placeholder="Açıklama giriniz..."
                                                            onChange={(e, changeEvent) => handleChange(changeEvent)}
                                                            value={values.information}
                                                            onBlur={handleBlur}
                                                            expand
                                                            height="100%"
                                                        />
                                                        <ErrorMessage name="information" component={'div'} />
                                                    </Box>
                                                </Box>
                                                <ButtonGroup>
                                                    <Button color="success" onClick={handleSubmit} disabled={!isValid}>
                                                        Güncelle
                                                    </Button>
                                                    <Button variant="outlined" onClick={closeAction}>
                                                        VAZGEÇ
                                                    </Button>
                                                </ButtonGroup>
                                            </Form>
                                        )
                                    }}
                                </Formik>
                            </Box>
                        )}
                        {status === OrderStatusFilter.SHIPPING && (
                            <>
                                <LastStepInformation {...formValues} orderId={orderId} closeAction={closeAction} updateOrderStatus={updateOrderStatus} />
                            </>
                        )}
                        {status === OrderStatusFilter.CANCELED && (
                            <Box sx={{ width: '100%' }}>
                                <Formik
                                    initialValues={cancelFormValues}
                                    validationSchema={cancelValidationSchema}
                                    onSubmit={(values: CancelUpdateFormValues) => {
                                        if (orderId) {
                                            updateOrderStatus({
                                                id: orderId,
                                                status: OrderStatusFilter.CANCELED,
                                                data: values,
                                            })
                                        }
                                        closeAction()
                                    }}
                                    enableReinitialize
                                    validateOnBlur
                                    validateOnMount
                                >
                                    {(props) => {
                                        const { values, handleSubmit, handleBlur, handleChange, isValid } = props
                                        return (
                                            <Form>
                                                <Box sx={{ width: '100%', height: 'fit-content' }}>
                                                    <TextField
                                                        multiline
                                                        rows={4.7}
                                                        name="rejectReason"
                                                        type="text"
                                                        placeholder="Siparişi iptal etme nedeninizi giriniz."
                                                        onChange={(e, changeEvent) => handleChange(changeEvent)}
                                                        value={values.rejectReason}
                                                        onBlur={handleBlur}
                                                        expand
                                                        height="100%"
                                                    />
                                                    <ErrorMessage name="rejectReason" component={'div'} />
                                                </Box>
                                                <ButtonGroup>
                                                    <Button color="error" onClick={handleSubmit} disabled={!isValid} {...apperance}>
                                                        İPTAL ET
                                                    </Button>
                                                    <Button variant="outlined" onClick={closeAction} {...apperance}>
                                                        GERİ DÖN
                                                    </Button>
                                                </ButtonGroup>
                                            </Form>
                                        )
                                    }}
                                </Formik>
                            </Box>
                        )}
                    </ContentRow>
                </Content>
            </ModalBody>
        </Dialog>
    )
}

export default UpdateModal
