import { BaseLookupModel, SubCategoryVariables } from 'services/be-api/lookup/types'
import { useBaseQuery } from '../_Base'
import { Query } from '../types'
import lookUpServices from 'services/be-api/lookup'

export const useGetVehicleTypes: Query<any, Array<BaseLookupModel>> = ({ enabled = true }) =>
    useBaseQuery({
        queryKeys: ['vehicle_types'],
        service: lookUpServices.getVehicleTypes,
        onSuccess: {
            messageDisplay: false,
            message: 'Successful, get vehicle types',
        },
        onLoading: {
            messageDisplay: false,
            message: 'Loading',
        },
        onError: {
            messageDisplay: false,
        },
        enabled,
    })

export const useGetVehicleMakes: Query<any, Array<BaseLookupModel>> = ({ enabled = true }) =>
    useBaseQuery({
        queryKeys: ['vehicle_makes'],
        service: lookUpServices.getVehicleMakes,
        onSuccess: {
            messageDisplay: false,
            message: 'Successful, get vehicle makes',
        },
        onLoading: {
            messageDisplay: false,
            message: 'Loading',
        },
        onError: {
            messageDisplay: false,
        },
        enabled,
    })

export const useGetWorkCategory: Query<any, Array<BaseLookupModel>> = ({ enabled = true }) =>
    useBaseQuery({
        queryKeys: ['work_category'],
        service: lookUpServices.getWorkCategory,
        onSuccess: {
            messageDisplay: false,
            message: 'Successful, get work category',
        },
        onLoading: {
            messageDisplay: false,
            message: 'Loading',
        },
        onError: {
            messageDisplay: false,
        },
        enabled,
    })

export const useGetWorkSubCategory: Query<SubCategoryVariables, Array<BaseLookupModel>> = (params) => {
    return useBaseQuery({
        queryKeys: ['work_sub_category', { params: params.queryKeys }],
        service: lookUpServices.getWorkSubCategory,
        onSuccess: {
            messageDisplay: false,
            message: 'Successful, get work category',
        },
        onLoading: {
            messageDisplay: false,
            message: 'Loading',
        },
        onError: {
            messageDisplay: false,
        },
        enabled: params.enabled,
    })
}
