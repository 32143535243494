import { Service } from 'services/types'
import { Request } from 'services/_base'
import { GetAppRatesVariables } from './types'
import { GET_APP_RATE } from './contants'

export const getAllRates: Service<GetAppRatesVariables> = ({ queryKey }) => {
    const { params } = queryKey[1]
    return Request.get(GET_APP_RATE(), {
        maxResultCount: params.maxResultCount,
        skipCount: params.skipCount,
        filter: params.filter,
        rating: params.rating,
        startDate: params.startDate,
        endDate: params.endDate,
    })
}
