export const ERequestStatus = {
    PURCHASED: 'Alışveriş Gerçekleşti',
    IN_NEGOTIATION: 'Görüşme Aşamasında',
    OFFER_COLLECTION_COMPLETED: 'Teklifler Görüntülenebilir',
    OPEN_TO_OFFERS: 'Tekliflere Açık',
    OFFERS_BEING_COLLECTED: 'Teklif Toplama Aşamasında',
    UNACCEPTED_OFFERS: 'Teklifler Sonuçlandırılmadı',
    UN_PUBLISHED_BY_ADMIN: 'Yönetici Tarafından Kaldırıldı',
    NO_OFFER: 'Hiçbir Teklifte Bulunulmadı',
} as const
