import { Box, styled } from '@mui/material'
import { ptr } from '../../../../utils/helpers'
import { colors } from '../../../../styles/color'
import TextField from '@mui/material/TextField'

export const ChatContainer = styled('div')({
    display: 'flex',
    width: '100%',
    height: ptr(600),
    background: colors.white.default,
    borderRadius: ptr(4),
    border: `1px solid ${colors.other.divider}`,
})

export const ChatLeft = styled('div')({
    height: '100%',
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
    borderRight: `1px solid ${colors.other.divider}`,
    overflowY: 'auto',
})

export const ChatRight = styled('div')({
    height: '100%',
    width: '60%',
})

export const SearchWrapper = styled('div')({
    height: ptr(68),
    padding: ptr(18.5),
    borderBottom: `1px solid ${colors.other.divider}`,
})

export const ChatSearchInput = styled(TextField)({
    borderColor: colors.primary.primaryMain,
    height: ptr(40),
    width: '100%',

    '& > div': {
        height: ptr(38),
        fontWeight: '400',
        paddingRight: '12px',
        fontSize: '18px',
        borderColor: colors.primary.primaryMain,
        color: colors.black.default,
        borderRadius: ptr(66),
    },
    '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
        color: colors.black.default,
    },
    '& .Mui-focused': {
        borderColor: colors.primary.primaryMain,
        color: `${colors.primary.primaryMain} !important`,
        '& .MuiOutlinedInput-notchedOutline': {
            border: '2px solid #FFAA00 !important',
        },
    },
})

export const PaddingWrapper = styled('div')({})

export const ChatTitle = styled('div')({
    fontSize: ptr(20),
    color: colors.primary.primaryMain,
    fontWeight: 500,
    lineHeight: ptr(32),
    margin: ptr(10),
    padding: ptr(12),
})

export const ChatRightHeader = styled(Box)({
    height: ptr(68),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: ptr(18),
    padding: `${ptr(14)} ${ptr(24)}`,
    borderBottom: `1px solid ${colors.other.divider}`,
    position: 'sticky',
    top: 0,
    backgroundColor: colors.white.default,
    zIndex: 10,
})

export const ChatRightHeaderInformation = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    marginRight: 'auto',
})

export const ChatRightHeaderInformationTitle = styled('span')({
    fontWeight: 400,
    fontSize: ptr(16),
    color: colors.text.secondaryTextLight,
})
export const ChatRightHeaderInformationSubtitle = styled('span')({
    fontWeight: 400,
    fontSize: ptr(14),
    color: colors.text.disabledTextLight,
})
export const ChatContent = styled(Box)({
    height: `calc(100% - ${ptr(108)})`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    backgroundColor: colors.lightBackground,
})

export const ChatMessageWrapper = styled(Box)({
    height: '100%',
    overflowY: 'scroll',
})

export const ChatFooter = styled(Box)({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: ptr(40),
    gap: ptr(10),
    backgroundColor: colors.lightBackground,
})

export const Circle = styled(Box)({
    height: ptr(8),
    width: ptr(8),
    borderRadius: '50%',
    backgroundColor: colors.warning.warningMain,
})

export const ChatFooterTitle = styled('span')({})
