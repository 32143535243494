import { Box, styled, TextField } from '@mui/material'
import { ptr } from '../../utils/helpers'
import { colors } from '../../styles/color'
import { ErrorMessage as CustomErrorMessage } from 'formik'

export const RequestsContainer = styled(Box)({
    height: '100vh',
    marginTop: ptr(44),
    marginRight: ptr(24),
})

export const Statistics = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    textAlign: 'center',
    alignItems: 'center',
    marginBottom: ptr(24),
    gap: ptr(16),
})

export const StatisticCard = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    padding: '14px 20px',
    justifyContent: 'flex-start',
    textAlign: 'start',
    width: '100%',
    height: ptr(80),
    background: colors.white.default,
    boxShadow: '0px 2px 10px rgba(76, 78, 100, 0.22)',
    borderRadius: '4px',
    gap: ptr(16),
})

export const StatisticCardInfo = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'flex-start',
})

export const RequestsFilterSection = styled('div')({
    boxShadow: ' 0px 2px 10px rgba(76, 78, 100, 0.22)',
    marginBottom: ptr(24),
})

export const RequestTableContainer = styled(Box)({
    boxShadow: ' 0px 2px 10px rgba(76, 78, 100, 0.22)',
    marginBottom: ptr(24),
})

export const RequestTableHead = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    textAlign: 'center',
    alignItems: 'center',
    background: colors.white.default,
    padding: `${ptr(21)}}`,
})

export const RequestTableFilterContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    width: '50%',
    justifyContent: 'right',
    gap: ptr(24),
    textAlign: 'center',
    alignItems: 'flex-start',
})

export const RequestSearchFilter = styled(TextField)({
    height: '40px',
    width: '100%',
    '& > div': {
        height: '57px',
        fontWeight: '300',
        paddingRight: '12px',
        fontSize: '16px',
    },
})

export const RequestStatus = styled(Box)({
    width: 'fit-content',
    padding: `${ptr(3)} ${ptr(10)}`,
    borderRadius: ptr(16),
})

export const ErrorMessageControler = styled('div')((props) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
}))
export const ErrorMessage = styled(CustomErrorMessage)((props) => ({
    color: colors.text.secondaryTextLight,
    fontSize: '14px',
}))
