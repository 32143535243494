import { PagedResultDto } from '../../types'
import { BaseUser } from '../../../models'
import { BaseCompany } from '../../../models/base/Company'

export interface Order {
    id: number
    requestedAmount: number
    status: string
    creationTime: string
    bankTransactionCode: string
    purchasePrice: number
    user: Omit<BaseUser, 'vehicleMakeNames'>
    specialOffer: SpecialOffer
}

export interface SpecialOffer {
    productCode: string
    productName: string
    productDescription: string
    productPrice: number
    productDiscountedPrice: number
    productStock: number
    isActive: boolean
    ownerIban: string
    company: SpecialOfferCompany
}

export interface SpecialOfferCompany extends Pick<BaseCompany, 'id' | 'name' | 'city'> {}

export enum OrderStatusFilter {
    PENDING = 'Beklemede',
    CANCELED = 'İptal Edildi',
    DELIVERED = 'Teslim Edildi',
    SHIPPING = 'Gönderildi',
    INPREPARATIO = 'Hazırlanıyor',
}

export enum OrderTypeFilter {
    TotalProductPriceAsc = 'Toplam fiyata göre artan',
    TotalProductPriceDsc = 'Toplam fiyata göre azalan',
    TotalProductStockAsc = 'Toplam adede göre artan',
    TotalProductStockDsc = 'Toplam adede göre azalan',
    CreationTimeAsc = 'Oluşturma zamanına göre artan',
    CreationTimeDsc = 'Oluşturma zamanına göre azalan',
}

export interface OrderFilterVariables {
    Filter?: string
    Status?: OrderStatusFilter
    OrderType?: OrderTypeFilter
    StartDate?: string
    EndDate?: string
    SkipCount?: number
    MaxResultCount?: string
}

export interface UpdateOrderStatusVariables {
    id: number
    status: Exclude<OrderStatusFilter, OrderStatusFilter.PENDING>
    data?:
        | {
              shippingTrackingNumber: string
              shippingNotes: string
              shippedVia: string
          }
        | { rejectReason: string }
}

export type GetOrderResponse = PagedResultDto<Order>

export type UpdateOrderStatusData = {
    orderId: number
    status: Exclude<OrderStatusFilter, OrderStatusFilter.PENDING>
}

export type GetOrderHistoryVariables = Pick<Order, 'id'>
export interface OrderHistory {
    operationDate: string
    shippedVia: string
    shippingNotes: string
    shippingTrackingNumber: string
    status: string
    rejectReason: string
}

export type OrderHistoriesResponse = OrderHistory[]
