import { useEffect, useState } from 'react'

import { Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import TextField from 'components/Input/TextField/TextField'
import citiesOfTurkey from 'utils/data/citiesOfTurkey.json'
import Button from 'components/Buttons/Button/Button'
import Select from 'components/Input/Select/Select'
import { ButtonsSide, CoInputContent, CoManagerNameContent, ErrorMessageControler, FormContainer } from '../index.styled'
import { AddCompanyProps } from './type'
import { CompanyModel } from 'services/be-api/companies/types'

const CompanyInfo = ({ value, onChange, handleNext, handleBack }: AddCompanyProps) => {
    const [userOptions, setUserOptions] = useState<{ value: number; name: string }[]>([])
    const [cityOptions, setCityOptions] = useState<{ value: string; name: string }[]>([])
    const [districtOptions, setDistrictOptions] = useState<{ value: string; name: string }[]>([])
    const [cityInput, setCityInput] = useState('')

    useEffect(() => {
        setCityOptions(citiesOfTurkey.data.map((child) => ({ value: child.il_adi, name: child.il_adi })))
    }, [])

    useEffect(() => {
        if (value && value.users) {
            setUserOptions(value.users.map((user) => ({ value: user?.id!, name: user?.fullName! })))
        }
    }, [value])

    useEffect(() => {
        setDistrictOptions(
            citiesOfTurkey.data
                .find((child) => child.il_adi === cityInput)
                ?.ilceler.map((ilce) => ({
                    value: ilce.ilce_adi,
                    name: ilce.ilce_adi,
                })) || []
        )
    }, [cityInput])

    useEffect(() => {
        setCityInput(value.city)
    }, [value.city])

    const validationSchema = Yup.object({
        managerId: Yup.number().required('Bu alan zorunludur.'),
        name: Yup.string().required('Bu alan zorunludur.').max(100, 'Maksimum 100 karakter olabilir'),
        taxNumber: Yup.string()
            .required('Bu alan zorunludur.')
            .max(11, 'Maksimum 11 karakter girebilirsiniz')
            .min(10, 'Minimum 10 karakter girebilirsiniz')
            .matches(/^[0-9]+$/, 'Sadece rakam girilebilir'),
        city: Yup.string().required('Bu alan zorunludur.'),
        district: Yup.string().required('Bu alan zorunludur.'),
        address: Yup.string().required('Bu alan zorunludur.').max(255, 'Maksimum 255 karakter girebilirsiniz'),
    })

    const onSubmit = (values: CompanyModel) => {
        onChange(values)
        handleNext()
    }

    const cityNameHandler = (e: any) => {
        setCityInput(e)
    }

    return (
        <Formik initialValues={{ ...value, managerId: value?.managerId ? value?.managerId : value.manager?.id }} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize>
            {({ isSubmitting, values, handleChange, handleSubmit, isValid, setFieldValue }) => {
                return (
                    <Form>
                        <FormContainer>
                            <CoManagerNameContent>
                                <ErrorMessageControler>
                                    <Select
                                        selectedValue={values.managerId}
                                        name="managerId"
                                        placeholder={'Yetkili Adı'}
                                        options={userOptions}
                                        onChange={(e, changeEvent) => handleChange(changeEvent)}
                                        expand
                                    ></Select>
                                    <ErrorMessage name="managerId" />
                                </ErrorMessageControler>
                            </CoManagerNameContent>

                            <CoInputContent>
                                <ErrorMessageControler>
                                    <TextField name="name" value={values.name} type="text" placeholder={'Firma Adı'} onChange={(e, changeEvent) => handleChange(changeEvent)} expand />
                                    <ErrorMessage name="name" />
                                </ErrorMessageControler>

                                <ErrorMessageControler>
                                    <TextField
                                        onChange={(e) => setFieldValue('taxNumber', String(e).substring(0, 11))}
                                        value={values?.taxNumber}
                                        name="taxNumber"
                                        type="number"
                                        placeholder="Vergi No"
                                        expand
                                    />
                                    <ErrorMessage name="taxNumber" />
                                </ErrorMessageControler>
                            </CoInputContent>
                            <CoInputContent>
                                <ErrorMessageControler>
                                    <Select
                                        selectedValue={values.city}
                                        name="city"
                                        options={cityOptions}
                                        onChange={(e, changeEvent) => {
                                            handleChange(changeEvent)
                                            cityNameHandler(e)
                                            setFieldValue('district', '')
                                        }}
                                        placeholder={'Şehir'}
                                        expand
                                    ></Select>
                                    <ErrorMessage name="city" />
                                </ErrorMessageControler>

                                <ErrorMessageControler>
                                    <Select
                                        selectedValue={values.district}
                                        name="district"
                                        options={districtOptions}
                                        onChange={(e, changeEvent) => handleChange(changeEvent)}
                                        placeholder={'İlçe'}
                                        expand
                                    ></Select>
                                    <ErrorMessage name="district" />
                                </ErrorMessageControler>
                            </CoInputContent>
                            <ErrorMessageControler>
                                <TextField multiline rows={4} onChange={(e, changeEvent) => handleChange(changeEvent)} value={values.address} name="address" type="text" placeholder="Adres" expand />
                                <ErrorMessage name="address" />
                            </ErrorMessageControler>
                        </FormContainer>
                        <ButtonsSide>
                            <Button id="next" onClick={handleSubmit} disabled={isSubmitting || !isValid}>
                                İleri
                            </Button>
                            <Button variant="outlined" color="secondary" onClick={handleBack}>
                                İptal
                            </Button>
                        </ButtonsSide>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default CompanyInfo
