import React, {useState} from "react";
import {TabsDetailModel} from "../../../../components/Tabs/types";
import {ChatBubbleOutline, NotificationsActive} from "@mui/icons-material";
import {ChatTab} from "./index.styled";
import Chat from "../chat";
import Notification from "../notification";

const UserProfileTab = () => {
    const [activeTabId, setActiveTabId] = useState<number>(0)
    const detailsArr: TabsDetailModel[] = [
        {
            name: "Sohbetler",
            icon: <ChatBubbleOutline /> ,
            children: <Chat />
        },
        {
            name: "Bildirimler",
            icon: <NotificationsActive />,
            children: <Notification />
        },
    ]
    return (
        <>
            <ChatTab
                details={detailsArr}
                value={activeTabId}
                onChange={(event, value) => setActiveTabId(value)}
                withIcon={true}
                iconPosition="start"
            />
        </>
    )
}

export default UserProfileTab