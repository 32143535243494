import { Badge, Box, styled } from '@mui/material'
import { colors } from 'styles/color'
import { ptr } from 'utils/helpers'

export const CustomBadge = styled(Badge)(() => ({
    display: 'flex',
    alignItems: 'center',
    padding: '4px 4px',
    borderRadius: '4px',
    justifyContent: 'center',
    width: '100%',
    textAlign: 'center',
}))

export const CustomCompanyProfileContainer = styled('div')({
    display: 'flex',
    justifyContent: 'flex-start',
    minHeight: '100vh',
    marginTop: ptr(44),
    marginRight: ptr(24),
    marginLeft: ptr(24),
    gap: ptr(24),
})

export const CustomCompanyProfileDiv = styled('div')({
    boxShadow: ' 0px 2px 10px rgba(76, 78, 100, 0.22)',
    marginBottom: ptr(24),
    height: 'fit-content',
})

export const CustomCompanyProfileBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    width: ptr(360),
    height: 'fit-content',
    padding: '48px 20px 24px',
    gap: ptr(28),
    background: colors.white.default,
})

export const CustomCompanyFirstBox = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
})
export const CustomCompanySecondBox = styled(Box)({
    marginTop: ptr(4),
    display: 'flex',
    justifyContent: 'space-between',
    gap: ptr(20),
})
export const CustomCompanyThirdBox = styled(Box)({
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    textAlign: 'start',
})
export const CustomCompanyFourthBox = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    gap: ptr(16),
})
export const CustomDetailsDiv = styled('div')({
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    gap: ptr(8),
})
export const CustomDetailsBox = styled(Box)({
    display: 'flex',
    width: ptr(280),
    justifyContent: 'flex-start',
    textAlign: 'center',
    alignItems: 'center',
})
export const CustomTableHeadBox = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: ptr(20),
    background: colors.white.default,
})
export const CustomCompanyProfileTableDiv = styled('div')({
    boxShadow: ' 0px 2px 10px rgba(76, 78, 100, 0.22)',
    marginBottom: ptr(24),
    height: 'fit-content',
})
