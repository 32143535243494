import { Request } from 'services/_base'
import { GET_ADMIN, PUT_UPDATE_ADMIN_INFO, PUT_UPDATE_ADMIN_PASSWORD, PUT_UPDATE_ADMIN_PORTAL } from './constants'
import { Service } from '../../types'
import { UpdateAdminInfoVariables, UpdateAdminPasswordVariables, UpdateAdminPortalVariables } from './types'
import { BankAccount } from '../companies/types'
import { BaseBankAccount } from '../../../models/base/BankAccount'

export const getAdmin: Service<any> = () => {
    return Request.get(GET_ADMIN, {})
}

export const updateAdminPassword: Service<UpdateAdminPasswordVariables> = ({ currentPassword, newPassword, newPasswordAgain }) => {
    return Request.put(PUT_UPDATE_ADMIN_PASSWORD, { currentPassword, newPassword, newPasswordAgain }, {})
}

export const updateAdminInfo: Service<UpdateAdminInfoVariables> = ({ name, surname, emailAddress }) => {
    return Request.put(PUT_UPDATE_ADMIN_INFO, { name, surname, emailAddress }, {})
}

export const updateAdminPortal: Service<UpdateAdminPortalVariables> = ({ phoneNumber, bankAccounts }) => {
    let modifiedBankAccounts: BankAccount = {} as BankAccount
    if (bankAccounts) {
        const _defaultBankAccount = bankAccounts as unknown as BaseBankAccount
        modifiedBankAccounts = {
            ibanNumber: _defaultBankAccount?.ibanNumber.replaceAll(' ', ''),
            bankName: _defaultBankAccount?.bankName,
            nameSurname: _defaultBankAccount?.nameSurname,
        }
    }
    return Request.put(PUT_UPDATE_ADMIN_PORTAL, { phoneNumber, bankAccounts: JSON.stringify([modifiedBankAccounts]) }, {})
}
