import { FC } from 'react'

import BottomGradient from '../../assets/Images/BottomGradient/BottomGradient.svg'
import BottomGradientBlack from '../../assets/Images/BottomGradient/BottomGradientBlack.svg'
import BottomGradientGreen from '../../assets/Images/BottomGradient/BottomGradientGreen.svg'
import BottomGradientPurple from '../../assets/Images/BottomGradient/BottomGradientPurple.svg'

import { BottomImageProps } from './types'
import { BottomImageDiv, CustomImg } from './index.styled'

const BottomImage: FC<BottomImageProps> = ({ type = 'Black' }) => {
    const getImage = () => {
        switch (type) {
            case 'Black':
                return BottomGradientBlack
            case 'Blue':
                return BottomGradient
            case 'Green':
                return BottomGradientGreen
            case 'Purple':
                return BottomGradientPurple
            default:
                return BottomGradient
        }
    }
    return (
        <BottomImageDiv>
            <CustomImg src={getImage()} alt="Dekoratif Küre" />
        </BottomImageDiv>
    )
}

export default BottomImage
