import { RequestParams, Service } from '../../types'
import { Request } from '../../_base'
import { GET_REQUESTS, GET_REQUEST_STATISTICS, GET_REQUEST_DETAIL, PUT_WITHDRAW_REQUEST, GET_OFFERS_BY_REQUEST_ID, PUT_ADMIN_CANCEL_OFFER } from './constants'
import { AdminCancelOfferVariables, OfferByRequestIdVariables, RequestDetailVariables, RequestFilterDto, WithdrawRequestVariables } from './types'

export const getRequestsStatisticCount: Service<RequestParams<RequestFilterDto>> = ({ queryKey }) => {
    const { params } = queryKey[1]
    return Request.get(GET_REQUEST_STATISTICS, {
        Filter: params.Filter,
        WorkCategoryName: params.WorkCategoryName,
        WorkSubCategoryName: params.WorkSubCategoryName,
        VehicleMakeName: params.VehicleMakeName,
        Status: params.Status,
        StartDate: params.StartDate,
        EndDate: params.EndDate,
        SkipCount: params.SkipCount,
        MaxResultCount: params.MaxResultCount,
    })
}

export const getRequests: Service<RequestParams<RequestFilterDto>> = ({ queryKey }) => {
    const { params } = queryKey[1]
    return Request.get(GET_REQUESTS, {
        Filter: params.Filter,
        WorkCategoryName: params.WorkCategoryName,
        WorkSubCategoryName: params.WorkSubCategoryName,
        VehicleMakeName: params.VehicleMakeName,
        Status: params.Status,
        StartDate: params.StartDate,
        EndDate: params.EndDate,
        SkipCount: params.SkipCount,
        MaxResultCount: params.MaxResultCount,
    })
}

export const getOffersByRequestId: Service<RequestParams<OfferByRequestIdVariables>> = ({ queryKey }) => {
    const { params } = queryKey[1]
    return Request.get(GET_OFFERS_BY_REQUEST_ID(params.id), {
        Filter: params.Filter,
        Status: params.Status,
    })
}

export const getRequestDetail: Service<RequestParams<RequestDetailVariables>> = ({ queryKey }) => {
    const { params } = queryKey[1]
    return Request.get(GET_REQUEST_DETAIL(params.id), {})
}

export const withdrawTheRequest: Service<WithdrawRequestVariables> = (data) => {
    return Request.put(
        PUT_WITHDRAW_REQUEST(data.id),
        {
            rejectReason: data.rejectReason,
        },
        {}
    )
}

export const adminCancelOffer: Service<AdminCancelOfferVariables> = (data) => {
    return Request.put(
        PUT_ADMIN_CANCEL_OFFER(data.id),
        {
            rejectReason: data.rejectReason,
        },
        {}
    )
}
