import { GridColDef } from '@mui/x-data-grid'
import { BuyerDemand } from 'services/be-api/dashboard/types'

export const topBuyerExportOptions = [
    {
        key: 'SATICI',
        label: 'SATICI',
        onRender: (item: string, element: BuyerDemand) => {
            return `${element?.fullName} ${element?.companyName}`
        },
    },
    {
        key: 'TOPLAM SATIS ADEDI',
        label: 'TOPLAM SATIS ADEDI',
        onRender: (item: string, element: BuyerDemand) => {
            return `${element?.totalRequestCount}`
        },
    },
    {
        key: 'TOPLAM SATIS MIKTARI',
        label: 'TOPLAM SATIS MIKTARI',
        onRender: (item: string, element: BuyerDemand) => {
            return `${element?.totalPurchasePrice} TL`
        },
    },
]

export const topBuyerHead: GridColDef[] = [
    { field: 'buyer', headerName: 'ALICI', width: 70 },
    { field: 'totalPurchases', headerName: 'TOPLAM ALIŞ ADEDİ', width: 130 },
    { field: 'totalPurchasesAmount', headerName: 'TOPLAM ALIŞ MİKTARI', width: 130 },
]

export const breadcrumbLinks = [
    { text: 'Dashboard', url: '' },
    { text: 'Kullanıcı Aksiyonları', url: '' },
    { text: 'Top Alıcı Detay', url: '/top-buyer-details', isLast: true },
]
