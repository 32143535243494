import React, { useEffect } from 'react'

import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'

import { CardsContainer, CardContainer, CardIcon } from './index.styled'
import Typography from 'components/Typography/Typography'
import { colors } from 'styles/color'
import { CardType, RadioProps } from './type'
import Radio from 'components/Buttons/Radio/Radio'

const CheckboxCard: React.FunctionComponent<RadioProps> = ({ setRadioValue, radioValue, handleChange, val }) => {
    useEffect(() => {
        setRadioValue(val)
    }, [val, setRadioValue])

    const userType: CardType[] = [
        { title: 'ALICI (USTA)', icon: <ShoppingBasketIcon />, name: 'role', value: 'Alıcı', text: 'Uygulama içerisinde sadece talep oluşturma yetkisi vardır.' },
        { title: 'SATICI (ÇIKMACI)', icon: <LocalOfferIcon />, name: 'role', value: 'Satıcı', text: 'Uygulama içerisinde oluşturulan taleplere sadece teklif verme yetkisi vardır.' },
    ]
    const radioValueHandler = (e: string) => {
        setRadioValue(e)
    }

    const sellerBuyer = userType
        .map((user) => user.value)
        .reverse()
        .toString()
        .replace(',', '  ')

    const isDisabled = radioValue === 'Satıcı & Alıcı' ? true : false

    return (
        <CardsContainer>
            {userType.map((card) => (
                <CardContainer
                    key={card.title}
                    sx={{
                        borderColor:
                            radioValue?.search('&').toString().length === 1
                                ? sellerBuyer === radioValue?.replace('&', '')
                                    ? colors.primary.primaryMain
                                    : ''
                                : radioValue === card.value
                                ? colors.primary.primaryMain
                                : '',
                    }}
                >
                    <CardIcon
                        sx={{
                            color:
                                radioValue?.search('&').toString().length === 1
                                    ? sellerBuyer === radioValue?.replace('&', '')
                                        ? colors.primary.primaryMain
                                        : ''
                                    : radioValue === card.value
                                    ? colors.primary.primaryMain
                                    : '',
                        }}
                    >
                        {card.icon}
                    </CardIcon>
                    <Typography color={colors.text.primaryTextLight} variant="body-normal-semibold">
                        {card.title}
                    </Typography>
                    <Typography color={colors.text.secondaryTextLight} variant="body-small-default">
                        {card.text}
                    </Typography>
                    <Radio
                        disabled={isDisabled}
                        onChange={(e, changeEvent) => {
                            radioValueHandler(e)
                            handleChange(changeEvent)
                        }}
                        name={card.name}
                        value={card.value}
                        checked={radioValue?.search('&').toString().length === 1 ? sellerBuyer === radioValue?.replace('&', '') : card.value === radioValue}
                    />
                </CardContainer>
            ))}
        </CardsContainer>
    )
}

export default CheckboxCard
