import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Stepper from 'components/Stepper/Stepper'
import DashboardLayout from 'layouts/Dashboard/DashboardLayout'
import ProductInfo from './ProductInfo'
import { useGetCompanies } from 'utils/hooks/queries/Companies'
import { StepperContainer, StepperContent, StepperForm } from './index.styled'
import ProductCategoryInformation from './ProductCategoryInformation'
import { ProductCategoryFormValues } from './ProductCategoryInformation/type'
import Summary from './Summary'
import { useGetVehicleMakes, useGetVehicleTypes, useGetWorkCategory } from 'utils/hooks/queries/LookUp'
import { ProductFormValues } from '../index.interface'
import { useGetAdmin } from '../../../utils/hooks/queries/Admin'

const CreateCampaign = () => {
    const navigate = useNavigate()
    const { data: vehicleMakesData } = useGetVehicleMakes({ queryKeys: {}, enabled: true })
    const { data: vehicleTypesData } = useGetVehicleTypes({ queryKeys: {}, enabled: true })
    const { data: workCategoriesData } = useGetWorkCategory({ queryKeys: {}, enabled: true })
    const { data: companiesData } = useGetCompanies({
        queryKeys: {
            maxResultCount: 1000000000,
        },
    })

    const [vehicleMakes, setVehicleMakes] = useState<string[]>([])
    const [vehicleTypes, setVehicleTypes] = useState<string[]>([])
    const [workCategories, setWorkCategories] = useState<string[]>([])
    const [selectedId, setSelectedId] = useState<number>()
    const [selectedStep, setSelectedStep] = useState(0)
    const [productValues, setProductValues] = useState<ProductFormValues>({} as ProductFormValues)
    const [productCategoryValues, setProductCategoryValues] = useState({
        VehicleMake: '',
        WorkCategory: '',
        VehicleType: '',
        blockedCompanies: [],
    } as ProductCategoryFormValues)

    const { data: adminData } = useGetAdmin({ queryKeys: {} })

    useEffect(() => {
        if (adminData?.data.result && adminData.data.result.bankAccounts) {
            const firstBankAccount = JSON.parse(adminData.data.result.bankAccounts)[0]
            if (firstBankAccount) {
                setProductValues((prevState) => ({ ...prevState, ownerIban: firstBankAccount.ibanNumber, ownerBankName: firstBankAccount.bankName, ownerFullName: firstBankAccount.nameSurname }))
            }
        }
    }, [adminData])

    useEffect(() => {
        setVehicleMakes(vehicleMakesData?.data?.result?.map((child) => child.name) ?? [])
    }, [vehicleMakesData])

    useEffect(() => {
        setWorkCategories(workCategoriesData?.data?.result?.map((child) => child.name) ?? [])
    }, [workCategoriesData])

    useEffect(() => {
        setVehicleTypes(vehicleTypesData?.data?.result?.map((child) => child.name) ?? [])
    }, [vehicleTypesData])

    const onChangeProductInfo = (val: ProductFormValues) => {
        setProductValues((prevState) => ({ ...prevState, ...val }))
    }

    const onProductCategoryInformation = (val: ProductCategoryFormValues) => {
        setProductCategoryValues((prevState) => ({
            ...prevState,
            ...val,
        }))
    }
    const handleNext = () => {
        setSelectedStep(selectedStep + 1)
    }

    const handleBack = () => {
        selectedStep !== 0 ? setSelectedStep(selectedStep - 1) : navigate('/products')
    }

    const steps = [
        { label: 'Ürün Ekle', stepNumber: '01' },
        { label: 'Ürün Kategori Bilgisi', stepNumber: '02' },
        { label: 'Özet', stepNumber: '03' },
    ]

    return (
        <DashboardLayout>
            <StepperContainer>
                <Stepper steps={steps} actStep={selectedStep} orientation="vertical" />
                <StepperForm className="stepper-form">
                    <StepperContent className="stepper-content">
                        {selectedStep === 0 && companiesData && (
                            <ProductInfo
                                handleNext={handleNext}
                                handleBack={handleBack}
                                productValues={productValues}
                                onChange={onChangeProductInfo}
                                companiesData={companiesData.data.result.companies}
                                setSelectedId={setSelectedId}
                            />
                        )}
                        {selectedStep === 1 && (
                            <ProductCategoryInformation
                                companies={companiesData?.data.result.companies ?? []}
                                workCategories={workCategories}
                                vehicleTypes={vehicleTypes}
                                vehicleMakes={vehicleMakes}
                                blockedCompanies={Array(4)
                                    .fill(undefined)
                                    .map((_, index) => `Label ${index + 1}`)}
                                handleBack={handleBack}
                                handleNext={handleNext}
                                value={productCategoryValues}
                                onChange={onProductCategoryInformation}
                            />
                        )}
                        {selectedStep === 2 && (
                            <Summary
                                handleBack={handleBack}
                                product={productValues}
                                productCategory={productCategoryValues}
                                companiesData={companiesData!.data.result.companies}
                                selectedId={selectedId!}
                            />
                        )}
                    </StepperContent>
                </StepperForm>
            </StepperContainer>
        </DashboardLayout>
    )
}

export default CreateCampaign
