import DashboardLayout from '../../layouts/Dashboard/DashboardLayout'
import { FilterGroup, FilterSection, PhotoBadge, ProductSubTableStatus, ProductTableFilterSection, ProductTableHead, ProductTableSection } from './index.styled'
import Typography from '../../components/Typography/Typography'
import React, { FC, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import AutocompleteContainer from '../../components/Input/AutoComplete/Autocomplete'
import Button from '../../components/Buttons/Button/Button'
import { EditOutlined, FilterCenterFocus, IosShare, RemoveCircleOutline } from '@mui/icons-material'
import { getKey, getValue, ptr } from '../../utils/helpers'
import ExportDropdown from '../../components/ExportDropdown'
import Table from '../../components/Table'
import { campaignStatusOptions, campaingSortingOptions, productExportOptions, productSubTableHead, productTableHead } from './constants'
import { TableBodyRowData } from '../../components/Table/types'
import { colors } from '../../styles/color'
import { ECampaingStatus, ESortingStatus, ProductOfferStatus, ProductSubTableProps, SpecialOfferOrders } from './index.interface'
import List from '../../components/List/List'
import { useNavigate } from 'react-router-dom'
import { useGetSpecialOfferOrders, useSpecialOffer, useUpdateSpecialOfferStatus } from '../../utils/hooks/queries/Products'
import { SpecialOffer, UpdateSpecialOfferStatus } from '../../services/be-api/products/types'
import { useGetCompanies } from '../../utils/hooks/queries/Companies'
import { CompanyModel } from '../../services/be-api/companies/types'
import Spinner from '../../components/Spinner'
import dayjs from 'dayjs'
import SearchInput from '../../components/Input/SearchInput/SearchInput'
import PriceText from '../../components/PriceText'
import { CustomExportBox } from 'pages/notifications/index.styled'

const ProductSubTable: FC<ProductSubTableProps> = ({ id }) => {
    const navigate = useNavigate()
    const [orders, setOrders] = useState<SpecialOfferOrders[]>([])
    const { data: ordersData, isLoading } = useGetSpecialOfferOrders({ queryKeys: { id } })

    useEffect(() => {
        if (ordersData?.data.result) setOrders(ordersData.data.result)
    }, [ordersData])

    const rowData: TableBodyRowData[] = orders.map((order) => {
        if (order.status === ProductOfferStatus.WAITING) {
            return {
                date: <Typography>{dayjs(order.creationTime).format('YYYY-MM-DD HH:mm')}</Typography>,
                user: (
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography weight={600} onClick={() => navigate(`/users/${order.user.id}`)}>
                            {order.user.fullName}
                        </Typography>
                        <Typography>Firma Adi</Typography>
                    </Box>
                ),
                amount: <Typography>{order.requestedAmount}</Typography>,
                status: <ProductSubTableStatus>{order.status}</ProductSubTableStatus>,
            }
        } else return {}
    })
    return (
        <>
            <Spinner open={isLoading} />
            <Table isLoading={false} head={productSubTableHead} rowsData={rowData} isClickable={false} pagination={false} />
        </>
    )
}

const Products = () => {
    const navigate = useNavigate()
    const [filterText, setFilterText] = useState<string>('')
    const [currentPage, setCurrentPage] = useState(0)
    const [rowPerPage, setRowPerPage] = useState(10)
    const [specialOffers, setSpecialOffers] = useState<SpecialOffer[]>([])
    const [specialOffersExport, setSpecialOffersExport] = useState<SpecialOffer[]>([])
    const [campaignStatus, setCampaignStatus] = useState<string | null>(null)
    const [sortType, setSortType] = useState<string | null>(null)
    const [companies, setCompanies] = useState<CompanyModel[]>([])
    const [company, setCompany] = useState<CompanyModel>({} as CompanyModel)
    const [exportToggle, setExportToggle] = useState(false)
    const {
        data: specialOfferData,
        refetch: refetchSpecialOffer,
        isLoading: specialOfferLoading,
        isSuccess: specialOfferDataSuccess,
    } = useSpecialOffer({
        queryKeys: {
            Filter: filterText.replace('#', '') || null,
            CompanyId: company.id || null,
            Status: getKey(ECampaingStatus, campaignStatus) || null,
            OrderType: getKey(ESortingStatus, sortType) || null,
            SkipCount: currentPage * rowPerPage,
            MaxResultCount: rowPerPage,
        },
    })

    const {
        data: specialOfferDataExport,
        isSuccess: specialOfferDataExportSuccess,
        refetch: refetchSpecialOfferExport,
    } = useSpecialOffer({
        queryKeys: {
            Filter: filterText.replace('#', '') || null,
            CompanyId: company.id || null,
            Status: getKey(ECampaingStatus, campaignStatus) || null,
            OrderType: getKey(ESortingStatus, sortType) || null,
            MaxResultCount: 1000,
        },
    })

    const { data: companiesData } = useGetCompanies({ queryKeys: {} })
    const { mutate: updateStatus, isSuccess: isUpdateStatusSuccess } = useUpdateSpecialOfferStatus()

    useEffect(() => {
        if (specialOfferData?.data.result && specialOfferDataSuccess) setSpecialOffers(specialOfferData.data.result.items)
    }, [specialOfferData, specialOfferDataSuccess])

    useEffect(() => {
        if (specialOfferDataExport?.data.result && specialOfferDataExportSuccess) setSpecialOffersExport(specialOfferDataExport.data.result.items)
    }, [specialOfferDataExport, specialOfferDataExportSuccess])

    useEffect(() => {
        if (companiesData?.data.result) setCompanies(companiesData.data.result.companies)
    }, [companiesData])

    useEffect(() => {
        refetchSpecialOffer()
    }, [isUpdateStatusSuccess, refetchSpecialOffer])

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowPerPage(parseInt(event.target.value, 10))
        setCurrentPage(0)
    }

    const onPageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setCurrentPage(newPage)
    }

    const handleExport = (exportToggle: boolean, set: React.Dispatch<React.SetStateAction<boolean>>) => {
        set(!exportToggle)
        refetchSpecialOfferExport()
    }
    const specialOfferRowData: TableBodyRowData[] = specialOffers.map((specialOffer) => {
        const productPhotos = JSON.parse(specialOffer.productPhotos)?.length > 0 ? JSON.parse(specialOffer.productPhotos) : []
        return {
            isRowColorful: specialOffer.isActive,
            rowColor: colors.error.errorOutlinedHoverBg,
            code: <Typography color={colors.warning.warningMain}>#{specialOffer.productCode}</Typography>,
            productName: (
                <Box sx={{ display: 'flex', gap: ptr(30), alignItems: 'center' }}>
                    <PhotoBadge badgeContent={specialOffer.requestCount}>
                        <Box
                            sx={{
                                height: '80px',
                                width: '80px',
                                backgroundColor: colors.text.primaryTextLight,
                                borderRadius: '4px',
                            }}
                        >
                            <img height="100%" width="100%" src={productPhotos[0]} alt={''} />
                        </Box>
                    </PhotoBadge>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography weight={600} color={colors.text.primaryTextLight}>
                            {specialOffer.productName}
                        </Typography>
                        <Box>
                            <Typography color={colors.text.secondaryTextLight}>{specialOffer.company.name}</Typography>
                        </Box>
                    </Box>
                </Box>
            ),
            info: (
                <Typography weight={400} size={14}>
                    {specialOffer.productDescription}
                </Typography>
            ),
            price: (
                <Typography color={colors.text.secondaryTextLight}>
                    <PriceText price={specialOffer.productPrice} />
                </Typography>
            ),
            campaignPrice: (
                <Typography color={colors.text.primaryTextLight}>
                    <PriceText price={specialOffer.productDiscountedPrice} />
                </Typography>
            ),
            stock: <Typography color={colors.warning.warningMain}>{specialOffer.productStock}</Typography>,
            detail: <ProductSubTable id={specialOffer.id} />,
            menu: (
                <List
                    listDetails={[
                        {
                            name: 'Düzenle',
                            icon: <EditOutlined style={{ color: colors.action.actionActiveLight }} />,
                            onClick: () => navigate(`/products/${specialOffer.id}/update`),
                        },
                        {
                            name: specialOffer.isActive ? 'Pasif Yap' : 'Aktif Yap',
                            icon: specialOffer.isActive ? (
                                <RemoveCircleOutline style={{ color: colors.action.actionActiveLight }} />
                            ) : (
                                <FilterCenterFocus style={{ color: colors.action.actionActiveLight }} />
                            ),
                            onClick: () => updateStatus({ id: specialOffer.id, status: specialOffer.isActive ? UpdateSpecialOfferStatus.DEACTIVE : UpdateSpecialOfferStatus.ACTIVE }),
                        },
                    ]}
                    width="230px"
                    minWidth="inherit"
                    gap="10px"
                    listPadding="15px"
                    itemButtonPadding="0px"
                    ListContainerPadding="0px 10px"
                    ListTextPadding="5px 0px"
                    backgroundColor={colors.white.default}
                    boxShadow="0px 5px 5px -3px #4C4E6424, 0px 8px 10px 1px #4C4E6424, 0px 3px 14px 2px #4C4E6433"
                    isTableActions={true}
                />
            ),
        }
    })

    return (
        <DashboardLayout>
            <Spinner open={specialOfferLoading} />
            <FilterSection>
                <Typography variant="h6-medium">Filtreleyerek Ara</Typography>
                <FilterGroup>
                    <Box width={'100%'}>
                        <AutocompleteContainer
                            width="100%"
                            placeholder="Firma Seç"
                            onChange={(e) => {
                                const activeCompany = companies.find((company) => company.name === (e as string))
                                if (activeCompany) setCompany(activeCompany)
                                else setCompany({} as CompanyModel)
                                if (currentPage !== 0) setCurrentPage(0)
                            }}
                            options={companies.map((company) => ({ value: company.name, label: company.name }))}
                            selectedValue={company.name}
                        />
                    </Box>
                    <Box width={'100%'}>
                        <AutocompleteContainer
                            width="100%"
                            placeholder="Kampanya Durumunu Seç"
                            options={campaignStatusOptions}
                            onChange={(e) => {
                                setCampaignStatus(getValue(ECampaingStatus, e as string))
                                if (currentPage !== 0) setCurrentPage(0)
                            }}
                            selectedValue={campaignStatus}
                        />
                    </Box>
                    <Box width={'100%'}>
                        <AutocompleteContainer
                            width="100%"
                            placeholder="Sırala"
                            onChange={(e) => {
                                setSortType(getValue(ESortingStatus, e as string))
                                if (currentPage !== 0) setCurrentPage(0)
                            }}
                            options={campaingSortingOptions}
                            selectedValue={sortType}
                        />
                    </Box>
                </FilterGroup>
            </FilterSection>
            <ProductTableSection>
                <ProductTableHead>
                    <CustomExportBox>
                        <Button
                            startIcon={<IosShare />}
                            onClick={() => handleExport(exportToggle, setExportToggle)}
                            variant="outlined"
                            colorsx={colors.secondary.secondaryAlternativeLight}
                            borderColor={colors.secondary.secondaryAlternativeLight}
                            padding={ptr(16)}
                        >
                            <ExportDropdown<SpecialOffer>
                                array={specialOffersExport}
                                fileName={'products'}
                                title={''}
                                sheetName={''}
                                headers={productExportOptions}
                                open={exportToggle}
                                buttonHeader={'Export'}
                                isSuccess={specialOfferDataExportSuccess}
                            />
                        </Button>
                        <Typography variant="body-smaller-default">Maksimum 1000 veri</Typography>
                    </CustomExportBox>
                    <ProductTableFilterSection>
                        <SearchInput
                            fullWidth
                            value={filterText}
                            onChange={(val) => {
                                setFilterText(val)
                                if (currentPage !== 0) setCurrentPage(0)
                            }}
                            placeholder="Kampanya Ara"
                        />
                        <Button width="50%" padding={`${ptr(7)} ${ptr(22)}`} onClick={() => navigate('/products/new')}>
                            ÜRÜN EKLE
                        </Button>
                    </ProductTableFilterSection>
                </ProductTableHead>
                <Table
                    count={specialOfferData?.data.result.totalCount}
                    isLoading={false}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    onPageChange={onPageChange}
                    head={productTableHead}
                    result={rowPerPage}
                    rowsData={specialOfferRowData}
                    isClickable={false}
                    page={currentPage}
                    menu={true}
                />
            </ProductTableSection>
        </DashboardLayout>
    )
}

export default Products
