import { Content, ContentBox, GeneralContainer, Key, KeyContent, LastCheck, LeftSide, SummaryContainer, Title, RightSide } from './index.styled'
import { AddCompanyProps } from './type'
import Button from 'components/Buttons/Button/Button'
import Typography from 'components/Typography/Typography'
import { useCompanyUpdate } from 'utils/hooks/queries/Companies'
import { ButtonsSide } from '../index.styled'
import { useNavigate } from 'react-router-dom'
import UpdateSummary from '../../../../assets/Images/UpdateSummary.png'
import { useEffect } from 'react'
import Spinner from '../../../../components/Spinner'

const Summary = ({ handleBack, companyInfo, companyId }: AddCompanyProps) => {
    const navigate = useNavigate()
    const { mutate: reSend, isSuccess, isLoading } = useCompanyUpdate()

    const onSubmit = () => {
        reSend({
            id: Number(companyId),
            name: companyInfo.name,
            taxNumber: companyInfo.taxNumber,
            city: companyInfo.city,
            district: companyInfo.district,
            address: companyInfo.address,
            managerId: companyInfo.managerId,
        })
    }

    useEffect(() => {
        if (isSuccess) {
            navigate(`/companies/${companyId}`)
        }
    }, [isSuccess, navigate, companyId])

    return (
        <GeneralContainer>
            <Spinner open={isLoading} />
            <SummaryContainer>
                <LeftSide>
                    <LastCheck>
                        <Typography variant="h5-medium">Neredeyse Tamam! 🚀</Typography>
                        <Typography>Oluşturmak istediğin firmaya ait bilgileri kontrol ettikten sonra onaylayabilirsin.</Typography>
                    </LastCheck>
                    <Title>Firma Bilgileri</Title>
                    <KeyContent>
                        <ContentBox>
                            <Key>Yönetici Adı</Key>
                        </ContentBox>
                        <ContentBox>
                            <Content>{companyInfo.users?.find((user) => user.id === companyInfo.managerId)?.name}</Content>
                        </ContentBox>
                    </KeyContent>
                    <KeyContent>
                        <ContentBox>
                            <Key>Firma Adı</Key>
                        </ContentBox>
                        <ContentBox>
                            <Content>{companyInfo.name}</Content>
                        </ContentBox>
                    </KeyContent>
                    <KeyContent>
                        <ContentBox>
                            <Key>Vergi No</Key>
                        </ContentBox>
                        <ContentBox>
                            <Content>{companyInfo.taxNumber}</Content>
                        </ContentBox>
                    </KeyContent>
                    <KeyContent>
                        <ContentBox>
                            <Key>İl</Key>
                        </ContentBox>
                        <ContentBox>
                            <Content>{companyInfo.city}</Content>
                        </ContentBox>
                    </KeyContent>
                    <KeyContent>
                        <ContentBox>
                            <Key>İlçe</Key>
                        </ContentBox>
                        <ContentBox>
                            <Content>{companyInfo.district}</Content>
                        </ContentBox>
                    </KeyContent>
                    <KeyContent>
                        <ContentBox>
                            <Key>Adres</Key>
                        </ContentBox>
                        <ContentBox>
                            <Content>{companyInfo.address}</Content>
                        </ContentBox>
                    </KeyContent>
                </LeftSide>
                <RightSide>
                    <img width={115} height={310} src={UpdateSummary} alt="update summary png" />
                </RightSide>
            </SummaryContainer>
            <ButtonsSide>
                <Button onClick={onSubmit} color={'success'} disabled={isLoading}>
                    Güncelle
                </Button>
                <Button variant="outlined" color="secondary" onClick={handleBack}>
                    Geri
                </Button>
            </ButtonsSide>
        </GeneralContainer>
    )
}

export default Summary
