import { Button, styled } from '@mui/material'
import { ptr } from 'utils/helpers'

export const CustomTimeSelectionContainer = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
})

export const CustomTimeSelectionButton = styled(Button)({
    width: ptr(114),
    height: ptr(38),
    variant: 'outlined',
})
