import React from 'react'

import HomeSharpIcon from '@mui/icons-material/HomeSharp'
import SupervisedUserCircleRoundedIcon from '@mui/icons-material/SupervisedUserCircleRounded'
import MapsHomeWorkSharpIcon from '@mui/icons-material/MapsHomeWorkSharp'
import FeedRoundedIcon from '@mui/icons-material/FeedRounded'
import ChatRoundedIcon from '@mui/icons-material/ChatRounded'
import ReportRoundedIcon from '@mui/icons-material/ReportRounded'
import GradeSharpIcon from '@mui/icons-material/GradeSharp'
import StarsSharpIcon from '@mui/icons-material/StarsSharp'
import ListAltIcon from '@mui/icons-material/ListAlt'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive'

import { SideBarContainer } from './SideBar.styled'
import List from 'components/List/List'
import { SideBarProps } from './types'
import Logo from '../Logo/Logo'

const SideBar: React.FunctionComponent<SideBarProps> = () => {
    const menuIconsStyles = {
        fontSize: '1.25rem',
    }

    const menuItems = [
        { text: 'Dashboard', id: '#', icon: <HomeSharpIcon sx={menuIconsStyles} />, path: '/' },

        { subheader: 'KİŞİLER', id: 'users', text: 'Kullanıcılar', icon: <SupervisedUserCircleRoundedIcon sx={menuIconsStyles} />, path: '/users' },
        { text: 'Firmalar', id: 'companies', icon: <MapsHomeWorkSharpIcon sx={menuIconsStyles} />, path: '/companies' },

        { subheader: 'KULLANICI AKSİYONLARI', id: 'requests', text: 'Talepler', icon: <FeedRoundedIcon sx={menuIconsStyles} />, path: '/requests' },
        { text: 'Sohbetler', id: 'chats', icon: <ChatRoundedIcon sx={menuIconsStyles} />, path: '/chats' },

        { subheader: 'KAMPANYALAR', id: 'products', text: 'Ürün Listesi', icon: <ListAltIcon sx={menuIconsStyles} />, path: '/products' },
        { text: 'Siparişler', id: 'orders', icon: <ShoppingCartIcon sx={menuIconsStyles} />, path: '/orders' },
        { text: 'Bildirimler', id: 'notifications', icon: <NotificationsActiveIcon sx={menuIconsStyles} />, path: '/notifications' },

        { subheader: 'DEĞERLENDİRMELER', id: 'complaint', text: 'Şikayetler', icon: <ReportRoundedIcon sx={menuIconsStyles} />, path: '/complaint' },
        { text: 'Kullanıcı Puanlamaları', id: 'user-ratings', icon: <GradeSharpIcon sx={menuIconsStyles} />, path: '/user-ratings' },
        { text: 'Uygulama Puanlamaları', id: 'app-ratings', icon: <StarsSharpIcon sx={menuIconsStyles} />, path: '/app-ratings' },
    ]

    return (
        <SideBarContainer>
            <Logo> ÇıkmaKolay </Logo>
            <List listDetails={menuItems} width="248px" minWidth="inherit" listPadding="0px 0px 6px 12px" itemButtonPadding="0px 0px 0px 17px" gap="10px" />
        </SideBarContainer>
    )
}

export default SideBar
