import React from 'react'

import * as Yup from 'yup'
import { Form, Formik } from 'formik'

import { AddCompanyProps, SubscriptionInformationFormValues } from './type'
import Button from 'components/Buttons/Button/Button'
import CheckBox from 'components/Buttons/Checkbox/Checkbox'

import Typography from 'components/Typography/Typography'
import Tooltip from 'components/Tooltip/Tooltip'
import { BottomCheckboxConteiner, BottomCheckboxContent, CheckboxContainer, SelectAllChecboxDiv, TopCheckboxConteiner, TopCheckboxsConteiner } from './index.styled'
import { ButtonsSide, ErrorMessage } from '../index.styled'

const SubscriptionInformation = ({ handleNext, handleBack, onChange, value, vehicleMakes, vehicleTypes, workCategories }: AddCompanyProps) => {
    const validationSchema = Yup.object({
        workCategories: Yup.array().required('Bu alan zorunludur.').min(1, 'En az birini seçmeniz gerekiyor'),
        vehicleTypes: Yup.array().required('Bu alan zorunludur.').min(1, 'En az birini seçmeniz gerekiyor'),
        vehicleMakes: Yup.array().required('Bu alan zorunludur.').min(1, 'En az birini seçmeniz gerekiyor'),
    })

    const onSubmit = (values: SubscriptionInformationFormValues) => {
        const sortedData = {
            workCategories: [...values.workCategories].sort(),
            vehicleTypes: [...values.vehicleTypes].sort(),
            vehicleMakes: [...values.vehicleMakes].sort(),
        }
        onChange(sortedData)
        handleNext()
    }

    return (
        <Formik initialValues={value} validationSchema={validationSchema} onSubmit={onSubmit} validateOnBlur enableReinitialize>
            {({ isSubmitting, values, handleSubmit, handleChange, isValid, handleBlur, setFieldValue }) => {
                return (
                    <Form>
                        <CheckboxContainer>
                            <TopCheckboxsConteiner>
                                <TopCheckboxConteiner>
                                    <Typography variant="body-small-semibold">Çalıştığı Kategori</Typography>
                                    {workCategories.map((option, index) => (
                                        <div onBlur={handleBlur} key={index}>
                                            <CheckBox
                                                size="small"
                                                name={`workCategories`}
                                                checked={values.workCategories.includes(option)}
                                                label={option}
                                                value={option}
                                                onChange={(e) => handleChange(e)}
                                            />
                                        </div>
                                    ))}
                                    <ErrorMessage name="workCategories" component={'div'} />
                                </TopCheckboxConteiner>
                                <TopCheckboxConteiner>
                                    <Typography variant="body-small-semibold">Vasıta Tipi</Typography>
                                    {vehicleTypes.map((option, index) => (
                                        <div onBlur={handleBlur} key={index}>
                                            <CheckBox
                                                size="small"
                                                name={`vehicleTypes`}
                                                checked={values.vehicleTypes.includes(option)}
                                                label={option}
                                                value={option}
                                                onChange={(e) => handleChange(e)}
                                            />
                                        </div>
                                    ))}
                                    <ErrorMessage name="vehicleTypes" component={'div'} />
                                </TopCheckboxConteiner>
                            </TopCheckboxsConteiner>
                            <BottomCheckboxConteiner className="BottomCheckboxConteiner">
                                <SelectAllChecboxDiv>
                                    <Typography variant="body-small-semibold">Çalıştığı Markalar </Typography>
                                    <Tooltip
                                        name={'Tümünü Seç'}
                                        children={
                                            <CheckBox
                                                checked={values.vehicleMakes.length === vehicleMakes.length}
                                                onChange={(e, checked) => setFieldValue('vehicleMakes', checked ? [...vehicleMakes] : [])}
                                            />
                                        }
                                    ></Tooltip>
                                </SelectAllChecboxDiv>
                                <div>
                                    <BottomCheckboxContent className="BottomCheckboxContent">
                                        {vehicleMakes.map((option, index) => (
                                            <span onBlur={handleBlur} key={index}>
                                                <CheckBox
                                                    size="small"
                                                    name={`vehicleMakes`}
                                                    checked={values.vehicleMakes.includes(option)}
                                                    label={option}
                                                    value={option}
                                                    onChange={(e) => handleChange(e)}
                                                />
                                            </span>
                                        ))}
                                    </BottomCheckboxContent>
                                    <ErrorMessage name="vehicleMakes" component={'div'} />
                                </div>
                            </BottomCheckboxConteiner>
                        </CheckboxContainer>
                        <ButtonsSide>
                            <Button id="next" onClick={handleSubmit} disabled={isSubmitting || !isValid}>
                                İleri
                            </Button>
                            <Button variant="outlined" color="secondary" onClick={handleBack}>
                                Geri
                            </Button>
                        </ButtonsSide>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default SubscriptionInformation
