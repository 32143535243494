import { Box } from '@mui/material'
import { CustomAdminBoxLeft, CustomAdminBoxRight, CustomAdminCard } from './index.styled'
import Typography from 'components/Typography/Typography'
import Button from 'components/Buttons/Button/Button'
import adminPng from '../../../assets/Images/Dashboard/admin.png'
import { ptr } from 'utils/helpers'
import { useNavigate } from 'react-router-dom'
import { AdminCardProps } from './types'

const AdminCard: React.FunctionComponent<AdminCardProps> = ({ admin }) => {
    const navigate = useNavigate()
    return (
        <CustomAdminCard>
            <CustomAdminBoxLeft>
                <Box>
                    <Typography variant="h5-medium">Hoş Geldin, </Typography>
                    <Typography variant="h5-bold"> {admin.name} 🎉 </Typography>
                </Box>
                <Typography variant="body-small-bold">Başka yerde arama, ÇıkmaBurada! 😎 </Typography>
                <Typography variant="body-small-default">Profilinle ilgili düzenlemeleri yapmak için profilini ziyaret edebilirsin.</Typography>
                <Button width={ptr(195)} onClick={() => navigate('/profile')}>
                    PROFİLİ GÖRÜNTÜLE
                </Button>
            </CustomAdminBoxLeft>
            <CustomAdminBoxRight>
                <img src={adminPng} width={307} height={206} alt="admin" />
            </CustomAdminBoxRight>
        </CustomAdminCard>
    )
}

export default AdminCard
