import { CardAvatarBox, CardDetailBox, CustomBadge, CustomCardBox, CustomCardPaper } from './index.styled'
import { Box, Rating } from '@mui/material'
import { colors } from 'styles/color'
import { badgeColor, ptr } from 'utils/helpers'
import { Report } from '@mui/icons-material'
import Typography from 'components/Typography/Typography'
import appRate from '../../../assets/Images/Dashboard/appRate.png'
import complaint from '../../../assets/Images/Dashboard/complaint.png'
import rate from '../../../assets/Images/Dashboard/rate.png'
import { useNavigate } from 'react-router-dom'
import { ISelectDate } from './types'

const CardsWithAvatar: React.FunctionComponent<ISelectDate> = ({ complaintCount, ratingDto, startDate, endDate }) => {
    const navigate = useNavigate()

    const handleClick = (path: string) => {
        navigate(`/${path}`, { state: { startDate: startDate, endDate: endDate } })
    }

    const total =
        Number(ratingDto?.unapprovedReviewsAvg.replace(',', '.')) * ratingDto?.unapprovedReviewsCount + Number(ratingDto?.approvedReviewsAvg.replace(',', '.')) * ratingDto?.approvedReviewsCount

    const result = total / (ratingDto?.unapprovedReviewsCount + ratingDto?.approvedReviewsCount)

    const totalVote = ratingDto?.unapprovedReviewsCount + ratingDto?.approvedReviewsCount

    return (
        <CustomCardBox>
            <CustomCardPaper sx={{ cursor: 'pointer' }} onClick={() => handleClick('app-ratings')}>
                <CardDetailBox>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', flexDirection: 'row' }}>
                        <Typography variant="body-normal-semibold">Uygulama Puanlama</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: ptr(10), justifyContent: 'space-around', flexDirection: 'row' }}>
                        <Rating value={Number(ratingDto?.ratingAvg.replace(',', '.'))} readOnly /> <Typography variant="body-smaller-default">{ratingDto?.ratingCount} Oy</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: ptr(10), justifyContent: 'space-around', flexDirection: 'row' }}>
                        <Typography variant="h5-medium">{!isNaN(Number(ratingDto?.ratingAvg.replace(',', '.'))) ? Number(ratingDto?.ratingAvg.replace(',', '.')).toFixed(2) : 0}</Typography>{' '}
                        <Typography variant="body-smaller-default">Puan</Typography>
                    </Box>
                </CardDetailBox>
                <CardAvatarBox>
                    <img width={100} height={135} src={appRate} alt="appRate png" />
                </CardAvatarBox>
            </CustomCardPaper>
            <CustomCardPaper sx={{ cursor: 'pointer' }} onClick={() => handleClick('user-ratings')}>
                <CardDetailBox>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', flexDirection: 'row' }}>
                        <Typography variant="body-normal-semibold">Satıcı Değerlendirme</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: ptr(10), justifyContent: 'space-around', flexDirection: 'row' }}>
                        <CustomBadge style={{ backgroundColor: badgeColor(totalVote > 0 ? result : 0), width: 'fit-content' }}>
                            <Typography color={colors.white.default} width={'100%'} variant="body-smaller-medium">
                                {totalVote > 0 ? result.toFixed(2) : '-'}
                            </Typography>
                        </CustomBadge>
                        <Typography variant="body-smaller-default">{ratingDto?.approvedReviewsCount + ratingDto?.unapprovedReviewsCount} Oy</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: ptr(10), justifyContent: 'space-around', flexDirection: 'row' }}>
                        <Typography variant="h5-medium">{ratingDto?.approvedReviewsAvg}</Typography> <Typography variant="body-smaller-default">Admin Onaylı Puan</Typography>
                    </Box>
                </CardDetailBox>
                <CardAvatarBox>
                    <img width={100} height={135} src={rate} alt="seller png" />
                </CardAvatarBox>
            </CustomCardPaper>
            <CustomCardPaper sx={{ cursor: 'pointer' }} onClick={() => handleClick('complaint')}>
                <CardDetailBox>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', flexDirection: 'row' }}>
                        <Typography variant="body-normal-semibold">Şikayetler</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: ptr(10), justifyContent: 'space-around', flexDirection: 'row' }}>
                        <Report sx={{ color: colors.error.errorMain }} />
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: ptr(10), justifyContent: 'space-around', flexDirection: 'row' }}>
                        <Typography variant="h5-medium">{complaintCount} </Typography> <Typography variant="body-smaller-default">Şikayet</Typography>
                    </Box>
                </CardDetailBox>
                <CardAvatarBox>
                    <img width={100} height={135} src={complaint} alt="complaint png" />
                </CardAvatarBox>
            </CustomCardPaper>
        </CustomCardBox>
    )
}

export default CardsWithAvatar
