import React from 'react'
import Profile from './profile'
import DashboardLayout from 'layouts/Dashboard/DashboardLayout'
import { ProfileContainer } from './index.styled'
import UserProfileTab from './userProfileTab'
import { useParams } from 'react-router-dom'
import { useGetUserProfile } from 'utils/hooks/queries/Users'
import NotFound from 'pages/notfound'
import Spinner from 'components/Spinner'

const UserProfile = () => {
    const { id } = useParams()

    const { isLoading, isError } = useGetUserProfile({
        queryKeys: {
            id: Number(id),
        },
    })

    return (
        <DashboardLayout>
            <div style={{ minHeight: '100vh' }}>
                <Spinner open={isLoading} />
                {!isLoading && !isError && (
                    <ProfileContainer>
                        <Profile />
                        <UserProfileTab />
                    </ProfileContainer>
                )}
                {!isLoading && isError && <NotFound item={'Kullanıcı'} />}
            </div>
        </DashboardLayout>
    )
}

export default UserProfile
