import { GridColDef } from '@mui/x-data-grid'

export const requestDetailTable: GridColDef[] = [
    { field: 'seller', headerName: 'SATICI' },
    { field: 'status', headerName: 'TEKLİF DURUMU' },
    { field: 'part', headerName: 'PARÇA' },
    { field: 'partCategory', headerName: 'PARÇA KATEGORİSİ' },
    { field: 'offer', headerName: 'VERİLEN TEKLİF' },
    { field: 'creationTime', headerName: 'OLUŞTURMA TARİHİ' },
    { field: 'actions', headerName: 'AKSİYON' },
]

export const EOfferStatus = {
    Accepted: 'Kabul Edildi',
    Rejected: 'Reddedildi',
    CanceledBySeller: 'Satıcı Tarafından İptal Edildi',
    CanceledByBuyer: 'Talep, Sahibi Tarafından Kaldırıldı',
    CancelledByAdmin: 'Teklif Admin Tarafından İptal Edildi',
    InNegotiation: 'Görüşme aşamasında',
    NotInterested: 'İlgilenilmedi',
    NotAnswered: 'Cevaplanmadı',
    ExpiredShop: 'Alışveriş Süresi Sonlandı',
    RevokeOffer: 'Teklif Geri Çekildi',
    BadOffer: 'Teklif En Ucuz İlk 7 Teklif Arasında Yer Almadı',
} as const

export const offerStatusOptions = Object.entries(EOfferStatus).map((status) => ({ label: status[1], value: status[0] }))
