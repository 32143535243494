import { styled, Tab, Tabs } from '@mui/material'
import { colors } from 'styles/color'
import { ptr } from '../../utils/helpers'

export const CustomTabs = styled(Tabs)((props) => ({
    width: '100%',
    '& .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.MuiButtonBase-root.MuiTab-root': {
        color: colors.text.disabledTextLight,
    },
    '& .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected.MuiButtonBase-root.MuiTab-root': {
        color: props.color ? colors.secondary.secondaryMain : colors.primary.primaryMain,
    },
    '& .MuiTabs-indicator.MuiTabs-indicator': {
        background: props.color ? colors.secondary.secondaryMain : colors.primary.primaryMain,
    },
}))
export const CustomTab = styled(Tab)(() => ({
    iconPosition: 'start',
    gap: '10px',
    flex: `1 ${ptr(200)}`,
}))
