import { v4 as uuidv4 } from 'uuid'
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage'
import Compressor from 'compressorjs'
import { FileModel } from './types'

const useFirebase = () => {
    const storage = getStorage()
    const generateKey = () => uuidv4().replaceAll('-', '')

    const compress = (file: File): Promise<File> => {
        return new Promise((resolve, reject) => {
            new Compressor(file, {
                quality: 0.6,
                success: (e) => resolve(e as File),
                error: reject,
            })
        })
    }
    const upload = async (file: File) => {
        const metadata = { contentType: file.type ? file.type : 'image/jpeg' }
        if (file.type.includes('image')) {
            const compressImage = await compress(file)
            const storageRef = ref(storage, 'web/' + generateKey() + '_' + file.name)
            const uploadTask = await uploadBytesResumable(storageRef, compressImage, metadata)
            const url = await getDownloadURL(uploadTask.ref)
            return {
                url,
                type: file.type,
                name: file.name,
            }
        }
    }

    const uploadMultiple = async (files: FileList): Promise<FileModel[]> => {
        let list = []

        for (const prop of Array.from(files)) {
            const up = await upload(prop)
            list.push(up)
        }
        return list as FileModel[]
    }

    return { upload, uploadMultiple }
}

export default useFirebase
