import { Stepper, Step, StepLabel, styled } from '@mui/material'
import { colors } from 'styles/color'

export const CustomStepper = styled(Stepper)({
    '& .MuiStepConnector-root.Mui-active .MuiStepConnector-line': {
        borderColor: colors.primary.primaryMain,
        borderTopWidth: '3px',
    },
    '& .MuiStepConnector-root.Mui-completed .MuiStepConnector-line': {
        borderColor: colors.primary.primaryMain,
        borderTopWidth: '3px',
    },
    '& .MuiStepConnector-root .MuiStepConnector-line': {
        display: 'none',
    },
})

export const CustomStep = styled(Step)({})

export const CustomBox = styled('div')({
    display: 'flex',
    alignItems: 'flex-start',
    minWidth: '15.625rem',
    '&.MuiContainer-root ': {
        width: ' 100%',
    },
})

export const CustomStepLabel = styled(StepLabel)({
    '& .MuiSvgIcon-root.Mui-active': {
        color: colors.primary.primaryMain,
        backgroundColor: colors.white.default,
    },
    '& .MuiSvgIcon-root.Mui-completed': {
        color: colors.primary.primaryMain,
        backgroundColor: colors.white.default,
        borderRadius: '100%',
    },
    '& .MuiStepLabel-label.Mui-active': {
        color: colors.text.primaryTextLight,
        fontWeight: '500',
        fontFamily: 'Inter',
    },
    '& .MuiStepLabel-label.Mui-completed': {
        color: colors.text.primaryTextLight,
        fontWeight: '500',
        fontFamily: 'Inter',
    },
    '& .MuiStepLabel-label': {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        fontSize: '2.125rem',
        color: colors.text.disabledTextLight,
        fontWeight: '500',
        fontFamily: 'Inter',
    },
    '& .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiStepIcon-root.Mui-completed.MuiSvgIcon-root.MuiStepIcon-root': {
        border: '0px',
        color: colors.primary.primaryMain,
    },
    '& .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiStepIcon-root.Mui-active.MuiSvgIcon-root.MuiStepIcon-root': {
        border: '5px solid',
        borderColor: colors.primary.primaryMain,
        borderRadius: '50%',
    },
    '& .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiStepIcon-root.MuiSvgIcon-root.MuiStepIcon-root': {
        color: colors.white.default,
    },
    '& .MuiStepConnector-root ..MuiStepConnector-lineVertical': {
        display: 'none',
    },
})

export const StepperContent = styled('div')({
    padding: '10px 0px 0px 24px',
})
