import { alpha, Box, Button, ImageListItem, styled } from '@mui/material'
import { ptr } from '../../../../utils/helpers'
import { colors } from '../../../../styles/color'

export const ProductImageItem = styled(ImageListItem)({
    height: `${ptr(84)} !important`,
    width: ptr(96),
    position: 'relative',
    ':hover .photo-overlay': {
        display: 'flex',
    },
})
export const ProductImageItemImg = styled('img')({
    height: ptr(84),
    width: ptr(96),
    objectFit: 'contain',
    borderRadius: ptr(4),
})
export const UploadPhotosButton = styled(Button)({
    backgroundColor: colors.primary.primaryMain,
    ':hover': {
        backgroundColor: colors.primary.primaryAlternativeDark,
    },
})

export const PhotoOverlay = styled(Box)({
    height: '100%',
    width: '100%',
    backgroundColor: alpha(colors.primary.primaryMain, 0.5),
    position: 'absolute',
    zIndex: '1',
    display: 'none',
    borderRadius: ptr(4),
    justifyContent: 'center',
    alignItems: 'center',
})
