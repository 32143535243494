import { Box, Divider, styled } from '@mui/material'
import { ptr } from '../../../utils/helpers'
import { colors } from 'styles/color'
import { ErrorMessage as CustomErrorMessage } from 'formik'

export const apperance = {
    padding: `${ptr(7)} ${ptr(22)}`,
    width: 'fit-content',
}

export const Header = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: ptr(8),
    paddingBottom: ptr(30),
})

export const HeaderTitle = styled(Box)({
    display: 'flex',
    gap: ptr(4),
})

export const Content = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
})

export const ContentRow = styled(Box)({
    display: 'flex',
    width: '100%',
    gap: ptr(10),
})

export const FooterSection = styled(Box)({
    display: 'flex',
})

export const ModalBody = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: ptr(10),
    padding: ptr(20),
})

export const ButtonGroup = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    gap: ptr(10),
    width: '100%',
    marginTop: ptr(16),
})

export const StepContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: ptr(10),
    width: ptr(147),
})

export const LastStepInformationContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: ptr(10),
    width: '100%',
    marginTop: 'auto',
})

export const LastStepInformationRow = styled(Box)({
    display: 'flex',
    gap: ptr(12),
    width: '100%',
})

export const LastStepInformationItem = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
})

export const LastStepDivider = styled(Divider)({
    margin: `${ptr(10)} 0`,
})
export const ErrorMessage = styled(CustomErrorMessage)(() => ({
    color: colors.text.secondaryTextLight,
    fontSize: '14px',
}))
