import { Service } from 'services/types'
import { Request } from 'services/_base'
import { GET_ALL_NOTIFICATION, POST_NOTIFICATION } from './constants'
import { CreateNotificationModel, GetNotificationsVariables } from './types'

export const createNotification: Service<CreateNotificationModel> = (data) => {
    return Request.post(POST_NOTIFICATION, data, {})
}
export const getAllNotifications: Service<GetNotificationsVariables> = ({ queryKey }) => {
    const { params } = queryKey[1]
    return Request.get(GET_ALL_NOTIFICATION, {
        role: params.role,
        position: params.position,
        status: params.status,
        IsPhoneConfirmed: params.isPhoneConfirmed,
        keyword: params.keyword,
        startDate: params.startDate,
        endDate: params.endDate,
        maxResultCount: params.maxResultCount,
        skipCount: params.skipCount,
        workCategory: params.workCategory,
        vehicleType: params.vehicleType,
        vehicleMake: params.vehicleMake,
    })
}
