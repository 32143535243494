import { Box, Button, styled } from '@mui/material'

export const Container = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100vh',
    width: '100%',
    zIndex: '9999',
})
export const CustomTextBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '60px',
})

export const CustomButton = styled(Button)({
    backgroundColor: '#FFAA00',
    marginTop: '30px',
    zIndex: '9999',
    ' &:hover': {
        backgroundColor: '#CC8800',
    },
})
