import { Badge, Box, Card, LinearProgress, LinearProgressProps, styled, Typography } from '@mui/material'
import { colors } from 'styles/color'
import { ptr } from 'utils/helpers'

export const MainPageContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    marginTop: ptr(44),
    marginRight: ptr(24),
    marginLeft: ptr(24),
    minHeight: '100vh',
    gap: ptr(24),
    width: '100%',
})
export const CustomAdminBox = styled(Box)({
    width: '100%',
})

export const CustomTimeSelectionBox = styled(Box)({
    width: '100%',
})

export const CustomChartContainer = styled(Box)({
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: ptr(650),
    gap: ptr(24),
})

export const CustomChartContainerCampaigns = styled(Box)({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    gap: ptr(24),
})

export const CustomChartBox = styled(Box)({
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
    width: '100%',
    height: ptr(650),
    background: colors.white.default,
    boxShadow: '0px 2px 10px rgba(76, 78, 100, 0.22)',
    borderRadius: '4px',
    padding: ptr(16),
    gap: ptr(8),
})

export const CustomChartLeftBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: ptr(330),
})

export const CustomChartMiddleBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: '100%',
    height: ptr(330),
    padding: ptr(16),
})

export const CustomListBox = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirections: 'row',
    gap: ptr(12),
})

export const CustomListBoxHead = styled(Box)({
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: '100%',
})

export const CustomListBoxHeadRight = styled(Box)({
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: ptr(20),
})

export const CustomListBoxListRight = styled(Box)({
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: '100%',
})

export const CustomListBoxChild = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
})

export const CustomChartBoxCampaigns = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '70%',
    height: ptr(370),
    background: colors.white.default,
    boxShadow: '0px 2px 10px rgba(76, 78, 100, 0.22)',
    borderRadius: '4px',
    padding: ptr(16),
})

export const CustomChartBoxCampaignsRight = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'flex-start',
    width: '30%',
    height: ptr(370),
    background: colors.white.default,
    boxShadow: '0px 2px 10px rgba(76, 78, 100, 0.22)',
    borderRadius: '4px',
    padding: ptr(16),
    gap: ptr(12),
})

export const CustomBox = styled(Box)({
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: ptr(400),
    gap: ptr(24),
})

export const CustomCard = styled(Card)({
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
    width: '100%',
    height: ptr(400),
    background: colors.white.default,
    boxShadow: '0px 2px 10px rgba(76, 78, 100, 0.22)',
    borderRadius: '4px',
    padding: ptr(16),
    justifyContent: 'space-between',
})

export const CustomCardPopular = styled(Card)({
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
    width: '100%',
    height: ptr(400),
    background: colors.white.default,
    boxShadow: '0px 2px 10px rgba(76, 78, 100, 0.22)',
    borderRadius: '4px',
    padding: ptr(16),
    gap: ptr(8),
})

export const LinearProgressWithLabel = (props: LinearProgressProps & { value: number }) => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <CustomLinearProgress color="success" variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    )
}

export const CustomBadge = styled(Badge)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4px 4px',
    borderRadius: '4px',
    width: ptr(30),
    height: ptr(20),
    textAlign: 'center',
})

export const CustomBadgeCampaigns = styled(Badge)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4px 50px 4px 4px',
    borderRadius: '4px',
    width: ptr(30),
    height: ptr(20),
    textAlign: 'center',
})

export const CustomChartTypoBox = styled(Box)({
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
})

export const CustomChartTypoBoxCamp = styled(Box)({
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: '100%',
})

export const CustomChartTypoBoxCampLeft = styled(Box)({
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: '100%',
})

export const CustomChartTypoBoxCampaigns = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'start',
    width: '66%',
    height: ptr(330),
})

export const CustomChartTypoBoxCampaignsDiv = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '33%',
    height: ptr(330),
})

export const CustomCardDivider = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
    gap: ptr(24),
})

export const CustomLinearProgress = styled(LinearProgress)({
    color: 'red',
    '&.MuiLinearProgress-colorPrimary': {
        background: '#FFDD99',
    },
    '& .MuiLinearProgress-bar': {
        background: colors.primary.primaryMain,
    },
})
