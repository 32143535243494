import { Service } from 'services/types'
import { Request } from 'services/_base'
import {
    GET_ALL_COMPANIES,
    GET_COMPANY,
    GET_COMPANY_COUNTS,
    GET_COMPANY_PROFILE,
    GET_COMPANY_PROFILE_COUNT,
    POST_CURRENT_COMPANY,
    PUT_ACTIVE_COMPANY,
    PUT_COMPANY_UPDATE,
    PUT_DEACTIVATE_COMPANY,
} from './constants'
import { CompanyModel, CreateCompanyModel, GetCompaniesVariables, GetCompanyProfileVariables, GetCompanyUpdateVariables } from './types'

export const getAllCompanies: Service<GetCompaniesVariables> = ({ queryKey }) => {
    const { params } = queryKey[1]
    return Request.get(GET_ALL_COMPANIES(), {
        maxResultCount: params.maxResultCount,
        skipCount: params.skipCount,
        filter: params.filter,
        city: params.city,
        status: params.status,
        startDate: params.startDate,
        endDate: params.endDate,
        role: params.role,
    })
}

export const getAllCompaniesCount: Service<GetCompaniesVariables> = ({ queryKey }) => {
    const { params } = queryKey[1]
    return Request.get(GET_COMPANY_COUNTS(), {
        maxResultCount: params.maxResultCount,
        skipCount: params.skipCount,
        filter: params.filter,
        city: params.city,
        status: params.status,
        startDate: params.startDate,
        endDate: params.endDate,
        role: params.role,
    })
}

export const createCompany: Service<CreateCompanyModel> = (data) => {
    return Request.post(POST_CURRENT_COMPANY, data, {})
}

export const deactiveCompany: Service<CompanyModel> = ({ id }) => {
    return Request.put(PUT_DEACTIVATE_COMPANY(id), {}, {})
}

export const activeCompany: Service<CompanyModel> = ({ id }) => {
    return Request.put(PUT_ACTIVE_COMPANY(id), {}, {})
}

export const companyUpdate: Service<GetCompanyUpdateVariables> = ({ id, taxNumber, managerId, name, address, isCompany, city, district }) => {
    return Request.put(PUT_COMPANY_UPDATE, { id, taxNumber, managerId, name, address, isCompany, city, district }, {})
}

export const getCompanyProfile: Service<any> = ({ queryKey }) => {
    const { params } = queryKey[1]

    return Request.get(GET_COMPANY(params.id), {
        id: params.id,
    })
}

export const getCompanyWithId: Service<any> = ({ queryKey }) => {
    const { params } = queryKey[1]

    return Request.get(GET_COMPANY(params.id), {})
}

export const getCompany: Service<GetCompanyProfileVariables> = ({ queryKey }) => {
    const { params } = queryKey[1]
    return Request.get(GET_COMPANY_PROFILE(params.id), {
        maxResultCount: params.maxResultCount,
        skipCount: params.skipCount,
        id: params.id,
    })
}

export const getCompanyProfileCount: Service<any> = ({ queryKey }) => {
    const { params } = queryKey[1]

    return Request.get(GET_COMPANY_PROFILE_COUNT(params.id), {
        id: params.id,
    })
}
