import { CustomBoxColumn, CustomCardBox, CustomCardPaper } from './index.styled'
import { Badge } from '@mui/material'
import { colors } from 'styles/color'
import { ptr } from 'utils/helpers'
import { ListAltOutlined, ListOutlined, ShoppingCartOutlined } from '@mui/icons-material'
import Typography from 'components/Typography/Typography'
import React from 'react'
import { SpecialOfferCountProps } from './types'

const CardsWithIconCampaigns: React.FunctionComponent<SpecialOfferCountProps> = ({ specialOfferCount }) => (
    <CustomCardBox>
        <CustomCardPaper>
            <Badge
                sx={{
                    background: colors.primary.primaryLightBg,
                    color: colors.primary.primaryMain,
                    borderRadius: '8px',
                    height: ptr(40),
                    width: ptr(40),
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <ListAltOutlined fontSize="large" />
            </Badge>
            <CustomBoxColumn>
                <Typography color={colors.text.secondaryTextLight} variant="body-small-default">
                    Toplam Ürün Sayısı
                </Typography>
                <Typography variant="h6-medium">{specialOfferCount?.totalProductCount}</Typography>
            </CustomBoxColumn>
        </CustomCardPaper>
        <CustomCardPaper>
            <Badge
                sx={{
                    background: colors.info.infoLightBg,
                    color: colors.info.infoMain,
                    borderRadius: '8px',
                    height: ptr(40),
                    width: ptr(40),
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <ListOutlined fontSize="large" />
            </Badge>
            <CustomBoxColumn>
                <Typography color={colors.text.secondaryTextLight} variant="body-small-default">
                    Toplam Aktif Ürünler
                </Typography>
                <Typography variant="h6-medium">{specialOfferCount?.totalActiveProductCount}</Typography>
            </CustomBoxColumn>
        </CustomCardPaper>
        <CustomCardPaper>
            <Badge
                sx={{
                    background: colors.white.greyLight,
                    color: colors.secondary.secondaryMain,
                    borderRadius: '8px',
                    height: ptr(40),
                    width: ptr(40),
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <ListOutlined fontSize="large" />
            </Badge>
            <CustomBoxColumn>
                <Typography color={colors.text.secondaryTextLight} variant="body-small-default">
                    Toplam Pasif Ürünler
                </Typography>
                <Typography variant="h6-medium">{specialOfferCount?.totalPassiveProductCount}</Typography>
            </CustomBoxColumn>
        </CustomCardPaper>
        <CustomCardPaper>
            <Badge
                sx={{
                    background: colors.success.SuccessLightBg,
                    color: colors.success.successMain,
                    borderRadius: '8px',
                    height: ptr(40),
                    width: ptr(40),
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <ShoppingCartOutlined fontSize="large" />
            </Badge>
            <CustomBoxColumn>
                <Typography color={colors.text.secondaryTextLight} variant="body-small-default">
                    Toplam Sipariş
                </Typography>
                <Typography variant="h6-medium">{specialOfferCount?.totalOrderCount}</Typography>
            </CustomBoxColumn>
        </CustomCardPaper>
    </CustomCardBox>
)

export default CardsWithIconCampaigns
