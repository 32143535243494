import { useEffect, useState } from 'react'

import Stepper from 'components/Stepper/Stepper'
import CompanyInfo from './CompanyInfo/CompanyInfo'
import CompanyAuthorizedInformation from './CompanyAuthorizedInformation/CompanyAuthorizedInformation'
import { CompanyInfoFormValues } from './CompanyInfo/type'
import { CompanyAuthorizedInformationFormValues } from './CompanyAuthorizedInformation/type'
import SubscriptionInformation from './SubscriptionInformation/SubscriptionInformation'
import Summary from './Summary/Summary'
import { SubscriptionInformationFormValues } from './SubscriptionInformation/type'
import { useNavigate } from 'react-router-dom'
import DashboardLayout from 'layouts/Dashboard/DashboardLayout'
import { StepperContainer, StepperContent, StepperForm } from './AddCompany.styled'
import { useGetVehicleMakes, useGetVehicleTypes, useGetWorkCategory } from 'utils/hooks/queries/LookUp'

const AddCompanies = () => {
    const { data: vehicleMakesData } = useGetVehicleMakes({ queryKeys: {}, enabled: true })
    const { data: vehicleTypesData } = useGetVehicleTypes({ queryKeys: {}, enabled: true })
    const { data: workCategoriesData } = useGetWorkCategory({ queryKeys: {}, enabled: true })
    const [vehicleMakes, setVehicleMakes] = useState<string[]>([])
    const [vehicleTypes, setVehicleTypes] = useState<string[]>([])
    const [workCategories, setWorkCategories] = useState<string[]>([])
    const [selectedStep, setSelectedStep] = useState(0)
    const [companyInfo, setCompanyInfo] = useState({} as CompanyInfoFormValues)
    const [authorizedInfo, setAuthorizedInfo] = useState({
        bankAccounts: [
            {
                bankName: '',
                ibanNumber: '',
                nameSurname: '',
            },
        ],
    } as CompanyAuthorizedInformationFormValues)
    const [subscriptionInfo, setSubscriptionInfo] = useState({
        workCategories: [],
        vehicleMakes: [],
        vehicleTypes: [],
    } as SubscriptionInformationFormValues)

    const navigate = useNavigate()

    useEffect(() => {
        setVehicleMakes(vehicleMakesData?.data?.result?.map((child) => child.name) ?? [])
    }, [vehicleMakesData])

    useEffect(() => {
        setWorkCategories(workCategoriesData?.data?.result?.map((child) => child.name) ?? [])
    }, [workCategoriesData])

    useEffect(() => {
        setVehicleTypes(vehicleTypesData?.data?.result?.map((child) => child.name) ?? [])
    }, [vehicleTypesData])

    const onChangeCompanyInfo = (val: CompanyInfoFormValues) => {
        setCompanyInfo((prevState) => ({
            ...prevState,
            ...val,
        }))
    }
    const onChangeAuthorizedInformation = (val: CompanyAuthorizedInformationFormValues) => {
        setAuthorizedInfo((prevState) => ({
            ...prevState,
            ...val,
        }))
    }
    const onChangeSubscriptionInformation = (val: SubscriptionInformationFormValues) => {
        setSubscriptionInfo((prevState) => ({
            ...prevState,
            ...val,
        }))
    }

    const handleNext = () => {
        setSelectedStep(selectedStep + 1)
    }

    const handleBack = () => {
        selectedStep !== 0 ? setSelectedStep(selectedStep - 1) : navigate('/companies')
    }

    const steps = [
        { label: 'Firma Bilgileri', stepNumber: '01' },
        { label: 'Firma Yetkili Bilgileri', stepNumber: '02' },
        { label: 'Çalışma Bilgileri', stepNumber: '03' },
        { label: 'Özet', stepNumber: '04' },
    ]
    return (
        <DashboardLayout>
            <StepperContainer>
                <Stepper steps={steps} actStep={selectedStep} orientation="vertical" />
                <StepperForm className="stepper-form">
                    <StepperContent className="stepper-content">
                        {selectedStep === 0 && <CompanyInfo handleBack={handleBack} handleNext={handleNext} value={companyInfo} onChange={onChangeCompanyInfo} />}
                        {selectedStep === 1 && <CompanyAuthorizedInformation handleBack={handleBack} handleNext={handleNext} value={authorizedInfo} onChange={onChangeAuthorizedInformation} />}
                        {selectedStep === 2 && (
                            <SubscriptionInformation
                                workCategories={workCategories}
                                vehicleTypes={vehicleTypes}
                                vehicleMakes={vehicleMakes}
                                handleBack={handleBack}
                                handleNext={handleNext}
                                value={subscriptionInfo}
                                onChange={onChangeSubscriptionInformation}
                            />
                        )}
                        {selectedStep === 3 && <Summary handleBack={handleBack} companyInfo={companyInfo} authorizedInfo={authorizedInfo} subscriptionInfo={subscriptionInfo} />}
                    </StepperContent>
                </StepperForm>
            </StepperContainer>
        </DashboardLayout>
    )
}

export default AddCompanies
