import React from 'react'

import * as Yup from 'yup'
import { Form, Formik } from 'formik'

import Button from 'components/Buttons/Button/Button'
import CheckBox from 'components/Buttons/Checkbox/Checkbox'
import { BottomCheckboxConteiner, BottomCheckboxContent, CheckboxContainer, TopCheckboxConteiner, TopCheckboxsConteiner } from './index.styled'

import Typography from 'components/Typography/Typography'
import { ButtonsSide, ErrorMessage } from '../index.styled'
import { SubscriptionInformationFormValues, UpdateUserProps } from './type'
import Tooltip from 'components/Tooltip/Tooltip'

const SubscriptionInformation: React.FunctionComponent<UpdateUserProps> = ({ vehicleMakesData, vehicleTypesData, workCategoriesData, handleNext, handleBack, onChange, value }) => {
    const validationSchema = Yup.object({
        workCategories: Yup.array().required('Bu alan zorunludur.').min(1, 'En az birini seçmeniz gerekiyor'),
        vehicleTypes: Yup.array().required('Bu alan zorunludur.').min(1, 'En az birini seçmeniz gerekiyor'),
        vehicleMakes: Yup.array().required('Bu alan zorunludur.').min(1, 'En az birini seçmeniz gerekiyor'),
    })

    const onSubmit = (values: SubscriptionInformationFormValues) => {
        const sortedData = {
            workCategories: [...values.workCategories].sort(),
            vehicleTypes: [...values.vehicleTypes].sort(),
            vehicleMakes: [...values.vehicleMakes].sort(),
        }
        onChange(sortedData)
        handleNext()
    }

    const checkAllBrand = (val: boolean) => {
        if (val) {
            return vehicleMakesData.map((item) => item.name)
        } else {
            return []
        }
    }

    const onChangeVehicleMakes = (name: string, checked: boolean) => {
        if (checked) {
            return [...value.vehicleMakes, name]
        } else {
            return value.vehicleMakes.filter((item) => item !== name)
        }
    }

    const onChangeWorkCategories = (name: string, checked: boolean) => {
        if (checked) {
            return [...value.workCategories, name]
        } else {
            return value.workCategories.filter((item) => item !== name)
        }
    }

    const onChangeVehicleTypes = (name: string, checked: boolean) => {
        if (checked) {
            return [...value.vehicleTypes, name]
        } else {
            return value.vehicleTypes.filter((item) => item !== name)
        }
    }
    const subscriptionsIsValid = () => {
        if (value.vehicleTypes.length !== 0 && value.workCategories.length !== 0 && value.vehicleMakes.length !== 0) return true
        else return false
    }

    return (
        <Formik initialValues={value} validationSchema={validationSchema} onSubmit={onSubmit} validateOnBlur enableReinitialize>
            {({ isSubmitting, values, handleChange, handleSubmit, isValid, handleBlur }) => (
                <Form>
                    <CheckboxContainer>
                        <TopCheckboxsConteiner>
                            <TopCheckboxConteiner>
                                <Typography variant="body-small-semibold">Çalıştığı Kategori</Typography>
                                {workCategoriesData.sort().map((option, index) => (
                                    <div onBlur={handleBlur} key={index}>
                                        <CheckBox
                                            size="small"
                                            name={`workCategories`}
                                            checked={value.workCategories.includes(option.name)}
                                            label={option.name}
                                            value={option.name}
                                            onChange={(e) => onChange(onChangeWorkCategories(option.name, e.target.checked), 'workCategories')}
                                        />
                                    </div>
                                ))}
                                <ErrorMessage name="workCategories" component={'div'} />
                            </TopCheckboxConteiner>
                            <TopCheckboxConteiner>
                                <Typography variant="body-small-semibold">Vasıta Tipi</Typography>
                                {vehicleTypesData.sort().map((option, index) => (
                                    <div onBlur={handleBlur} key={index}>
                                        <CheckBox
                                            size="small"
                                            name={`vehicleTypes`}
                                            checked={value.vehicleTypes.includes(option.name)}
                                            label={option.name}
                                            value={option.name}
                                            onChange={(e) => onChange(onChangeVehicleTypes(option.name, e.target.checked), 'vehicleTypes')}
                                        />
                                    </div>
                                ))}
                                <ErrorMessage name="vehicleTypes" component={'div'} />
                            </TopCheckboxConteiner>
                        </TopCheckboxsConteiner>
                        <BottomCheckboxConteiner className="BottomCheckboxConteiner">
                            <Typography variant="body-small-semibold">
                                Çalıştığı Markalar{' '}
                                <span>
                                    <Tooltip
                                        name={'Tümünü Seç'}
                                        children={
                                            <CheckBox checked={values.vehicleMakes.length === vehicleMakesData.length} onChange={(e) => onChange(checkAllBrand(e.target.checked), 'vehicleMakes')} />
                                        }
                                    ></Tooltip>
                                </span>
                            </Typography>{' '}
                            <div>
                                <BottomCheckboxContent className="BottomCheckboxContent">
                                    {vehicleMakesData.sort().map((option, index) => (
                                        <span onBlur={handleBlur} key={index}>
                                            <CheckBox
                                                size="small"
                                                name={'vehicleMakes'}
                                                checked={value.vehicleMakes.includes(option.name)}
                                                label={option.name}
                                                value={option.name}
                                                onChange={(e) => onChange(onChangeVehicleMakes(option.name, e.target.checked), 'vehicleMakes')}
                                            />
                                        </span>
                                    ))}
                                </BottomCheckboxContent>
                                <ErrorMessage name="vehicleMakes" component={'div'} />
                            </div>
                        </BottomCheckboxConteiner>
                    </CheckboxContainer>
                    <ButtonsSide>
                        <Button id="next" onClick={handleSubmit} disabled={isSubmitting || !isValid || !subscriptionsIsValid()}>
                            İleri
                        </Button>
                        <Button variant="outlined" color="secondary" onClick={handleBack}>
                            Geri
                        </Button>
                    </ButtonsSide>
                </Form>
            )}
        </Formik>
    )
}

export default SubscriptionInformation
