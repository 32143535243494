import requestsService from 'services/be-api/requests'
import { useBaseMutation, useBaseQuery } from '../_Base'
import { Mutation, Query } from '../types'
import {
    AdminCancelOfferVariables,
    RequestDetailResponse,
    RequestDetailVariables,
    RequestFilterDto,
    RequestOfferResponse,
    RequestOfferVariables,
    RequestResponse,
    RequestStatisticCountResponse,
    WithdrawRequestVariables,
} from '../../../../services/be-api/requests/types'
import { RequestParams } from '../../../../services/types'

export const useRequestStatisticCount: Query<RequestFilterDto, RequestStatisticCountResponse> = (params) => {
    return useBaseQuery({
        queryKeys: ['requests_count', { params: params.queryKeys }],
        service: requestsService.getRequestsStatisticCount,
        onSuccess: {
            messageDisplay: false,
            message: 'Successful, get requests statistic count.',
        },
        onLoading: {
            messageDisplay: false,
            message: 'Loading',
        },
        onError: {
            messageDisplay: false,
        },
    })
}

export const useRequests: Query<RequestParams<RequestFilterDto>, RequestResponse> = (params) => {
    return useBaseQuery({
        queryKeys: ['requests', { params: params.queryKeys }],
        service: requestsService.getRequests,
        onSuccess: {
            messageDisplay: false,
            message: 'Successful, get requests statistic count.',
        },
        onLoading: {
            messageDisplay: false,
            message: 'Loading',
        },
        onError: {
            messageDisplay: false,
        },
    })
}

export const useOffersByRequestId: Query<RequestOfferVariables, RequestOfferResponse> = (params) => {
    return useBaseQuery({
        queryKeys: ['get_offers_by_request_id', { params: params.queryKeys }],
        service: requestsService.getOffersByRequestId,
        onSuccess: {
            messageDisplay: false,
            message: 'Successful, get requests statistic count.',
        },
        onLoading: {
            messageDisplay: false,
            message: 'Loading',
        },
        onError: {
            messageDisplay: false,
        },
    })
}

export const useRequestDetail: Query<RequestDetailVariables, RequestDetailResponse> = (params) => {
    return useBaseQuery({
        queryKeys: ['request_detail', { params: params.queryKeys }],
        service: requestsService.getRequestDetail,
        onSuccess: {
            messageDisplay: false,
            message: 'Successful, get requests statistic count.',
        },
        onLoading: {
            messageDisplay: false,
            message: 'Loading',
        },
        onError: {
            messageDisplay: false,
        },
    })
}

export const useWithdrawRequest: Mutation<WithdrawRequestVariables, void> = () => {
    return useBaseMutation({
        service: requestsService.withdrawTheRequest,
        onSuccess: {
            messageDisplay: true,
            message: 'Talep Yayından Kaldırıldı',
        },
        onLoading: {
            messageDisplay: true,
            message: 'Talep Yayından Kaldırılıyor',
        },
        onError: {
            message: 'Iptal etme sebebini eklerken hata olustu',
            messageDisplay: true,
        },
    })
}

export const useAdminCancelOffer: Mutation<AdminCancelOfferVariables, void> = () => {
    return useBaseMutation({
        service: requestsService.adminCancelOffer,
        onSuccess: {
            messageDisplay: true,
            message: 'Teklif İptal Edildi',
        },
        onLoading: {
            messageDisplay: true,
            message: 'Loading',
        },
        onError: {
            message: 'Iptal etme sebebini eklerken hata olustu',
            messageDisplay: true,
        },
    })
}
