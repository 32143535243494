import { CustomBoxColumn, CustomCardBox, CustomCardPaper } from './index.styled'
import { Badge } from '@mui/material'
import { colors } from 'styles/color'
import { ptr } from 'utils/helpers'
import { Sell, ShoppingBasket, TextSnippetOutlined } from '@mui/icons-material'
import Typography from 'components/Typography/Typography'
import { DemandStatusProps } from './types'

const CardsWithIconUserAct: React.FunctionComponent<DemandStatusProps> = ({ demandStatus }) => (
    <CustomCardBox>
        <CustomCardPaper>
            <Badge
                sx={{
                    background: colors.primary.primaryLightBg,
                    color: colors.primary.primaryMain,
                    borderRadius: '8px',
                    height: ptr(40),
                    width: ptr(40),
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <TextSnippetOutlined fontSize="large" />
            </Badge>
            <CustomBoxColumn>
                <Typography color={colors.text.secondaryTextLight} variant="body-small-default">
                    Toplam Açılan Talep
                </Typography>
                <Typography variant="h6-medium">{demandStatus?.totalDemandCount}</Typography>
            </CustomBoxColumn>
        </CustomCardPaper>
        <CustomCardPaper>
            <Badge
                sx={{
                    background: colors.success.SuccessLightBg,
                    color: colors.success.successMain,
                    borderRadius: '8px',
                    height: ptr(40),
                    width: ptr(40),
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Sell fontSize="large" />
            </Badge>
            <CustomBoxColumn>
                <Typography color={colors.text.secondaryTextLight} variant="body-small-default">
                    Toplam Verilen Teklif
                </Typography>
                <Typography variant="h6-medium">{demandStatus?.totalOfferCount}</Typography>
            </CustomBoxColumn>
        </CustomCardPaper>
        <CustomCardPaper>
            <Badge
                sx={{
                    background: colors.info.infoLightBg,
                    color: colors.info.infoMain,
                    borderRadius: '8px',
                    height: ptr(40),
                    width: ptr(40),
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <ShoppingBasket fontSize="large" />
            </Badge>
            <CustomBoxColumn>
                <Typography color={colors.text.secondaryTextLight} variant="body-small-default">
                    Toplam Alışveriş Miktarı
                </Typography>
                <Typography variant="h6-medium">{demandStatus?.totalPurchasedDemandCount}</Typography>
            </CustomBoxColumn>
        </CustomCardPaper>
    </CustomCardBox>
)

export default CardsWithIconUserAct
