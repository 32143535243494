export const GET_ALL_TOP_SPECIAL_OFFER = '/dashboard/top-special-offer'
export const GET_ALL_TOP_CATEGORY_DEMAND = '/dashboard/top-category-demand'
export const GET_ALL_TOP_SELLER_DEMAND = '/dashboard/top-seller-demand'
export const GET_ALL_TOP_BUYER_DEMAND = '/dashboard/top-buyer-demand'
export const GET_ALL_TOP_BUYER_SPECIAL_OFFER = '/dashboard/top-buyer-special-offer'
export const GET_ALL_TOP_CATEGORY_SPECIAL_OFFER = '/dashboard/top-category-special-offer'
export const GET_ALL_SPECIAL_OFFER_CHART = '/dashboard/special-offer-chart'
export const GET_ALL_USER_CHART = '/dashboard/user-chart'
export const GET_TOP_CATEGORY_SPECIAL_OFFER = '/dashboard/top-category-special-offer-chart'
export const GET_TOP_CATEGORY_DEMAND_OFFER = '/dashboard/top-category-demand-offer-chart'
