import { Box, Card, styled } from '@mui/material'
import { colors } from 'styles/color'
import { ptr } from 'utils/helpers'

export const CustomAdminCard = styled(Card)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px 0px 0px 20px',
    width: '100%',
    height: ptr(206),
    background: colors.white.default,
    boxShadow: '0px 2px 10px rgba(76, 78, 100, 0.22)',
    borderRadius: '10px',
})

export const CustomAdminBoxLeft = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    textAlign: 'center',
    gap: ptr(12),
})

export const CustomAdminBoxRight = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    textAlign: 'center',
    gap: ptr(12),
    marginTop: '10px',
})
