import { useBaseMutation, useBaseQuery } from '../_Base'
import ordersService from '../../../../services/be-api/orders'
import { Mutation, Query } from '../types'
import { RequestParams } from '../../../../services/types'
import { GetOrderHistoryVariables, GetOrderResponse, OrderFilterVariables, OrderHistoriesResponse, UpdateOrderStatusVariables } from '../../../../services/be-api/orders/types'

export const useGetOrders: Query<RequestParams<OrderFilterVariables>, GetOrderResponse> = (params) => {
    return useBaseQuery({
        queryKeys: ['Orders', { params: params.queryKeys }],
        service: ordersService.getOrders,
        onSuccess: {
            messageDisplay: false,
            message: 'Successful, get requests statistic count.',
        },
        onLoading: {
            messageDisplay: false,
            message: 'Loading',
        },
        onError: {
            messageDisplay: false,
        },
        cacheTime: 0,
    })
}

export const useUpdateOrderStatus: Mutation<UpdateOrderStatusVariables, any> = () => {
    return useBaseMutation({
        service: ordersService.updateOrderStatus,
        onSuccess: {
            messageDisplay: false,
            message: 'Successful, get requests statistic count.',
        },
        onLoading: {
            messageDisplay: false,
            message: 'Loading',
        },
        onError: {
            messageDisplay: false,
        },
    })
}

export const useGetOrderHistory: Query<GetOrderHistoryVariables, OrderHistoriesResponse> = (params) => {
    return useBaseQuery({
        queryKeys: ['Orders', { params: params.queryKeys }],
        service: ordersService.getOrderHistory,
        onSuccess: {
            messageDisplay: false,
            message: 'Successful, get order history',
        },
        onLoading: {
            messageDisplay: false,
            message: 'Loading',
        },
        onError: {
            messageDisplay: false,
        },
    })
}
