import * as React from 'react'

import { CustomBox, CustomStep, CustomStepLabel, CustomStepper } from './Stepper.styled'
import { StepperProps } from './types'
import Typography from 'components/Typography/Typography'
import { colors } from 'styles/color'

const Stepper: React.FunctionComponent<StepperProps> = ({ steps, actStep, orientation }) => {
    return (
        <CustomBox>
            <CustomStepper orientation={orientation} activeStep={actStep} sx={{ width: '100%' }}>
                {steps.map((step) => {
                    const stepProps: { completed?: boolean } = {}
                    return (
                        <CustomStep key={step.stepNumber} {...stepProps}>
                            <CustomStepLabel>
                                {' '}
                                {step.stepNumber}{' '}
                                <Typography variant="body-small-semibold" color={colors.text.primaryTextLight}>
                                    {step.label}
                                </Typography>{' '}
                            </CustomStepLabel>
                        </CustomStep>
                    )
                })}
            </CustomStepper>
        </CustomBox>
    )
}

export default Stepper
