import React from 'react'
import { TooltipProps } from './types'
import { CustomTooltip } from './Tooltip.styled'

const Tooltip: React.FunctionComponent<TooltipProps> = ({ name, children }) => {
    return (
        <CustomTooltip title={name}>
            <span>{children}</span>
        </CustomTooltip>
    )
}

export default Tooltip
