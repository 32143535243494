import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import 'react-toastify/dist/ReactToastify.css'
import './styles/projectDefault.scss'
import 'flatpickr/dist/themes/confetti.css'
import 'flatpickr/dist/l10n/tr.js'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact, { BugsnagPluginReactResult } from '@bugsnag/plugin-react'
import './services/_base/firebase'

Bugsnag.start({
    apiKey: 'e5edd1e0b02fdd71d74ed0a51282a8f9', // TODO: it will be added to env
    plugins: [new BugsnagPluginReact()],
    enabledReleaseStages: ['production'],
})

const plugin = Bugsnag.getPlugin('react') as BugsnagPluginReactResult
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ErrorBoundary = plugin.createErrorBoundary(React) as any

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <React.StrictMode>
        <ErrorBoundary>
            <App />
        </ErrorBoundary>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
