import { Chart } from 'components/Chart/Chart'
import { EChartsOption } from 'echarts'
import React from 'react'
import { OrderStatusRatesProps } from './types'

const OrderStatusChart: React.FunctionComponent<OrderStatusRatesProps> = ({ orderStatusRates }) => {
    const OptionCampaigns: EChartsOption = {
        textStyle: {
            fontSize: 10,
        },
        tooltip: {
            trigger: 'item',
        },
        legend: {
            top: ' 92%',
            left: 'center',

            textStyle: {
                color: 'black',
                fontSize: '12px',
            },
        },
        series: [
            {
                name: 'Sipariş Durum Dağılımı',
                type: 'pie',
                radius: ['40%', '60%'],
                width: '400',
                top: '-60',
                left: 'center',
                height: '400',
                data: [
                    { value: orderStatusRates?.orderCanceledCount, name: 'İptal Edildi' },
                    { value: orderStatusRates?.orderDeliverdCount, name: 'Teslim Edildi' },
                    { value: orderStatusRates?.orderInPreparatioCount, name: 'Hazırlanıyor' },
                    { value: orderStatusRates?.orderPendingCount, name: 'Beklemede' },
                    { value: orderStatusRates?.orderShippedCount, name: 'Kargolandı' },
                ],
                label: {
                    show: true,
                    formatter: function (params) {
                        if (params.value !== 0) {
                            return params.name
                        } else {
                            return ''
                        }
                    },
                },
                labelLine: {
                    show: false,
                },
            },
        ],
    }
    return <Chart option={OptionCampaigns} />
}

export default OrderStatusChart
