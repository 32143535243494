import { GetComplaintReasonResponse, GetComplaintResponse, GetComplaintVariables } from 'services/be-api/complaint/types'
import { Query } from '../types'
import { useBaseQuery } from '../_Base'
import { getAllComplaints, getAllComplaintsReason } from 'services/be-api/complaint/endpoints'

export const useGetAllComplaints: Query<GetComplaintVariables, GetComplaintResponse> = (params) =>
    useBaseQuery({
        queryKeys: ['complaints', { params: params.queryKeys }],
        service: getAllComplaints,
        onSuccess: {
            messageDisplay: false,
            message: 'Successful.',
        },
        onLoading: {
            messageDisplay: false,
            message: 'Loading',
        },
        onError: {
            messageDisplay: false,
        },
        enabled: params.enabled,
    })

export const useGetAllComplaintsReasons: Query<any, GetComplaintReasonResponse> = () =>
    useBaseQuery({
        queryKeys: ['complaintsReasons'],
        service: getAllComplaintsReason,
        onSuccess: {
            messageDisplay: false,
            message: 'Successful.',
        },
        onLoading: {
            messageDisplay: false,
            message: 'Loading',
        },
        onError: {
            messageDisplay: false,
        },
    })
