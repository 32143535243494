import { Badge, Box, styled, TextField } from '@mui/material'
import { ptr } from '../../utils/helpers'
import { colors } from '../../styles/color'
import { ProductOfferStatus } from './index.interface'

export const FilterSection = styled(Box)({
    boxShadow: ' 0px 2px 10px rgba(76, 78, 100, 0.22)',
    marginBottom: ptr(24),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-around',
    height: ptr(144),
    gap: ptr(16),
    padding: ptr(20),
    background: colors.white.default,
})

export const FilterGroup = styled(Box)({
    display: 'flex',
    gap: ptr(24),
    width: '100%',
})

export const ProductTableSection = styled(Box)({
    boxShadow: ' 0px 2px 10px rgba(76, 78, 100, 0.22)',
    marginBottom: ptr(24),
})

export const ProductTableHead = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    textAlign: 'center',
    alignItems: 'center',
    background: colors.white.default,
    padding: `${ptr(21)}}`,
})

export const ProductTableFilterSection = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    width: '30%',
    justifyContent: 'right',
    gap: ptr(24),
    textAlign: 'center',
    alignItems: 'flex-start',
})

export const ProductSearchInput = styled(TextField)({
    height: '40px',
    '& > div': {
        height: '40px',
        fontWeight: '300',
        paddingRight: '12px',
        fontSize: '16px',
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: colors.primary.primaryMain,
        },
    },
})

export const PhotoBadge = styled(Badge)({
    '& .MuiBadge-badge': {
        backgroundColor: colors.badge.score3,
    },
})

export const ProductSubTableStatus = styled(Box)((props) => {
    let backgroundColor
    let color
    switch (props.children) {
        case ProductOfferStatus.ARRIVED:
            backgroundColor = colors.white.grey + '80'
            color = colors.secondary.secondaryMain
            break
        case ProductOfferStatus.CANCELED:
            backgroundColor = colors.error.errorOutlinedHoverBg
            color = colors.error.errorMain
            break
        case ProductOfferStatus.CONFIRMED:
            backgroundColor = colors.success.successOutlinedHoverBg
            color = colors.success.successMain
            break
        case ProductOfferStatus.SENT:
            backgroundColor = colors.success.successOutlinedHoverBg
            color = colors.success.successMain
            break
        case ProductOfferStatus.WAITING:
            backgroundColor = colors.warning.warningOutlinedHoverBg
            color = colors.warning.warningMain
            break
        default:
            break
    }
    return {
        width: 'fit-content',
        backgroundColor,
        color,
        padding: `${ptr(3)} ${ptr(10)}`,
        borderRadius: ptr(16),
    }
})
