import { alpha, Box, Button, ImageListItem, styled } from '@mui/material'
import { ptr } from '../../../../utils/helpers'
import { colors } from '../../../../styles/color'
import InputMask from 'react-input-mask'

export const ProductImageItem = styled(ImageListItem)({
    height: `${ptr(84)} !important`,
    width: ptr(96),
    position: 'relative',
    ':hover .photo-overlay': {
        display: 'flex',
    },
})
export const ProductImageItemImg = styled('img')({
    height: ptr(84),
    width: ptr(96),
    objectFit: 'scale-down',
    borderRadius: ptr(4),
})

export const UploadPhotosButton = styled(Button)({
    backgroundColor: colors.primary.primaryMain,
    ':hover': {
        backgroundColor: colors.primary.primaryAlternativeDark,
    },
})

export const PhotoOverlay = styled(Box)({
    height: '100%',
    width: '100%',
    backgroundColor: alpha(colors.primary.primaryMain, 0.5),
    position: 'absolute',
    zIndex: '1',
    display: 'none',
    borderRadius: ptr(4),
    justifyContent: 'center',
    alignItems: 'center',
})

export const CustomInputMask = styled(InputMask)({
    borderRadius: '4px',
    height: '3.3rem',
    padding: '0 16.5px',
    border: `1px solid #0000003b`,
    width: '100%',
    ' &:hover': {
        outline: 'none',
        border: `1px solid ${colors.black.default}`,
    },
    ' &:focus': {
        outline: 'none',
        border: `2px solid ${colors.primary.primaryMain}`,
    },
    '&::placeholder': {
        color: colors.text.disabledTextLight,
    },
})
