import {Mutation, Query} from "../types";
import {useBaseMutation, useBaseQuery} from "../_Base";
import adminServices from "services/be-api/admin"
import {
    AdminPortalResponse,
    GetAdminServiceResponse, UpdateAdminInfoVariables,
    UpdateAdminPasswordVariables, UpdateAdminPortalVariables,
} from "../../../../services/be-api/admin/types";
import {updateAdminInfo} from "../../../../services/be-api/admin/endpoints";

export const useGetAdmin: Query<any, GetAdminServiceResponse> = () => {
    return useBaseQuery({
        queryKeys: ['admin'],
        service: adminServices.getAdmin,
        onSuccess: {
            messageDisplay: false,
            message: 'Successful, get user detail.',
        },
        onLoading: {
            messageDisplay: false,
            message: 'Loading',
        },
        onError: {
            messageDisplay: false,
        },
    })
}

export const useUpdateAdmin: Mutation<UpdateAdminInfoVariables, any> = () => {
    return useBaseMutation({
        service: updateAdminInfo,
        onSuccess: {
            messageDisplay: true,
            message: 'Admin başarıyla güncellendi.',
        },
        onLoading: {
            messageDisplay: true,
            message: 'Loading',
        },
        onError: {
            messageDisplay: true,
        },
    })
}

export const useUpdateAdminPassword: Mutation<UpdateAdminPasswordVariables, any> = () => {
    return useBaseMutation({
        service: adminServices.updateAdminPassword,
        onSuccess: {
            messageDisplay: true,
            message: 'Admin başarıyla güncellendi.',
        },
        onLoading: {
            messageDisplay: true,
            message: 'Loading',
        },
        onError: {
            messageDisplay: true,
        },
    })
}

export const useUpdateAdminPortal: Mutation<UpdateAdminPortalVariables, AdminPortalResponse> = () => {
    return useBaseMutation({
        service: adminServices.updateAdminPortal,
        onSuccess: {
            messageDisplay: true,
            message: 'Admin başarıyla güncellendi.',
        },
        onLoading: {
            messageDisplay: true,
            message: 'Loading',
        },
        onError: {
            messageDisplay: true,
        },
    })
}