import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'

import { Divider } from '@mui/material'
import AddBoxIcon from '@mui/icons-material/AddBox'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'

import TextField from 'components/Input/TextField/TextField'
import Button from 'components/Buttons/Button/Button'
import Select from 'components/Input/Select/Select'
import { ButtonsSide, CoInputContent, CustomInputMask, ErrorMessage, ErrorMessageControler, FormContainer } from '../index.styled'
import { BankSection } from './index.styled'
import CheckboxCard from './CheckboxCard'
import Spinner from 'components/Spinner'

import { BankAccountModel, UserInfoFormValues, UserInfoProps } from './type'
import { ValueOf } from 'models/common/types/ValueOf'
import { CompanyModel } from 'services/be-api/companies/types'
import { colors } from 'styles/color'
import { useSearchParams } from 'react-router-dom'

const UserInfo = ({ value, onChange, handleNext, handleBack, companiesData, companiesSuccess, setSelectedId, companiesLoading, isCompaniesByIdSuccessRefetch }: UserInfoProps) => {
    const [radioValue, setRadioValue] = useState<string>('')
    const [selectedcompanies, setSelectedCompanies] = useState<CompanyModel[]>([])
    const [companyOptions, setCompanyOptions] = useState<{ value: number; name: string }[]>([])
    const [bankAccounts, setBankAccounts] = useState<BankAccountModel[]>([{ bankName: '', ibanNumber: '', nameSurname: '' }])
    const [searchParams] = useSearchParams()

    useEffect(() => {
        if (value.bankAccounts && value.bankAccounts.length) {
            setBankAccounts([...value.bankAccounts])
        }
    }, [value.bankAccounts])

    useEffect(() => {
        if (companiesSuccess && companiesData?.data.result) setSelectedCompanies(companiesData?.data.result.companies)
    }, [companiesSuccess, companiesData])

    useEffect(() => {
        setCompanyOptions(selectedcompanies.map((company) => ({ value: company.id, name: company.name })))
    }, [selectedcompanies])

    const validationSchema = Yup.object().shape({
        role: Yup.string().required('Bu alan zorunludur.'),
        companiesName: Yup.number().required('Bu alan zorunludur.'),
        userNameSurname: Yup.string()
            .required('Bu alan zorunludur.')
            .max(64, 'Maksimum 64 karakter olabilir')
            .matches(/^(?!\s)/, 'Kullanıcı İsim Soyisim alanında başta boşluk olamaz.')
            .matches(/(\S+\s)+/, 'Kullanıcı İsim Soyisim alanında en az 2 kelime olmalı.')
            .matches(/(\S+\s){1}/, 'Kullanıcı İsim Soyisim alanında yazılar arasında sadece 1 boşluk olmalı.')
            .matches(/\S+$/, 'Kullanıcı İsim Soyisim alanında sonda boşluk olmamalıdır.'), // /^(?!\s)(\S+)(?:\s\S+)+$/ çoklu regex çalışmazsa (gözümden bir şey kaçtıysa) çalışan alternatif regex
        telNumber: Yup.string()
            .required('Bu alan zorunludur.')
            .test('is-valid-telNumber', 'Geçersiz telefon numarası', (value) => {
                const lengthOfNumbers = value.replace(/_/g, '').length
                return lengthOfNumbers === 17
            }),
        eMail: Yup.string().max(64, 'Maksimum 64 karakter olabilir').email('Geçersiz eMail adresi'),
        reference: Yup.string().required('Bu alan zorunludur.').max(255, 'Maksimum 255 karakter girebilirsiniz'),
    })

    const onSubmit = (values: UserInfoFormValues) => {
        if (radioValue === 'satıcı') onChange({ ...values, bankAccounts })
        else onChange({ ...values, bankAccounts: [{ bankName: '', ibanNumber: '', nameSurname: '' }] })

        handleNext()
    }

    const addBankAccount = () => {
        setBankAccounts((prevState) => [...prevState, { bankName: '', ibanNumber: '', nameSurname: '' }])
    }

    const onDeleteBankAccount = (index: number) => {
        setBankAccounts((prevState) => prevState.filter((_child, i) => i !== index))
    }

    const onChangeBankAccountValue = (index: number, key: keyof BankAccountModel, val: ValueOf<BankAccountModel>) => {
        let existBankAccounts = bankAccounts
        existBankAccounts[index][key] = val
        setBankAccounts([...existBankAccounts])
    }
    const isBankAccountsInvalid = () => {
        const check = bankAccounts.some((bankAccount) => {
            if (bankAccount.bankName.length > 0 && bankAccount.ibanNumber.replaceAll(' ', '').replaceAll('_', '').length === 26 && bankAccount.nameSurname.length > 0) return false
            else if (bankAccount.bankName === '' && bankAccount?.ibanNumber?.replaceAll(' ', '').replaceAll('_', '').length === 0 && bankAccount.nameSurname === '') return false
            else return true
        })
        return radioValue === 'satıcı' && value.bankAccounts.length >= 0 && check
    }
    const isNewBankAccountsInvalid = () => {
        const check = bankAccounts.some((bankAccount) => {
            if (bankAccount.bankName.length > 0 && bankAccount.ibanNumber.replaceAll(' ', '').replaceAll('_', '').length === 26 && bankAccount.nameSurname.length > 0) return false
            else return true
        })
        return radioValue === 'satıcı' && value.bankAccounts.length >= 0 && check
    }

    useEffect(() => {
        const companyId = Number(searchParams.get('companyId'))
        const company = companyOptions.find((company) => company.value === companyId)
        if (company) {
            onChange({ ...value, companiesName: company.value })
            setSelectedId(company.value)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyOptions, searchParams])

    const idSelectHandler = async (id: number) => {
        await setSelectedId(id)
        isCompaniesByIdSuccessRefetch()
    }

    return (
        <Formik initialValues={value} validationSchema={validationSchema} onSubmit={onSubmit} validateOnBlur enableReinitialize validateOnMount={true}>
            {(props) => {
                const { isSubmitting, values, handleChange, handleSubmit, isValid, handleBlur } = props
                return (
                    <Form>
                        <Spinner open={companiesLoading} />
                        <FormContainer>
                            <CheckboxCard radioValue={radioValue} setRadioValue={setRadioValue} handleChange={handleChange} val={values.role} />
                            <CoInputContent>
                                <ErrorMessageControler>
                                    <Select
                                        selectedValue={values.companiesName}
                                        name="companiesName"
                                        options={companyOptions}
                                        onChange={(id, changeEvent) => {
                                            handleChange(changeEvent)
                                            idSelectHandler(id as number)
                                        }}
                                        placeholder={'Firma Seç'}
                                        expand
                                        disabled={!!Number(searchParams.get('companyId'))}
                                    ></Select>

                                    <ErrorMessage name="companiesName" component={'div'} />
                                </ErrorMessageControler>

                                <ErrorMessageControler>
                                    <TextField
                                        name="userNameSurname"
                                        placeholder="Kullanıcı İsim Soyisim"
                                        onChange={(e, changeEvent) => handleChange(changeEvent)}
                                        value={values.userNameSurname}
                                        onBlur={handleBlur}
                                        expand
                                    />
                                    <ErrorMessage name="userNameSurname" component={'div'} />
                                </ErrorMessageControler>
                            </CoInputContent>

                            <CoInputContent>
                                <ErrorMessageControler>
                                    <CustomInputMask
                                        name="telNumber"
                                        mask="+\90 999 999 99 99"
                                        placeholder="Telefon No"
                                        onChange={(changeEvent) => handleChange(changeEvent)}
                                        value={values.telNumber}
                                        onBlur={handleBlur}
                                    />
                                    <ErrorMessage name="telNumber" component={'div'} />
                                </ErrorMessageControler>

                                <ErrorMessageControler>
                                    <TextField
                                        name="eMail"
                                        type="email"
                                        placeholder="Email (Opsiyonel)"
                                        onChange={(e, changeEvent) => handleChange(changeEvent)}
                                        value={values.eMail}
                                        onBlur={handleBlur}
                                        expand
                                    />
                                    <ErrorMessage name="eMail" component={'div'} />
                                </ErrorMessageControler>
                            </CoInputContent>

                            <ErrorMessageControler>
                                <TextField
                                    multiline
                                    rows={2}
                                    name="reference"
                                    type="text"
                                    placeholder="Referanslar"
                                    onChange={(e, changeEvent) => handleChange(changeEvent)}
                                    value={values.reference}
                                    onBlur={handleBlur}
                                    expand
                                />
                                <ErrorMessage name="reference" component={'div'} />
                            </ErrorMessageControler>
                        </FormContainer>

                        {radioValue === 'satıcı' && (
                            <BankSection>
                                <Divider sx={{ marginTop: '20px' }}></Divider>
                                {bankAccounts?.map((bank, index) => (
                                    <div key={index}>
                                        <CoInputContent sx={{ marginBottom: '20px' }}>
                                            <ErrorMessageControler>
                                                <TextField
                                                    name="nameSurname"
                                                    placeholder="Adı Soyadı"
                                                    type="text"
                                                    onChange={(e) => onChangeBankAccountValue(index, 'nameSurname', e as string)}
                                                    value={bank.nameSurname}
                                                    width="100%"
                                                    inputProps={{ maxLength: 64 }}
                                                />
                                                <ErrorMessage name="nameSurname" component={'div'} />
                                            </ErrorMessageControler>
                                            <ErrorMessageControler>
                                                <CustomInputMask
                                                    name="ibanNumber"
                                                    placeholder="IBAN Numarası"
                                                    mask="\TR99 9999 9999 9999 9999 9999 99"
                                                    onChange={(e) => onChangeBankAccountValue(index, 'ibanNumber', e.target.value as string)}
                                                    value={bank.ibanNumber}
                                                    width="100%"
                                                    onBlur={handleBlur}
                                                />
                                                <ErrorMessage name="ibanNumber" component={'div'} />
                                            </ErrorMessageControler>
                                        </CoInputContent>
                                        <CoInputContent sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                                            <ErrorMessageControler>
                                                <TextField
                                                    name="bankName"
                                                    type="text"
                                                    placeholder="Banka Adı"
                                                    onChange={(e) => onChangeBankAccountValue(index, 'bankName', e as string)}
                                                    value={bank.bankName}
                                                    width="50%"
                                                    inputProps={{ maxLength: 64 }}
                                                />
                                                <ErrorMessage name="bankName" component={'div'} />
                                            </ErrorMessageControler>
                                            {bankAccounts.length - 1 === index ? (
                                                <Button disabled={isNewBankAccountsInvalid()} width="50%" color="secondary" startIcon={<AddBoxIcon />} variant="outlined" onClick={addBankAccount}>
                                                    BAŞKA BANKA EKLE
                                                </Button>
                                            ) : (
                                                <DeleteOutlinedIcon sx={{ color: colors.error.errorAlternativeLight, cursor: 'pointer' }} onClick={() => onDeleteBankAccount(index)} />
                                            )}
                                        </CoInputContent>
                                    </div>
                                ))}
                            </BankSection>
                        )}
                        <ButtonsSide>
                            <Button id="next" onClick={handleSubmit} disabled={isSubmitting || !isValid || isBankAccountsInvalid()}>
                                İleri
                            </Button>
                            <Button variant="outlined" color="secondary" onClick={handleBack}>
                                İptal
                            </Button>
                        </ButtonsSide>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default UserInfo
