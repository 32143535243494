import { Query } from '../types'
import { useBaseQuery } from '../_Base'

import { RequestParams } from 'services/types'
import {
    BuyerDemandResponse,
    CategoryDemandResponse,
    CategorySpecialOfferResponse,
    SellerDemandResponse,
    SpecialOfferChartResponse,
    SpecialOfferChartVariables,
    SpecialOfferResponse,
    TopBuyerDemandFilter,
    TopBuyerSpecialOfferResponse,
    TopBuyerSpecialOfferVariables,
    TopCategoryDemandFilter,
    TopCategorySpecialOffer,
    TopDemandOfferChart,
    TopSellerDemandFilter,
    TopSpecialOfferChart,
    TopSpecialOfferFilter,
    UserChartResponse,
    UserChartVariables,
} from 'services/be-api/dashboard/types'
import {
    getAllSpecialOfferChart,
    getAllTopBuyerDemand,
    getAllTopBuyerSpecialOffer,
    getAllTopCategoryDemand,
    getAllTopCategorySpecialOffer,
    getAllTopSellerDemand,
    getAllTopSpecialOffer,
    getAllUserChart,
    getTopDemandOfferChart,
    getTopSpecialOfferChart,
} from 'services/be-api/dashboard/endpoints'

export const useGetAllTopSpecialOffer: Query<RequestParams<TopSpecialOfferFilter>, SpecialOfferResponse> = (params) => {
    return useBaseQuery({
        queryKeys: ['special_offer', { params: params.queryKeys }],
        service: getAllTopSpecialOffer,
        onSuccess: {
            messageDisplay: false,
            message: 'Succesfully, get all users',
        },
        onLoading: {
            messageDisplay: false,
            message: 'Loading',
        },
        onError: {
            messageDisplay: false,
        },
        enabled: params.enabled,
    })
}
export const useGetAllTopCategoryDemand: Query<RequestParams<TopCategoryDemandFilter>, CategoryDemandResponse> = (params) => {
    return useBaseQuery({
        queryKeys: ['category-demand', { params: params.queryKeys }],
        service: getAllTopCategoryDemand,
        onSuccess: {
            messageDisplay: false,
            message: 'Succesfully, get all users',
        },
        onLoading: {
            messageDisplay: false,
            message: 'Loading',
        },
        onError: {
            messageDisplay: false,
        },
        enabled: params.enabled,
    })
}
export const useGetAllTopSellerDemand: Query<RequestParams<TopSellerDemandFilter>, SellerDemandResponse> = (params) => {
    return useBaseQuery({
        queryKeys: ['seller-demand', { params: params.queryKeys }],
        service: getAllTopSellerDemand,
        onSuccess: {
            messageDisplay: false,
            message: 'Succesfully, get all users',
        },
        onLoading: {
            messageDisplay: false,
            message: 'Loading',
        },
        onError: {
            messageDisplay: false,
        },
        enabled: params.enabled,
    })
}
export const useGetAllTopBuyerDemand: Query<RequestParams<TopBuyerDemandFilter>, BuyerDemandResponse> = (params) => {
    return useBaseQuery({
        queryKeys: ['buyer-demand', { params: params.queryKeys }],
        service: getAllTopBuyerDemand,
        onSuccess: {
            messageDisplay: false,
            message: 'Succesfully, get all users',
        },
        onLoading: {
            messageDisplay: false,
            message: 'Loading',
        },
        onError: {
            messageDisplay: false,
        },
        enabled: params.enabled,
    })
}

export const useGetAllTopBuyerSpecialOffer: Query<RequestParams<TopBuyerSpecialOfferVariables>, TopBuyerSpecialOfferResponse> = (params) => {
    return useBaseQuery({
        queryKeys: ['buyer-special-offer', { params: params.queryKeys }],
        service: getAllTopBuyerSpecialOffer,
        onSuccess: {
            messageDisplay: false,
            message: 'Succesfully, get all users',
        },
        onLoading: {
            messageDisplay: false,
            message: 'Loading',
        },
        onError: {
            messageDisplay: false,
        },
        enabled: params.enabled,
    })
}

export const useGetAllTopCategorySpecialOffer: Query<RequestParams<TopCategorySpecialOffer>, CategorySpecialOfferResponse> = (params) => {
    return useBaseQuery({
        queryKeys: ['category-special-offer', { params: params.queryKeys }],
        service: getAllTopCategorySpecialOffer,
        onSuccess: {
            messageDisplay: false,
            message: 'Succesfully, get all users',
        },
        onLoading: {
            messageDisplay: false,
            message: 'Loading',
        },
        onError: {
            messageDisplay: false,
        },
        enabled: params.enabled,
    })
}

export const useGetAllSpecialOfferChart: Query<RequestParams<SpecialOfferChartVariables>, SpecialOfferChartResponse> = (params) => {
    return useBaseQuery({
        queryKeys: ['special-offer-chart', { params: params.queryKeys }],
        service: getAllSpecialOfferChart,
        onSuccess: {
            messageDisplay: false,
            message: 'Succesfully, get all users',
        },
        onLoading: {
            messageDisplay: false,
            message: 'Loading',
        },
        onError: {
            messageDisplay: false,
        },
        enabled: params.enabled,
    })
}

export const useGetAllUserChart: Query<RequestParams<UserChartVariables>, UserChartResponse> = (params) => {
    return useBaseQuery({
        queryKeys: ['user-chart', { params: params.queryKeys }],
        service: getAllUserChart,
        onSuccess: {
            messageDisplay: false,
            message: 'Succesfully, get all users',
        },
        onLoading: {
            messageDisplay: false,
            message: 'Loading',
        },
        onError: {
            messageDisplay: false,
        },
        enabled: params.enabled,
    })
}

export const useGetTopSpecialOfferChart: Query<RequestParams<UserChartVariables>, TopSpecialOfferChart[]> = (params) => {
    return useBaseQuery({
        queryKeys: ['top-special-offer-chart', { params: params.queryKeys }],
        service: getTopSpecialOfferChart,
        onSuccess: {
            messageDisplay: false,
            message: 'Succesfully, get all users',
        },
        onLoading: {
            messageDisplay: false,
            message: 'Loading',
        },
        onError: {
            messageDisplay: false,
        },
        enabled: params.enabled,
    })
}

export const useGetTopDemandOfferChart: Query<RequestParams<UserChartVariables>, TopDemandOfferChart[]> = (params) => {
    return useBaseQuery({
        queryKeys: ['top-demand-offer-chart', { params: params.queryKeys }],
        service: getTopDemandOfferChart,
        onSuccess: {
            messageDisplay: false,
            message: 'Succesfully, get all users',
        },
        onLoading: {
            messageDisplay: false,
            message: 'Loading',
        },
        onError: {
            messageDisplay: false,
        },
        enabled: params.enabled,
    })
}
