import { GridColDef } from '@mui/x-data-grid'
import { CategorySpecialOffer } from 'services/be-api/dashboard/types'

export const topCategoryDemandExportOptions = [
    {
        key: 'ANA KATEGORI',
        label: 'ANA KATEGORI',
        onRender: (item: string, element: CategorySpecialOffer) => {
            return `${element?.workCategory}`
        },
    },
    {
        key: 'ALT KATEGORI',
        label: 'ALT KATEGORI',
        onRender: (item: string, element: CategorySpecialOffer) => {
            return `${element?.vehicleType}`
        },
    },
    {
        key: 'TOPLAM STOK',
        label: 'TOPLAM STOK',
        onRender: (item: string, element: CategorySpecialOffer) => {
            return `${element?.totalStockCount}`
        },
    },
    {
        key: 'STOK HAREKET',
        label: 'STOK HAREKET',
        onRender: (item: string, element: CategorySpecialOffer) => {
            return `${element?.totalMovementStockCount}`
        },
    },
    {
        key: 'TOPLAM SATIS ADET',
        label: 'TOPLAM SATIS ADET',
        onRender: (item: string, element: CategorySpecialOffer) => {
            return `${element?.totalSales}`
        },
    },
    {
        key: 'TOPLAM SATIS MIKTARI',
        label: 'TOPLAM SATIS MIKTARI',
        onRender: (item: string, element: CategorySpecialOffer) => {
            return `${element?.totalAmount}`
        },
    },
]

export const popularCategoryHead: GridColDef[] = [
    { field: 'workCategory', headerName: 'ANA KATEGORİ', width: 140 },
    { field: 'vehicleType', headerName: 'ALT KATEGORİ', width: 140 },
    { field: 'totalStockCount', headerName: 'TOPLAM STOK', width: 70 },
    { field: 'totalMovementStockCount', headerName: 'STOK HAREKET', width: 70 },
    { field: 'totalSales', headerName: 'TOPLAM SATIŞ ADET', width: 30 },
    { field: 'totalAmount', headerName: 'TOPLAM SATIŞ MİKTARI', width: 70 },
]

export const breadcrumbLinks = [
    { text: 'Dashboard', url: '' },
    { text: 'Kampanyalar', url: '' },
    { text: 'Popüler Kategoriler Detayı', url: '/popular-campaings-category-details', isLast: true },
]
