import Avatar from '../../../../../components/Avatar/Avatar'
import React, { FC, MouseEventHandler } from 'react'
import { ChatCompanyName, ChatTime, ChatUserContainer, ChatUserInformation, ChatUsername, ChatUserWrapper, SidebarNameCompany, SidebarNameId, StyledBadge } from './index.styled'
import { UserProfileChat, UserProfileChatStatus } from '../../../../../services/be-api/users/types'
import { colors } from '../../../../../styles/color'
import Typography from 'components/Typography/Typography'

const ChatUserSection: FC<UserProfileChat & { onClick: MouseEventHandler<HTMLDivElement>; active: boolean }> = ({ user, sentAt, status, onClick, active, conversationId }) => {
    return (
        <ChatUserContainer onClick={onClick} sx={{ backgroundColor: active ? colors.primary.primaryMain : 'none', color: active ? colors.white.default : 'none' }}>
            <ChatUserWrapper>
                <StyledBadge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    variant="dot"
                    sx={{
                        '.MuiBadge-dot': {
                            backgroundColor: status === UserProfileChatStatus.Ended ? 'red' : 'green',
                        },
                    }}
                >
                    <Avatar name="Ali Safak" width={40} height={40} src={user.avatar} />
                </StyledBadge>
                <ChatUserInformation>
                    <SidebarNameCompany>
                        <SidebarNameId>
                            <ChatUsername>{user.fullName}</ChatUsername>
                            <Typography color={active ? colors.white.default : colors.primary.primaryMain}> {`#${conversationId}`}</Typography>
                        </SidebarNameId>
                        <ChatCompanyName sx={{ color: active ? colors.white.default : 'inherit' }}>{user.companyName}</ChatCompanyName>
                    </SidebarNameCompany>
                </ChatUserInformation>
                <ChatTime>{sentAt}</ChatTime>
            </ChatUserWrapper>
        </ChatUserContainer>
    )
}

export default ChatUserSection
