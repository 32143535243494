import { useBaseQuery } from '../_Base'
import { Query } from '../types'
import { RequestParams } from '../../../../services/types'
import { GetChatVariables, GetChatsRespons } from 'services/be-api/chats/types'
import { getChats } from 'services/be-api/chats/endpoints'

export const useGetChats: Query<RequestParams<GetChatVariables>, GetChatsRespons> = (params) => {
    return useBaseQuery({
        queryKeys: ['Orders', { params: params.queryKeys }],
        service: getChats,
        onSuccess: {
            messageDisplay: false,
            message: 'Successful, get requests statistic count.',
        },
        onLoading: {
            messageDisplay: false,
            message: 'Loading',
        },
        onError: {
            messageDisplay: false,
        },
        enabled: params.enabled,
    })
}
