import { styled } from '@mui/material'
import { ptr } from 'utils/helpers'

export const NoDataPicturesWithText = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    position: 'relative',
    gap: ptr(20),
    userSelect: 'none',
    pointerEvents: 'none',
})
export const NoDataPictures = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    height: '70%',
})
export const Div1 = styled('div')({
    display: 'flex',
    alignItems: 'flex-start',
})
export const Div2 = styled('div')({
    display: 'flex',
    alignItems: 'flex-end',
})
export const Picture = styled('img')({
    width: ptr(100),
    height: 'auto',
    opacity: 0.3,
})
export const Picture2 = styled('img')({
    width: ptr(120),
    height: 'auto',
    opacity: 0.3,
})
