import React, { useEffect, useState } from 'react'

import { useGetAllTopCategorySpecialOffer } from 'utils/hooks/queries/Dashboard/useDashboard'
import { useGetVehicleTypes, useGetWorkCategory } from 'utils/hooks/queries/LookUp'

import DatePicker from 'components/DateRangePicker/DateRangePicker'
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs'
import DashboardLayout from 'layouts/Dashboard/DashboardLayout'
import Button from 'components/Buttons/Button/Button'
import Spinner from 'components/Spinner'
import Table from 'components/Table/Table'
import ExportDropdown from 'components/ExportDropdown'
import { TableBodyRowData } from 'components/Table/types'
import Typography from 'components/Typography/Typography'
import AutocompleteContainer from 'components/Input/AutoComplete/Autocomplete'
import { CustomCardDiv, CustomExportBox, CustomFilterBox, CustomFilterDiv, CustomHeadDiv, CustomTableDiv, PopularityContainer } from '../index.styled'
import { endDateConverter, ptr, startDateConverter } from 'utils/helpers'

import { Box } from '@mui/material'
import { breadcrumbLinks, popularCategoryHead, topCategoryDemandExportOptions } from './constants'
import { IosShare } from '@mui/icons-material'
import { CategorySpecialOffer } from 'services/be-api/dashboard/types'
import { colors } from 'styles/color'
import { useLocation } from 'react-router-dom'

const PopularCategorySpecialOffer = () => {
    const [popularCategory, setPopularCategory] = useState<Array<CategorySpecialOffer>>([])
    const [exportArray, setExportArray] = useState<Array<CategorySpecialOffer>>([])
    const location = useLocation()
    const selectedDate = location.state
    const [exportToggle, setExportToggle] = useState(false)
    const { data: workCategoriesData } = useGetWorkCategory({ queryKeys: {}, enabled: true })
    const { data: vehicleTypesData } = useGetVehicleTypes({ queryKeys: {}, enabled: true })
    const [workCategories, setWorkCategories] = useState<{ value: string; label: string }[]>([])
    const [vehicleTypes, setVehicleTypes] = useState<{ value: string; label: string }[]>([])

    const [currentPage, setCurrentPage] = useState(0)
    const [rowPerPage, setRowPerPage] = useState(10)
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [selectedWorkCategory, setSelectedWorkCategory] = useState('')
    const [selectedVehicleType, setSelectedVehicleType] = useState('')

    const {
        data: popularCategoryData,
        isSuccess: isPopularCategorySuccess,
        isLoading: isPopularCategoryLoading,
    } = useGetAllTopCategorySpecialOffer({
        queryKeys: {
            startDate: startDate.length ? startDateConverter(startDate) : null,
            endDate: endDate.length ? endDateConverter(endDate) : null,
            skipCount: currentPage * rowPerPage || null,
            maxResultCount: rowPerPage,
            workCategoryName: selectedWorkCategory || null,
            workSubCategoryName: selectedVehicleType || null,
        },
    })
    const {
        data: popularCategoryDataExport,
        isSuccess: isPopularCategorySuccessExport,
        refetch: isPopularCategoryDemandRefetchExport,
    } = useGetAllTopCategorySpecialOffer({
        queryKeys: {
            maxResultCount: 1000,
            startDate: startDate.length ? startDateConverter(startDate) : null,
            endDate: endDate.length ? endDateConverter(endDate) : null,
            skipCount: currentPage * rowPerPage || null,
            workCategoryName: selectedWorkCategory || null,
            workSubCategoryName: selectedVehicleType || null,
        },
        enabled: false,
    })

    useEffect(() => {
        if (selectedDate) {
            setStartDate(selectedDate.startDate)
            setEndDate(selectedDate.endDate)
        }
    }, [selectedDate])

    useEffect(() => {
        if (isPopularCategorySuccessExport && popularCategoryDataExport?.data.result) {
            setExportArray(popularCategoryDataExport.data.result.items)
        }
    }, [popularCategoryDataExport, isPopularCategorySuccessExport])

    useEffect(() => {
        if (isPopularCategorySuccess && popularCategoryData?.data) {
            setPopularCategory(popularCategoryData?.data.result.items)
        }
    }, [isPopularCategorySuccess, popularCategoryData])

    useEffect(() => {
        setWorkCategories(workCategoriesData?.data?.result?.map((child) => ({ value: child.name, label: child.name })) ?? [])
    }, [workCategoriesData])

    useEffect(() => {
        setVehicleTypes(vehicleTypesData?.data?.result?.map((child) => ({ value: child.name, label: child.name })) ?? [])
    }, [vehicleTypesData])

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowPerPage(parseInt(event.target.value, 10))
        setCurrentPage(0)
    }
    const onPageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setCurrentPage(newPage)
    }

    const handleSelectDate = (sDate: string, eDate: string) => {
        setStartDate(sDate)
        setEndDate(eDate)
    }

    const rowData: TableBodyRowData[] = popularCategory.map((data) => {
        return {
            workCategory: <Typography> {data.workCategory}</Typography>,
            vehicleType: <Typography> {data.vehicleType}</Typography>,
            totalStockCount: <Typography> {data.totalStockCount}</Typography>,
            totalMovementStockCount: <Typography> {data.totalMovementStockCount}</Typography>,
            totalSales: <Typography> {data.totalSales}</Typography>,
            totalAmount: <Typography> {data.totalAmount}</Typography>,
        }
    })

    const handleExport = (exportToggle: boolean, set: React.Dispatch<React.SetStateAction<boolean>>) => {
        set(!exportToggle)
        isPopularCategoryDemandRefetchExport()
    }
    return (
        <DashboardLayout>
            <PopularityContainer>
                <Breadcrumbs links={breadcrumbLinks} />
                <CustomTableDiv>
                    <CustomFilterBox>
                        <Typography variant="h6-medium">Filtreleyerek Ara</Typography>
                        <CustomCardDiv>
                            <Box width={'30%'}>
                                <AutocompleteContainer
                                    width="100%"
                                    placeholder="Ana Kategori"
                                    options={workCategories}
                                    onChange={(e) => {
                                        setSelectedWorkCategory(e as string)
                                        if (currentPage !== 0) setCurrentPage(0)
                                    }}
                                />
                            </Box>
                            <Box width={'30%'}>
                                <AutocompleteContainer
                                    width="100%"
                                    placeholder="Vasıta Tipi"
                                    options={vehicleTypes}
                                    onChange={(e) => {
                                        setSelectedVehicleType(e as string)
                                        if (currentPage !== 0) setCurrentPage(0)
                                    }}
                                />
                            </Box>
                        </CustomCardDiv>
                    </CustomFilterBox>
                </CustomTableDiv>
                <CustomTableDiv>
                    <CustomHeadDiv style={{ height: ptr(81) }}>
                        <CustomExportBox>
                            <Button
                                startIcon={<IosShare />}
                                onClick={() => handleExport(exportToggle, setExportToggle)}
                                variant="outlined"
                                colorsx={colors.secondary.secondaryAlternativeLight}
                                borderColor={colors.secondary.secondaryAlternativeLight}
                                padding={ptr(16)}
                            >
                                <ExportDropdown<CategorySpecialOffer>
                                    array={exportArray}
                                    fileName={'Top Kategori Detayı Tablo'}
                                    title={''}
                                    sheetName={''}
                                    headers={topCategoryDemandExportOptions}
                                    open={exportToggle}
                                    buttonHeader={'Export'}
                                    isSuccess={isPopularCategorySuccessExport}
                                />
                            </Button>
                            <Typography variant="body-smaller-default">Maksimum 1000 veri</Typography>
                        </CustomExportBox>
                        <CustomFilterDiv>
                            <Box width={'30%'}>
                                <DatePicker
                                    setEndDate={setEndDate}
                                    setStartDate={setStartDate}
                                    startDate={startDate}
                                    endDate={endDate}
                                    value={`${startDate} - ${endDate}`}
                                    width={'100%'}
                                    placeholder={'Tarihe Göre Filtrele'}
                                    onSelectDate={({ startDate, endDate }) => {
                                        handleSelectDate(startDate, endDate)
                                        if (currentPage !== 0) setCurrentPage(0)
                                    }}
                                />
                            </Box>
                        </CustomFilterDiv>
                    </CustomHeadDiv>

                    <div>
                        <Spinner open={isPopularCategoryLoading} />
                        <Table
                            count={popularCategoryData?.data.result.totalCount}
                            isLoading={isPopularCategoryLoading}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            onPageChange={onPageChange}
                            head={popularCategoryHead}
                            result={rowPerPage}
                            rowsData={rowData}
                            isClickable={false}
                            page={currentPage}
                        ></Table>
                    </div>
                </CustomTableDiv>
            </PopularityContainer>
        </DashboardLayout>
    )
}

export default PopularCategorySpecialOffer
