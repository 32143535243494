export const ProductOfferStatus = {
    ARRIVED: 'Teslim Edildi',
    CANCELED: 'İptal Edildi',
    CONFIRMED: 'Onaylandı',
    SENT: 'Gönderildi',
    WAITING: 'Beklemede',
} as const

export const ECampaingStatus = {
    ACTIVE: 'Aktif Kampanyalar',
    PASSIVE: 'Pasif Kampanyalar',
} as const

export const ESortingStatus = {
    ProductPriceAsc: 'Liste fiyatı düşükten pahalıya',
    ProductPriceDsc: 'Liste fiyatı pahalıdan düşüğe',
    ProductDiscountedPriceAsc: 'İndirim fiyatı düşükten pahalıya',
    ProductDiscountedPriceDsc: 'İndirim fiyatı pahalıdan düşüğe',
} as const

export interface BankAccount {
    bankName: string
    iban: string
    accountHolderFullName: string
}

export interface IProduct {
    companyId: number
    productName: string
    productInfo: string
    productImages: string[]
    price: number
    campaignPrice: number
    stockAmount: number
    ownerIban: string
    ownerFullName: string
    ownerBankName: string
}

export interface ProductFormValues extends IProduct {}

export interface ProductSubTableProps {
    id: number
}

export interface SpecialOfferOrders {
    id: number
    creatorUserId: number
    requestedAmount: number
    status: string
    creationTime: string
    bankTransactionCode: string
    specialOfferSnapshot: string
    user: User
}

export interface User {
    id: number
    name: string
    surname: string
    fullName: string
    emailAddress: string
    phoneNumber: string
    bankAccounts: string
}
