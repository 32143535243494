import { Service } from 'services/types'
import { Request } from 'services/_base'
import { GET_VEHICLE_MAKES, GET_VEHICLE_TYPES, GET_WORK_CATEGORY, GET_WORK_SUBCATEGORIES } from './constants'

export const getVehicleMakes: Service<{}> = () => {
    return Request.get(GET_VEHICLE_MAKES, {})
}

export const getVehicleTypes: Service<{}> = () => {
    return Request.get(GET_VEHICLE_TYPES, {})
}

export const getWorkCategory: Service<{}> = () => {
    return Request.get(GET_WORK_CATEGORY, {})
}

export const getWorkSubCategory: Service<any> = ({ queryKey }) => {
    const { params } = queryKey[1]
    return Request.get(GET_WORK_SUBCATEGORIES(params.category), {})
}
