import { Autocomplete, Box, styled, TextField } from '@mui/material'
import { colors } from 'styles/color'
import { ptr } from 'utils/helpers'

export const CustomAppRateContainer = styled('div')({
    marginTop: ptr(44),
    marginRight: ptr(24),
    marginLeft: ptr(24),
})

export const CustomTableDiv = styled('div')({
    boxShadow: ' 0px 2px 10px rgba(76, 78, 100, 0.22)',
    marginBottom: ptr(24),
})

export const CustomFilterBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-around',
    height: ptr(144),
    gap: ptr(16),
    padding: ptr(20),
    background: colors.white.default,
})

export const CustomCardDiv = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-between',
})

export const CustomHeadDiv = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    textAlign: 'center',
    alignItems: 'center',
    background: colors.white.default,
    padding: '0px 20px',
})

export const CustomExportBox = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    gap: ptr(16),
})

export const CustomFilterDiv = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'right',
    gap: ptr(24),
    textAlign: 'center',
    height: ptr(80),
    alignItems: 'center',
})

export const CustomSearchInput = styled(TextField)({
    borderColor: colors.primary.primaryMain,
    height: ptr(40),
    '& > div': {
        height: '80px',
        fontWeight: '400',
        paddingRight: '12px',
        fontSize: '18px',
        borderColor: colors.primary.primaryMain,
        color: colors.black.default,
    },
    '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
        color: colors.black.default,
    },
    '& .Mui-focused': {
        borderColor: colors.primary.primaryMain,
        color: `${colors.primary.primaryMain} !important`,
        '& .MuiOutlinedInput-notchedOutline': {
            border: '2px solid #FFAA00 !important',
        },
    },
})

export const CustomAutocompleteAppRate = styled(Autocomplete)({
    height: '100%',
    '& .MuiFormControl-root > div': {
        height: '100%',
    },
    '& > .MuiBox-root  .MuiAutocomplete-tag': {
        border: '1px solid rgba(76, 78, 100, 0.22)',
        backgroundColor: 'transparent',
    },
    '& .Mui-focused': {
        borderColor: colors.primary.primaryMain,
        color: `${colors.primary.primaryMain} !important`,
        '& .MuiOutlinedInput-notchedOutline': {
            border: '2px solid #FFAA00 !important',
        },
    },
})
