import React, { useEffect, useState } from 'react'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import citiesOfTurkey from 'utils/data/citiesOfTurkey.json'
import TextField from 'components/Input/TextField/TextField'
import Button from 'components/Buttons/Button/Button'
import Select from 'components/Input/Select/Select'

import { AddCompanyProps, CompanyInfoFormValues } from './type'
import { ButtonsSide, CoInputContent, ErrorMessage, ErrorMessageControler, FormContainer } from '../AddCompany.styled'

const CompanyInfo = ({ value, onChange, handleNext, handleBack }: AddCompanyProps) => {
    const [cityOptions, setCityOptions] = useState<{ value: string; name: string }[]>([])
    const [districtOptions, setDistrictOptions] = useState<{ value: string; name: string }[]>([])
    const [cityInput, setcityInput] = useState('')
    const [districtInput, setDistrictInput] = useState<string>('default') //"default" yazmasının nedeni: stepper'da ileri geri yapıldığında eğer "" içi boş string olarak gelirse validation kontrolünde algortimaya hata çıkarıyor ve button'nu disabled olmasına neden oluyor o yüzden içi boş string yerine herhangi bir şey yazılarak kodun çalışmasını sağlanması amaçlanıyor

    useEffect(() => {
        setcityInput(value.city)
    }, [value.city])

    useEffect(() => {
        setCityOptions(citiesOfTurkey.data.map((child) => ({ value: child.il_adi, name: child.il_adi })))
    }, [])

    useEffect(() => {
        setDistrictOptions(
            citiesOfTurkey.data
                .find((child) => child.il_adi === cityInput)
                ?.ilceler.map((ilce) => ({
                    value: ilce.ilce_adi,
                    name: ilce.ilce_adi,
                })) || []
        )
    }, [cityInput])

    const validationSchema = Yup.object({
        companiesName: Yup.string().required('Bu alan zorunludur.').max(64, 'Maksimum 64 karakter olabilir'),
        taxNumber: Yup.string()
            .required('Bu alan zorunludur.')
            .max(11, 'Maksimum 11 karakter girebilirsiniz')
            .min(10, 'Minimum 10 karakter girebilirsiniz')
            .matches(/^[0-9]+$/, 'Sadece rakam girilebilir'),
        city: Yup.string().required('Bu alan zorunludur.'),
        district: Yup.string().required('Bu alan zorunludur.'),
        address: Yup.string().required('Bu alan zorunludur.').max(255, 'Maksimum 255 karakter girebilirsiniz'),
    })

    const onSubmit = (values: CompanyInfoFormValues) => {
        onChange(values)
        handleNext()
    }

    const cityNameHandler = (name: string) => {
        setcityInput(name)
        setDistrictInput('')
    }

    const districtConfigration = () => districtInput === ''
    return (
        <Formik initialValues={value} validationSchema={validationSchema} onSubmit={onSubmit} validateOnBlur enableReinitialize validateOnMount={true}>
            {(props) => {
                const { isSubmitting, values, handleChange, handleSubmit, isValid, handleBlur, setFieldValue } = props
                return (
                    <Form>
                        <FormContainer>
                            <CoInputContent>
                                <ErrorMessageControler>
                                    <TextField
                                        name="companiesName"
                                        placeholder="Firma Adı"
                                        onChange={(e, changeEvent) => handleChange(changeEvent)}
                                        value={values.companiesName}
                                        onBlur={handleBlur}
                                        expand
                                    />
                                    <ErrorMessage name="companiesName" component={'div'} />
                                </ErrorMessageControler>

                                <ErrorMessageControler>
                                    <TextField
                                        onChange={(e) => setFieldValue('taxNumber', String(e).substring(0, 11))}
                                        value={values.taxNumber}
                                        name="taxNumber"
                                        type="number"
                                        placeholder="Vergi No"
                                        onBlur={handleBlur}
                                        expand
                                    />
                                    <ErrorMessage name="taxNumber" component={'div'} />
                                </ErrorMessageControler>
                            </CoInputContent>

                            <CoInputContent>
                                <ErrorMessageControler>
                                    <Select
                                        selectedValue={values.city}
                                        name="city"
                                        options={cityOptions}
                                        onChange={(name, changeEvent) => {
                                            cityNameHandler(name as string)
                                            handleChange(changeEvent)
                                            setFieldValue('district', '')
                                        }}
                                        placeholder={'Şehir'}
                                        expand
                                    ></Select>
                                    <ErrorMessage name="city" component={'div'} />
                                </ErrorMessageControler>

                                <ErrorMessageControler>
                                    <Select
                                        selectedValue={values.district}
                                        name="district"
                                        options={districtOptions}
                                        onChange={(name, changeEvent) => {
                                            setDistrictInput(name as string)
                                            handleChange(changeEvent)
                                        }}
                                        placeholder={'İlçe'}
                                        expand
                                    ></Select>
                                    <ErrorMessage name="district" component={'div'} />
                                </ErrorMessageControler>
                            </CoInputContent>

                            <ErrorMessageControler>
                                <TextField
                                    multiline
                                    rows={4}
                                    onChange={(e, changeEvent) => handleChange(changeEvent)}
                                    value={values.address}
                                    name="address"
                                    type="text"
                                    placeholder="Adres"
                                    onBlur={handleBlur}
                                    expand
                                />
                                <ErrorMessage name="address" component={'div'} />
                            </ErrorMessageControler>
                        </FormContainer>
                        <ButtonsSide>
                            <Button id="next" onClick={handleSubmit} disabled={isSubmitting || !isValid || districtConfigration()}>
                                İleri
                            </Button>
                            <Button variant="outlined" color="secondary" onClick={handleBack}>
                                İptal
                            </Button>
                        </ButtonsSide>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default CompanyInfo
