import React, { FC, useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { Box, Divider, ImageList } from '@mui/material'
import TextField from 'components/Input/TextField/TextField'
import Button from 'components/Buttons/Button/Button'
import Select from 'components/Input/Select/Select'
import { ButtonsSide, CoInputContent, CurrencyInput, ErrorMessage, ErrorMessageControler, FormContainer } from '../index.styled'
import Spinner from 'components/Spinner'
import { CompanyModel } from 'services/be-api/companies/types'
import { colors } from 'styles/color'
import Typography from '../../../../components/Typography/Typography'
import { ptr } from '../../../../utils/helpers'
import { ArrowBack, ArrowForward, DeleteOutline } from '@mui/icons-material'
import { ProductFormValues } from '../../index.interface'
import useFirebase from '../../../../utils/hooks/firebase/useFirebase'
import { ProductInfoProps } from './types'
import { CustomInputMask, PhotoOverlay, ProductImageItem, ProductImageItemImg, UploadPhotosButton } from './index.styled'

const ProductInfo: FC<ProductInfoProps> = ({ productValues, onChange, handleNext, companiesData, setSelectedId, handleBack }) => {
    const { uploadMultiple } = useFirebase()
    const [companies, setCompanies] = useState<{ name: string; value: number }[]>([])
    const [productImages, setProductImages] = useState<string[]>(productValues.productImages || [])
    const [isUploading, setUploadingStatus] = useState<boolean>(false)
    const uploadInputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (companiesData)
            setCompanies(
                companiesData.map((company: CompanyModel) => ({
                    value: company.id,
                    name: company.name,
                }))
            )
    }, [companiesData])

    const validationSchema = Yup.object({
        companyId: Yup.number().required('Bu alan zorunludur'),
        productName: Yup.string().required('Bu alan zorunludur').max(100, 'Ürün ismi maksimum 100 karakter olmalıdır'),
        productInfo: Yup.string().required('Bu alan zorunludur').max(250, 'Ürün açıklaması maksimum 250 karakter olmalıdır'),
        price: Yup.number()
            .min(1, "Fiyat 0'a eşit veya 0'dan küçük olamaz.")
            .moreThan(Yup.ref('campaignPrice'), 'İndirimli fiyattan küçük olamaz')
            .required('Bu alan zorunludur')
            .typeError('Lütfen geçerli bir sayı giriniz'),
        campaignPrice: Yup.number()
            .lessThan(Yup.ref('price'), 'Liste fiyatından büyük olamaz')
            .min(1, "Kampanya fiyatı 0'a eşit veya 0'dan küçük olamaz.")
            .typeError('Lütfen geçerli bir sayı giriniz')
            .required('Bu alan zorunludur'),
        stockAmount: Yup.number().min(1, "Stok 0'a eşit veya 0'dan küçük olamaz.").typeError('Lütfen geçerli bir sayı giriniz').required('Bu alan zorunludur'),
        ownerFullName: Yup.string().required('Bu alan zorunludur'),
        ownerIban: Yup.string()
            .required('Bu alan zorunludur')
            .test('isValidIban', 'Geçerli bir IBAN giriniz', (value) => {
                return !!(value && value.replaceAll(' ', '').replaceAll('_', '').length === 26)
            }),
        ownerBankName: Yup.string().required('Bu alan zorunludur'),
    })

    const onSubmit = (values: ProductFormValues) => {
        onChange({ ...values })
        handleNext()
    }

    const removeUploadPhoto = (currentProductImage: string) => {
        const newPhotos = productImages.filter((productImage) => productImage !== currentProductImage)
        onChange({ ...productValues, productImages: newPhotos })
    }

    useEffect(() => {
        setProductImages(productValues.productImages)
    }, [productValues.productImages])

    return (
        <Formik initialValues={productValues} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize validateOnBlur validateOnMount={true}>
            {({ values, handleChange, handleSubmit, isValid, handleBlur, setFieldValue }) => {
                return (
                    <Form>
                        <Spinner open={!companiesData || isUploading} />
                        <FormContainer>
                            <CoInputContent>
                                <ErrorMessageControler>
                                    <Select
                                        selectedValue={values?.companyId}
                                        name="companyId"
                                        options={companies}
                                        onChange={(id, changeEvent) => {
                                            handleChange(changeEvent)
                                            setSelectedId(id as number)
                                        }}
                                        placeholder={'Firma Seç'}
                                        expand
                                    ></Select>
                                    <ErrorMessage name="companyId" component={'div'} />
                                </ErrorMessageControler>

                                <ErrorMessageControler>
                                    <TextField
                                        name="productName"
                                        placeholder="Ürün İsmi"
                                        onChange={(e, changeEvent) => handleChange(changeEvent)}
                                        value={values.productName}
                                        onBlur={handleBlur}
                                        expand
                                    />
                                    <ErrorMessage name="productName" component={'div'} />
                                </ErrorMessageControler>
                            </CoInputContent>

                            <ErrorMessageControler>
                                <TextField
                                    multiline
                                    rows={2}
                                    name="productInfo"
                                    type="text"
                                    placeholder="Ürün Açıklaması"
                                    onChange={(e, changeEvent) => handleChange(changeEvent)}
                                    value={values.productInfo}
                                    onBlur={handleBlur}
                                    expand
                                />
                                <ErrorMessage name="productInfo" component={'div'} />
                            </ErrorMessageControler>
                            <ErrorMessageControler>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: ptr(10) }}>
                                    <Box sx={{ width: '100%' }}>
                                        <ImageList cols={5} gap={3}>
                                            {productImages?.map((productImage, index: number) => (
                                                <ProductImageItem>
                                                    <ProductImageItemImg height="100%" width="100%" src={productImage} alt={`${productValues.productName}_${index}`} />
                                                    <PhotoOverlay className="photo-overlay" onClick={() => removeUploadPhoto(productImage)}>
                                                        <DeleteOutline sx={{ color: colors.white.default }} />
                                                    </PhotoOverlay>
                                                </ProductImageItem>
                                            ))}
                                        </ImageList>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'flex-end',
                                            alignItems: 'flex-end',
                                            width: '100%',
                                        }}
                                    >
                                        <input
                                            ref={uploadInputRef}
                                            type="file"
                                            name="productImages"
                                            hidden
                                            multiple
                                            accept="image/*"
                                            onChange={async (e) => {
                                                setUploadingStatus(true)
                                                if (e.currentTarget.files) {
                                                    const uploadData = await uploadMultiple(e.currentTarget.files)
                                                    if (uploadData) {
                                                        const processedData = uploadData.map((upload) => upload.url)
                                                        if (!productImages) {
                                                            setProductImages(processedData)
                                                            setFieldValue('productImages', processedData)
                                                        } else {
                                                            setProductImages((prevState) => [...prevState, ...processedData])
                                                            setFieldValue('productImages', [...productImages, ...processedData])
                                                        }
                                                    }
                                                }
                                                setUploadingStatus(false)
                                            }}
                                        />

                                        <UploadPhotosButton variant="contained" onClick={() => uploadInputRef.current && uploadInputRef.current.click()}>
                                            YENİ FOTOGRAF YÜKLE
                                        </UploadPhotosButton>
                                        <Typography size={12} color={colors.text.secondaryTextLight}>
                                            Maksimum 10 fotoğraf ekleyebilirsiniz.
                                        </Typography>
                                    </Box>
                                </Box>
                                <ErrorMessage name="productImages" component={'div'} />
                            </ErrorMessageControler>
                            <CoInputContent>
                                <ErrorMessageControler>
                                    <CurrencyInput
                                        name="price"
                                        placeholder="Liste Fiyatı"
                                        decimalsLimit={2}
                                        decimalScale={2}
                                        onValueChange={(value) => setFieldValue('price', value && parseInt(value))}
                                        onBlur={handleBlur}
                                        value={values.price}
                                    />
                                    <ErrorMessage name="price" component={'div'} />
                                </ErrorMessageControler>
                                <ErrorMessageControler>
                                    <CurrencyInput
                                        name="campaignPrice"
                                        placeholder="İndirimli Fiyatı"
                                        decimalsLimit={2}
                                        decimalScale={2}
                                        onValueChange={(value) => setFieldValue('campaignPrice', value && parseInt(value))}
                                        onBlur={handleBlur}
                                        value={values.campaignPrice}
                                    />
                                    <ErrorMessage name="campaignPrice" component={'div'} />
                                </ErrorMessageControler>
                                <ErrorMessageControler>
                                    <TextField name="stockAmount" placeholder="Stok" onChange={(e, changeEvent) => handleChange(changeEvent)} value={values.stockAmount} onBlur={handleBlur} expand />
                                    <ErrorMessage name="stockAmount" component={'div'} />
                                </ErrorMessageControler>
                            </CoInputContent>
                            <Divider />
                            <CoInputContent>
                                <ErrorMessageControler>
                                    <TextField
                                        name="ownerFullName"
                                        placeholder="Adı Soyadı"
                                        onChange={(e, changeEvent) => handleChange(changeEvent)}
                                        value={values.ownerFullName}
                                        onBlur={handleBlur}
                                        expand
                                    />
                                    <ErrorMessage name="ownerFullName" component={'div'} />
                                </ErrorMessageControler>
                                <ErrorMessageControler>
                                    <CustomInputMask
                                        name="ownerIban"
                                        placeholder="IBAN Numarası"
                                        onChange={(changeEvent) => handleChange(changeEvent)}
                                        value={values.ownerIban}
                                        onBlur={handleBlur}
                                        mask="TR 9999 9999 9999 9999 9999 9999"
                                    />
                                    <ErrorMessage name="ownerIban" component={'div'} />
                                </ErrorMessageControler>
                            </CoInputContent>
                            <CoInputContent>
                                <ErrorMessageControler>
                                    <TextField
                                        name="ownerBankName"
                                        placeholder="Banka Adı"
                                        onChange={(e, changeEvent) => handleChange(changeEvent)}
                                        value={values.ownerBankName}
                                        onBlur={handleBlur}
                                        expand
                                    />
                                    <ErrorMessage name="ownerBankName" component={'div'} />
                                </ErrorMessageControler>
                            </CoInputContent>
                        </FormContainer>

                        <ButtonsSide>
                            <Button id="next" onClick={handleSubmit} disabled={!isValid || productImages?.length <= 0 || productImages?.length > 10} endIcon={<ArrowForward />}>
                                İleri
                            </Button>
                            <Button variant="outlined" color="secondary" onClick={handleBack} startIcon={<ArrowBack />}>
                                İptal
                            </Button>
                        </ButtonsSide>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default ProductInfo
