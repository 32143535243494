import { RequestParams } from '../../types'
import { BaseUser } from 'models'

export interface UserModel {
    totalFilteredCount: number
    totalCount: number
    unconfirmedCount: number
    sellerCount: number
    buyerCount: number
    users: BaseUser[]
}

export interface UserStatistics extends Omit<UserModel, 'users'> {}

// export interface CreateUserModel extends Omit<BaseUser, "company" | "userName"> {}
export type CreateUserModel = Partial<Omit<BaseUser, 'company' | 'userName'>>

export interface GetUsersVariables extends PagedRequestDto, RequestParams<PagedRequestDto> {}

export interface PagedRequestDto {
    role?: 'Alici' | 'Satici' | 'Alici_Satici'
    position?: 'Yönetici' | 'Çalışan'
    status?: 'Aktif' | 'Pasif' | 'Beklemede'
    isPhoneConfirmed?: 'true' | 'false'
    maxResultCount?: number
    skipCount?: number
    filter?: string
    startDate?: string
    endDate?: string
    workCategory?: string
    vehicleType?: string
    vehicleMake?: string
    isFcmToken?: boolean
}

export type GetAllUsersServiceResponse = UserModel

export type GetUserProfileVariables = RequestParams<Pick<BaseUser, 'id'>>

export interface UserProfileModel {
    id: number
    userName: string
    name: string
    surname: string
    emailAddress: string
    isActive: boolean
    fullName: string
    isSeller: boolean
    isBuyer: boolean
    reference: string
    phoneNumber: string
    isPhoneNumberConfirmed: boolean
    avatar: string | null
    position: string
    companyId: number
    status: string
    role: string
    company: Company
    workCategories: WorkCategory[]
    vehicleMakes: VehicleMake[]
    vehicleTypes: VehicleType[]
    bankAccounts: string
    userRateAvg?: string
    shipmentAvg?: string
    qualityAvg?: string
    communicationAvg?: string
}

export type GetUserProfileResponse = UserProfileModel

export interface Company {
    id: number
    name: string
    taxNumber: string
    city: string
    district: string
    address: string
    managerId: number
    isActive: boolean
    isConfirmed: boolean
    status: string
}

export interface WorkCategory {
    userId: number
    workCategoryName: string
}

export interface VehicleMake {
    userId: number
    vehicleMakeName: string
}

export interface VehicleType {
    userId: number
    vehicleTypeName: string
}

export interface UpdateUserRoleModel {
    userId: number
    isBuyer: boolean
}

export interface UpdateUserVariables {
    id: number
    name: string
    surname: string
    emailAddress?: string | null
    reference: string
    companyId: number
    vehicleTypeNames: string[]
    vehicleMakeNames: string[]
    workCategoryNames: string[]
    avatar: string
    position: string
    bankAccounts: string
    phoneNumber: string
    isBuyer: boolean
    isSeller: boolean
}

export interface UpdateUserResponse {
    id: number
    userName: string
    name: string
    surname: string
    emailAddress: string
    isActive: boolean
    fullName: string
    isSeller: boolean
    isBuyer: boolean
    reference: string
    phoneNumber: string
    isPhoneNumberConfirmed: boolean
    avatar: string
    position: string
    bankAccounts: string
    companyId: number
    status: string
    role: string
}

export interface GetUserProfileCountModel {
    user: UserProfileCount
    offerCount: number
    boughtCount: number
    demandCount: number
    soldCount: number
}

export interface UserProfileCount {
    id: number
    name: string
    surname: string
    fullName: string
    emailAddress: string
    phoneNumber: string
    bankAccounts: string
}

export interface GetUserProfileCountVariables
    extends PagedRequestDto,
        RequestParams<{
            id: number
        }> {}

export type GetUserProfileNotificationVariables = {
    id: number
    skipCount: number
    maxResultCount: number
}

export type ProfileNotification = {
    id: number
    text: string
    title: string
    isRead: boolean
    creationTime: string
}
export interface ProfileNotificationWithCount {
    items: ProfileNotification[]
    totalCount: number
}

export type GetUserProfileChatVariables = {
    id: number
    Keyword: string
}

export type GetUserProfileChatDetailVariables = {
    conversationId: number
    SkipCount: number
    MaxResultCount: number
}

export enum UserProfileChatStatus {
    OnGoing = 12,
    Ended = 23,
}

export type UserProfileChat = {
    user: Pick<BaseUser, 'id' | 'fullName' | 'avatar'> & { companyName: string }
    sentAt: string
    status: UserProfileChatStatus
    conversationId: number
    page: number
    setPage: React.Dispatch<React.SetStateAction<number>>
}

export interface UserProfileChatDetail {
    sentAt: string
    senderUserId: number
    receiverUserId: number
    messageType: string
    messageData: string
    isMessageRead: boolean
    conversationId: number
}

export type GetUserActivateVariables = Pick<BaseUser, 'id'>

export type GetUserProfileChatDetailResponse = UserProfileChatDetail[]
export type GetUserProfileChatResponse = UserProfileChat[]
export type GetUserModelActivateServiceResponse = {
    data: ''
}
export type GetUserProfileNotificationResponse = ProfileNotificationWithCount
export type GetUserProfileCountResponse = GetUserProfileCountModel
export type GetUserServiceResponse = { user: BaseUser }
