import { GridColDef } from '@mui/x-data-grid'
import { SellerDemand } from 'services/be-api/dashboard/types'

export const topSellerExportOptions = [
    {
        key: 'SATICI',
        label: 'SATICI',
        onRender: (item: string, element: SellerDemand) => {
            return `${element?.fullName} ${element?.companyName}`
        },
    },
    {
        key: 'TOPLAM SATIS ADEDI',
        label: 'TOPLAM SATIS ADEDI',
        onRender: (item: string, element: SellerDemand) => {
            return `${element?.totalRequestCount}`
        },
    },
    {
        key: 'TOPLAM SATIS MIKTARI',
        label: 'TOPLAM SATIS MIKTARI',
        onRender: (item: string, element: SellerDemand) => {
            return `${element?.totalPurchasePrice} TL`
        },
    },
]

export const topSellerHead: GridColDef[] = [
    { field: 'seller', headerName: 'SATICI', width: 70 },
    { field: 'totalSales', headerName: 'TOPLAM SATIŞ ADEDİ', width: 130 },
    { field: 'totalSalesAmount', headerName: 'TOPLAM SATIŞ MİKTARI', width: 130 },
]

export const breadcrumbLinks = [
    { text: 'Dashboard', url: '' },
    { text: 'Kullanıcı Aksiyonları', url: '' },
    { text: 'Top Satıcı Detay', url: '/top-seller-details', isLast: true },
]
