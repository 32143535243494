import { RequestParams, Service } from 'services/types'
import { Request } from 'services/_base'

import {
    GET_CURRENT_USER,
    GET_USERS_ALL,
    GET_USER_PROFILE,
    GET_USER_PROFILE_COUNT,
    POST_CURRENT_USER,
    PUT_ACTIVE_USER,
    PUT_DEACTIVATE_USER,
    PUT_UPDATE_USER,
    UPDATE_USER_ROLE,
    GET_USER_PROFILE_NOTIFIATION,
    GET_USER_PROFILE_CHAT,
    GET_USER_PROFILE_CHAT_DETAIL,
    PUT_USER_STATUS_VERIFY,
} from './constants'
import {
    CreateUserModel,
    GetAllUsersServiceResponse,
    GetUserProfileChatDetailVariables,
    GetUserProfileChatVariables,
    GetUserProfileNotificationVariables,
    GetUserProfileVariables,
    GetUserServiceResponse,
    GetUsersVariables,
    UpdateUserRoleModel,
    UpdateUserVariables,
} from './types'

export const getUser: Service<any> = () => {
    return Request.get<any, GetUserServiceResponse>(GET_CURRENT_USER, {})
}

export const createUser: Service<CreateUserModel> = (data) => {
    return Request.post(POST_CURRENT_USER, data, {})
}

export const getAllUser: Service<GetUsersVariables> = ({ queryKey }) => {
    const { params } = queryKey[1]
    return Request.get<any, GetAllUsersServiceResponse>(GET_USERS_ALL, {
        role: params.role,
        position: params.position,
        status: params.status,
        IsPhoneConfirmed: params.isPhoneConfirmed,
        filter: params.filter,
        startDate: params.startDate,
        endDate: params.endDate,
        skipCount: params.skipCount,
        maxResultCount: params.maxResultCount,
        workCategory: params.workCategory,
        vehicleType: params.vehicleType,
        vehicleMake: params.vehicleMake,
        isFcmToken: params.isFcmToken,
    })
}

export const updateUserRole: Service<UpdateUserRoleModel> = ({ userId, isBuyer }) => {
    return Request.put(UPDATE_USER_ROLE(userId), { isBuyer }, {})
}

export const getUserProfile: Service<GetUserProfileVariables> = (querykeys) => {
    const params = querykeys.queryKey[1].params
    return Request.get(GET_USER_PROFILE(params.id), {})
}

export const deactiveUser: Service<any> = ({ id }) => {
    return Request.put(PUT_DEACTIVATE_USER(id), {}, {})
}

export const activeUser: Service<any> = ({ id }) => {
    return Request.put(PUT_ACTIVE_USER(id), {}, {})
}

export const updateUser: Service<UpdateUserVariables> = ({
    id,
    name,
    surname,
    emailAddress,
    reference,
    companyId,
    vehicleTypeNames,
    vehicleMakeNames,
    workCategoryNames,
    avatar,
    position,
    bankAccounts,
    phoneNumber,
    isBuyer,
    isSeller,
}) => {
    return Request.put(
        PUT_UPDATE_USER,
        { id, name, surname, emailAddress, reference, companyId, vehicleTypeNames, vehicleMakeNames, workCategoryNames, avatar, position, bankAccounts, phoneNumber, isBuyer, isSeller },
        {}
    )
}

export const getUserProfileCount: Service<any> = ({ queryKey }) => {
    const { params } = queryKey[1]

    return Request.get(GET_USER_PROFILE_COUNT(params.id), {
        id: params.id,
    })
}

export const getUserProfileNotification: Service<RequestParams<GetUserProfileNotificationVariables>> = ({ queryKey }) => {
    const { params } = queryKey[1]
    return Request.get(GET_USER_PROFILE_NOTIFIATION(params.id), {
        skipCount: params.skipCount,
        maxResultCount: params.maxResultCount,
    })
}

export const getUserProfileChats: Service<RequestParams<GetUserProfileChatVariables>> = ({ queryKey }) => {
    const { params } = queryKey[1]
    return Request.get(GET_USER_PROFILE_CHAT(params.id), {
        Keyword: params.Keyword,
    })
}
export const getUserProfileChatDetail: Service<RequestParams<GetUserProfileChatDetailVariables>> = ({ queryKey }) => {
    const { params } = queryKey[1]
    return Request.get(GET_USER_PROFILE_CHAT_DETAIL(params.conversationId), {
        SkipCount: params.SkipCount,
        MaxResultCount: params.MaxResultCount,
    })
}

export const putUserStatusVerify: Service<any> = ({ id }) => {
    return Request.put(PUT_USER_STATUS_VERIFY(id), {}, {})
}
