import { Mutation, Query } from '../types'
import useBaseQuery from '../_Base/useBaseQuery'
import {
    activeCompany,
    companyUpdate,
    createCompany,
    deactiveCompany,
    getAllCompanies,
    getAllCompaniesCount,
    getCompany,
    getCompanyProfileCount,
    getCompanyWithId,
} from 'services/be-api/companies/endpoints'
import { useBaseMutation } from '../_Base'
import {
    CreateCompanyModel,
    GetCompaniesCountServiceResponse,
    GetCompaniesModelServiceResponse,
    GetCompaniesVariables,
    GetCompanyActivateVariables,
    GetCompanyDetail,
    GetCompanyModelActivateServiceResponse,
    GetCompanyModelServiceResponse,
    GetCompanyProfileCountResponse,
    GetCompanyProfileCountVariables,
    GetCompanyProfileVariables,
    GetCompanyUpdateVariables,
    GetCompanyWithIdVariables,
} from 'services/be-api/companies/types'

export const useGetCompanies: Query<GetCompaniesVariables, GetCompaniesModelServiceResponse> = (params) =>
    useBaseQuery({
        queryKeys: ['companies', { params: params.queryKeys }],
        service: getAllCompanies,
        onSuccess: {
            messageDisplay: false,
            message: 'Successful.',
        },
        onLoading: {
            messageDisplay: false,
            message: 'Loading',
        },
        onError: {
            messageDisplay: false,
        },
        enabled: params.enabled,
    })

export const useGetCompaniesCounts: Query<GetCompaniesVariables, GetCompaniesCountServiceResponse> = (params) =>
    useBaseQuery({
        queryKeys: ['companies-count', { params: params.queryKeys }],
        service: getAllCompaniesCount,
        onSuccess: {
            messageDisplay: false,
            message: 'Successful.',
        },
        onLoading: {
            messageDisplay: false,
            message: 'Loading',
        },
        onError: {
            messageDisplay: false,
        },
    })

export const useGetCompanyProfile: Query<GetCompanyProfileVariables, GetCompanyModelServiceResponse> = (params) =>
    useBaseQuery({
        queryKeys: ['companies-profile', { params: params.queryKeys }],
        service: getCompany,
        onSuccess: {
            messageDisplay: false,
            message: 'Successful.',
        },
        onLoading: {
            messageDisplay: false,
            message: 'Loading',
        },
        onError: {
            messageDisplay: false,
        },
    })

export const useGetCompanyWithId: Query<GetCompanyWithIdVariables, GetCompanyDetail> = (params) =>
    useBaseQuery({
        queryKeys: ['companies-update', { params: params.queryKeys }],
        service: getCompanyWithId,
        onSuccess: {
            messageDisplay: false,
            message: 'Successful.',
        },
        onLoading: {
            messageDisplay: false,
            message: 'Loading',
        },
        onError: {
            messageDisplay: false,
        },
        enabled: params.enabled,
    })

export const useCreateCompany: Mutation<CreateCompanyModel, void> = () => {
    return useBaseMutation({
        service: createCompany,
        onSuccess: {
            messageDisplay: true,
            message: 'Şirket Başarıyla Oluşturuldu.',
        },
        onLoading: {
            messageDisplay: true,
            message: 'Şirket Oluşturuluyor',
        },
        onError: {
            messageDisplay: true,
        },
    })
}

export const useDeactiveCompany: Mutation<GetCompanyActivateVariables, GetCompanyModelActivateServiceResponse> = () => {
    return useBaseMutation({
        service: deactiveCompany,
        onSuccess: {
            messageDisplay: true,
            message: 'Şirket başarıyla askıya alındı.',
        },
        onLoading: {
            messageDisplay: true,
            message: 'Loading',
        },
        onError: {
            messageDisplay: true,
        },
    })
}

export const useActiveCompany: Mutation<GetCompanyActivateVariables, GetCompanyModelActivateServiceResponse> = () => {
    return useBaseMutation({
        service: activeCompany,
        onSuccess: {
            messageDisplay: true,
            message: 'Şirket başarıyla aktif edildi.',
        },
        onLoading: {
            messageDisplay: true,
            message: 'Loading',
        },
        onError: {
            messageDisplay: true,
        },
    })
}

export const useCompanyUpdate: Mutation<GetCompanyUpdateVariables, GetCompanyModelServiceResponse> = () => {
    return useBaseMutation({
        service: companyUpdate,
        onSuccess: {
            messageDisplay: true,
            message: 'Şirket başarıyla güncellendi.',
        },
        onLoading: {
            messageDisplay: true,
            message: 'Loading',
        },
        onError: {
            messageDisplay: true,
        },
    })
}

export const useGetCompanyProfileCount: Query<GetCompanyProfileCountVariables, GetCompanyProfileCountResponse> = (params) =>
    useBaseQuery({
        queryKeys: ['companies-profile', { params: params.queryKeys }],
        service: getCompanyProfileCount,
        onSuccess: {
            messageDisplay: false,
            message: 'Successful.',
        },
        onLoading: {
            messageDisplay: false,
            message: 'Loading',
        },
        onError: {
            messageDisplay: false,
        },
    })
