import { Badge, Box, Paper, styled, TextField } from '@mui/material'
import { colors } from 'styles/color'
import { ptr } from 'utils/helpers'

export const CustomChatContainer = styled('div')({
    marginTop: ptr(44),
    marginRight: ptr(24),
    marginLeft: ptr(24),
})

export const CustomBadge = styled(Badge)((props) => ({
    display: 'flex',
    alignItems: 'flex-start',
    padding: '4px 4px',
    borderRadius: '16px',
    width: '100%',
    textAlign: 'center',
}))

export const CustomTableDiv = styled('div')({
    boxShadow: ' 0px 2px 10px rgba(76, 78, 100, 0.22)',
    marginBottom: ptr(24),
})

export const CustomCardDiv = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-between',
})

export const CustomSearchInput = styled(TextField)({
    borderColor: colors.primary.primaryMain,
    height: ptr(40),
    '& > div': {
        height: '80px',
        fontWeight: '400',
        paddingRight: '12px',
        fontSize: '18px',
        borderColor: colors.primary.primaryMain,
        color: colors.black.default,
    },
    '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
        color: colors.black.default,
    },
    '& .Mui-focused': {
        borderColor: colors.primary.primaryMain,
        color: `${colors.primary.primaryMain} !important`,
        '& .MuiOutlinedInput-notchedOutline': {
            border: '2px solid #FFAA00 !important',
        },
    },
})

export const CustomFilterBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-around',
    height: ptr(144),
    gap: ptr(16),
    padding: ptr(20),
    background: colors.white.default,
})

export const CustomHeadDiv = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    textAlign: 'center',
    alignItems: 'center',
    background: colors.white.default,
    padding: '0px 20px',
})

export const CustomFilterDiv = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'right',
    gap: ptr(24),
    textAlign: 'center',
    height: ptr(80),
    alignItems: 'center',
})

export const CustomCardBox = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    textAlign: 'center',
    alignItems: 'center',
    marginBottom: ptr(24),
    gap: ptr(16),
    height: ptr(120),
})

export const CustomBoxColumn = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
})

export const CustomCardPaper = styled(Paper)({
    display: 'flex',
    alignItems: 'center',
    padding: '14px 20px',
    justifyContent: 'flex-start',
    textAlign: 'center',
    width: '100%',
    height: ptr(100),
    background: colors.white.default,
    boxShadow: '0px 2px 10px rgba(76, 78, 100, 0.22)',
    borderRadius: '4px',
    gap: ptr(16),
})

export const CustomExportBox = styled(Box)({
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    textAlign: 'center',
    width: '30%',
})

export const CustomExportBoxTypo = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    gap: ptr(16),
})

export const CustomFilteredBox = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    gap: ptr(20),
    width: '70%',
})

export const CustomHeadBox = styled(Box)({
    display: 'flex',
    alignItems: 'flex-start',
    padding: '4px 4px',
    borderRadius: '16px',
    width: '50%',
    textAlign: 'center',
})

export const CustomContentBox = styled(Box)({
    display: 'flex',
    alignItems: 'flex-start',
    borderRadius: '16px',
    width: '100%',
    alignContent: 'flex-start',
    justifyContent: 'flex-start',
})

export const CustomPhotoBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'flex-start',
    gap: ptr(10),
})

export const CustomMenuBox = styled(Box)({
    background: colors.white.default,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    borderRadius: '16px',
    textAlign: 'center',
    width: ptr(169),
    height: 'fit-content',
    padding: ptr(10),
    boxShadow: '0px 5px 5px -3px rgba(76, 78, 100, 0.2), 0px 8px 10px 1px rgba(76, 78, 100, 0.14), 0px 3px 14px 2px rgba(76, 78, 100, 0.12)',
})

export const CustomMenuBoxTypo = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    textAlign: 'center',
    gap: ptr(10),
    flexDirection: 'column',
})

export const CustomUserRateTypography = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    gap: ptr(10),
})
