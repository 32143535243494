import DashboardLayout from 'layouts/Dashboard/DashboardLayout'
import {
    CustomAdminBox,
    CustomBadge,
    CustomBadgeCampaigns,
    CustomBox,
    CustomCardPopular,
    CustomChartBox,
    CustomChartBoxCampaigns,
    CustomChartBoxCampaignsRight,
    CustomChartContainer,
    CustomChartContainerCampaigns,
    CustomChartLeftBox,
    CustomChartMiddleBox,
    CustomChartTypoBox,
    CustomChartTypoBoxCamp,
    CustomChartTypoBoxCampaigns,
    CustomChartTypoBoxCampaignsDiv,
    CustomChartTypoBoxCampLeft,
    CustomListBox,
    CustomListBoxChild,
    CustomListBoxHead,
    CustomListBoxHeadRight,
    CustomListBoxListRight,
    CustomTimeSelectionBox,
    LinearProgressWithLabel,
    MainPageContainer,
} from './index.styled'
import TimeSelection from './timeSelection'
import { dateFormat, ISelectDate } from 'models/common/selectDate'
import AdminCard from './adminCard'
import CardsWithAvatar from './cardWithAvatar'
import DemandChart from './charts/demandChart'
import OfferChart from './charts/offerChart'
import { Badge, Box, Button, Divider, IconButton, ListItem } from '@mui/material'
import { badgeColor, endDateConverter, ptr, splitNumber, startDateConverter } from 'utils/helpers'
import Typography from 'components/Typography/Typography'
import Avatar from 'components/Avatar/Avatar'
import { colors } from 'styles/color'
import { Cancel, CheckCircle, ListAltOutlined, LocalShipping, MoreVert, Sell, ShoppingBag, ShoppingBasket, ShoppingCart, WatchLaterOutlined } from '@mui/icons-material'
import PopButton from 'components/Buttons/PopButton/PopButton'
import Icon from 'components/Icons/Icon'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import CardsWithIconUserAct from './cardWithIcon/userAct'
import CardsWithIconCampaigns from './cardWithIcon/campaigns'
import OrderStatusChart from './charts/orderStatusChart'
import { useGetAdmin } from 'utils/hooks/queries/Admin'
import { IAdmin } from 'services/be-api/admin/types'
import {
    useGetAllSpecialOfferChart,
    useGetAllTopBuyerDemand,
    useGetAllTopBuyerSpecialOffer,
    useGetAllTopSpecialOffer,
    useGetAllUserChart,
    useGetTopDemandOfferChart,
    useGetTopSpecialOfferChart,
} from 'utils/hooks/queries/Dashboard/useDashboard'
import { BuyerDemand, SpecialOffer, SpecialOfferChartResponse, TopBuyerSpecialOfferItem, TopDemandOfferChart, TopSpecialOfferChart, UserChartResponse } from 'services/be-api/dashboard/types'
import Spinner from 'components/Spinner'
import NoDataMessage from './noDataPictures'
import useDateStore from 'context/date-store'

const MainPage = () => {
    const [specialOffer, setSpecialOffer] = useState<SpecialOfferChartResponse>({} as SpecialOfferChartResponse)
    const [userChart, setUserChart] = useState<UserChartResponse>({} as UserChartResponse)
    const [buyerSpecial, setBuyerSpecial] = useState<Array<TopBuyerSpecialOfferItem>>([])
    const [topBuyer, setTopBuyer] = useState<BuyerDemand[]>([])
    const [topSpecialOffer, setTopSpecialOffer] = useState<SpecialOffer[]>([])
    const [topSpecialOfferChart, setTopSpecialOfferChart] = useState<TopSpecialOfferChart[]>([])
    const [topDemandOfferChart, setTopDemandOfferChart] = useState<TopDemandOfferChart[]>([])

    const [selectedDate, setSelectedDate] = useState<ISelectDate>({
        startDate: dayjs().format(dateFormat),
        endDate: dayjs().format(dateFormat),
    })
    const [admin, setAdmin] = useState({} as IAdmin)
    const { data: adminData, isSuccess: adminSuccess, isLoading: adminLoading } = useGetAdmin({ queryKeys: {} })
    const navigate = useNavigate()
    const { setEndDate, setStartDate, endDate, startDate, reset } = useDateStore()

    const {
        data: specialOfferData,
        isSuccess: specialOfferSuccess,
        isLoading: specialOfferLoading,
    } = useGetAllSpecialOfferChart({
        queryKeys: {
            startDate: startDate?.length ? startDateConverter(startDate) : startDateConverter(selectedDate.startDate) || null,
            endDate: endDate?.length ? endDateConverter(endDate) : endDateConverter(selectedDate.endDate) || null,
        },
    })

    const {
        data: userChartData,
        isSuccess: userChartSuccess,
        isLoading: userChartLoading,
    } = useGetAllUserChart({
        queryKeys: {
            startDate: startDate?.length ? startDateConverter(startDate) : startDateConverter(selectedDate.startDate) || null,
            endDate: endDate?.length ? endDateConverter(endDate) : endDateConverter(selectedDate.endDate) || null,
        },
    })

    const { data: topSpecialOfferChartData, isSuccess: topSpecialOfferChartSuccess } = useGetTopSpecialOfferChart({
        queryKeys: {
            startDate: startDate?.length ? startDateConverter(startDate) : startDateConverter(selectedDate.startDate) || null,
            endDate: endDate?.length ? endDateConverter(endDate) : endDateConverter(selectedDate.endDate) || null,
        },
    })

    const { data: topDemandOfferChartData, isSuccess: topDemandOfferChartSuccess } = useGetTopDemandOfferChart({
        queryKeys: {
            startDate: startDate?.length ? startDateConverter(startDate) : startDateConverter(selectedDate.startDate) || null,
            endDate: endDate?.length ? endDateConverter(endDate) : endDateConverter(selectedDate.endDate) || null,
        },
    })

    const { data: topSpecialOfferData, isSuccess: isTopSpecialOfferSuccess } = useGetAllTopSpecialOffer({
        queryKeys: {
            workCategoryName: null,
            workSubCategoryName: null,
            filter: null,
            maxResultCount: 5,
            skipCount: null,
        },
    })

    const { data: topBuyerSpecialOfferData, isSuccess: topBuyerSpecialOfferSuccess } = useGetAllTopBuyerSpecialOffer({
        queryKeys: {
            startDate: startDate?.length ? startDateConverter(startDate) : startDateConverter(selectedDate.startDate) || null,
            endDate: endDate?.length ? endDateConverter(endDate) : endDateConverter(selectedDate.endDate) || null,
            skipCount: null,
            maxResultCount: 5,
        },
    })

    const { data: topBuyerData, isSuccess: isTopBuyerSuccess } = useGetAllTopBuyerDemand({
        queryKeys: {
            skipCount: null,
            maxResultCount: 5,
        },
    })

    useEffect(() => {
        if (specialOfferData && specialOfferSuccess) {
            setSpecialOffer(specialOfferData.data.result)
        }
    }, [specialOfferData, specialOfferSuccess])

    useEffect(() => {
        if (userChartData && userChartSuccess) {
            setUserChart(userChartData.data.result)
        }
    }, [userChartData, userChartSuccess])

    useEffect(() => {
        if (adminData && adminSuccess) {
            setAdmin(adminData.data.result)
        }
    }, [adminData, adminSuccess])

    useEffect(() => {
        if (isTopSpecialOfferSuccess && topSpecialOfferData?.data) {
            setTopSpecialOffer(topSpecialOfferData?.data.result.items)
        }
    }, [isTopSpecialOfferSuccess, topSpecialOfferData])

    useEffect(() => {
        if (topBuyerSpecialOfferData && topBuyerSpecialOfferSuccess) {
            setBuyerSpecial(topBuyerSpecialOfferData.data.result.items)
        }
    }, [topBuyerSpecialOfferData, topBuyerSpecialOfferSuccess])

    useEffect(() => {
        if (isTopBuyerSuccess && topBuyerData?.data) {
            setTopBuyer(topBuyerData?.data.result.items)
        }
    }, [isTopBuyerSuccess, topBuyerData])

    useEffect(() => {
        if (topSpecialOfferChartData && topSpecialOfferChartSuccess) {
            setTopSpecialOfferChart(topSpecialOfferChartData?.data.result)
        }
    }, [topSpecialOfferChartData, topSpecialOfferChartSuccess])

    useEffect(() => {
        if (topDemandOfferChartData && topDemandOfferChartSuccess) {
            setTopDemandOfferChart(topDemandOfferChartData?.data.result)
        }
    }, [topDemandOfferChartData, topDemandOfferChartSuccess])

    const handleClick = (path: string, sdate: string, edate: string) => {
        navigate(`/${path}`, { state: { startDate: selectedDate.startDate, endDate: selectedDate.endDate } })

        setStartDate(sdate)
        setEndDate(edate)
    }

    return (
        <DashboardLayout>
            <Spinner open={specialOfferLoading || userChartLoading || adminLoading} />
            <MainPageContainer>
                <CustomAdminBox>
                    <AdminCard admin={admin} />
                </CustomAdminBox>
                <CustomTimeSelectionBox>
                    <TimeSelection
                        onSelectDate={setSelectedDate}
                        startDate={selectedDate.startDate}
                        endDate={selectedDate.endDate}
                        navigateStartDate={startDate || ''}
                        navigateEndDate={endDate || ''}
                        reset={reset}
                    />
                </CustomTimeSelectionBox>
                <Typography variant="h5-medium" color={colors.text.secondaryTextLight}>
                    KAMPANYALAR
                </Typography>
                <CardsWithIconCampaigns specialOfferCount={specialOffer.specialOfferCount} />
                <CustomChartContainerCampaigns>
                    <CustomChartBoxCampaigns>
                        <CustomChartTypoBoxCampaigns>
                            <CustomChartLeftBox>
                                <CustomChartTypoBoxCampLeft>
                                    <Typography variant="h6-medium">Sipariş Durum Dağılımı</Typography>
                                    <Typography color={colors.text.secondaryTextLight} variant="body-small-default">
                                        {specialOffer.orderStatusRates?.totalCount} Sipariş
                                    </Typography>
                                </CustomChartTypoBoxCampLeft>
                                <OrderStatusChart orderStatusRates={specialOffer.orderStatusRates} />
                            </CustomChartLeftBox>
                        </CustomChartTypoBoxCampaigns>
                        <Divider orientation="vertical" />
                        <CustomChartTypoBoxCampaignsDiv>
                            <CustomChartTypoBoxCamp>
                                <CustomListBoxHead>
                                    <Typography variant="h6-medium">Durum Listesi</Typography>
                                    <Typography color={colors.text.secondaryTextLight} variant="body-small-default">
                                        {specialOffer.orderStatusRates?.totalCount} Sipariş
                                    </Typography>
                                </CustomListBoxHead>
                                <PopButton
                                    button={
                                        <IconButton sx={{ padding: '0' }}>
                                            <Icon icon={<MoreVert width={4} height={16} />} />
                                        </IconButton>
                                    }
                                    popArea={
                                        <Button
                                            sx={{ width: ptr(160), height: ptr(55), background: colors.white.default, border: '1px solid black', color: colors.black.default, cursor: 'pointer' }}
                                            variant="outlined"
                                            onClick={() => handleClick('dashboard-top-buyer-details', selectedDate.startDate, selectedDate.endDate)}
                                        >
                                            <ListAltOutlined fontSize="small" />{' '}
                                            <Typography pointer variant="body-smaller-default">
                                                Detay Raporuna Git
                                            </Typography>
                                        </Button>
                                    }
                                    pos={{ x: -150, y: 25 }}
                                />{' '}
                            </CustomChartTypoBoxCamp>
                            <CustomChartMiddleBox>
                                <CustomListBox
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flexDirections: 'row',
                                        gap: ptr(12),
                                    }}
                                >
                                    <Badge
                                        sx={{
                                            background: colors.primary.primaryLightBg,
                                            color: colors.primary.primaryMain,
                                            borderRadius: '8px',
                                            height: ptr(40),
                                            width: ptr(40),
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <WatchLaterOutlined fontSize="large" />
                                    </Badge>
                                    <CustomListBoxChild sx={{}}>
                                        <Typography variant="body-small-bold" color={colors.text.primaryTextLight}>
                                            Beklemede
                                        </Typography>
                                        <Typography variant="body-smaller-default" color={colors.text.secondaryTextLight}>
                                            {specialOffer.orderStatusRates?.orderPendingCount} Sipariş
                                        </Typography>
                                    </CustomListBoxChild>
                                </CustomListBox>
                                <CustomListBox
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flexDirections: 'row',
                                        gap: ptr(12),
                                    }}
                                >
                                    <Badge
                                        sx={{
                                            background: colors.info.infoLightBg,
                                            color: colors.info.infoMain,
                                            borderRadius: '8px',
                                            height: ptr(40),
                                            width: ptr(40),
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <ShoppingBag fontSize="large" />
                                    </Badge>
                                    <CustomListBoxChild sx={{}}>
                                        <Typography variant="body-small-bold" color={colors.text.primaryTextLight}>
                                            Hazırlanıyor
                                        </Typography>
                                        <Typography variant="body-smaller-default" color={colors.text.secondaryTextLight}>
                                            {specialOffer.orderStatusRates?.orderInPreparatioCount} Sipariş
                                        </Typography>
                                    </CustomListBoxChild>
                                </CustomListBox>
                                <CustomListBox
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flexDirections: 'row',
                                        gap: ptr(12),
                                    }}
                                >
                                    <Badge
                                        sx={{
                                            background: colors.success.SuccessLightBg,
                                            color: colors.success.successMain,
                                            borderRadius: '8px',
                                            height: ptr(40),
                                            width: ptr(40),
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <LocalShipping fontSize="large" />
                                    </Badge>
                                    <CustomListBoxChild sx={{}}>
                                        <Typography variant="body-small-bold" color={colors.text.primaryTextLight}>
                                            Kargolandı
                                        </Typography>
                                        <Typography variant="body-smaller-default" color={colors.text.secondaryTextLight}>
                                            {specialOffer.orderStatusRates?.orderShippedCount} Sipariş
                                        </Typography>
                                    </CustomListBoxChild>
                                </CustomListBox>
                                <CustomListBox
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flexDirections: 'row',
                                        gap: ptr(12),
                                    }}
                                >
                                    <Badge
                                        sx={{
                                            background: colors.secondary.secondaryLightBg,
                                            color: colors.secondary.secondaryMain,
                                            borderRadius: '8px',
                                            height: ptr(40),
                                            width: ptr(40),
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <CheckCircle fontSize="large" />
                                    </Badge>
                                    <CustomListBoxChild sx={{}}>
                                        <Typography variant="body-small-bold" color={colors.text.primaryTextLight}>
                                            Teslim Edildi
                                        </Typography>
                                        <Typography variant="body-smaller-default" color={colors.text.secondaryTextLight}>
                                            {specialOffer.orderStatusRates?.orderDeliverdCount} Sipariş
                                        </Typography>
                                    </CustomListBoxChild>
                                </CustomListBox>
                                <CustomListBox
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flexDirections: 'row',
                                        gap: ptr(12),
                                    }}
                                >
                                    <Badge
                                        sx={{
                                            background: colors.error.errorLightBg,
                                            color: colors.error.errorMain,
                                            borderRadius: '8px',
                                            height: ptr(40),
                                            width: ptr(40),
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Cancel fontSize="large" />
                                    </Badge>
                                    <CustomListBoxChild sx={{}}>
                                        <Typography variant="body-small-bold" color={colors.text.primaryTextLight}>
                                            İptal Edildi
                                        </Typography>
                                        <Typography variant="body-smaller-default" color={colors.text.secondaryTextLight}>
                                            {specialOffer.orderStatusRates?.orderCanceledCount} Sipariş
                                        </Typography>
                                    </CustomListBoxChild>
                                </CustomListBox>
                            </CustomChartMiddleBox>
                        </CustomChartTypoBoxCampaignsDiv>{' '}
                    </CustomChartBoxCampaigns>
                    <CustomChartBoxCampaignsRight>
                        <CustomListBoxHeadRight>
                            <Typography variant="h6-medium">Toplam Sipariş Tutarı</Typography>
                            <Typography color={colors.primary.primaryMain} variant="h4-medium">
                                {splitNumber(specialOffer.purchasedDto?.totalPurchased)}
                                <span style={{ fontSize: '16px', fontWeight: 'normal', color: colors.white.grey }}>TL</span>
                            </Typography>
                        </CustomListBoxHeadRight>
                        {specialOffer.purchasedDto?.categoryDto.map((item) => (
                            <>
                                <Divider orientation="horizontal" sx={{ width: '100%' }} />
                                <CustomListBoxListRight>
                                    <Typography variant="body-small-default">{item.name} </Typography>
                                    <Typography variant="body-small-semibold">
                                        {splitNumber(item.price)} <span>TL</span>
                                    </Typography>
                                </CustomListBoxListRight>
                            </>
                        ))}
                    </CustomChartBoxCampaignsRight>
                </CustomChartContainerCampaigns>
                <CustomBox>
                    <CustomCardPopular>
                        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'space-between' }}>
                            <Typography variant="h6-medium">Popüler Kampanya Kategoriler</Typography>{' '}
                            <PopButton
                                button={
                                    <IconButton sx={{ padding: '0' }}>
                                        <Icon icon={<MoreVert width={4} height={16} />} />
                                    </IconButton>
                                }
                                popArea={
                                    <Button
                                        variant="outlined"
                                        style={{ border: '1px solid black', background: colors.white.default, color: colors.black.default }}
                                        onClick={() => handleClick('dashboard-popular-campaings-category-details', selectedDate.startDate, selectedDate.endDate)}
                                    >
                                        Detay
                                    </Button>
                                }
                                pos={{ x: -80, y: 25 }}
                            />{' '}
                        </Box>
                        {topSpecialOfferChart.length >= 1 ? (
                            topSpecialOfferChart.map((item, index) => (
                                <ListItem style={{ width: '100%' }} key={index}>
                                    <Typography disabled width="100%" variant="body-normal-semibold">
                                        {item.name}
                                        <LinearProgressWithLabel
                                            variant="determinate"
                                            value={Number(item.categoryPercent.replace(',', '.'))}
                                            valueBuffer={Number(item.categoryPercent.replace(',', '.'))}
                                        />
                                    </Typography>
                                </ListItem>
                            ))
                        ) : (
                            <NoDataMessage />
                        )}
                    </CustomCardPopular>
                    <CustomCardPopular>
                        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'space-between' }}>
                            <Typography variant="h6-medium">
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: ptr(12), justifyContent: 'flex-start' }}>
                                    <ShoppingBag sx={{ color: colors.primary.primaryMain }} /> Top 5 Kampanya
                                </Box>
                            </Typography>
                            <PopButton
                                button={
                                    <IconButton sx={{ padding: '0' }}>
                                        <Icon icon={<MoreVert width={4} height={16} />} />
                                    </IconButton>
                                }
                                popArea={
                                    <Button
                                        variant="outlined"
                                        style={{ border: '1px solid black', background: colors.white.default, color: colors.black.default }}
                                        onClick={() => handleClick('dashboard-top-campaign-details', selectedDate.startDate, selectedDate.endDate)}
                                    >
                                        Detay
                                    </Button>
                                }
                                pos={{ x: -80, y: 25 }}
                            />{' '}
                        </Box>
                        {topSpecialOffer?.length >= 1 ? (
                            topSpecialOffer.map((item, index) => (
                                <ListItem style={{ width: '100%', display: 'flex', justifyContent: 'space-between', textAlign: 'start', alignItems: 'center' }} key={index}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: ptr(12), justifyContent: 'flex-start' }}>
                                        <img
                                            src={item.productPhotos && Array.isArray(JSON.parse(item.productPhotos)) && (JSON.parse(item.productPhotos) || '')}
                                            width={40}
                                            height={30}
                                            alt={item.productName}
                                        />
                                        <Box>
                                            <Typography width="100%" variant="body-small-semibold">
                                                {item.productName}
                                            </Typography>
                                            <Typography color={colors.text.secondaryTextLight} width="100%" variant="body-smaller-default">
                                                {item.company.name}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <CustomBadgeCampaigns sx={{ padding: '0px 120px 0px 0px' }}>
                                        <Typography color={colors.primary.primaryMain} width={'100%'} variant="body-small-default">
                                            #{item.productCode}
                                        </Typography>
                                    </CustomBadgeCampaigns>
                                </ListItem>
                            ))
                        ) : (
                            <NoDataMessage />
                        )}
                    </CustomCardPopular>
                    <CustomCardPopular>
                        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'space-between' }}>
                            <Typography variant="h6-medium">
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: ptr(12), justifyContent: 'flex-start' }}>
                                    <ShoppingCart sx={{ color: colors.primary.primaryMain }} /> Top 5 Kampanya Alıcısı
                                </Box>
                            </Typography>
                            <PopButton
                                button={
                                    <IconButton sx={{ padding: '0' }}>
                                        <Icon icon={<MoreVert width={4} height={16} />} />
                                    </IconButton>
                                }
                                popArea={
                                    <Button
                                        variant="outlined"
                                        style={{ border: '1px solid black', color: colors.black.default, background: colors.white.default }}
                                        onClick={() => handleClick('dashboard-top-campaign-buyer-details', selectedDate.startDate, selectedDate.endDate)}
                                    >
                                        Detay
                                    </Button>
                                }
                                pos={{ x: -80, y: 25 }}
                            />{' '}
                        </Box>
                        {buyerSpecial?.length >= 1 ? (
                            buyerSpecial.map((item, index) => (
                                <ListItem style={{ width: '100%', display: 'flex', justifyContent: 'space-between', textAlign: 'start', alignItems: 'center' }} key={index}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: ptr(12), justifyContent: 'flex-start' }}>
                                        <Avatar name={item.fullName} />
                                        <Box>
                                            <Typography width="100%" variant="body-small-semibold">
                                                {item.fullName}
                                            </Typography>
                                            <Typography color={colors.text.secondaryTextLight} width="100%" variant="body-smaller-default">
                                                {item.companyName}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </ListItem>
                            ))
                        ) : (
                            <NoDataMessage />
                        )}
                    </CustomCardPopular>{' '}
                </CustomBox>
                <Typography variant="h5-medium" color={colors.text.secondaryTextLight}>
                    KULLANICI AKSİYONLARI
                </Typography>
                <CardsWithAvatar startDate={selectedDate.startDate} endDate={selectedDate.endDate} ratingDto={userChart.ratingDto} complaintCount={userChart.complaintCount} />
                <CardsWithIconUserAct demandStatus={userChart.demandStatusDto} />
                <CustomChartContainer>
                    <CustomChartBox>
                        <CustomChartTypoBox>
                            <Typography variant="h6-medium">Aktif Talep Durumu Dağılımları</Typography>
                            <PopButton
                                button={
                                    <IconButton sx={{ padding: '0' }}>
                                        <Icon icon={<MoreVert width={4} height={16} />} />
                                    </IconButton>
                                }
                                popArea={
                                    <Button
                                        variant="outlined"
                                        style={{
                                            border: '1px solid black',
                                            color: colors.black.default,
                                            background: colors.white.default,
                                        }}
                                        onClick={() => handleClick('requests', selectedDate.startDate, selectedDate.endDate)}
                                    >
                                        Detay
                                    </Button>
                                }
                                pos={{ x: -80, y: 25 }}
                            />
                        </CustomChartTypoBox>
                        <DemandChart demandStatus={userChart?.demandStatusDto} />
                    </CustomChartBox>
                    <CustomChartBox>
                        <CustomChartTypoBox>
                            <Typography variant="h6-medium">Teklif Durumu Dağılımları</Typography>
                        </CustomChartTypoBox>
                        <OfferChart offerStatus={userChart?.offerStatusDto} />
                    </CustomChartBox>
                </CustomChartContainer>
                <CustomBox>
                    <CustomCardPopular>
                        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'space-between' }}>
                            <Typography variant="h6-medium">Popüler Kategoriler</Typography>{' '}
                            <PopButton
                                button={
                                    <IconButton sx={{ padding: '0' }}>
                                        <Icon icon={<MoreVert width={4} height={16} />} />
                                    </IconButton>
                                }
                                popArea={
                                    <Button
                                        variant="outlined"
                                        style={{ border: '1px solid black', color: colors.black.default, background: colors.white.default }}
                                        onClick={() => handleClick('dashboard-popular-category-details', selectedDate.startDate, selectedDate.endDate)}
                                    >
                                        Detay
                                    </Button>
                                }
                                pos={{ x: -80, y: 25 }}
                            />{' '}
                        </Box>
                        {topDemandOfferChart?.length >= 1 ? (
                            topDemandOfferChart.map((item, index) => (
                                <ListItem style={{ width: '100%' }} key={index}>
                                    <Typography disabled width="100%" variant="body-normal-semibold">
                                        {item.name}
                                        <LinearProgressWithLabel
                                            variant="determinate"
                                            value={Number(item.categoryPercent.toString().replace(',', '.'))}
                                            valueBuffer={Number(item.categoryPercent.toString().replace(',', '.'))}
                                        />
                                    </Typography>
                                </ListItem>
                            ))
                        ) : (
                            <NoDataMessage />
                        )}
                    </CustomCardPopular>
                    <CustomCardPopular>
                        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'space-between' }}>
                            <Typography variant="h6-medium">
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: ptr(12), justifyContent: 'flex-start' }}>
                                    <Sell sx={{ color: colors.success.successMain }} /> Top 5 Satıcı
                                </Box>
                            </Typography>
                            <PopButton
                                button={
                                    <IconButton sx={{ padding: '0' }}>
                                        <Icon icon={<MoreVert width={4} height={16} />} />
                                    </IconButton>
                                }
                                popArea={
                                    <Button
                                        variant="outlined"
                                        style={{ border: '1px solid black', color: colors.black.default, background: colors.white.default }}
                                        onClick={() => handleClick('dashboard-top-seller-details', selectedDate.startDate, selectedDate.endDate)}
                                    >
                                        Detay
                                    </Button>
                                }
                                pos={{ x: -80, y: 25 }}
                            />{' '}
                        </Box>
                        {userChart.topUserDto?.length >= 1 ? (
                            userChart.topUserDto?.map((item, index) => (
                                <ListItem style={{ width: '100%', display: 'flex', justifyContent: 'space-between', textAlign: 'start', alignItems: 'center' }} key={index}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: ptr(12), justifyContent: 'flex-start' }}>
                                        <Avatar name={item.fullName} />
                                        <Box>
                                            <Typography width="100%" variant="body-small-semibold">
                                                {item.fullName}
                                            </Typography>
                                            <Typography color={colors.text.secondaryTextLight} width="100%" variant="body-smaller-default">
                                                {item.companyName}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <CustomBadge style={{ backgroundColor: badgeColor(Number(item.userRateAvg.replace(',', '.'))) }}>
                                        <Typography color={colors.white.default} width={'100%'} variant="body-smaller-medium">
                                            {item.userRateAvg}
                                        </Typography>
                                    </CustomBadge>
                                </ListItem>
                            ))
                        ) : (
                            <NoDataMessage />
                        )}
                    </CustomCardPopular>
                    <CustomCardPopular>
                        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'space-between' }}>
                            <Typography variant="h6-medium">
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: ptr(12), justifyContent: 'flex-start' }}>
                                    <ShoppingBasket sx={{ color: colors.info.infoMain }} /> Top 5 Alıcı
                                </Box>
                            </Typography>
                            <PopButton
                                button={
                                    <IconButton sx={{ padding: '0' }}>
                                        <Icon icon={<MoreVert width={4} height={16} />} />
                                    </IconButton>
                                }
                                popArea={
                                    <Button
                                        variant="outlined"
                                        style={{ border: '1px solid black', color: colors.black.default, background: colors.white.default }}
                                        onClick={() => handleClick('dashboard-top-buyer-details', selectedDate.startDate, selectedDate.endDate)}
                                    >
                                        Detay
                                    </Button>
                                }
                                pos={{ x: -80, y: 25 }}
                            />{' '}
                        </Box>
                        {topBuyer?.length >= 1 ? (
                            topBuyer.map((item, index) => (
                                <ListItem style={{ width: '100%', display: 'flex', justifyContent: 'space-between', textAlign: 'start', alignItems: 'center' }} key={index}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: ptr(12), justifyContent: 'flex-start' }}>
                                        <Avatar name={item.fullName} />
                                        <Box>
                                            <Typography width="100%" variant="body-small-semibold">
                                                {item.fullName}
                                            </Typography>
                                            <Typography color={colors.text.secondaryTextLight} width="100%" variant="body-smaller-default">
                                                {item.companyName}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </ListItem>
                            ))
                        ) : (
                            <NoDataMessage />
                        )}
                    </CustomCardPopular>{' '}
                </CustomBox>
            </MainPageContainer>
        </DashboardLayout>
    )
}

export default MainPage
