import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { IosShare, LocalOffer, ShoppingBasket, Verified } from '@mui/icons-material'
import { Box } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import dayjs from 'dayjs'

import Button from 'components/Buttons/Button/Button'
import ExportDropdown from 'components/ExportDropdown'
import { TableBodyRowData } from 'components/Table/types'
import Spinner from 'components/Spinner'
import Table from 'components/Table/Table'
import { endDateConverter, ptr, startDateConverter } from 'utils/helpers'

import { CustomExportBox, CustomFilterDiv, CustomHeadDiv, CustomTableDiv } from '../index.styled'
import { User } from '../types'
import { colors } from 'styles/color'
import Typography from 'components/Typography/Typography'
import { EUserRoles } from 'utils/constants'
import { useGetAllUsers } from 'utils/hooks/queries/Users'
import { CreateNotificationProps } from './types'
import SearchInput from '../../../components/Input/SearchInput/SearchInput'

export const exportOptions = [
    {
        key: 'KULLANICI',
        label: 'KULLANICI',
        onRender: (item: string, element: User) => {
            return `${element?.name} ${element?.surname}`
        },
    },
    {
        key: 'TELEFON',
        label: 'TELEFON',
        onRender: (item: string, element: User) => {
            return `${element?.phoneNumber}`
        },
    },
    {
        key: 'ROL',
        label: 'ROL',
        onRender: (item: string, element: User) => {
            return `${element?.role}`
        },
    },
    {
        key: 'CALISTIGI KATEGORI',
        label: 'CALISTIGI KATEGORI',
        onRender: (item: string, element: User) => {
            const workCategories = element.workCategories?.map((workCategory) => workCategory.workCategoryName)
            const concatenatedString = workCategories?.join(', ')
            return concatenatedString
        },
    },
    {
        key: 'VASITA TIPI',
        label: 'VASITA TIPI',
        onRender: (item: string, element: User) => {
            const vehicleTypeNames = element.vehicleTypes?.map((vehicleType) => vehicleType.vehicleTypeName)
            const concatenatedString = vehicleTypeNames?.join(', ')
            return concatenatedString
        },
    },
    {
        key: 'KAYIT TARIHI',
        label: 'KAYIT TARIHI',
        onRender: (item: string, element: User) => {
            return dayjs(element.creationTime).format('YYYY-MM-DD HH:mm')
        },
    },
]

const usersHead: GridColDef[] = [
    { field: 'user', headerName: 'KULLANICI', width: 70 },
    { field: 'telephone', headerName: 'TELEFON', width: 130 },
    { field: 'role', headerName: 'ROL', width: 100 },
    { field: 'position', headerName: 'POZİSYON', width: 100 },
    { field: 'workCategories', headerName: 'ÇALIŞTIĞI KATEGORİ', width: 200 },
    { field: 'vehicleTypes', headerName: 'VASITA TİPİ', width: 130 },
    { field: 'creationTimes', headerName: 'KAYIT TARİHİ', width: 130 },
]

const CreateNotifications = ({
    role,
    position,
    startDate,
    selectedWorkCategories,
    selectedVehicleTypes,
    selectedVehicleMakes,
    endDate,
    setSelectedIds,
    selectedIds,
    setSellerCount,
    setBuyerCount,
    handleToggle,
    isNotificationCreateLoading,
    setCurrentPage,
    rowPerPage,
    setRowPerPage,
    currentPage,
}: CreateNotificationProps) => {
    const [users, setUsers] = useState<User[]>([])
    const [exportArray, setExportArray] = useState<User[]>([])

    const navigate = useNavigate()

    const [exportToggle, setExportToggle] = useState(false)

    const [filterText, setFilterText] = useState('')

    const {
        data: usersData,
        isSuccess: isGetAllUsersSuccess,
        isLoading: isGetAllUsersLoading,
    } = useGetAllUsers({
        queryKeys: {
            role: role,
            position: position,
            startDate: startDate.length ? startDateConverter(startDate) : null,
            endDate: endDate.length ? endDateConverter(endDate) : null,
            workCategory: selectedWorkCategories || null,
            vehicleType: selectedVehicleTypes || null,
            vehicleMake: selectedVehicleMakes || null,
            filter: filterText || null,
            skipCount: currentPage * rowPerPage,
            maxResultCount: rowPerPage,
            isPhoneConfirmed: true,
            isFcmToken: true,
        },
    })
    const {
        data: usersDataExport,
        isSuccess: isGetAllUsersSuccessExport,
        refetch: isGetAllUsersRefetchExport,
    } = useGetAllUsers({
        queryKeys: {
            maxResultCount: 1000,
            role: role,
            position: position,
            startDate: startDate.length ? startDateConverter(startDate) : null,
            endDate: endDate.length ? endDateConverter(endDate) : null,
            workCategory: selectedWorkCategories || null,
            vehicleType: selectedVehicleTypes || null,
            vehicleMake: selectedVehicleMakes || null,
            filter: filterText || null,
            isPhoneConfirmed: true,
            isFcmToken: true,
        },
        enabled: false,
    })

    useEffect(() => {
        if (isGetAllUsersSuccessExport && usersDataExport?.data.result) {
            setExportArray(usersDataExport.data.result.users)
        }
    }, [usersDataExport, isGetAllUsersSuccessExport])

    useEffect(() => {
        if (isGetAllUsersSuccess && usersData?.data) {
            setUsers(usersData?.data.result.users)
        }
    }, [isGetAllUsersSuccess, usersData])

    const handleTo = (id: number, type: string) => {
        navigate(`/${type}/${id}`)
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowPerPage(parseInt(event.target.value, 10))
        setCurrentPage(0)
    }
    const onPageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setCurrentPage(newPage)
    }

    const handleExport = (exportToggle: boolean, set: React.Dispatch<React.SetStateAction<boolean>>) => {
        set(!exportToggle)
        isGetAllUsersRefetchExport()
    }

    useEffect(() => {
        let sCount = 0
        let bCount = 0

        const filteredData = users.filter((user) => selectedIds.includes(user.id!.toString()))
        for (const user of filteredData) {
            if (user.role === EUserRoles.SELLER) sCount++
            else if (user.role === EUserRoles.BUYER) bCount++
            else {
                sCount++
                bCount++
            }
        }
        setSellerCount(sCount)
        setBuyerCount(bCount)
    }, [selectedIds, users, setSellerCount, setBuyerCount])
    const rowData: TableBodyRowData[] =
        users &&
        users.map((user) => {
            return {
                selectableId: user.id,
                user: (
                    <Box>
                        <Box>
                            <Typography variant="body-normal-semibold" onClick={() => handleTo(user.id as number, 'users')}>
                                {user.name} {user.surname}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography color={colors.text.secondaryTextLight} variant="body-normal-default" onClick={() => handleTo(user.id as number, 'users')}>
                                {user?.company?.name}
                            </Typography>
                        </Box>
                    </Box>
                ),
                telephone: (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: ptr(12.75) }}>
                        <Typography variant="body-normal-semibold">{user.phoneNumber}</Typography>
                        {user.isPhoneNumberConfirmed && <Verified style={{ color: colors.success.successAlternativeDark }} />}
                    </Box>
                ),
                role: (
                    <div>
                        {user.role === EUserRoles.BOTH ? (
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '0.5rem' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                                    <LocalOffer style={{ color: colors.success.successMain }} />
                                    <Typography>{EUserRoles.SELLER}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <ShoppingBasket style={{ color: colors.info.infoMain }} />
                                    <Typography>{EUserRoles.BUYER}</Typography>
                                </Box>
                            </Box>
                        ) : user.role === EUserRoles.BUYER ? (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                                <ShoppingBasket style={{ color: colors.info.infoMain }} />
                                <Typography>{EUserRoles.BUYER}</Typography>
                            </Box>
                        ) : (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                                <LocalOffer style={{ color: colors.success.successMain }} />
                                <Typography>{EUserRoles.SELLER}</Typography>
                            </Box>
                        )}
                    </div>
                ),
                position: (
                    <Box>
                        <Typography variant="body-normal-semibold">{user.position}</Typography>
                    </Box>
                ),
                workCategories: (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                        {user.workCategories?.map((workCategory) => (
                            <Typography key={workCategory.workCategoryName} variant="body-normal-default">
                                {workCategory.workCategoryName}
                            </Typography>
                        ))}
                    </Box>
                ),
                vehicleTypes: (
                    <Box>
                        {user.vehicleTypes?.map((vehicleType) => (
                            <Typography key={vehicleType.vehicleTypeName} variant="body-normal-default">
                                {vehicleType.vehicleTypeName}
                            </Typography>
                        ))}
                    </Box>
                ),
                creationTimes: (
                    <Box>
                        <Typography variant="body-normal-default">{dayjs(user.creationTime).format('YYYY-MM-DD HH:mm')}</Typography>
                    </Box>
                ),
            }
        })
    return (
        <CustomTableDiv>
            <CustomHeadDiv style={{ height: ptr(81) }}>
                <CustomExportBox>
                    <Button
                        startIcon={<IosShare />}
                        onClick={() => handleExport(exportToggle, setExportToggle)}
                        variant="outlined"
                        colorsx={colors.secondary.secondaryAlternativeLight}
                        borderColor={colors.secondary.secondaryAlternativeLight}
                        padding={ptr(16)}
                    >
                        <ExportDropdown<User>
                            array={exportArray}
                            fileName={'Bildirim Oluşturma Tablo'}
                            title={''}
                            sheetName={''}
                            headers={exportOptions}
                            open={exportToggle}
                            buttonHeader={'Export'}
                            isSuccess={isGetAllUsersSuccessExport}
                        />
                    </Button>
                    <Typography variant="body-smaller-default">Maksimum 1000 veri</Typography>
                </CustomExportBox>
                <CustomFilterDiv style={{ gap: '20px' }}>
                    <SearchInput
                        value={filterText}
                        onChange={(val) => {
                            setFilterText(val)
                            if (currentPage !== 0) setCurrentPage(0)
                        }}
                        placeholder="Firma veya Kullanıcı Ara"
                    />
                    <Button size="large" disabled={selectedIds.length < 1 || isNotificationCreateLoading} onClick={handleToggle}>
                        BİLDİRİM GÖNDER
                    </Button>
                </CustomFilterDiv>
            </CustomHeadDiv>
            <div>
                <Spinner open={isGetAllUsersLoading} />
                <Table
                    count={usersData?.data.result.totalFilteredCount}
                    isLoading={isGetAllUsersLoading}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    onPageChange={onPageChange}
                    head={usersHead}
                    result={rowPerPage}
                    rowsData={rowData}
                    isClickable={false}
                    page={currentPage}
                    checkBox={true}
                    onSelectAll={(ids) => {
                        setSelectedIds(ids)
                    }}
                    onSelected={(id: string) => {
                        if (selectedIds.includes(id)) setSelectedIds(selectedIds.filter((fil: string) => fil !== id))
                        else setSelectedIds([...selectedIds, id])
                    }}
                    selectedIds={selectedIds}
                ></Table>
            </div>
        </CustomTableDiv>
    )
}

export default CreateNotifications
