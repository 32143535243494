import { styled } from '@mui/material'
import { ptr } from '../../../../utils/helpers'

export const CheckboxContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '1.65rem',
}))
export const TopCheckboxsConteiner = styled('div')(() => ({
    width: '60%',
    display: 'flex',
    justifyContent: 'space-between',
    // gap: '12rem',
}))
export const TopCheckboxConteiner = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
}))
export const BottomCheckboxConteiner = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '0.7rem',
}))
export const BottomCheckboxContent = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
    maxHeight: '600px',
    overflowX: 'auto',
    columnGap: ptr(37.5),
}))
