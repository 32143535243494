import DashboardLayout from '../../layouts/Dashboard/DashboardLayout'
import Typography from '../../components/Typography/Typography'
import { FilterGroup, FilterSection, OfferTableHead, OfferTableSection, OrderStatus } from './index.styled'
import { Box, IconButton } from '@mui/material'
import AutocompleteContainer from '../../components/Input/AutoComplete/Autocomplete'
import React, { FC, useEffect, useState } from 'react'
import { ProductTableFilterSection } from '../products/index.styled'
import Button from '../../components/Buttons/Button/Button'
import { CancelSharp, ChangeCircle, IosShare } from '@mui/icons-material'
import { endDateConverter, getKey, ptr, startDateConverter } from '../../utils/helpers'
import ExportDropdown from '../../components/ExportDropdown'
import Table from '../../components/Table'
import { useNavigate } from 'react-router-dom'
import { offerTableHead, orderExportOptions, orderStatusOptions, orderTransactionHistoryTableHead, orderTypeOptions } from './constants'
import { useGetOrderHistory, useGetOrders, useUpdateOrderStatus } from '../../utils/hooks/queries/Orders'
import { TableBodyRowData } from '../../components/Table/types'
import dayjs from 'dayjs'
import { colors } from '../../styles/color'
import { Order, OrderHistory, OrderStatusFilter, OrderTypeFilter, UpdateOrderStatusData } from 'services/be-api/orders/types'
import UpdateModal from './UpdateModal'
import { OrderTransactionHistoryTableProps } from './types'
import Spinner from '../../components/Spinner'
import DatePicker from '../../components/DateRangePicker/DateRangePicker'
import Modal from '../../components/Modal/Modal'
import SearchInput from '../../components/Input/SearchInput/SearchInput'
import PriceText from '../../components/PriceText'
import { CustomExportBox } from 'pages/notifications/index.styled'

const OrderTransactionHistoryTable: FC<OrderTransactionHistoryTableProps> = ({ id }) => {
    const [transactionHistories, setTransactionHistories] = useState<OrderHistory[]>([])
    const { data: orderHistories } = useGetOrderHistory({ queryKeys: { id }, enabled: true })

    useEffect(() => {
        if (orderHistories?.data.result) setTransactionHistories(orderHistories.data.result)
    }, [orderHistories])
    let rowData: TableBodyRowData[] = transactionHistories?.map((transactionHistory) => ({
        updateDate: <Typography>{dayjs(transactionHistory.operationDate).format('YYYY/MM/DD HH:mm')}</Typography>,
        status: (
            <Box sx={{ display: 'flex', gap: ptr(25) }}>
                <OrderStatus className={`${getKey(OrderStatusFilter, transactionHistory.status).toLowerCase()}`}>{transactionHistory.status}</OrderStatus>
                {transactionHistory.status === OrderStatusFilter.SHIPPING && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: ptr(8) }}>
                        <Box sx={{ display: 'flex', gap: ptr(10), alignItems: 'center' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography size={12}>Firma Adı</Typography>
                                <Typography size={14} weight={600}>
                                    {transactionHistory.shippedVia}
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography size={12}>Paket Takip Num.</Typography>
                                <Typography size={14} weight={600}>
                                    {transactionHistory.shippingTrackingNumber}
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography size={12}>Açıklama</Typography>
                            <Typography size={14}>{transactionHistory.shippingNotes}</Typography>
                        </Box>
                    </Box>
                )}
                {transactionHistory.status === OrderStatusFilter.CANCELED && (
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                        <Typography weight={500} size={12} color={colors.text.secondaryTextLight}>
                            Açıklama
                        </Typography>
                        <Typography size={14} color={colors.text.primaryTextLight}>
                            {transactionHistory.rejectReason}
                        </Typography>
                    </Box>
                )}
            </Box>
        ),
    }))

    return (
        <>
            <Table count={1} isLoading={false} head={orderTransactionHistoryTableHead} rowsData={rowData} isClickable={true} pagination={false} />
            <Spinner open={false} />
        </>
    )
}

export const Orders = () => {
    const navigate = useNavigate()
    const [filterText, setFilterText] = useState<string>('')
    const [currentPage, setCurrentPage] = useState(0)
    const [rowPerPage, setRowPerPage] = useState(10)
    const [orders, setOrders] = useState<Order[]>([])
    const [exportArray, setExportArray] = useState<Order[]>([])
    const [isOrderUpdateModalVisible, setIsOrderUpdateModalVisible] = useState<boolean>(false)
    const [orderStatus, setOrderStatus] = useState<string>('')
    const [orderType, setOrderType] = useState<string>('')
    const [updateOrderStatusData, setUpdateOrderStatusData] = useState<UpdateOrderStatusData>({} as UpdateOrderStatusData)
    const [startDate, setStartDate] = useState<string>('')
    const [endDate, setEndDate] = useState<string>('')
    const [isDelete, setIsDelete] = useState<boolean>(false)
    const [exportToggle, setExportToggle] = useState(false)

    const { data: ordersData, refetch: refetchOrders } = useGetOrders({
        queryKeys: {
            Filter: filterText.replace('#', '') || null,
            Status: getKey(OrderStatusFilter, orderStatus) || null,
            OrderType: getKey(OrderTypeFilter, orderType) || null,
            StartDate: startDate.length ? startDateConverter(startDate) : null,
            EndDate: endDate.length ? endDateConverter(endDate) : null,
            SkipCount: currentPage * rowPerPage,
            MaxResultCount: rowPerPage,
        },
    })
    const {
        data: ordersDataExport,
        refetch: refetchOrdersExport,
        isSuccess: isOrdersDataSuccessExport,
    } = useGetOrders({
        queryKeys: {
            Filter: filterText.replace('#', '') || null,
            Status: getKey(OrderStatusFilter, orderStatus) || null,
            OrderType: getKey(OrderTypeFilter, orderType) || null,
            StartDate: startDate.length ? startDateConverter(startDate) : null,
            EndDate: endDate.length ? endDateConverter(endDate) : null,
            MaxResultCount: 1000,
        },
    })

    const { mutate: updateOrderStatus, data: updateOrderStatusResultData } = useUpdateOrderStatus()

    useEffect(() => {
        if (isOrdersDataSuccessExport && ordersDataExport?.data.result) {
            setExportArray(ordersDataExport.data.result.items)
        }
    }, [ordersDataExport, isOrdersDataSuccessExport])

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowPerPage(parseInt(event.target.value, 10))
        setCurrentPage(0)
    }

    const onPageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setCurrentPage(newPage)
    }

    const handleSelectDate = (sDate: string, eDate: string) => {
        setStartDate(sDate)
        setEndDate(eDate)
    }

    useEffect(() => {
        if (ordersData?.data.result) setOrders(ordersData.data.result.items)
    }, [ordersData])

    useEffect(() => {
        refetchOrders()
    }, [refetchOrders, updateOrderStatusResultData?.data.success])

    const handleExport = (exportToggle: boolean, set: React.Dispatch<React.SetStateAction<boolean>>) => {
        set(!exportToggle)
        refetchOrdersExport()
    }

    const rowData: TableBodyRowData[] = orders?.map((order: Order) => {
        const isDisabled = order.status === OrderStatusFilter.CANCELED || order.status === OrderStatusFilter.DELIVERED
        return {
            orderTime: (
                <Typography weight={400} size={14}>
                    {dayjs(order.creationTime).format('YYYY-MM-DD, HH:mm')}
                </Typography>
            ),
            bankTransactionCode: <Typography weight={600}>{order.bankTransactionCode}</Typography>,
            orderUser: (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography weight={600} onClick={() => navigate(`/users/${order.user?.id}`)}>
                        {order.user?.fullName}
                    </Typography>
                    <Typography onClick={() => navigate(`/companies/${order.user.company.id}`)}>{order.user?.company?.name}</Typography>
                </Box>
            ),
            productInfo: (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography weight={600}>{order.specialOffer.productDescription}</Typography>
                    <Typography color={colors.primary.primaryMain}>#{order.specialOffer.productCode}</Typography>
                </Box>
            ),
            price: (
                <Typography>
                    <PriceText price={order.purchasePrice * order.requestedAmount} />
                </Typography>
            ),
            amount: <Typography>{order.requestedAmount}</Typography>,
            productStatus: (
                <Box sx={{ display: 'flex', alignItems: 'center', width: '90%', justifyContent: 'space-between' }}>
                    <OrderStatus className={`${getKey(OrderStatusFilter, order.status).toLowerCase()}`}>{order.status}</OrderStatus>

                    <Button
                        color="success"
                        endIcon={<ChangeCircle />}
                        padding={ptr(10)}
                        size="small"
                        borderRadius={ptr(4)}
                        disabled={isDisabled}
                        onClick={() => {
                            setIsOrderUpdateModalVisible(true)
                            setUpdateOrderStatusData({
                                orderId: order.id,
                                status: order.status as Exclude<OrderStatusFilter, OrderStatusFilter.PENDING>,
                            })
                        }}
                    >
                        Güncelle
                    </Button>
                    <IconButton
                        disabled={isDisabled}
                        onClick={() => {
                            setUpdateOrderStatusData((prevState) => ({
                                ...prevState,
                                orderId: order.id,
                                status: OrderStatusFilter.CANCELED,
                            }))
                            setIsOrderUpdateModalVisible(true)
                        }}
                    >
                        <CancelSharp sx={{ color: isDisabled ? colors.action.actionDisabledBgLight : colors.error.errorMain }} />
                    </IconButton>
                </Box>
            ),
            detail: <OrderTransactionHistoryTable id={order.id} />,
        }
    })

    return (
        <DashboardLayout>
            <FilterSection>
                <Typography variant="h6-medium">Filtreleyerek Ara</Typography>
                <FilterGroup>
                    <Box width={'100%'}>
                        <AutocompleteContainer
                            width="100%"
                            placeholder="Sipariş Durumu Seç"
                            onChange={(e) => {
                                setOrderStatus(e as string)
                                if (currentPage !== 0) setCurrentPage(0)
                            }}
                            options={orderStatusOptions}
                            selectedValue={orderStatus}
                        />
                    </Box>
                    <Box width={'100%'}>
                        <AutocompleteContainer
                            width="100%"
                            placeholder="Sırala"
                            options={orderTypeOptions}
                            onChange={(e) => {
                                setOrderType(e as string)
                                if (currentPage !== 0) setCurrentPage(0)
                            }}
                            selectedValue={orderType}
                        />
                    </Box>
                    <Box width={'100%'}>
                        <DatePicker
                            setEndDate={setEndDate}
                            setStartDate={setStartDate}
                            startDate={startDate}
                            endDate={endDate}
                            width={'100%'}
                            placeholder={'Tarihe Göre Filtrele'}
                            onSelectDate={({ startDate, endDate }) => {
                                handleSelectDate(startDate, endDate)
                                if (currentPage !== 0) setCurrentPage(0)
                            }}
                        />
                    </Box>
                </FilterGroup>
            </FilterSection>
            <OfferTableSection>
                <OfferTableHead>
                    <CustomExportBox>
                        <Button
                            startIcon={<IosShare />}
                            onClick={() => handleExport(exportToggle, setExportToggle)}
                            variant="outlined"
                            colorsx={colors.secondary.secondaryAlternativeLight}
                            borderColor={colors.secondary.secondaryAlternativeLight}
                            padding={ptr(16)}
                        >
                            <ExportDropdown<Order>
                                array={exportArray}
                                fileName={'Orders'}
                                title={''}
                                sheetName={''}
                                headers={orderExportOptions}
                                open={exportToggle}
                                buttonHeader={'Export'}
                                isSuccess={isOrdersDataSuccessExport}
                            />
                        </Button>
                        <Typography variant="body-smaller-default">Maksimum 1000 veri</Typography>
                    </CustomExportBox>
                    <ProductTableFilterSection>
                        <SearchInput
                            value={filterText}
                            onChange={(val) => {
                                setFilterText(val)
                                if (currentPage !== 0) setCurrentPage(0)
                            }}
                            placeholder="Sipariş Ara"
                        />
                    </ProductTableFilterSection>
                </OfferTableHead>
                <Table
                    count={ordersData?.data.result.totalCount}
                    isLoading={false}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    onPageChange={onPageChange}
                    head={offerTableHead}
                    result={rowPerPage}
                    rowsData={rowData}
                    isClickable={false}
                    page={currentPage}
                />
            </OfferTableSection>
            <UpdateModal
                isModalOpen={isOrderUpdateModalVisible}
                closeAction={() => {
                    setIsOrderUpdateModalVisible(false)
                    setUpdateOrderStatusData({} as UpdateOrderStatusData)
                }}
                updateOrderStatus={updateOrderStatus}
                {...updateOrderStatusData}
            />
            <Modal
                open={isDelete}
                onClose={() => setIsDelete(false)}
                onConfirm={() => {
                    updateOrderStatus({ id: updateOrderStatusData.orderId, status: updateOrderStatusData.status })
                    setIsDelete(false)
                }}
                header={'Siparişi iptal etmek istiyor musunuz?'}
                subheader={''}
            ></Modal>
        </DashboardLayout>
    )
}

export default Orders
