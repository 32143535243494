import { GridColDef } from '@mui/x-data-grid'
import { SpecialOffer } from 'services/be-api/dashboard/types'

export const topSpecialOffersExportOptions = [
    {
        key: 'URUN KODU',
        label: 'URUN KODU',
        onRender: (item: string, element: SpecialOffer) => {
            return `${element?.productCode}`
        },
    },
    {
        key: 'URUN ISMI',
        label: 'URUN ISMI',
        onRender: (item: string, element: SpecialOffer) => {
            return `${element?.productName}`
        },
    },
    {
        key: 'URUN ACIKLAMASI',
        label: 'URUN ACIKLAMASI',
        onRender: (item: string, element: SpecialOffer) => {
            return `${element?.productDescription}`
        },
    },
    {
        key: 'SATILAN STOK ',
        label: 'SATILAN STOK ',
        onRender: (item: string, element: SpecialOffer) => {
            return `${element?.purchasedStock}`
        },
    },
    {
        key: 'KALAN STOK ',
        label: 'KALAN STOK ',
        onRender: (item: string, element: SpecialOffer) => {
            return `${element?.remainingStock}`
        },
    },
    {
        key: 'YAPILAN SATIS',
        label: 'YAPILAN SATIS',
        onRender: (item: string, element: SpecialOffer) => {
            return `${element?.productPrice}`
        },
    },
]
export const topSpecailOfferTableHead: GridColDef[] = [
    { field: 'productCode', headerName: 'ÜRÜN KODU', width: 140 },
    { field: 'productName', headerName: 'ÜRÜN İSMİ', width: 140 },
    { field: 'productDescription', headerName: 'ÜRÜN AÇIKLAMASI', width: 400 },
    { field: 'remainingStock', headerName: 'KALAN STOK', width: 60 },
    { field: 'purchasedStock', headerName: 'SATILAN STOK', width: 60 },
    { field: 'purchasedPrice', headerName: 'YAPILAN SATIŞ', width: 80 },
]

export const breadcrumbLinks = [
    { text: 'Dashboard', url: '' },
    { text: 'Kampanyalar', url: '' },
    { text: 'Top Kampanya Detayı', url: '/top-campaign-details', isLast: true },
]
