import { GridColDef } from '@mui/x-data-grid'
import Table from 'components/Table/Table'
import { TableBodyRowData } from 'components/Table/types'
import DashboardLayout from 'layouts/Dashboard/DashboardLayout'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
    CustomBadge,
    CustomBoxColumn,
    CustomCardBox,
    CustomCardDiv,
    CustomCardPaper,
    CustomCompaniesContainer,
    CustomExportBox,
    CustomFilterBox,
    CustomFilterDiv,
    CustomHeadDiv,
    CustomTableDiv,
} from './index.styled'
import citiesOfTurkey from 'utils/data/citiesOfTurkey.json'
import DatePicker from 'components/DateRangePicker/DateRangePicker'
import { Badge, Box } from '@mui/material'
import { endDateConverter, ptr, startDateConverter } from 'utils/helpers'
import Button from 'components/Buttons/Button/Button'
import { colors } from 'styles/color'
import ExportDropdown from 'components/ExportDropdown'
import { IosShare, MapsHomeWork, PendingActions } from '@mui/icons-material'
import Typography from 'components/Typography/Typography'
import { useGetCompanies, useGetCompaniesCounts } from 'utils/hooks/queries/Companies/useCompanies'
import { CompanyModel } from 'services/be-api/companies/types'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import AutocompleteContainer from 'components/Input/AutoComplete/Autocomplete'
import Spinner from 'components/Spinner'
import SearchInput from '../../components/Input/SearchInput/SearchInput'

export const userRoleOption = [
    {
        value: 'Alici',
        label: 'Alıcı',
    },
    {
        value: 'Satici',
        label: 'Satıcı',
    },
]

export const companiesHead: GridColDef[] = [
    { field: 'companyName', headerName: 'FİRMA ve VERGİ NUMARASI', width: 70 },
    { field: 'managerName', headerName: 'YETKİLİ ve NUMARASI', width: 130 },
    { field: 'managerRol', headerName: 'ROL', width: 60 },
    { field: 'province', headerName: 'İL ve İLÇE', width: 130 },
    { field: 'status', headerName: 'DURUM', width: 130 },
]

export const statusOption = [
    {
        value: 'Aktif',
        label: 'Aktif',
    },
    {
        value: 'Pasif',
        label: 'Pasif',
    },
    {
        value: 'Beklemede',
        label: 'Beklemede',
    },
]

export const exportOptions = [
    {
        key: 'FIRMA ADI VE VERGI NUMARASI',
        label: 'FIRMA ADI VE VERGI NUMARASI',
        onRender: (item: string, element: CompanyModel) => {
            return `${element?.name}/${element?.taxNumber}`
        },
    },
    {
        key: 'YETKILI ADI VE TELEFON NUMARASI',
        label: 'YETKILI ADI VE TELEFON NUMARASI',
        onRender: (item: string, element: CompanyModel) => {
            return `${element?.manager?.name} ${element?.manager?.surname}/${element?.manager?.phoneNumber}`
        },
    },
    {
        key: 'ROL',
        label: 'ROL',
        onRender: (item: string, element: CompanyModel) => {
            return `${element.manager?.role}`
        },
    },
    {
        key: 'IL VE ILCE',
        label: 'IL VE ILCE',
        onRender: (item: string, element: CompanyModel) => {
            return `${element?.city}/${element?.district}`
        },
    },
    {
        key: 'DURUM',
        label: 'DURUM',
        onRender: (item: string, element: CompanyModel) => {
            return `${element?.status}`
        },
    },
]
const Companies = () => {
    const [companies, setCompanies] = useState<Array<CompanyModel>>([])
    const [exportArray, setExportArray] = useState<Array<CompanyModel>>([])
    const navigate = useNavigate()
    const [cityOptions, setCityOptions] = useState<{ value: string | number; label: string | number }[]>([])
    const [statusOptions, setStatusOptions] = useState<{ value: string | number; name: string | number }[]>([])
    const [selectedCity, setSelectedCity] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [exportToggle, setExportToggle] = useState(false)
    const [filterText, setFilterText] = useState('')
    const [currentPage, setCurrentPage] = useState(0)
    const [rowPerPage, setRowPerPage] = useState(10)
    const [role, setRole] = useState<string>('')
    dayjs.extend(customParseFormat)
    const {
        data: companiesData,
        isSuccess: companiesSuccess,
        isLoading: companiesLoading,
    } = useGetCompanies({
        queryKeys: {
            role: role,
            maxResultCount: rowPerPage,
            skipCount: currentPage * rowPerPage || null,
            filter: filterText || null,
            city: selectedCity || null,
            status: statusOptions || null,
            startDate: startDate.length ? startDateConverter(startDate) : null,
            endDate: endDate.length ? endDateConverter(endDate) : null,
        },
    })

    const { data: countData } = useGetCompaniesCounts({
        queryKeys: {
            role: role,
            maxResultCount: null,
            skipCount: null,
            filter: filterText || null,
            city: selectedCity || null,
            status: statusOptions || null,
            startDate: startDate.length ? startDateConverter(startDate) : null,
            endDate: endDate.length ? endDateConverter(endDate) : null,
        },
    })

    const {
        data: companiesDataExport,
        isSuccess: isCompaniesSuccessExport,
        refetch: companiesExportRefetch,
    } = useGetCompanies({
        queryKeys: {
            role: role,
            maxResultCount: 1000,
            skipCount: null,
            filter: filterText,
            city: selectedCity,
            status: statusOptions,
            startDate: startDate.length ? startDateConverter(startDate) : null,
            endDate: endDate.length ? endDateConverter(endDate) : null,
        },
        enabled: false,
    })

    useEffect(() => {
        if (isCompaniesSuccessExport && companiesDataExport?.data.result) {
            setExportArray(companiesDataExport.data.result.companies)
        }
    }, [isCompaniesSuccessExport, companiesDataExport])

    useEffect(() => {
        if (companiesSuccess && companiesData?.data.result) {
            setCompanies(companiesData?.data.result.companies)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companiesSuccess, companiesData])

    useEffect(() => {
        setCityOptions(citiesOfTurkey.data.map((child) => ({ value: child.il_adi, label: child.il_adi })))
    }, [])

    const toNewCompany = () => {
        navigate('/companies/new')
    }

    const handleTo = (id: number, role: string) => {
        navigate(`/${role}/${id}`)
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowPerPage(parseInt(event.target.value, 10))
        setCurrentPage(0)
    }

    const onPageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setCurrentPage(newPage)
    }

    const handleSelectDate = (sDate: string, eDate: string) => {
        setStartDate(sDate)
        setEndDate(eDate)
    }

    const handleExport = (exportToggle: boolean, set: React.Dispatch<React.SetStateAction<boolean>>) => {
        set(!exportToggle)
        companiesExportRefetch()
    }

    const rowData: TableBodyRowData[] = companies.map((p) => {
        return {
            companyName: (
                <Box>
                    <Box>
                        <Typography variant="body-normal-semibold" onClick={() => handleTo(p.id, 'companies')}>
                            {p.name}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography color={colors.text.secondaryTextLight} variant="body-normal-default">
                            {p.taxNumber}
                        </Typography>
                    </Box>
                </Box>
            ),

            managerName: (
                <Box>
                    <Box>
                        <Typography variant="body-normal-semibold" onClick={() => handleTo(p.manager!.id, 'users')}>
                            {p.manager?.name} {p.manager?.surname}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography color={colors.text.secondaryTextLight} variant="body-normal-default">
                            {p.manager?.phoneNumber}
                        </Typography>
                    </Box>
                </Box>
            ),
            managerRole: (
                <Box>
                    <Typography variant="body-small-default">{p.manager?.role}</Typography>
                </Box>
            ),
            province: (
                <Box>
                    <Typography variant="body-normal-default">
                        {p.city}/{p.district}
                    </Typography>
                </Box>
            ),
            status: (
                <Box>
                    <CustomBadge style={{ backgroundColor: p.status === 'Beklemede' ? '#fff3db' : p.status === 'Aktif' ? '#dbf8c9' : '#E5EBEC' }}>
                        <Typography width={'100%'} variant="body-smaller-default" color={p.status === 'Beklemede' ? '#FDB528' : p.status === 'Aktif' ? '#72E128' : '#5578AD'}>
                            {p.status}
                        </Typography>
                    </CustomBadge>
                </Box>
            ),
        }
    })

    return (
        <DashboardLayout>
            <CustomCompaniesContainer>
                <CustomCardBox>
                    <CustomCardPaper>
                        <Badge
                            sx={{
                                background: colors.info.infoLightBg,
                                color: colors.info.infoMain,
                                borderRadius: '8px',
                                height: ptr(40),
                                width: ptr(40),
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <MapsHomeWork fontSize="medium" />
                        </Badge>
                        <CustomBoxColumn>
                            <Typography color={colors.text.secondaryTextLight} variant="body-small-default">
                                Toplam Firma Sayısı
                            </Typography>
                            <Typography variant="h6-medium">{countData?.data.result.totalCount}</Typography>
                        </CustomBoxColumn>
                    </CustomCardPaper>
                    <CustomCardPaper>
                        <Badge
                            sx={{
                                background: colors.success.SuccessLightBg,
                                color: colors.success.successMain,
                                borderRadius: '8px',
                                height: ptr(40),
                                width: ptr(40),
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <MapsHomeWork fontSize="medium" />
                        </Badge>
                        <CustomBoxColumn>
                            <Typography color={colors.text.secondaryTextLight} variant="body-small-default">
                                Toplam Aktif Firma
                            </Typography>
                            <Typography variant="h6-medium">{countData?.data.result.totalActiveCount}</Typography>
                        </CustomBoxColumn>
                    </CustomCardPaper>
                    <CustomCardPaper>
                        <Badge
                            sx={{ background: colors.white.greyLight, color: colors.white.grey, borderRadius: '8px', height: ptr(40), width: ptr(40), alignItems: 'center', justifyContent: 'center' }}
                        >
                            <MapsHomeWork fontSize="medium" />
                        </Badge>
                        <CustomBoxColumn>
                            <Typography color={colors.text.secondaryTextLight} variant="body-small-default">
                                Toplam Pasif Firma
                            </Typography>
                            <Typography variant="h6-medium">{countData?.data.result.totalPassiveCount}</Typography>
                        </CustomBoxColumn>
                    </CustomCardPaper>
                    <CustomCardPaper>
                        <Badge
                            sx={{
                                background: colors.other.badgeOrangeLightBg,
                                color: colors.warning.warningMain,
                                borderRadius: '8px',
                                height: ptr(40),
                                width: ptr(40),
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <PendingActions fontSize="medium" />
                        </Badge>
                        <CustomBoxColumn>
                            <Typography color={colors.text.secondaryTextLight} variant="body-small-default">
                                Toplam Ön Kayıt Firma
                            </Typography>
                            <Typography variant="h6-medium">{countData?.data.result.totalUnConfirmedCount}</Typography>
                        </CustomBoxColumn>
                    </CustomCardPaper>
                </CustomCardBox>
                <CustomTableDiv>
                    <CustomFilterBox>
                        <Typography variant="h6-medium">Filtreleyerek Ara</Typography>
                        <CustomCardDiv>
                            <Box width={'30%'}>
                                <AutocompleteContainer
                                    width={'100%'}
                                    label="İl Seç"
                                    onChange={(e) => {
                                        setSelectedCity(e as any)
                                        if (currentPage !== 0) setCurrentPage(0)
                                    }}
                                    options={cityOptions}
                                />
                            </Box>
                            <Box width={'30%'}>
                                <AutocompleteContainer
                                    label="Rol Seç"
                                    width="100%"
                                    onChange={(e) => {
                                        setRole(e as string)
                                        if (currentPage !== 0) setCurrentPage(0)
                                    }}
                                    options={userRoleOption}
                                    selectedValue={userRoleOption.find((userRole) => userRole.value === role)?.label || ''}
                                />
                            </Box>
                            <Box width={'30%'}>
                                <AutocompleteContainer
                                    width={'100%'}
                                    label="Firma Durumu Seç"
                                    onChange={(e) => {
                                        setStatusOptions(e as any)
                                        if (currentPage !== 0) setCurrentPage(0)
                                    }}
                                    options={statusOption}
                                />
                            </Box>
                            <Box width={'30%'}>
                                <DatePicker
                                    setEndDate={setEndDate}
                                    setStartDate={setStartDate}
                                    startDate={startDate}
                                    endDate={endDate}
                                    width={'100%'}
                                    placeholder={'Tarihe Göre Filtrele'}
                                    onSelectDate={({ startDate, endDate }) => {
                                        handleSelectDate(startDate, endDate)
                                        if (currentPage !== 0) setCurrentPage(0)
                                    }}
                                />
                            </Box>
                        </CustomCardDiv>
                    </CustomFilterBox>
                </CustomTableDiv>
                <CustomTableDiv>
                    <CustomHeadDiv style={{ height: ptr(81) }}>
                        <CustomExportBox>
                            <Button
                                startIcon={<IosShare />}
                                onClick={() => handleExport(exportToggle, setExportToggle)}
                                variant="outlined"
                                colorsx={colors.secondary.secondaryAlternativeLight}
                                borderColor={colors.secondary.secondaryAlternativeLight}
                                padding={ptr(16)}
                            >
                                <ExportDropdown<CompanyModel>
                                    array={exportArray}
                                    fileName={'export'}
                                    title={''}
                                    sheetName={''}
                                    headers={exportOptions}
                                    open={exportToggle}
                                    buttonHeader={'Export'}
                                    isSuccess={isCompaniesSuccessExport}
                                />
                            </Button>
                            <Typography variant="body-smaller-default">Maksimum 1000 veri</Typography>
                        </CustomExportBox>
                        <CustomFilterDiv style={{ gap: '20px' }}>
                            <SearchInput
                                value={filterText}
                                onChange={(val) => {
                                    setFilterText(val)
                                    if (currentPage !== 0) setCurrentPage(0)
                                }}
                                placeholder="Firma veya Yetkili Ara"
                            />
                            <Button size="large" onClick={toNewCompany}>
                                FİRMA EKLE
                            </Button>
                        </CustomFilterDiv>
                    </CustomHeadDiv>
                    <Spinner open={companiesLoading} />
                    <Table
                        count={companiesData?.data.result.totalCount}
                        isLoading={companiesLoading}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        onPageChange={onPageChange}
                        head={companiesHead}
                        result={rowPerPage}
                        rowsData={rowData}
                        isClickable={false}
                        page={currentPage}
                    ></Table>
                </CustomTableDiv>
            </CustomCompaniesContainer>
        </DashboardLayout>
    )
}

export default Companies
