import { Service } from 'services/types'
import { Request } from 'services/_base'
import { GetComplaintVariables } from './types'
import { GET_ALL_COMPLAINTS, GET_ALL_REASON } from './constants'

export const getAllComplaints: Service<GetComplaintVariables> = ({ queryKey }) => {
    const { params } = queryKey[1]
    return Request.get(GET_ALL_COMPLAINTS(), {
        maxResultCount: params.maxResultCount,
        skipCount: params.skipCount,
        filter: params.filter,
        reason: params.reason,
        startDate: params.startDate,
        endDate: params.endDate,
    })
}

export const getAllComplaintsReason: Service<any> = () => {
    return Request.get(GET_ALL_REASON, {})
}
