import { Box, styled } from '@mui/material'
import { colors } from '../../styles/color'
import { ptr } from '../../utils/helpers'
import InputMask from 'react-input-mask'
import { ErrorMessage } from 'formik'

export const Container = styled(Box)({
    backgroundColor: colors.white.default,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 2px 10px rgba(76, 78, 100, 0.22)',
})

export const FormContainer = styled(Box)({
    backgroundColor: colors.white.default,
    display: 'flex',
    flexDirection: 'column',
    gap: ptr(16),
    padding: ptr(24),
    margin: ptr(24),
    boxShadow: '0px 2px 10px rgba(76, 78, 100, 0.22)',
})

export const FormHeader = styled('span')({
    color: colors.text.primaryTextLight,
    fontSize: ptr(20),
    fontWeight: 500,
})

export const FormLine = styled(Box)({
    display: 'flex',
    gap: ptr(24),
})

export const PasswordRuleTitle = styled('ul')({
    color: colors.text.secondaryTextLight,
})

export const PasswordRule = styled('li')({
    color: colors.text.secondaryTextLight,
})

export const CustomInputMask = styled(InputMask)({
    borderRadius: '4px',
    height: '3.3rem',
    padding: '0 16.5px',
    border: `1px solid #0000003b`,
    width: '96%',
    ' &:hover': {
        outline: 'none',
        border: `1px solid ${colors.black.default}`,
    },
    ' &:focus': {
        outline: 'none',
        border: `2px solid ${colors.primary.primaryMain}`,
    },
    '&::placeholder': {
        color: colors.text.disabledTextLight,
    },
})

export const ErrorMessageContainer = styled(ErrorMessage)({
    color: colors.text.secondaryTextLight,
    fontSize: 'smaller',
})

export const FormItemContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
})
