import { Box, styled } from '@mui/material'
import { ptr } from '../../../../../utils/helpers'
import { colors } from '../../../../../styles/color'

export const ChatMessageContainer = styled(Box)({
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    gap: ptr(16),
    padding: `${ptr(0)} ${ptr(20)}`,
    marginBottom: ptr(2),
    height: 'fit-content',
    ':first-child': {
        paddingTop: ptr(10),
    },
})

export const MessageWrapper = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: ptr(3),
    width: '100%',
    '&.left': {
        display: 'flex',
        alignItems: 'flex-start',
    },
})

export const MessageContainer = styled('span')({
    background: colors.warning.warningMain,
    padding: `${ptr(12)} ${ptr(16)}`,
    color: colors.white.default,
    borderRadius: ptr(10),
    fontSize: ptr(14),
    maxWidth: ptr(490),
    boxShadow: '0 1px 3px #4C4E6438, 0 1px 1px #4C4E6438, 0 2px 1px -1px #4C4E6438',
    height: 'auto',
    wordBreak: 'break-all',
    '&.right': {
        borderTopRightRadius: 0,
    },
    '&.left': {
        borderTopLeftRadius: 0,
        backgroundColor: colors.white.default,
        color: colors.text.secondaryTextLight,
    },
})

export const MessageContainerInformation = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: ptr(3),
})

export const TimeContainer = styled('span')({
    fontSize: ptr(12),
    fontWeight: 400,
    color: colors.text.disabledTextLight,
})
