import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'

const firebaseConfig = {
    apiKey: 'AIzaSyAnZ6PeGbw9aGntrPENS3hXVLPQpgkKmOE',
    authDomain: 'cikmaburada.firebaseapp.com',
    databaseURL: 'https://cikmaburada-default-rtdb.firebaseio.com/',
    projectId: 'cikmaburada',
    storageBucket: 'cikmaburada.appspot.com',
    messagingSenderId: '222623208447',
    appId: '1:222623208447:web:5c5c4e6bf5150f2f024dca',
    measurementId: 'G-3QMNJW3TT3',
}

// Initialize Firebase
export const app = initializeApp(firebaseConfig)
export const analytics = getAnalytics(app)
