import { Box, styled, TextField } from '@mui/material'
import { colors } from 'styles/color'
import { ptr } from 'utils/helpers'

export const PopularityContainer = styled('div')({
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    gap: ptr(16),
    padding: `0 ${ptr(16)} 0 ${ptr(8)}`,
})

export const CustomTableDiv = styled('div')({
    boxShadow: ' 0px 2px 10px rgba(76, 78, 100, 0.22)',
    marginBottom: ptr(24),
})

export const CustomFilterBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-around',
    height: ptr(150),
    gap: ptr(16),
    padding: ptr(20),
    background: colors.white.default,
})

export const CustomCardDiv = styled('div')({
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-between',
})

export const CustomHeadDiv = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    textAlign: 'center',
    alignItems: 'center',
    background: colors.white.default,
    padding: '0px 20px',
})

export const CustomFilterDiv = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'right',
    gap: ptr(24),
    textAlign: 'center',
    height: ptr(80),
    alignItems: 'center',
})
export const NameCompanynameDiv = styled('div')({
    display: 'flex',
    flexDirection: 'column',
})

export const CustomExportBox = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    gap: ptr(16),
})

export const CustomSearchInput = styled(TextField)({
    height: '56px',
    '& > div': {
        height: '56px',
        fontWeight: '300',
        paddingRight: '12px',
        fontSize: '18px',
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: colors.primary.primaryMain,
        },
    },
})
