import { useEffect, useState } from 'react'

import * as Yup from 'yup'
import { Form, Formik, FormikHelpers } from 'formik'

import DashboardLayout from 'layouts/Dashboard/DashboardLayout'
import { CustomCardBox, CustomCardDiv, CustomFilterBox, CustomNotificationsContainer, CustomTableDiv, ModalInputs, SelectPageType, SelectPageTypes } from './index.styled'
import Typography from 'components/Typography/Typography'
import { colors } from 'styles/color'

import Cards from './cards'
import { useGetVehicleMakes, useGetVehicleTypes, useGetWorkCategory } from 'utils/hooks/queries/LookUp'
import { Box } from '@mui/material'
import DatePicker from 'components/DateRangePicker/DateRangePicker'
import Modal from 'components/Modal/Modal'
import AutocompleteContainer from 'components/Input/AutoComplete/Autocomplete'

import { LocalOffer, ShoppingBasket } from '@mui/icons-material'
import EditNotificationsIcon from '@mui/icons-material/EditNotifications'
import NotificationsPausedIcon from '@mui/icons-material/NotificationsPaused'
import PermIdentityIcon from '@mui/icons-material/PermIdentity'
import TextField from 'components/Input/TextField/TextField'
import { FormProps } from './types'
import { ErrorMessage } from 'pages/companies/AddCompanies/AddCompany.styled'
import CreateNotifications from './createNotifications'
import PastNotifications from './pastNotifications'
import { CreateNotificationModel } from 'services/be-api/notification/types'
import { useCreateNotification } from 'utils/hooks/queries/Notification'
import { ptr } from 'utils/helpers'

const userRoleOption = [
    {
        value: 'Alici',
        label: 'Alıcı',
    },
    {
        value: 'Satici',
        label: 'Satıcı',
    },
]

const userPositionOption = [
    {
        value: 'Yönetici',
        label: 'Yönetici',
    },
    {
        value: 'Çalışan',
        label: 'Çalışan',
    },
]

const Notifications = () => {
    const { mutate: reSend, isLoading: isNotificationCreateLoading } = useCreateNotification()

    const [open, setOpen] = useState(false)

    const { data: vehicleMakesData } = useGetVehicleMakes({ queryKeys: {}, enabled: true })
    const { data: vehicleTypesData } = useGetVehicleTypes({ queryKeys: {}, enabled: true })
    const { data: workCategoriesData } = useGetWorkCategory({ queryKeys: {}, enabled: true })
    const [vehicleMakes, setVehicleMakes] = useState<{ value: string; label: string }[]>([])
    const [vehicleTypes, setVehicleTypes] = useState<{ value: string; label: string }[]>([])
    const [workCategories, setWorkCategories] = useState<{ value: string; label: string }[]>([])

    const [selectedPageType, setSelectedPageType] = useState('new-notification')

    const [selectedWorkCategories, setSelectedWorkCategories] = useState('')
    const [selectedVehicleTypes, setSelectedVehicleTypes] = useState('')
    const [selectedVehicleMakes, setSelectedVehicleMakes] = useState('')
    const [role, setRole] = useState<string>('')
    const [position, setPosition] = useState<string>('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')

    // newnotification sayaç stateleri
    const [selectedIds, setSelectedIds] = useState<string[]>([])
    const [buyerCount, setBuyerCount] = useState<number>(0)
    const [sellerCount, setSellerCount] = useState<number>(0)
    // pastnotification sayaç stateleri
    const [pastBuyerCount, setPastBuyerCount] = useState<number>(0)
    const [pastSellerCount, setPastSellerCount] = useState<number>(0)
    const [pastTotalCount, setPastTotalCount] = useState<number>(0)

    const [currentPage, setCurrentPage] = useState(0)
    const [rowPerPage, setRowPerPage] = useState(10)

    useEffect(() => {
        setVehicleMakes(vehicleMakesData?.data?.result?.map((child) => ({ value: child.name, label: child.name })) ?? [])
    }, [vehicleMakesData])

    useEffect(() => {
        setWorkCategories(workCategoriesData?.data?.result?.map((child) => ({ value: child.name, label: child.name })) ?? [])
    }, [workCategoriesData])

    useEffect(() => {
        setVehicleTypes(vehicleTypesData?.data?.result?.map((child) => ({ value: child.name, label: child.name })) ?? [])
    }, [vehicleTypesData])

    const handleSelectDate = (sDate: string, eDate: string) => {
        setStartDate(sDate)
        setEndDate(eDate)
    }

    const handleToggle = () => {
        setOpen(!open)
    }
    const handleModalConfirm = () => {
        setOpen(!open)
    }

    const initialValues: FormProps = {
        notificationTitle: '',
        notificationExplaination: '',
    }

    const validationSchema = Yup.object({
        notificationTitle: Yup.string().required('Bu alan zorunludur.').max(20, 'Maksimum 20 karakter olabilir'),
        notificationExplaination: Yup.string().required('Bu alan zorunludur.').max(100, 'Maksimum 100 karakter olabilir'),
    })

    const onSubmit = (values: FormProps, { resetForm }: FormikHelpers<FormProps>) => {
        const inputDTO: CreateNotificationModel = {
            title: values.notificationTitle,
            body: values.notificationExplaination,
            userIds: selectedIds,
        }
        reSend(inputDTO)
        setSelectedIds([])
        setBuyerCount(0)
        setSellerCount(0)
        resetForm()
    }

    return (
        <DashboardLayout>
            <CustomNotificationsContainer>
                <SelectPageTypes>
                    <SelectPageType
                        onClick={() => {
                            setSelectedPageType('new-notification')
                            setCurrentPage(0)
                        }}
                        style={{ borderBottom: selectedPageType === 'new-notification' ? `2.5px solid ${colors.primary.primaryMain}` : '' }}
                    >
                        <Typography variant="body-small-medium" color={selectedPageType === 'new-notification' ? colors.primary.primaryMain : colors.text.secondaryTextLight}>
                            <EditNotificationsIcon />{' '}
                        </Typography>
                        <Typography variant="body-small-medium" color={selectedPageType === 'new-notification' ? colors.primary.primaryMain : colors.text.secondaryTextLight}>
                            YENİ BİLDİRİM OLUŞTUR{' '}
                        </Typography>
                    </SelectPageType>
                    <SelectPageType
                        onClick={() => setSelectedPageType('past-notification')}
                        style={{ borderBottom: selectedPageType !== 'new-notification' ? `2.5px solid ${colors.primary.primaryMain}` : '' }}
                    >
                        <Typography variant="body-small-medium" color={selectedPageType !== 'new-notification' ? colors.primary.primaryMain : colors.text.secondaryTextLight}>
                            <NotificationsPausedIcon />{' '}
                        </Typography>
                        <Typography variant="body-small-medium" color={selectedPageType !== 'new-notification' ? colors.primary.primaryMain : colors.text.secondaryTextLight}>
                            BİLDİRİM GEÇMİŞİ{' '}
                        </Typography>
                    </SelectPageType>
                </SelectPageTypes>
                {selectedPageType === 'new-notification' ? (
                    <CustomCardBox>
                        <Cards
                            icon={<PermIdentityIcon fontSize="medium" />}
                            color={colors.secondary.secondaryOutlinedRestingBg}
                            backgroundColor={colors.secondary.secondaryLightBg}
                            name="Toplam Seçili Kullanıcı"
                            countValue={selectedIds.length}
                        />
                        <Cards
                            icon={<ShoppingBasket fontSize="medium" />}
                            color={colors.info.infoMain}
                            backgroundColor={colors.info.infoLightBg}
                            name="Toplam Seçili Alıcı (Usta)"
                            countValue={buyerCount}
                        />
                        <Cards
                            icon={<LocalOffer fontSize="medium" />}
                            color={colors.success.successMain}
                            backgroundColor={colors.success.SuccessLightBg}
                            name="Toplam Seçili Satıcı (Çıkmacı)"
                            countValue={sellerCount}
                        />
                    </CustomCardBox>
                ) : (
                    <CustomCardBox>
                        <Cards
                            icon={<PermIdentityIcon fontSize="medium" />}
                            color={colors.secondary.secondaryOutlinedRestingBg}
                            backgroundColor={colors.secondary.secondaryLightBg}
                            name="Toplam Gönderilmiş Bildirim"
                            countValue={pastTotalCount}
                        />
                        <Cards
                            icon={<ShoppingBasket fontSize="medium" />}
                            color={colors.info.infoMain}
                            backgroundColor={colors.info.infoLightBg}
                            name="Toplam Bildirim Alan Alıcı (Usta)"
                            countValue={pastBuyerCount}
                        />
                        <Cards
                            icon={<LocalOffer fontSize="medium" />}
                            color={colors.success.successMain}
                            backgroundColor={colors.success.SuccessLightBg}
                            name="Toplam Bildirim Alan Satıcı (Çıkmacı)"
                            countValue={pastSellerCount}
                        />
                    </CustomCardBox>
                )}
                <CustomTableDiv>
                    <CustomFilterBox>
                        <Typography variant="h6-medium">Filtreleyerek Ara</Typography>
                        <CustomCardDiv>
                            <Box sx={{ width: '30%' }}>
                                <AutocompleteContainer
                                    width={'100%'}
                                    placeholder={'Çalıştığı Kategori'}
                                    options={workCategories}
                                    onChange={(e) => {
                                        setSelectedWorkCategories(e as string)
                                        if (currentPage !== 0) setCurrentPage(0)
                                    }}
                                />
                            </Box>
                            <Box sx={{ width: '30%' }}>
                                <AutocompleteContainer
                                    width={'100%'}
                                    placeholder={'Vasıta Tipi'}
                                    options={vehicleTypes}
                                    onChange={(e) => {
                                        setSelectedVehicleTypes(e as string)
                                        if (currentPage !== 0) setCurrentPage(0)
                                    }}
                                />
                            </Box>
                            <Box sx={{ width: '30%' }}>
                                <AutocompleteContainer
                                    width={'100%'}
                                    placeholder={'Marka'}
                                    options={vehicleMakes}
                                    onChange={(e) => {
                                        setSelectedVehicleMakes(e as string)
                                        if (currentPage !== 0) setCurrentPage(0)
                                    }}
                                />
                            </Box>
                        </CustomCardDiv>
                        <CustomCardDiv>
                            <Box width={'30%'}>
                                <AutocompleteContainer
                                    width="100%"
                                    placeholder="Rol Seç"
                                    onChange={(e) => {
                                        setRole(e as string)
                                        if (currentPage !== 0) setCurrentPage(0)
                                    }}
                                    options={userRoleOption}
                                    selectedValue={userRoleOption.find((userRole) => userRole.value === role)?.label || ''}
                                />
                            </Box>
                            <Box width={'30%'}>
                                <AutocompleteContainer
                                    width="100%"
                                    placeholder="Pozisyon Seç"
                                    options={userPositionOption}
                                    onChange={(e) => {
                                        setPosition(e as string)
                                        if (currentPage !== 0) setCurrentPage(0)
                                    }}
                                    selectedValue={userPositionOption.find((userPosition) => userPosition.value === position)?.label || ''}
                                />
                            </Box>
                            <Box width={'30%'}>
                                <DatePicker
                                    setEndDate={setEndDate}
                                    setStartDate={setStartDate}
                                    startDate={startDate}
                                    endDate={endDate}
                                    width={'100%'}
                                    placeholder={'Tarihe Göre Filtrele'}
                                    onSelectDate={({ startDate, endDate }) => {
                                        handleSelectDate(startDate, endDate)
                                        if (currentPage !== 0) setCurrentPage(0)
                                    }}
                                />
                            </Box>
                        </CustomCardDiv>
                    </CustomFilterBox>
                </CustomTableDiv>
                {selectedPageType === 'new-notification' ? (
                    <CreateNotifications
                        role={role}
                        position={position}
                        startDate={startDate}
                        endDate={endDate}
                        selectedWorkCategories={selectedWorkCategories}
                        selectedVehicleTypes={selectedVehicleTypes}
                        selectedVehicleMakes={selectedVehicleMakes}
                        setSelectedIds={setSelectedIds}
                        selectedIds={selectedIds}
                        setSellerCount={setSellerCount}
                        setBuyerCount={setBuyerCount}
                        handleToggle={handleToggle}
                        isNotificationCreateLoading={isNotificationCreateLoading}
                        currentPage={currentPage}
                        rowPerPage={rowPerPage}
                        setCurrentPage={setCurrentPage}
                        setRowPerPage={setRowPerPage}
                    />
                ) : (
                    <PastNotifications
                        role={role}
                        position={position}
                        startDate={startDate}
                        endDate={endDate}
                        selectedWorkCategories={selectedWorkCategories}
                        selectedVehicleTypes={selectedVehicleTypes}
                        selectedVehicleMakes={selectedVehicleMakes}
                        setPastBuyerCount={setPastBuyerCount}
                        setPastSellerCount={setPastSellerCount}
                        setPastTotalCount={setPastTotalCount}
                        currentPage={currentPage}
                        rowPerPage={rowPerPage}
                        setCurrentPage={setCurrentPage}
                        setRowPerPage={setRowPerPage}
                    />
                )}
            </CustomNotificationsContainer>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} validateOnBlur enableReinitialize>
                {({ isSubmitting, values, handleChange, handleSubmit, isValid, handleBlur }) => (
                    <Modal
                        alertIcon={false}
                        open={open}
                        onClose={() => handleToggle()}
                        onConfirm={() => {
                            handleModalConfirm()
                            handleSubmit()
                        }}
                        header={'Bildirim Gönder'}
                        disabled={isSubmitting || !isValid}
                        subheader={
                            <div style={{ marginBottom: ptr(8) }}>
                                <Typography>
                                    Toplam gönderilecek bildirim sayısı <Typography color={colors.primary.primaryMain}>{selectedIds.length}</Typography> adettir.{' '}
                                </Typography>
                            </div>
                        }
                    >
                        <ModalInputs>
                            <Form>
                                <ModalInputs>
                                    <div>
                                        <TextField
                                            name="notificationTitle"
                                            onBlur={handleBlur}
                                            onChange={(e, changeEvent) => handleChange(changeEvent)}
                                            placeholder="Bildirim Başlığı"
                                            value={values.notificationTitle}
                                            expand
                                        />
                                        <ErrorMessage name="notificationTitle" component={'div'} />
                                    </div>
                                    <div>
                                        <TextField
                                            name="notificationExplaination"
                                            onBlur={handleBlur}
                                            onChange={(e, changeEvent) => handleChange(changeEvent)}
                                            placeholder="Bildirim açıklaması giriniz"
                                            value={values.notificationExplaination}
                                            multiline
                                            rows={3}
                                            expand
                                        />
                                        <ErrorMessage name="notificationExplaination" component={'div'} />
                                    </div>
                                </ModalInputs>
                            </Form>
                        </ModalInputs>
                    </Modal>
                )}
            </Formik>
        </DashboardLayout>
    )
}

export default Notifications
