import { GridColDef } from '@mui/x-data-grid'
import { CategoryDemand } from 'services/be-api/dashboard/types'

export const topCategoryDemandExportOptions = [
    {
        key: 'VASITA TIPI',
        label: 'VASITA TIPI',
        onRender: (item: string, element: CategoryDemand) => {
            return `${element?.workSubCategory}`
        },
    },
    {
        key: 'ANA KATEGORI',
        label: 'ANA KATEGORI',
        onRender: (item: string, element: CategoryDemand) => {
            return `${element?.workCategory}`
        },
    },
    {
        key: 'TOPLAM TALEP',
        label: 'TOPLAM TALEP',
        onRender: (item: string, element: CategoryDemand) => {
            return `${element?.totalDemandCount}`
        },
    },
    {
        key: 'TOPLAM TEKLIF ',
        label: 'TOPLAM TEKLIF ',
        onRender: (item: string, element: CategoryDemand) => {
            return `${element?.totalOfferCount}`
        },
    },
    {
        key: 'TOPLAM SATIS %',
        label: 'TOPLAM SATIS %',
        onRender: (item: string, element: CategoryDemand) => {
            return `${element?.totalSalesPercent}`
        },
    },
    {
        key: 'TOPLAM SATIS MIKTARI',
        label: 'TOPLAM SATIS MIKTARI',
        onRender: (item: string, element: CategoryDemand) => {
            return `${element?.totalAmount}`
        },
    },
]

export const popularCategoryHead: GridColDef[] = [
    { field: 'workSubCategory', headerName: 'VASITA TIPI' },
    { field: 'workCategory', headerName: 'ANA KATEGORİ' },
    { field: 'totalDemandCount', headerName: 'TOPLAM TALEP' },
    { field: 'totalOfferCount', headerName: 'TOPLAM TEKLİF' },
    { field: 'totalSalesPercent', headerName: 'TOPLAM SATIŞ %' },
    { field: 'totalAmount', headerName: 'TOPLAM SATIŞ MİKTARI' },
]

export const breadcrumbLinks = [
    { text: 'Dashboard', url: '' },
    { text: 'Kullanıcı Aksiyonları', url: '' },
    { text: 'Popüler Kategoriler Detayı', url: '/popular-campaings-category-details', isLast: true },
]
