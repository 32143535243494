import { Request } from '../../_base'
import { CREATE_SPECIAL_OFFER, GET_SPECIAL_OFFER, GET_SPECIAL_OFFER_DETAIL, GET_SPECIAL_OFFER_ORDERS, PUT_UPDATE_SPECIAL_OFFER, PUT_UPDATE_SPECIAL_OFFER_STATUS } from './constants'
import { RequestParams, Service } from '../../types'
import { CreateSpecialOffer, GetSpecialOfferOrders, SpecialOfferDetailVariables, SpecialOfferFilter, UpdateSpecialOffer, UpdateSpecialOfferVariables } from './types'

export const getProductSpecialOffer: Service<RequestParams<SpecialOfferFilter>> = ({ queryKey }) => {
    const { params } = queryKey[1]
    return Request.get(GET_SPECIAL_OFFER, params)
}

export const createProductSpecialOffer: Service<CreateSpecialOffer> = (data) => {
    return Request.post(CREATE_SPECIAL_OFFER, data, {})
}

export const updateSpecialOfferStatus: Service<UpdateSpecialOfferVariables> = (data) => {
    return Request.put(PUT_UPDATE_SPECIAL_OFFER_STATUS(data.id, data.status), {}, {})
}

export const getSpecialOfferOrders: Service<RequestParams<GetSpecialOfferOrders>> = ({ queryKey }) => {
    const { params } = queryKey[1]
    return Request.get(GET_SPECIAL_OFFER_ORDERS(params.id), {})
}

export const getSpecialOfferDetail: Service<RequestParams<SpecialOfferDetailVariables>> = ({ queryKey }) => {
    const { params } = queryKey[1]
    return Request.get(GET_SPECIAL_OFFER_DETAIL(params.id), {})
}

export const updateSpecialOffer: Service<UpdateSpecialOffer> = ({ id, data }) => {
    return Request.put(PUT_UPDATE_SPECIAL_OFFER(id), data, {})
}
