import { useEffect, useState } from 'react'

import Stepper from 'components/Stepper/Stepper'
import { useNavigate, useParams } from 'react-router-dom'
import DashboardLayout from 'layouts/Dashboard/DashboardLayout'
import { CompanyModel, UpdateCompanyModel } from 'services/be-api/companies/types'
import { StepperContainer, StepperContent, StepperForm } from './index.styled'
import CompanyInfo from './companyInfo'
import Summary from './summaryPage'
import { useGetCompanyProfile } from 'utils/hooks/queries/Companies'
import Spinner from 'components/Spinner'

const UpdateCompany = () => {
    const [selectedStep, setSelectedStep] = useState(0)

    const [companyInfo, setCompanyInfo] = useState({} as UpdateCompanyModel)
    const companyId = useParams()
    const navigate = useNavigate()
    const { data, isSuccess, isLoading } = useGetCompanyProfile({
        queryKeys: {
            id: companyId.id!,
        },
    })

    const onChangeCompanyInfo = (val: CompanyModel) => {
        setCompanyInfo((prevState) => ({
            ...prevState,
            ...val,
        }))
    }

    const handleNext = () => {
        setSelectedStep(selectedStep + 1)
    }

    const handleBack = () => {
        selectedStep !== 0 ? setSelectedStep(selectedStep - 1) : navigate(`/companies/${companyId.id}`)
    }

    const steps = [
        { label: 'Firma Bilgileri', stepNumber: '01' },
        { label: 'Özet', stepNumber: '02' },
    ]

    useEffect(() => {
        if (isSuccess && !isLoading && data?.data.result) {
            setCompanyInfo(data?.data.result)
        }
    }, [data?.data.result, isLoading, isSuccess])

    return (
        <DashboardLayout>
            <Spinner open={isLoading} />
            <StepperContainer sx={{ width: '97%' }}>
                <Stepper steps={steps} actStep={selectedStep} orientation="vertical" />
                <StepperForm className="stepper-form">
                    <StepperContent className="stepper-content">
                        {selectedStep === 0 && <CompanyInfo handleBack={handleBack} handleNext={handleNext} value={companyInfo} onChange={onChangeCompanyInfo} />}
                        {selectedStep === 1 && <Summary handleBack={handleBack} companyInfo={companyInfo} companyId={companyId.id!} />}
                    </StepperContent>
                </StepperForm>
            </StepperContainer>
        </DashboardLayout>
    )
}

export default UpdateCompany
