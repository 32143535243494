import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Stepper from 'components/Stepper/Stepper'
import DashboardLayout from 'layouts/Dashboard/DashboardLayout'
import UserInfo from './userInfo'

import { useGetCompanies, useGetCompanyWithId } from 'utils/hooks/queries/Companies'
import { UserInfoFormValues } from './userInfo/type'
import { StepperContainer, StepperContent, StepperForm } from './index.styled'
import SubscriptionInformation from './SubscriptionInformation'
import { SubscriptionInformationFormValues } from './SubscriptionInformation/type'
import Summary from './Summary'
import { useGetVehicleMakes, useGetVehicleTypes, useGetWorkCategory } from 'utils/hooks/queries/LookUp'

const AddUsers = () => {
    const { data: vehicleMakesData } = useGetVehicleMakes({ queryKeys: {}, enabled: true })
    const { data: vehicleTypesData } = useGetVehicleTypes({ queryKeys: {}, enabled: true })
    const { data: workCategoriesData } = useGetWorkCategory({ queryKeys: {}, enabled: true })
    const {
        data: companiesData,
        isSuccess: companiesSuccess,
        isLoading: companiesLoading,
    } = useGetCompanies({
        queryKeys: {
            maxResultCount: 10000000,
        },
    })
    const [selectedId, setSelectedId] = useState<number>()

    const [selectedCompanyVehicleMakes, setSelectedCompanyVehicleMakes] = useState<string[]>([])
    const [selectedCompanyVehicleTypes, setSelectedCompanyVehicleTypes] = useState<string[]>([])
    const [selectedCompanyWorkCategories, setSelectedCompanyWorkCategories] = useState<string[]>([])
    const [vehicleMakes, setVehicleMakes] = useState<string[]>([])
    const [vehicleTypes, setVehicleTypes] = useState<string[]>([])
    const [workCategories, setWorkCategories] = useState<string[]>([])
    const [selectedStep, setSelectedStep] = useState(0)
    const [userInfo, setUserInfo] = useState({
        bankAccounts: [
            {
                bankName: '',
                ibanNumber: '',
                nameSurname: '',
            },
        ],
    } as UserInfoFormValues)
    const [subscriptionInfo, setSubscriptionInfo] = useState({
        workCategories: [],
        vehicleMakes: [],
        vehicleTypes: [],
    } as SubscriptionInformationFormValues)

    const {
        data: companiesDataById,
        isSuccess: companiesByIdSuccess,
        refetch: isCompaniesByIdSuccessRefetch,
    } = useGetCompanyWithId({
        queryKeys: {
            id: selectedId,
        },
        enabled: false,
    })

    useEffect(() => {
        if (companiesByIdSuccess && companiesDataById?.data.result.manager) {
            setSelectedCompanyWorkCategories(companiesDataById?.data.result.manager?.workCategories.map((x) => x.workCategoryName))
            setSelectedCompanyVehicleMakes(companiesDataById.data.result.manager?.vehicleMakes.map((w) => w.vehicleMakeName))
            setSelectedCompanyVehicleTypes(companiesDataById.data.result.manager?.vehicleTypes.map((w) => w.vehicleTypeName))
        }
    }, [companiesDataById?.data.result, companiesByIdSuccess])

    const navigate = useNavigate()

    useEffect(() => {
        setVehicleMakes(vehicleMakesData?.data?.result?.map((child) => child.name) ?? [])
    }, [vehicleMakesData])

    useEffect(() => {
        setWorkCategories(workCategoriesData?.data?.result?.map((child) => child.name) ?? [])
    }, [workCategoriesData])

    useEffect(() => {
        setVehicleTypes(vehicleTypesData?.data?.result?.map((child) => child.name) ?? [])
    }, [vehicleTypesData])

    const onChangeUserInfo = (val: UserInfoFormValues) => {
        setUserInfo((prevState) => ({
            ...prevState,
            ...val,
        }))
    }

    const onChangeSubscriptionInformation = (val: SubscriptionInformationFormValues) => {
        setSubscriptionInfo((prevState) => ({
            ...prevState,
            ...val,
        }))
    }
    useEffect(() => {
        setSubscriptionInfo({
            workCategories: selectedCompanyWorkCategories,
            vehicleTypes: selectedCompanyVehicleTypes,
            vehicleMakes: selectedCompanyVehicleMakes,
        })
    }, [companiesDataById?.data.result, companiesByIdSuccess, selectedId, selectedCompanyVehicleMakes, selectedCompanyVehicleTypes, selectedCompanyWorkCategories])

    const handleNext = () => {
        setSelectedStep(selectedStep + 1)
    }

    const handleBack = () => {
        selectedStep !== 0 ? setSelectedStep(selectedStep - 1) : navigate('/users')
    }

    const steps = [
        { label: 'Firma Çalışan Bilgileri', stepNumber: '01' },
        { label: 'Çalışma Bilgileri', stepNumber: '02' },
        { label: 'Özet', stepNumber: '03' },
    ]

    return (
        <DashboardLayout>
            <StepperContainer>
                <Stepper steps={steps} actStep={selectedStep} orientation="vertical" />
                <StepperForm className="stepper-form">
                    <StepperContent className="stepper-content">
                        {selectedStep === 0 && companiesData && (
                            <UserInfo
                                handleBack={handleBack}
                                handleNext={handleNext}
                                value={userInfo}
                                onChange={onChangeUserInfo}
                                companiesData={companiesData}
                                companiesSuccess={companiesSuccess}
                                companiesLoading={companiesLoading}
                                setSelectedId={setSelectedId}
                                isCompaniesByIdSuccessRefetch={isCompaniesByIdSuccessRefetch}
                            />
                        )}
                        {selectedStep === 1 && (
                            <SubscriptionInformation
                                workCategories={workCategories}
                                vehicleTypes={vehicleTypes}
                                vehicleMakes={vehicleMakes}
                                handleBack={handleBack}
                                handleNext={handleNext}
                                value={subscriptionInfo}
                                onChange={onChangeSubscriptionInformation}
                                companiesDataById={companiesDataById?.data.result}
                            />
                        )}
                        {selectedStep === 2 && <Summary handleBack={handleBack} userInfo={userInfo} subscriptionInfo={subscriptionInfo} companiesData={companiesData} selectedId={selectedId!} />}
                    </StepperContent>
                </StepperForm>
            </StepperContainer>
        </DashboardLayout>
    )
}

export default AddUsers
