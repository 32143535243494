import { Breadcrumbs, styled, Link } from '@mui/material'
import { colors } from 'styles/color'

export const CustomBreadcrumbs = styled(Breadcrumbs)((props) => ({
    '& .MuiTypography-root.MuiTypography-inherit.MuiLink-root.MuiLink-underlineHover.MuiTypography-root.MuiLink-root': {
        color: colors.text.secondaryTextLight,
    },
    '& .MuiTypography-root.MuiLink-root': {
        color: colors.text.primaryTextLight,
    },
}))

export const CustomLink = styled(Link)((props) => ({
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
}))
