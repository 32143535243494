import { GetAppRatesResponse, GetAppRatesVariables } from 'services/be-api/app-rate/types'
import { useBaseQuery } from '../_Base'
import { getAllRates } from 'services/be-api/app-rate/endpoint'
import { Query } from '../types'

export const useGetAppRates: Query<GetAppRatesVariables, GetAppRatesResponse> = (params) =>
    useBaseQuery({
        queryKeys: ['appRates', { params: params.queryKeys }],
        service: getAllRates,
        onSuccess: {
            messageDisplay: false,
            message: 'Successful.',
        },
        onLoading: {
            messageDisplay: false,
            message: 'Loading',
        },
        onError: {
            messageDisplay: false,
        },
        enabled: params.enabled,
    })
