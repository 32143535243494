import DashboardLayout from 'layouts/Dashboard/DashboardLayout'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetUserProfile } from 'utils/hooks/queries/Users'
import { StepperContainer, StepperContent, StepperForm } from './index.styled'
import Stepper from 'components/Stepper/Stepper'
import UserInfo from './userInfo'
import SubscriptionInformation from './subscription'
import Summary from './summary'
import { useGetVehicleMakes, useGetVehicleTypes, useGetWorkCategory } from 'utils/hooks/queries/LookUp'
import { UserProfileModel } from 'services/be-api/users/types'
import { useGetCompanies } from 'utils/hooks/queries/Companies'
import { SubscriptionInformationFormValues } from './subscription/type'
import Spinner from 'components/Spinner'
import { UserInfoFormValues } from './userInfo/type'

export const steps = [
    { label: 'Firma Çalışan Bilgileri', stepNumber: '01' },
    { label: 'Abonelik Bilgileri', stepNumber: '02' },
    { label: 'Özet', stepNumber: '03' },
]

const UpdateUser = () => {
    const [radioValue, setRadioValue] = useState<string>('')
    const [userDetail, setUserDetail] = useState<UserProfileModel>()
    const [selectedId, setSelectedId] = useState<number | undefined>(Number())
    const [selectedStep, setSelectedStep] = useState(0)
    const [workCategories, setWorkCategories] = useState<string[]>([])
    const [vehicleMakes, setVehicleMakes] = useState<string[]>([])
    const [vehicleTypes, setVehicleTypes] = useState<string[]>([])
    const navigate = useNavigate()
    const userId = useParams()
    const [userInfo, setUserInfo] = useState<UserInfoFormValues>({
        avatar: '',
        role: '',
        name: '',
        fullName: '',
        phoneNumber: '',
        reference: '',
        position: '',
        emailAddress: '',
        bankAccounts: [
            {
                bankName: '',
                ibanNumber: '',
                nameSurname: '',
            },
        ],
        companyId: 0,
    })
    const [subscriptionInfo, setSubscriptionInfo] = useState<SubscriptionInformationFormValues>({
        workCategories: [],
        vehicleTypes: [],
        vehicleMakes: [],
    })

    const { data: companiesData } = useGetCompanies({
        queryKeys: {
            maxResultCount: 10000000,
        },
    })
    const {
        data: profileData,
        isSuccess: profileSuccess,
        isLoading: profileLoading,
    } = useGetUserProfile({
        queryKeys: {
            id: userId.id,
        },
    })

    const { data: vehicleMakesData, isSuccess: vehicleMakesDataSuccess, isLoading: vehicleMakesDataLoading } = useGetVehicleMakes({ queryKeys: {}, enabled: true })
    const { data: vehicleTypesData, isSuccess: vehicleTypesDataSuccess, isLoading: vehicleTypesDataLoading } = useGetVehicleTypes({ queryKeys: {}, enabled: true })
    const { data: workCategoriesData, isSuccess: workCategoriesDataSuccess, isLoading: workCategoriesDataLoading } = useGetWorkCategory({ queryKeys: {}, enabled: true })

    useEffect(() => {
        if (profileSuccess && profileData?.data?.result) {
            setUserDetail(profileData?.data?.result)
            setWorkCategories(profileData?.data.result.workCategories.map((x) => x.workCategoryName))
            setVehicleMakes(profileData?.data?.result.vehicleMakes.map((w) => w.vehicleMakeName))
            setVehicleTypes(profileData?.data?.result.vehicleTypes.map((w) => w.vehicleTypeName))
        }
    }, [profileData?.data.result, profileSuccess])

    useEffect(() => {
        if (userDetail && profileSuccess) {
            setUserInfo({
                avatar: userDetail?.avatar!,
                role: userDetail?.role!,
                name: userDetail?.company.name!,
                fullName: userDetail?.fullName!,
                phoneNumber: userDetail?.phoneNumber!,
                reference: userDetail?.reference!,
                emailAddress: userDetail?.emailAddress!,
                bankAccounts: userDetail.bankAccounts ? (Array.isArray(JSON.parse(userDetail.bankAccounts)) ? JSON.parse(userDetail.bankAccounts) : Array(JSON.parse(userDetail.bankAccounts))) : [],
                companyId: userDetail.company.id,
                position: userDetail?.position,
            })
            setSelectedId(userDetail?.companyId)
        }
    }, [profileSuccess, profileData?.data.result, userDetail])

    useEffect(() => {
        setSubscriptionInfo({
            workCategories: workCategories,
            vehicleTypes: vehicleTypes,
            vehicleMakes: vehicleMakes,
        })
    }, [profileSuccess, profileData?.data.result, workCategories, vehicleTypes, vehicleMakes])

    const handleNext = () => {
        setSelectedStep(selectedStep + 1)
    }

    const handleBack = () => {
        selectedStep !== 0 ? setSelectedStep(selectedStep - 1) : navigate(`/users/${userId.id}`)
    }

    const onChangeUserInfo = (val: UserInfoFormValues) => {
        setUserInfo((prevState) => ({
            ...prevState,
            ...val,
        }))
    }

    const onChangeSubscriptionInformation = (val: any, type: 'workCategories' | 'vehicleTypes' | 'vehicleMakes' | undefined) => {
        setSubscriptionInfo({
            ...subscriptionInfo,
            [type!]: val,
        })
    }

    return (
        <DashboardLayout>
            <Spinner open={profileLoading} />

            <StepperContainer>
                <Stepper steps={steps} actStep={selectedStep} orientation="vertical" />
                <StepperForm className="stepper-form">
                    <StepperContent className="stepper-content">
                        {selectedStep === 0 && profileData && (
                            <UserInfo
                                handleBack={handleBack}
                                handleNext={handleNext}
                                value={userInfo}
                                companiesData={companiesData}
                                companiesSuccess={profileSuccess}
                                companiesLoading={profileLoading}
                                setSelectedId={setSelectedId}
                                onChange={onChangeUserInfo}
                                radioValue={radioValue}
                                setRadioValue={setRadioValue}
                            />
                        )}
                        {selectedStep === 1 && workCategoriesDataSuccess && vehicleTypesDataSuccess && vehicleMakesDataSuccess && (
                            <>
                                <Spinner open={vehicleMakesDataLoading && workCategoriesDataLoading && vehicleTypesDataLoading} />
                                <SubscriptionInformation
                                    handleNext={handleNext}
                                    handleBack={handleBack}
                                    onChange={onChangeSubscriptionInformation}
                                    value={subscriptionInfo}
                                    vehicleMakesData={vehicleMakesData?.data.result!}
                                    vehicleTypesData={vehicleTypesData?.data.result!}
                                    workCategoriesData={workCategoriesData?.data.result!}
                                />
                            </>
                        )}
                        {selectedStep === 2 && (
                            <Summary
                                handleBack={handleBack}
                                userInfo={userInfo}
                                subscriptionInfo={subscriptionInfo}
                                companiesData={userInfo.name}
                                selectedId={selectedId!}
                                userId={Number(userId.id)}
                                radioValue={radioValue}
                            />
                        )}
                    </StepperContent>
                </StepperForm>
            </StepperContainer>
        </DashboardLayout>
    )
}

export default UpdateUser
