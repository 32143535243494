import { RadioGroup, styled } from '@mui/material'
import { colors } from 'styles/color'

export const CardsContainer = styled('div')((props) => ({
    display: 'flex',
    width: '100%',
}))
export const CardContainer = styled('div')((props) => ({
    border: `1px solid ${colors.other.divider}`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    gap: '18px',
    flexBasis: '50%',
    padding: '18px',
    lineHeight: '21px',
}))
export const CardIcon = styled('div')((props) => ({
    color: colors.text.secondaryTextLight,
}))
export const CustomRadioGroup = styled(RadioGroup)((props) => ({}))
