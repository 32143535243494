export const POST_CURRENT_COMPANY = '/admin/companies/with-manager'
export const GET_ALL_COMPANIES = () => '/admin/companies'
export const GET_COMPANY_COUNTS = () => '/admin/companies/counts'
export const GET_COMPANY_PROFILE = (id: number) => `/admin/companies/${id}/with-users`
export const GET_COMPANY = (id: number) => `/company/${id}`
export const GET_COMPANY_PROFILE_COUNT = (id: number) => `/admin/companies/${id}/demand-offer-counts`
export const PUT_DEACTIVATE_COMPANY = (id: number) => `/admin/companies/${id}/de-activate`
export const PUT_ACTIVE_COMPANY = (id: number) => `/admin/companies/${id}/activate`
export const PUT_COMPANY_STATUS_VERIFY = (id: number) => `/admin/companies/${id}/confirm`
export const PUT_COMPANY_UPDATE = '/admin/companies'
