import { RouteType } from './types'
import MainPage from 'pages/mainPage'
import LoginPage from 'pages/login'
import NotFound from 'pages/notfound'
import AddCompanies from 'pages/companies/AddCompanies/AddCompany'
import Companies from 'pages/companies'
import AddUsers from 'pages/users/addUsers'
import CompanyProfile from 'pages/companies/companyProfile'
import UpdateCompany from 'pages/companies/updateCompany'
import Users from 'pages/users'
import UserProfile from 'pages/users/userProfile'
import UpdateUser from 'pages/users/updateUser'
import Admin from '../../pages/admin'
import UserRate from 'pages/userRate'
import AppRate from 'pages/appRate'
import Requests from '../../pages/requests'
import RequestDetail from '../../pages/requests/requestDetail'
import Notifications from 'pages/notifications'
import Products from '../../pages/products'
import CreateCampaign from '../../pages/products/createCampaign'
import Complaint from 'pages/complaint'
import Chat from 'pages/chat'
import PopularCategory from 'pages/popularity/popularCategory'
import TopSeller from 'pages/popularity/topSeller'
import TopBuyer from 'pages/popularity/topBuyer'
import TopCampaignDetail from 'pages/popularity/topCampaignDetail'
import PopularCategorySpecialOffer from 'pages/popularity/popularCategoryDetail'
import TopCampaignBuyerDetail from 'pages/popularity/topCampaignBuyer'

import Orders from '../../pages/orders'
import UpdateCampaign from '../../pages/products/updateCampaign'

const RouterList: RouteType[] = [
    { Page: MainPage, path: '/', pageType: 'protected' },
    { Page: PopularCategory, path: '/dashboard-popular-category-details', pageType: 'protected' },
    { Page: TopSeller, path: '/dashboard-top-seller-details', pageType: 'protected' },
    { Page: TopBuyer, path: '/dashboard-top-buyer-details', pageType: 'protected' },
    { Page: TopCampaignDetail, path: '/dashboard-top-campaign-details', pageType: 'protected' },
    { Page: PopularCategorySpecialOffer, path: '/dashboard-popular-campaings-category-details', pageType: 'protected' },
    { Page: TopCampaignBuyerDetail, path: '/dashboard-top-campaign-buyer-details', pageType: 'protected' },
    { Page: LoginPage, path: '/login', pageType: 'public' },
    { Page: NotFound, path: '*', pageType: 'public' },
    { Page: Companies, path: '/companies', pageType: 'protected' },
    { Page: AddCompanies, path: '/companies/new', pageType: 'protected' },
    { Page: AddUsers, path: '/users/new', pageType: 'protected' },
    { Page: CompanyProfile, path: '/companies/:id', pageType: 'protected' },
    { Page: UserProfile, path: '/users/:id', pageType: 'protected' },
    { Page: UpdateCompany, path: '/companies/:id/update', pageType: 'protected' },
    { Page: Users, path: '/users', pageType: 'protected' },
    { Page: UpdateUser, path: '/users/:id/update', pageType: 'protected' },
    { Page: Admin, path: '/profile', pageType: 'protected' },
    { Page: Requests, path: '/requests', pageType: 'protected' },
    { Page: RequestDetail, path: '/requests/:id', pageType: 'protected' },
    { Page: UserRate, path: '/user-ratings', pageType: 'protected' },
    { Page: AppRate, path: '/app-ratings', pageType: 'protected' },
    { Page: Notifications, path: '/notifications', pageType: 'protected' },
    { Page: Products, path: '/products', pageType: 'protected' },
    { Page: CreateCampaign, path: '/products/new', pageType: 'protected' },
    { Page: UpdateCampaign, path: '/products/:specialOfferId/update', pageType: 'protected' },
    { Page: Complaint, path: '/complaint', pageType: 'protected' },
    { Page: Chat, path: '/chats', pageType: 'protected' },
    { Page: Orders, path: '/orders', pageType: 'protected' },
]

export default RouterList
