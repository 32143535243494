import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'

import Typography from 'components/Typography/Typography'
import SummaryImg from '../../../../assets/Images/Summary.svg'
import { useCreateUser } from 'utils/hooks/queries/Users'
import Button from 'components/Buttons/Button/Button'
import { BankContent, Content, ContentBox, GeneralContainer, Key, KeyContent, LastCheck, LeftSide, RigtSide, RoleContent, SummaryContainer, Title } from './index.styled'
import { ButtonsSide } from '../index.styled'

import { parseNameSurname } from 'utils/helpers'
import { AddCompanyProps } from './type'
import { CreateUserModel } from 'services/be-api/users/types'
import { colors } from 'styles/color'
import Spinner from '../../../../components/Spinner'

const Summary = ({ handleBack, userInfo, subscriptionInfo, companiesData, selectedId }: AddCompanyProps) => {
    const { mutate: reSend, isSuccess, isLoading } = useCreateUser()
    const navigate = useNavigate()

    const companyInfo = companiesData?.data.result.companies.find((company: any) => selectedId === company.id)

    const onSubmit = () => {
        let user = parseNameSurname(userInfo.userNameSurname)
        const inputDTO: CreateUserModel = {
            companyId: selectedId,
            name: user.name,
            surname: user.surname,
            emailAddress: userInfo.eMail !== '' ? userInfo.eMail : null,
            isSeller: userInfo.role === 'satıcı',
            isBuyer: userInfo.role !== 'satıcı',
            reference: userInfo.reference,
            phoneNumber: userInfo.telNumber.replaceAll(' ', ''),
            workCategoryNames: subscriptionInfo.workCategories,
            vehicleTypeNames: subscriptionInfo.vehicleTypes,
            vehicleMakeNames: subscriptionInfo.vehicleMakes,
            position: 'Çalışan',
            bankAccounts: userInfo.bankAccounts.length ? JSON.stringify(userInfo.bankAccounts) : null,
        }
        reSend(inputDTO)
    }

    useEffect(() => {
        if (isSuccess) navigate('/users')
    }, [isSuccess, navigate])

    return (
        <GeneralContainer>
            <Spinner open={isLoading} />
            <SummaryContainer>
                <LeftSide>
                    <LastCheck>
                        <Typography variant="h5-medium">Neredeyse Tamam! 🚀</Typography>
                        <Typography>Oluşturmak istediğin kullanıcıya ait bilgileri kontrol ettikten sonra onaylayabilirsin.</Typography>
                    </LastCheck>

                    <Title>Kullanıcı Bilgileri</Title>
                    <KeyContent>
                        <ContentBox>
                            <Key>Firma İsmi</Key>
                        </ContentBox>
                        <ContentBox>
                            <Content>{companyInfo.name}</Content>
                        </ContentBox>
                    </KeyContent>
                    <KeyContent>
                        <ContentBox>
                            <Key>Kullanıcı Tipi</Key>
                        </ContentBox>
                        <ContentBox>
                            <RoleContent
                                style={{
                                    backgroundColor: userInfo.role === 'satıcı' ? colors.success.successMain : colors.info.infoMain,
                                    background:
                                        userInfo.role === 'satıcı'
                                            ? `linear-gradient(0deg, rgba(255, 255, 255, 0.88), rgba(255, 255, 255, 0.88)), #72E128`
                                            : 'linear-gradient(0deg, rgba(255, 255, 255, 0.88), rgba(255, 255, 255, 0.88)), #26C6F9',
                                }}
                            >
                                <Content>
                                    {userInfo.role === 'satıcı' ? (
                                        <LocalOfferIcon sx={{ color: colors.success.successMain, fontSize: '1rem' }} />
                                    ) : (
                                        <ShoppingBasketIcon sx={{ color: colors.info.infoMain, fontSize: '1rem' }} />
                                    )}
                                </Content>
                                <Content>{userInfo.role ? userInfo.role.charAt(0).toUpperCase() + userInfo.role.slice(1) : ''}</Content>
                            </RoleContent>
                        </ContentBox>
                    </KeyContent>
                    <KeyContent>
                        <ContentBox>
                            <Key>Kullanıcı İsmi</Key>
                        </ContentBox>
                        <ContentBox>
                            <Content>{userInfo.userNameSurname}</Content>
                        </ContentBox>
                    </KeyContent>
                    <KeyContent>
                        <ContentBox>
                            <Key>Telefon No</Key>
                        </ContentBox>
                        <ContentBox>
                            <Content>{userInfo.telNumber}</Content>
                        </ContentBox>
                    </KeyContent>
                    <KeyContent>
                        <ContentBox>
                            <Key>Email</Key>
                        </ContentBox>
                        <ContentBox>
                            <Content>{userInfo.eMail ? userInfo.eMail : '-'}</Content>
                        </ContentBox>
                    </KeyContent>
                    <KeyContent>
                        <ContentBox>
                            <Key>Referanslar</Key>
                        </ContentBox>
                        <ContentBox>
                            <Content>{userInfo.reference}</Content>
                        </ContentBox>
                    </KeyContent>

                    {userInfo.bankAccounts.map((account, index) => account.bankName !== '' && index === 0 && <Title>Banka Bilgileri</Title>)}
                    {userInfo.bankAccounts.map((account) => (
                        <KeyContent sx={{ alignItems: 'flex-start' }}>
                            <ContentBox>
                                <Key>{account.bankName}</Key>
                            </ContentBox>
                            <ContentBox>
                                <BankContent>
                                    <Content>{account.nameSurname}</Content>
                                    <Content>{account.ibanNumber}</Content>
                                </BankContent>
                            </ContentBox>
                        </KeyContent>
                    ))}

                    <Title>Abonelik Bilgisi</Title>
                    <KeyContent>
                        <ContentBox>
                            <Key>Çalıştığı Kategori</Key>{' '}
                        </ContentBox>
                        <ContentBox>
                            {subscriptionInfo.workCategories.map((option, index) => (
                                <Content sx={{ marginRight: '10px' }}>{option + (subscriptionInfo.workCategories.length - 1 !== index && ',')}</Content>
                            ))}{' '}
                        </ContentBox>
                    </KeyContent>
                    <KeyContent>
                        <ContentBox>
                            <Key>Vasıta Tipi</Key>{' '}
                        </ContentBox>
                        <ContentBox>
                            {subscriptionInfo.vehicleTypes.map((option, index) => (
                                <Content sx={{ marginRight: '10px' }}>{option + (subscriptionInfo.vehicleTypes.length - 1 !== index && ',')}</Content>
                            ))}
                        </ContentBox>
                    </KeyContent>
                    <KeyContent>
                        <ContentBox>
                            <Key>Çalıştığı Markalar</Key>{' '}
                        </ContentBox>
                        <ContentBox>
                            {subscriptionInfo.vehicleMakes.map((option, index) => (
                                <Content sx={{ marginRight: '10px' }}>{option + (subscriptionInfo.vehicleMakes.length - 1 !== index && ',')} </Content>
                            ))}
                        </ContentBox>
                    </KeyContent>
                </LeftSide>
                <RigtSide>
                    <img width={115} height={310} src={SummaryImg} alt="Elinde Tabet Tutan Adam" />
                </RigtSide>
            </SummaryContainer>
            <ButtonsSide>
                <Button onClick={onSubmit} disabled={isLoading} color={'success'}>
                    Oluştur
                </Button>
                <Button variant="outlined" color="secondary" onClick={handleBack}>
                    Geri
                </Button>
            </ButtonsSide>
        </GeneralContainer>
    )
}

export default Summary
