import { Box, Button, styled, TextField } from '@mui/material'
import { ptr } from '../../../utils/helpers'
import { colors } from '../../../styles/color'

export const RequestDetailContainer = styled(Box)({
    height: 'fit-content',
    marginTop: ptr(44),
    marginRight: ptr(24),
    display: 'flex',
    flexDirection: 'column',
    gap: ptr(24),
})

export const RequestDetailInformation = styled(Box)({
    width: '100%',
    height: 'fit-content',
    backgroundColor: colors.white.default,
    padding: `${ptr(24)} ${ptr(20)}`,
    borderRadius: ptr(4),
    display: 'flex',
    flexDirection: 'column',
    gap: ptr(24),
})

export const RemoveRequestButton = styled(Button)({
    width: 'fit-content',
    marginLeft: 'auto',
    color: colors.error.errorMain,
    borderColor: colors.error.errorMain,
    ':hover': {
        borderColor: colors.error.errorMain,
    },
})

export const RequestDetailTableContainer = styled(Box)({
    width: '100%',
    height: 'fit-content',
    backgroundColor: colors.white.default,
    padding: `${ptr(24)} ${ptr(20)}`,
    borderRadius: ptr(4),
    display: 'flex',
    flexDirection: 'column',
    gap: ptr(24),
})

export const RequestDetailTableHeader = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    background: colors.white.default,
})

export const RequestDetailTableFilter = styled(Box)({
    display: 'flex',
    gap: ptr(24),
    width: '50%',
    marginLeft: 'auto',
})

export const RequestDetailOfferSearch = styled(TextField)({
    height: '59px',
    width: '100%',
    '& > div': {
        height: '100%',
        width: '100%',
        fontWeight: '300',
        paddingRight: '12px',
        fontSize: '16px',
    },
})
