import { GridColDef } from '@mui/x-data-grid'
import Table from 'components/Table/Table'
import { TableBodyRowData } from 'components/Table/types'
import DashboardLayout from 'layouts/Dashboard/DashboardLayout'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
    AddUserButton,
    CustomBoxColumn,
    CustomCardBox,
    CustomCardDiv,
    CustomCardPaper,
    CustomCompaniesContainer,
    CustomFilterBox,
    CustomFilterDiv,
    CustomHeadDiv,
    CustomTableDiv,
} from './index.styled'
import DatePicker from 'components/DateRangePicker/DateRangePicker'
import { Badge, Box, Chip, IconButton } from '@mui/material'
import { endDateConverter, ptr, startDateConverter } from 'utils/helpers'
import Button from 'components/Buttons/Button/Button'
import { colors } from 'styles/color'
import ExportDropdown from 'components/ExportDropdown'
import { IosShare, LocalOffer, PendingActions, PermIdentity, RemoveCircle, ShoppingBasket, Verified } from '@mui/icons-material'
import Typography from 'components/Typography/Typography'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { useChangeUserRole, useGetAllUsers } from '../../utils/hooks/queries/Users'
import { EUserRoles } from '../../utils/constants'
import Tooltip from '../../components/Tooltip/Tooltip'
import { UserStatistics } from '../../services/be-api/users/types'
import AutocompleteContainer from '../../components/Input/AutoComplete/Autocomplete'
import Modal from '../../components/Modal/Modal'
import Spinner from '../../components/Spinner'
import { CustomExportBox } from 'pages/companies/index.styled'
import SearchInput from '../../components/Input/SearchInput/SearchInput'
import { BaseUser } from 'models'

export const usersHead: GridColDef[] = [
    { field: 'user', headerName: 'KULLANICI', width: 70 },
    { field: 'telephone', headerName: 'TELEFON', width: 130 },
    { field: 'role', headerName: 'ROL', width: 130 },
    { field: 'position', headerName: 'POZISYON', width: 130 },
    { field: 'status', headerName: 'DURUM', width: 130 },
]

export const userRoleOption = [
    {
        value: 'Alici',
        label: 'Alıcı',
    },
    {
        value: 'Satici',
        label: 'Satıcı',
    },
]

export const userPositionOption = [
    {
        value: 'Yönetici',
        label: 'Yönetici',
    },
    {
        value: 'Çalışan',
        label: 'Çalışan',
    },
]

export const userConfirmationStatusOption = [
    { value: 'Aktif', label: 'Aktif' },
    { value: 'Pasif', label: 'Pasif' },
    { value: 'Beklemede', label: 'Beklemede' },
]

export const userGsmStatusOption = [
    { value: 'true', label: 'Onaylı' },
    { value: 'false', label: 'Onaysız' },
]

export const exportOptions = [
    {
        key: 'KULLANICI',
        label: 'KULLANICI',
        onRender: (item: string, element: BaseUser) => {
            return `${element?.name} ${element?.surname}`
        },
    },
    {
        key: 'TELEFON',
        label: 'TELEFON',
        onRender: (item: string, element: BaseUser) => {
            return `${element?.phoneNumber}`
        },
    },
    {
        key: 'ROL',
        label: 'ROL',
        onRender: (item: string, element: BaseUser) => {
            return `${element?.role}`
        },
    },
    {
        key: 'POZISYON',
        label: 'POZISYON',
        onRender: (item: string, element: BaseUser) => {
            return `${element?.position}`
        },
    },
    {
        key: 'DURUM',
        label: 'DURUM',
        onRender: (item: string, element: BaseUser) => {
            return `${element?.status}`
        },
    },
]
const Users = () => {
    const [users, setUsers] = useState<BaseUser[]>([])
    const [usersExport, setUsersExport] = useState<BaseUser[]>([])

    const [userStatistic, setUserStatistic] = useState<UserStatistics>()
    const [usersAmount, setUsersAmount] = useState<number>()
    const navigate = useNavigate()

    const [exportToggle, setExportToggle] = useState(false)
    const [filterText, setFilterText] = useState('')
    const [currentPage, setCurrentPage] = useState(0)
    const [rowPerPage, setRowPerPage] = useState(10)
    // Filter State
    const [role, setRole] = useState<string>('')
    const [position, setPosition] = useState<string>('')
    const [userConfirmationStatus, setUserConfirmationStatus] = useState('')
    const [isPhoneConfirmed, setPhoneConfirmedStatus] = useState<'true' | 'false' | ''>('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [changeRoleModalVisible, setChangeRoleModalVisible] = useState<boolean>(false)
    const [changeRoleData, setChangeRoleData] = useState<Pick<BaseUser, 'id' | 'isBuyer'>>()
    dayjs.extend(customParseFormat)

    dayjs.extend(customParseFormat)
    const {
        data: usersData,
        isSuccess: isGetAllUsersSuccess,
        isLoading: isGetAllUsersLoading,
        refetch: refetchUsers,
    } = useGetAllUsers({
        queryKeys: {
            role: role,
            position,
            status: userConfirmationStatus,
            isPhoneConfirmed: isPhoneConfirmed || undefined,
            filter: filterText || null,
            startDate: startDate.length ? startDateConverter(startDate) : null,
            endDate: endDate.length ? endDateConverter(endDate) : null,
            maxResultCount: rowPerPage,
            skipCount: currentPage * rowPerPage || null,
        },
    })

    const {
        data: userDataExport,
        isSuccess: isUsersSuccessExport,
        refetch: usersExportRefetch,
    } = useGetAllUsers({
        queryKeys: {
            role: role,
            position,
            status: userConfirmationStatus,
            isPhoneConfirmed: isPhoneConfirmed || undefined,
            filter: filterText || null,
            startDate: startDate.length ? startDateConverter(startDate) : null,
            endDate: endDate.length ? endDateConverter(endDate) : null,
            maxResultCount: 1000,
            skipCount: null,
        },
        enabled: false,
    })
    const { mutate: changeUserRoleMutate, isSuccess: isSuccessChangeUserRole } = useChangeUserRole()

    const changeUserRole = async (userId: number, isBuyer: boolean) => {
        changeUserRoleMutate({ userId, isBuyer })
    }

    useEffect(() => {
        isSuccessChangeUserRole && refetchUsers()
    }, [isSuccessChangeUserRole, refetchUsers])

    useEffect(() => {
        if (isGetAllUsersSuccess && usersData?.data) {
            setUsers(usersData?.data.result.users)
            setUsersAmount(usersData?.data.result.totalFilteredCount)
            const statistics = usersData?.data.result
            if (statistics) {
                setUserStatistic({
                    buyerCount: statistics.buyerCount,
                    sellerCount: statistics.sellerCount,
                    totalFilteredCount: statistics.totalFilteredCount,
                    unconfirmedCount: statistics.unconfirmedCount,
                    totalCount: statistics?.totalCount,
                })
            }
        }
    }, [isGetAllUsersSuccess, usersData])

    useEffect(() => {
        if (userDataExport && isUsersSuccessExport) {
            setUsersExport(userDataExport?.data.result.users)
        }
    }, [isUsersSuccessExport, userDataExport])

    const toNewUser = () => {
        navigate('/users/new')
    }
    const handleTo = (id: number, type: string) => {
        navigate(`/${type}/${id}`)
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowPerPage(parseInt(event.target.value, 10))
        setCurrentPage(0)
    }

    const onPageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setCurrentPage(newPage)
    }

    const handleSelectDate = (sDate: string, eDate: string) => {
        setStartDate(sDate)
        setEndDate(eDate)
    }

    const handleExport = (exportToggle: boolean, set: React.Dispatch<React.SetStateAction<boolean>>) => {
        set(!exportToggle)
        usersExportRefetch()
    }

    const rowData: TableBodyRowData[] =
        users &&
        users.map((user) => {
            return {
                user: (
                    <Box>
                        <Box>
                            <Typography variant="body-normal-semibold" onClick={() => handleTo(user.id as number, 'users')}>
                                {user.name} {user.surname}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography color={colors.text.secondaryTextLight} variant="body-normal-default" onClick={() => handleTo(user.id as number, 'users')}>
                                {user?.company?.name}
                            </Typography>
                        </Box>
                    </Box>
                ),
                telephone: (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: ptr(12.75) }}>
                        <Typography variant="body-normal-semibold">{user.phoneNumber}</Typography>
                        {user.isPhoneNumberConfirmed && <Verified style={{ color: colors.success.successAlternativeDark }} />}
                    </Box>
                ),
                role: (
                    <>
                        {user.role === EUserRoles.BOTH ? (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                                    <LocalOffer style={{ color: colors.success.successMain }} />
                                    <Typography>{EUserRoles.SELLER}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <ShoppingBasket style={{ color: colors.info.infoMain }} />
                                    <Typography>{EUserRoles.BUYER}</Typography>
                                </Box>
                                <IconButton
                                    onClick={() => {
                                        if (user.id) {
                                            setChangeRoleModalVisible(true)
                                            setChangeRoleData({
                                                id: user.id,
                                                isBuyer: false,
                                            })
                                        }
                                    }}
                                >
                                    <RemoveCircle style={{ color: colors.error.errorAlternativeLight }} />
                                </IconButton>
                            </Box>
                        ) : user.role === EUserRoles.BUYER ? (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                                <ShoppingBasket style={{ color: colors.info.infoMain }} />
                                <Typography>{EUserRoles.BUYER}</Typography>
                            </Box>
                        ) : (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                                <LocalOffer style={{ color: colors.success.successMain }} />
                                <Typography>{EUserRoles.SELLER}</Typography>
                            </Box>
                        )}
                    </>
                ),
                position: (
                    <Box>
                        <Typography variant="body-normal-semibold">{user.position}</Typography>
                    </Box>
                ),
                status: (
                    <Box>
                        <Typography variant="body-normal-default">
                            <Chip
                                sx={{
                                    background:
                                        user.status === 'Aktif'
                                            ? colors.success.successOutlinedHoverBg
                                            : user.status === 'Pasif'
                                            ? colors.secondary.secondaryOutlinedHoverBg
                                            : colors.warning.warningOutlinedHoverBg,
                                    color: user.status === 'Aktif' ? colors.success.successMain : user.status === 'Pasif' ? colors.secondary.secondaryMain : colors.warning.warningMain,
                                }}
                                label={user.status}
                            />
                        </Typography>
                    </Box>
                ),
            }
        })

    return (
        <DashboardLayout>
            <CustomCompaniesContainer>
                <CustomCardBox>
                    <CustomCardPaper>
                        <Badge
                            sx={{
                                background: colors.white.greyLight,
                                color: colors.white.grey,
                                borderRadius: '8px',
                                height: ptr(40),
                                width: ptr(40),
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <PermIdentity fontSize="medium" />
                        </Badge>
                        <CustomBoxColumn>
                            <Typography color={colors.text.secondaryTextLight} variant="body-small-default">
                                Toplam Kullanıcı
                            </Typography>
                            <Typography variant="h6-medium">{userStatistic?.totalCount}</Typography>
                        </CustomBoxColumn>
                    </CustomCardPaper>
                    <CustomCardPaper>
                        <Badge
                            sx={{
                                background: colors.other.badgeOrangeLightBg,
                                color: colors.warning.warningMain,
                                borderRadius: '8px',
                                height: ptr(40),
                                width: ptr(40),
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <PendingActions fontSize="medium" />
                        </Badge>
                        <CustomBoxColumn>
                            <Typography color={colors.text.secondaryTextLight} variant="body-small-default">
                                Toplam Ön Kayıt Kullanıcı
                            </Typography>
                            <Typography variant="h6-medium">{userStatistic?.unconfirmedCount}</Typography>
                        </CustomBoxColumn>
                    </CustomCardPaper>
                    <CustomCardPaper>
                        <Badge
                            sx={{
                                background: colors.info.infoOutlinedRestingBg,
                                color: colors.info.infoMain,
                                borderRadius: '8px',
                                height: ptr(40),
                                width: ptr(40),
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <ShoppingBasket fontSize="medium" />
                        </Badge>
                        <CustomBoxColumn>
                            <Typography color={colors.text.secondaryTextLight} variant="body-small-default">
                                Toplam Alıcı (Usta)
                            </Typography>
                            <Typography variant="h6-medium">{userStatistic?.buyerCount}</Typography>
                        </CustomBoxColumn>
                    </CustomCardPaper>
                    <CustomCardPaper>
                        <Badge
                            sx={{
                                background: colors.success.successOutlinedRestingBg,
                                color: colors.success.successMain,
                                borderRadius: '8px',
                                height: ptr(40),
                                width: ptr(40),
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <LocalOffer fontSize="medium" />
                        </Badge>
                        <CustomBoxColumn>
                            <Typography color={colors.text.secondaryTextLight} variant="body-small-default">
                                Toplam Satıcı (Çıkmacı)
                            </Typography>
                            <Typography variant="h6-medium">{userStatistic?.sellerCount}</Typography>
                        </CustomBoxColumn>
                    </CustomCardPaper>
                </CustomCardBox>
                <CustomTableDiv>
                    <CustomFilterBox>
                        <Typography variant="h6-medium">Filtreleyerek Ara</Typography>
                        <CustomCardDiv>
                            <Box width={'30%'}>
                                <AutocompleteContainer
                                    label="Rol Seç"
                                    width="100%"
                                    onChange={(e) => {
                                        setRole(e as string)
                                        if (currentPage !== 0) setCurrentPage(0)
                                    }}
                                    options={userRoleOption}
                                    selectedValue={userRoleOption.find((userRole) => userRole.value === role)?.label || ''}
                                />
                            </Box>
                            <Box width={'30%'}>
                                <AutocompleteContainer
                                    width="100%"
                                    label="Pozisyon Seç"
                                    options={userPositionOption}
                                    onChange={(e) => {
                                        setPosition(e as string)
                                        if (currentPage !== 0) setCurrentPage(0)
                                    }}
                                    selectedValue={userPositionOption.find((userPosition) => userPosition.value === position)?.label || ''}
                                />
                            </Box>
                            <Box width={'30%'}>
                                <AutocompleteContainer
                                    width="100%"
                                    label="Kullanıcı Durumunu Seç"
                                    onChange={(e) => {
                                        setUserConfirmationStatus(e as any)
                                        if (currentPage !== 0) setCurrentPage(0)
                                    }}
                                    options={userConfirmationStatusOption}
                                    selectedValue={userConfirmationStatusOption.find((userConfirmation) => userConfirmation.value === userConfirmationStatus)?.label || ''}
                                />
                            </Box>
                            <Box width={'30%'}>
                                <AutocompleteContainer
                                    width="100%"
                                    label="GSM Onay Durumu Seç"
                                    onChange={(e) => {
                                        setPhoneConfirmedStatus(e as any)
                                        if (currentPage !== 0) setCurrentPage(0)
                                    }}
                                    options={userGsmStatusOption}
                                    selectedValue={userGsmStatusOption.find((gsmStatus) => gsmStatus.value === isPhoneConfirmed)?.label || ''}
                                />
                            </Box>
                            <Box width={'30%'}>
                                <DatePicker
                                    setEndDate={setEndDate}
                                    setStartDate={setStartDate}
                                    startDate={startDate}
                                    endDate={endDate}
                                    width={'100%'}
                                    placeholder={'Tarihe Göre Filtrele'}
                                    onSelectDate={({ startDate, endDate }) => {
                                        handleSelectDate(startDate, endDate)
                                        if (currentPage !== 0) setCurrentPage(0)
                                    }}
                                />
                            </Box>
                        </CustomCardDiv>
                    </CustomFilterBox>
                </CustomTableDiv>
                <CustomTableDiv>
                    <CustomHeadDiv style={{ height: ptr(81) }}>
                        <CustomExportBox>
                            <Button startIcon={<IosShare />} onClick={() => handleExport(exportToggle, setExportToggle)} variant="outlined" color={'secondary'} padding={ptr(16)}>
                                <ExportDropdown<BaseUser>
                                    array={usersExport}
                                    fileName={'export'}
                                    title={''}
                                    sheetName={''}
                                    headers={exportOptions}
                                    open={exportToggle}
                                    buttonHeader={'Export'}
                                    isSuccess={isUsersSuccessExport}
                                />
                            </Button>
                            <Typography variant="body-smaller-default">Maksimum 1000 veri</Typography>
                        </CustomExportBox>

                        <CustomFilterDiv style={{ gap: '20px' }}>
                            <SearchInput
                                value={filterText}
                                onChange={(val) => {
                                    setFilterText(val)
                                    if (currentPage !== 0) setCurrentPage(0)
                                }}
                                placeholder="Firma veya Kullanıcı Ara"
                            />
                            <Box sx={{ width: 'fit-content' }}>
                                <Tooltip name="Kullanıcı eklemek için lütfen tıklayınız">
                                    <AddUserButton onClick={toNewUser}>KULLANICI EKLE</AddUserButton>
                                </Tooltip>
                            </Box>
                        </CustomFilterDiv>
                    </CustomHeadDiv>
                    <Table
                        count={usersAmount}
                        isLoading={isGetAllUsersLoading}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        onPageChange={onPageChange}
                        head={usersHead}
                        result={rowPerPage}
                        rowsData={rowData}
                        isClickable={false}
                        page={currentPage}
                    ></Table>
                </CustomTableDiv>
            </CustomCompaniesContainer>
            <Modal
                open={changeRoleModalVisible}
                onClose={() => setChangeRoleModalVisible(false)}
                onConfirm={() => {
                    changeRoleData && changeUserRole(changeRoleData.id!, changeRoleData.isBuyer)
                    setChangeRoleModalVisible(false)
                }}
                header="Rol Değiştirme"
                subheader={`Kullanıcıyı ${changeRoleData?.isBuyer ? 'Alıcı olarak eklemek' : 'Alıcı olarak çıkarmak'} istiyor musunuz?`}
            />
            <Spinner open={isGetAllUsersLoading} />
        </DashboardLayout>
    )
}

export default Users
