import { Chart } from 'components/Chart/Chart'
import { EChartsOption } from 'echarts'
import { DemandStatusProps } from 'pages/mainPage/cardWithIcon/userAct/types'
import React from 'react'

const DemandChart: React.FunctionComponent<DemandStatusProps> = ({ demandStatus }) => {
    const Option: EChartsOption = {
        tooltip: {
            trigger: 'item',
        },
        legend: {
            top: ' 85%',
            left: 'center',

            textStyle: {
                color: 'black',
                fontSize: '12px',
                width: '100%',
                padding: 10,
            },
        },
        series: [
            {
                name: 'Aktif Talep Durum Dağılımları',
                type: 'pie',
                radius: ['40%', '60%'],
                width: '100%',
                top: '-70',
                left: 'center',
                height: '100%',
                data: [
                    { value: Number(demandStatus?.offerCollectionCompletedPercent.replace(',', '.')), name: 'Teklif Toplama Tamamlanan' },
                    { value: Number(demandStatus?.offersBeingCollectedPercent.replace(',', '.')), name: 'Teklif Toplanma Başlayan' },
                    { value: Number(demandStatus?.oneOfferInNegotiationPercent.replace(',', '.')), name: 'Teklif Görüşmesi Başlayan' },
                    { value: Number(demandStatus?.openToOffersPercent.replace(',', '.')), name: 'Tekliflere Açık Olan' },
                    { value: Number(demandStatus?.purchasedPercent.replace(',', '.')), name: 'Satın Alınan' },
                    { value: Number(demandStatus?.noOfferPercent.replace(',', '.')), name: 'Teklif Almamış olan' },
                    { value: Number(demandStatus?.unacceptedOffersPercent.replace(',', '.')), name: 'Teklif Kabul Edilmeyen' },
                    { value: Number(demandStatus?.unpublishedByAdminPercent.replace(',', '.')), name: 'Yayınlanmayan (Yönetici)' },
                    { value: Number(demandStatus?.unpublishedByBuyerPercent.replace(',', '.')), name: 'Yayınlanmayan (Alıcı)' },
                ],
                label: {
                    show: true,
                    formatter: function (params) {
                        if (params.value !== 0) {
                            return params.name
                        } else {
                            return ''
                        }
                    },
                },
                labelLine: {
                    show: false,
                },
            },
        ],
    }
    return <Chart option={Option} />
}

export default DemandChart
