import DashboardLayout from 'layouts/Dashboard/DashboardLayout'
import { Box, Divider } from '@mui/material'
import AutocompleteContainer from 'components/Input/AutoComplete/Autocomplete'
import DatePicker from 'components/DateRangePicker/DateRangePicker'
import Button from 'components/Buttons/Button/Button'
import { EditOutlined, IosShare } from '@mui/icons-material'
import ExportDropdown from 'components/ExportDropdown'
import Spinner from 'components/Spinner'
import Table from 'components/Table'
import { endDateConverter, ptr, startDateConverter } from 'utils/helpers'
import { colors } from 'styles/color'
import React, { useEffect, useState } from 'react'
import { GridColDef } from '@mui/x-data-grid'
import { useNavigate } from 'react-router-dom'
import { CustomBadge, CustomChatContainer, CustomContentBox, CustomExportBox, CustomExportBoxTypo, CustomFilteredBox, CustomHeadDiv, CustomPhotoBox, CustomTableDiv } from './index.styled'
import { useGetChats } from 'utils/hooks/queries/Chats'
import { Conversation } from 'services/be-api/chats/types'
import { TableBodyRowData } from 'components/Table/types'
import Typography from 'components/Typography/Typography'
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera'
import SearchInput from '../../components/Input/SearchInput/SearchInput'
import List from '../../components/List/List'

export const userRatesHead: GridColDef[] = [
    { field: 'demand', headerName: 'TALEP', width: 40 },
    { field: 'sender', headerName: 'SON MESAJI ATAN', width: 90 },
    { field: 'receiver', headerName: 'SON MESAJI ALAN', width: 90 },
    { field: 'content', headerName: 'İÇERİK', width: 90 },
    { field: 'offer', headerName: 'TEKLİF', width: 90 },
    { field: 'status', headerName: 'SOHBET DURUMU', width: 90 },
    { field: 'actions', headerName: 'AKSIYON', width: 90 },
]

export const exportOptions = [
    {
        key: 'TALEP',
        label: 'TALEP',
        onRender: (item: string, element: Conversation) => {
            return `${element?.demandId}`
        },
    },
    {
        key: 'SON MESAJI ATAN',
        label: 'SON MESAJI ATAN',
        onRender: (item: string, element: Conversation) => {
            return `${element?.sender.fullName}`
        },
    },
    {
        key: 'SON MESAJI ALAN',
        label: 'SON MESAJI ALAN',
        onRender: (item: string, element: Conversation) => {
            return `${element?.receiver.fullName}`
        },
    },
    {
        key: 'ICERIK',
        label: 'ICERIK',
        onRender: (item: string, element: Conversation) => {
            return `${element?.messageData ? element?.messageData : '-'}`
        },
    },
    {
        key: 'TEKLIF',
        label: 'TEKLIF',
        onRender: (item: string, element: Conversation) => {
            return `${element?.price}`
        },
    },
    {
        key: 'SOHBET DURUMU',
        label: 'SOHBET DURUMU',
        onRender: (item: string, element: Conversation) => {
            return `${element?.status === 12 ? 'Görüşme Devam Ediyor' : 'Sohbet Sonlandırıldı'}`
        },
    },
]

export const statusOption = [
    {
        value: 'Ongoing',
        label: 'Görüşme Aşamasında',
    },
    {
        value: 'Ended',
        label: 'Sohbet Sonlandırıldı',
    },
]

const Chat = () => {
    const [chats, setChats] = useState<Array<Conversation>>([])
    const [chatCounts, setChatCounts] = useState(0)
    const [exportArray, setExportArray] = useState<Array<Conversation>>([])
    const navigate = useNavigate()
    const [statusOptions, setStatusOptions] = useState<{ value: string | number; name: string | number }[]>([])
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [exportToggle, setExportToggle] = useState(false)
    const [filterText, setFilterText] = useState('')
    const [currentPage, setCurrentPage] = useState(0)
    const [rowPerPage, setRowPerPage] = useState(10)
    const {
        data: chatsData,
        isSuccess: chatsDataSuccess,
        isLoading: chatsDataLoading,
    } = useGetChats({
        queryKeys: {
            maxResultCount: rowPerPage,
            skipCount: currentPage * rowPerPage || null,
            keyword: filterText || null,
            status: statusOptions || null,
            startDate: startDate.length ? startDateConverter(startDate) : null,
            endDate: endDate.length ? endDateConverter(endDate) : null,
        },
    })

    const {
        data: chatDataExport,
        isSuccess: chatDataSuccessExport,
        refetch: chatExportRefetch,
    } = useGetChats({
        queryKeys: {
            maxResultCount: 1000,
            skipCount: null,
            keyword: filterText || null,
            status: statusOptions || null,
            startDate: startDate.length ? startDateConverter(startDate) : null,
            endDate: endDate.length ? endDateConverter(endDate) : null,
        },
        enabled: false,
    })

    useEffect(() => {
        if (chatDataExport && chatDataExport?.data.result && chatDataSuccessExport) {
            setExportArray(chatDataExport.data.result.conversations)
        }
    }, [chatDataSuccessExport, chatDataExport])

    useEffect(() => {
        if (chatsData?.data.result.conversations && chatsDataSuccess) {
            setChats(chatsData?.data.result.conversations)
            setChatCounts(chatsData.data.result.conversationCount)
        }
    }, [chatsData?.data.result.conversationCount, chatsData?.data.result.conversations, chatsDataSuccess])

    const handleTo = (id: number, role: string) => {
        navigate(`/${role}/${id}`)
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowPerPage(parseInt(event.target.value, 10))
        setCurrentPage(0)
    }

    const onPageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setCurrentPage(newPage)
    }

    const handleSelectDate = (sDate: string, eDate: string) => {
        setStartDate(sDate)
        setEndDate(eDate)
    }

    const handleExport = (exportToggle: boolean, set: React.Dispatch<React.SetStateAction<boolean>>) => {
        set(!exportToggle)
        chatExportRefetch()
    }

    const rowData: TableBodyRowData[] = chats.map((p) => {
        return {
            demand: (
                <>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Typography color={colors.primary.primaryMain} variant="body-small-default" onClick={() => handleTo(p.demandId, 'requests')}>
                            #{p.demandId}
                        </Typography>
                    </Box>
                    <Divider orientation="vertical"></Divider>
                </>
            ),
            sender: (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="body-normal-semibold" onClick={() => handleTo(p.sender.id, 'users')}>
                        {p.sender.fullName}
                    </Typography>
                    <Typography color={colors.text.secondaryTextLight} variant="body-smaller-default">
                        {p.sender.companyName}
                    </Typography>
                </Box>
            ),
            receiver: (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="body-normal-semibold" onClick={() => handleTo(p.receiver.id, 'users')}>
                        {p.receiver.fullName}
                    </Typography>
                    <Typography color={colors.text.secondaryTextLight} variant="body-smaller-default">
                        {p.receiver.companyName}
                    </Typography>
                </Box>
            ),
            content: (
                <CustomContentBox>
                    {p.messageType === 23 ? (
                        <Typography width={'100%'} color={colors.text.secondaryTextLight} variant="body-smaller-default" isLineClamp lineClampRow={2}>
                            {p.messageData}
                        </Typography>
                    ) : p.messageType === -1 ? (
                        <Typography width={'100%'} color={colors.text.secondaryTextLight} variant="body-smaller-default"></Typography>
                    ) : (
                        <Typography width={'100%'} color={colors.text.secondaryTextLight} variant="body-smaller-default" onClick={() => window.open(p.messageData, '_blank')}>
                            <CustomPhotoBox>
                                <PhotoCameraIcon fontSize="small" /> Fotoğraf
                            </CustomPhotoBox>
                        </Typography>
                    )}
                </CustomContentBox>
            ),
            offer: (
                <CustomContentBox>
                    <Typography width={'100%'} color={colors.text.secondaryTextLight} variant="body-smaller-default">
                        {p.price}
                    </Typography>
                </CustomContentBox>
            ),
            status: (
                <CustomContentBox>
                    <CustomBadge style={{ backgroundColor: p.status === 12 ? colors.primary.primaryLightBg : '#ffeae9' }}>
                        <Typography color={p.status === 12 ? colors.primary.primaryMain : colors.error.errorMain} width={'100%'} variant="body-small-medium">
                            {p.status === 12 ? 'Görüşme Aşamasında' : 'Sohbet Sonlandırıldı'}
                        </Typography>
                    </CustomBadge>
                </CustomContentBox>
            ),
            menu: (
                <List
                    listDetails={[
                        {
                            name: 'Görüntüle',
                            icon: <EditOutlined style={{ color: colors.action.actionActiveLight }} />,
                            onClick: () => navigate(`/users/${p.sender.id}?conversationId=${p.id}`),
                        },
                    ]}
                    width="230px"
                    minWidth="inherit"
                    gap="10px"
                    listPadding="15px"
                    itemButtonPadding="0px"
                    ListContainerPadding="0px 10px"
                    ListTextPadding="5px 0px"
                    backgroundColor={colors.white.default}
                    boxShadow="0px 5px 5px -3px #4C4E6424, 0px 8px 10px 1px #4C4E6424, 0px 3px 14px 2px #4C4E6433"
                    isTableActions={true}
                />
            ),
        }
    })
    return (
        <DashboardLayout>
            <CustomChatContainer>
                <CustomTableDiv>
                    <CustomHeadDiv style={{ height: ptr(81) }}>
                        <CustomExportBox>
                            <CustomExportBoxTypo>
                                <Button
                                    startIcon={<IosShare />}
                                    onClick={() => handleExport(exportToggle, setExportToggle)}
                                    variant="outlined"
                                    colorsx={colors.secondary.secondaryAlternativeLight}
                                    borderColor={colors.secondary.secondaryAlternativeLight}
                                    padding={ptr(16)}
                                >
                                    <ExportDropdown<Conversation>
                                        array={exportArray}
                                        fileName={'export'}
                                        title={''}
                                        sheetName={''}
                                        headers={exportOptions}
                                        open={exportToggle}
                                        buttonHeader={'Export'}
                                        isSuccess={chatDataSuccessExport}
                                    />
                                </Button>
                                <Typography variant="body-smaller-default">Maksimum 1000 veri</Typography>
                            </CustomExportBoxTypo>
                        </CustomExportBox>
                        <CustomFilteredBox>
                            <Box sx={{ width: '30%' }}>
                                <SearchInput
                                    fullHeight
                                    fullWidth
                                    value={filterText}
                                    onChange={(val) => {
                                        setFilterText(val)
                                        if (currentPage !== 0) setCurrentPage(0)
                                    }}
                                    placeholder="Sohbet Ara"
                                />
                            </Box>
                            <Box sx={{ width: '30%' }}>
                                <AutocompleteContainer
                                    width={'100%'}
                                    label="Sohbet Durumu Seç"
                                    onChange={(e) => {
                                        setStatusOptions(e as any)
                                        if (currentPage !== 0) setCurrentPage(0)
                                    }}
                                    options={statusOption}
                                />
                            </Box>
                            <Box sx={{ width: '30%' }}>
                                <DatePicker
                                    setEndDate={setEndDate}
                                    setStartDate={setStartDate}
                                    startDate={startDate}
                                    endDate={endDate}
                                    width={'100%'}
                                    height={ptr(53)}
                                    placeholder={'Tarihe Göre Filtrele'}
                                    onSelectDate={({ startDate, endDate }) => {
                                        handleSelectDate(startDate, endDate)
                                        if (currentPage !== 0) setCurrentPage(0)
                                    }}
                                />
                            </Box>
                        </CustomFilteredBox>
                    </CustomHeadDiv>

                    <Spinner open={chatsDataLoading} />
                    <Table
                        count={chatCounts}
                        isLoading={false}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        onPageChange={onPageChange}
                        head={userRatesHead}
                        result={rowPerPage}
                        rowsData={rowData}
                        isClickable={false}
                        page={currentPage}
                        menu={true}
                    ></Table>
                </CustomTableDiv>
            </CustomChatContainer>
        </DashboardLayout>
    )
}

export default Chat
