import { Badge, Box, styled } from '@mui/material'
import { ptr } from '../../../../../utils/helpers'
import { colors } from '../../../../../styles/color'

export const ChatUserContainer = styled('div')({
    minWidth: ptr(300),
    minHeight: ptr(68),
    display: 'flex',
    alignItems: 'center',
    ':hover': {
        cursor: 'pointer',
    },
})
export const ChatUserWrapper = styled('div')({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: `0 ${ptr(12)}`,
    gap: ptr(16),
})
export const StyledBadge = styled(Badge)(({ theme }) => ({
    position: 'relative',
    height: 'fit-content',
    width: 'fit-content',
    '& .MuiBadge-badge': {
        background: colors.warning.warningMain,
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        position: 'absolute',
        bottom: ptr(2),
        right: ptr(2),
        // '&::after': {
        //     position: 'absolute',
        //     top: 0,
        //     left: 0,
        //     width: '100%',
        //     height: '100%',
        //     borderRadius: '50%',
        //     border: '1px solid currentColor',
        //     content: '""',
        // },
    },
}))

export const ChatUserInformation = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    marginRight: 'auto',
})
export const ChatUsername = styled('span')({
    fontWeight: 'bold',
    lineHeight: ptr(24),
})
export const ChatCompanyName = styled('span')({
    color: colors.text.disabledTextLight,
    fontSize: ptr(14),
    lineHeight: ptr(21),
})
export const ChatTime = styled('span')({
    height: '100%',
    alignSelf: 'self-start',
    fontSize: ptr(14),
    fontWeight: 500,
})
export const SidebarNameCompany = styled('span')({
    display: 'flex',
    flexDirection: 'column',
})
export const SidebarNameId = styled('span')({
    display: 'flex',
    gap: ptr(8),
})
