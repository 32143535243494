import { styled } from '@mui/material'
import { colors } from 'styles/color'

export const GeneralContainer = styled('div')({})
export const SummaryContainer = styled('div')((props) => ({
    display: 'flex',
    width: '100%',
    // flexDirection:"column",
    // alignItems: "space-between"
}))
export const LeftSide = styled('div')((props) => ({
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
}))
export const RightSide = styled('div')((props) => ({
    width: '50%',
}))
export const LastCheck = styled('div')((props) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
}))

export const Title = styled('div')((props) => ({
    color: colors.text.primaryTextLight,
    fontSize: '0.875rem',
    fontWeight: 600,
    marginTop: '16px',
}))
export const KeyContent = styled('div')((props) => ({
    display: 'flex',
    alignItems: 'center',
    marginTop: '8px',
}))
export const Key = styled('div')((props) => ({
    display: 'inline-block',
    color: colors.text.secondaryTextLight,
    fontSize: '1rem',
    fontWeight: 600,
    width: '120px',
}))
export const Content = styled('div')((props) => ({
    display: 'inline-block',
    color: colors.text.secondaryTextLight,
    fontSize: '1rem',
    fontWeight: 400,
}))
export const ContentBox = styled('div')((props) => ({}))
export const BankContainer = styled('div')((props) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
}))
export const BankContent = styled('div')((props) => ({
    display: 'flex',
    gap: ' 15px',
}))
