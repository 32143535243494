import { ChatMessageProps } from './types'
import { FC, useCallback, useState } from 'react'
import { ChatMessageContainer, MessageContainer, MessageContainerInformation, MessageWrapper, TimeContainer } from './index.styled'
import { DoneAll } from '@mui/icons-material'
import { colors } from '../../../../../styles/color'
import { EDirection } from './chatMessage.enum'
import Avatar from '../../../../../components/Avatar/Avatar'
import dayjs from 'dayjs'
import 'dayjs/locale/tr'
import Tooltip from '../../../../../components/Tooltip/Tooltip'
import ImageViewer from 'react-simple-image-viewer'
import { ptr } from '../../../../../utils/helpers'

dayjs.locale('tr')

interface MessageProps {
    title: string
    direction: EDirection
    type: string
}

interface TimeProps {
    time: string
    direction?: EDirection
}

const Message: FC<MessageProps> = ({ title, direction, type }) => {
    const [isImageViewing, setIsImageViewing] = useState<boolean>(false)

    const openImageViewer = useCallback(() => {
        setIsImageViewing(true)
    }, [])

    const closeImageViewer = () => {
        setIsImageViewing(false)
    }
    const splitedMessage = title.split('\n')
    return (
        <MessageContainer className={direction}>
            {Number(type) === 12 ? <img src={title} height="100px" width="100px" onClick={openImageViewer} alt="Fotograf yuklenemedi" /> : splitedMessage.map((message) => <div>{message}</div>)}
            {isImageViewing && (
                <ImageViewer
                    src={[title]}
                    onClose={closeImageViewer}
                    disableScroll={false}
                    backgroundStyle={{
                        height: '85vh',
                        width: 'fit-content',
                        backgroundColor: 'rgba(25,25,25, 0.4)',
                        zIndex: '99',
                        left: '50%',
                        top: '50%',
                        transform: 'translate(-50%, -50%)',
                        borderRadius: ptr(10),
                    }}
                />
            )}
        </MessageContainer>
    )
}

const Time: FC<TimeProps> = ({ time, direction }) => {
    return (
        <TimeContainer sx={{ display: 'flex', justifyContent: direction === EDirection.LEFT ? 'flex-start' : 'flex-end' }}>
            <Tooltip name={time}>{time}</Tooltip>
        </TimeContainer>
    )
}

const ChatMessage: FC<ChatMessageProps> = ({ direction, user, message, isRead, sendAt, type }) => {
    return (
        <ChatMessageContainer>
            {direction === EDirection.LEFT && <Avatar name={user.fullName} height={32} width={32} src={user.avatar} />}
            <MessageWrapper className={direction}>
                <Message direction={direction} title={message} type={type} />
                <MessageContainerInformation>
                    {direction === EDirection.RIGHT && <DoneAll fontSize="small" sx={{ color: isRead ? colors.success.successMain : colors.secondary.secondaryMain }} />}
                    <Time direction={direction} time={sendAt} />
                </MessageContainerInformation>
            </MessageWrapper>
            {direction === EDirection.RIGHT && <Avatar name={user.fullName} height={32} width={32} src={user.avatar} />}
        </ChatMessageContainer>
    )
}

export default ChatMessage
