import DashboardLayout from 'layouts/Dashboard/DashboardLayout'
import { CustomComplaintContainer, CustomExportBox, CustomExportBoxDiv, CustomFilteredBox, CustomHeadBox, CustomHeadDiv, CustomHeadReasonBox, CustomTableDiv } from './index.styled'
import { Box } from '@mui/material'
import AutocompleteContainer from 'components/Input/AutoComplete/Autocomplete'
import DatePicker from 'components/DateRangePicker/DateRangePicker'
import Button from 'components/Buttons/Button/Button'
import { IosShare } from '@mui/icons-material'
import ExportDropdown from 'components/ExportDropdown'
import Spinner from 'components/Spinner'
import Table from 'components/Table'
import { endDateConverter, ptr, startDateConverter } from 'utils/helpers'
import { colors } from 'styles/color'
import React, { useEffect, useState } from 'react'
import { ComplaintsModel } from 'services/be-api/complaint/types'
import { useGetAllComplaints, useGetAllComplaintsReasons } from 'utils/hooks/queries/Complaints'
import { GridColDef } from '@mui/x-data-grid'
import { useLocation, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { OptionType } from 'components/Input/AutoComplete/types'
import Typography from 'components/Typography/Typography'
import { TableBodyRowData } from 'components/Table/types'
import SearchInput from '../../components/Input/SearchInput/SearchInput'

export const userRatesHead: GridColDef[] = [
    { field: 'demand', headerName: 'TALEP', width: 40 },
    { field: 'complainer', headerName: 'BİLDİREN', width: 90 },
    { field: 'complainant', headerName: 'BİLDİRİLEN', width: 90 },
    { field: 'reason', headerName: 'ŞİKAYET KONUSU', width: 90 },
    { field: 'description', headerName: 'ŞİKAYET AÇIKLAMASI', width: 90 },
    { field: 'time', headerName: 'ŞİKAYET ZAMANI', width: 90 },
]

export const exportOptions = [
    {
        key: 'TALEP',
        label: 'TALEP',
        onRender: (item: string, element: ComplaintsModel) => {
            return `${element?.demand.id}`
        },
    },
    {
        key: 'BILDIREN',
        label: 'BILDIREN',
        onRender: (item: string, element: ComplaintsModel) => {
            return `${element?.complainer.name} ${element?.complainer?.surname}`
        },
    },
    {
        key: 'BILDIRILEN',
        label: 'BILDIRILEN',
        onRender: (item: string, element: ComplaintsModel) => {
            return `${element?.complainant.name} ${element?.complainant?.surname}`
        },
    },
    {
        key: 'SIKAYET KONUSU',
        label: 'SIKAYET KONUSU',
        onRender: (item: string, element: ComplaintsModel) => {
            return `${element?.reason}`
        },
    },
    {
        key: 'SIKAYET ACIKLAMASI',
        label: 'SIKAYET ACIKLAMASI',
        onRender: (item: string, element: ComplaintsModel) => {
            return `${element?.description}`
        },
    },
    {
        key: 'SIKAYET ZAMANI',
        label: 'SIKAYET ZAMANI',
        onRender: (item: string, element: ComplaintsModel) => {
            return `${dayjs(element?.creationTime).format('DD.MM.YYYY')}`
        },
    },
]

const Complaint = () => {
    const location = useLocation()
    const selectedDate = location.state
    const [complaints, setComplaints] = useState<Array<ComplaintsModel>>([])
    const [exportArray, setExportArray] = useState<Array<ComplaintsModel>>([])
    const navigate = useNavigate()
    const [statusOptions, setStatusOptions] = useState<{ value: string | number; name: string | number }[]>([])
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [exportToggle, setExportToggle] = useState(false)
    const [reasonOptions, setReasonOptions] = useState<OptionType[]>([])
    const [filterText, setFilterText] = useState('')
    const [currentPage, setCurrentPage] = useState(0)
    const [rowPerPage, setRowPerPage] = useState(10)
    const {
        data: complaintsData,
        isSuccess: complaintsDataSuccess,
        isLoading: complaintsDataLoading,
    } = useGetAllComplaints({
        queryKeys: {
            maxResultCount: rowPerPage,
            skipCount: currentPage * rowPerPage || null,
            filter: filterText || null,
            reason: statusOptions || null,
            startDate: startDate.length ? startDateConverter(startDate) : null,
            endDate: endDate.length ? endDateConverter(endDate) : null,
        },
    })

    const {
        data: complaintsDataExport,
        isSuccess: complaintsDataSuccessExport,
        refetch: complaintsExportRefetch,
    } = useGetAllComplaints({
        queryKeys: {
            maxResultCount: 1000,
            skipCount: null,
            filter: filterText,
            reason: statusOptions,
            startDate: startDate.length ? startDateConverter(startDate) : null,
            endDate: endDate.length ? endDateConverter(endDate) : null,
        },
        enabled: false,
    })

    const { data: reasonsData, isSuccess: reasonsDataSuccess } = useGetAllComplaintsReasons({ queryKeys: {} })

    useEffect(() => {
        if (reasonsData && reasonsDataSuccess) {
            setReasonOptions(
                reasonsData.data.result.map((item) => ({
                    value: item.title,
                    label: item.title,
                }))
            )
        }
    }, [reasonsData, reasonsDataSuccess])

    useEffect(() => {
        if (selectedDate) {
            setStartDate(selectedDate.startDate)
            setEndDate(selectedDate.endDate)
        }
    }, [selectedDate])

    useEffect(() => {
        if (complaintsData && complaintsDataSuccess) {
            setComplaints(complaintsData.data.result.items)
        }
    }, [complaintsData, complaintsDataSuccess, reasonsData])

    useEffect(() => {
        if (complaintsDataExport && complaintsDataExport?.data.result && complaintsDataSuccessExport) {
            setExportArray(complaintsDataExport.data.result.items)
        }
    }, [complaintsDataExport, complaintsDataSuccessExport])

    const handleTo = (id: number, role: string) => {
        navigate(`/${role}/${id}`)
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowPerPage(parseInt(event.target.value, 10))
        setCurrentPage(0)
    }

    const onPageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setCurrentPage(newPage)
    }

    const handleSelectDate = (sDate: string, eDate: string) => {
        setStartDate(sDate)
        setEndDate(eDate)
    }

    const handleExport = (exportToggle: boolean, set: React.Dispatch<React.SetStateAction<boolean>>) => {
        set(!exportToggle)
        complaintsExportRefetch()
    }

    const rowData: TableBodyRowData[] = complaints.map((p) => {
        return {
            demand: (
                <Box>
                    <Typography color={colors.primary.primaryMain} variant="body-small-default" onClick={() => handleTo(p.demandId, 'requests')}>
                        #{p.demandId}
                    </Typography>
                </Box>
            ),

            complainer: (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="body-normal-semibold" onClick={() => handleTo(p.complainer.id, 'users')}>
                        {p.complainer.name} {p.complainer.surname}
                    </Typography>
                    <Typography color={colors.text.secondaryTextLight} variant="body-smaller-default">
                        {p.complainer.company.name}
                    </Typography>
                </Box>
            ),
            complainant: (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="body-normal-semibold" onClick={() => handleTo(p.complainant.id, 'users')}>
                        {p.complainant.name} {p.complainant.surname}
                    </Typography>
                    <Typography color={colors.text.secondaryTextLight} variant="body-smaller-default">
                        {p.complainant.company.name}
                    </Typography>
                </Box>
            ),
            reason: (
                <CustomHeadReasonBox>
                    <Typography color={colors.error.errorMain} width={'100%'} variant="body-small-default">
                        {p.reason}
                    </Typography>
                </CustomHeadReasonBox>
            ),
            description: (
                <CustomHeadBox>
                    <Typography color={colors.text.secondaryTextLight} width={'100%'} variant="body-normal-default">
                        {p.description}
                    </Typography>
                </CustomHeadBox>
            ),
            time: (
                <Box>
                    <Typography color={colors.text.secondaryTextLight} width={'100%'} variant="body-small-medium">
                        {dayjs(p.creationTime).format('DD.MM.YYYY HH:mm')}
                    </Typography>
                </Box>
            ),
        }
    })
    return (
        <DashboardLayout>
            <CustomComplaintContainer>
                <CustomTableDiv>
                    <CustomHeadDiv style={{ height: ptr(81) }}>
                        <CustomExportBox>
                            <CustomExportBoxDiv>
                                <Button
                                    startIcon={<IosShare />}
                                    onClick={() => handleExport(exportToggle, setExportToggle)}
                                    variant="outlined"
                                    colorsx={colors.secondary.secondaryAlternativeLight}
                                    borderColor={colors.secondary.secondaryAlternativeLight}
                                    padding={ptr(16)}
                                >
                                    <ExportDropdown<ComplaintsModel>
                                        array={exportArray}
                                        fileName={'export'}
                                        title={''}
                                        sheetName={''}
                                        headers={exportOptions}
                                        open={exportToggle}
                                        buttonHeader={'Export'}
                                        isSuccess={complaintsDataSuccessExport}
                                    />
                                </Button>
                                <Typography variant="body-smaller-default">Maksimum 1000 veri</Typography>
                            </CustomExportBoxDiv>
                        </CustomExportBox>
                        <CustomFilteredBox>
                            <Box sx={{ width: '30%' }}>
                                <SearchInput
                                    fullHeight
                                    fullWidth
                                    value={filterText}
                                    onChange={(val) => {
                                        setFilterText(val)
                                        if (currentPage !== 0) setCurrentPage(0)
                                    }}
                                    placeholder="Şikayeti Ara"
                                />
                            </Box>
                            <Box sx={{ width: '30%' }}>
                                <AutocompleteContainer
                                    width={'100%'}
                                    label="Şikayet Konusunu Seç"
                                    onChange={(e) => {
                                        setStatusOptions(e as any)
                                        if (currentPage !== 0) setCurrentPage(0)
                                    }}
                                    options={reasonOptions}
                                />
                            </Box>
                            <Box sx={{ width: '30%' }}>
                                <DatePicker
                                    setEndDate={setEndDate}
                                    setStartDate={setStartDate}
                                    startDate={startDate}
                                    endDate={endDate}
                                    value={`${startDate} - ${endDate}`}
                                    width={'100%'}
                                    height={ptr(53)}
                                    placeholder={'Tarihe Göre Filtrele'}
                                    onSelectDate={({ startDate, endDate }) => {
                                        handleSelectDate(startDate, endDate)
                                        if (currentPage !== 0) setCurrentPage(0)
                                    }}
                                />
                            </Box>
                        </CustomFilteredBox>
                    </CustomHeadDiv>
                    <Spinner open={complaintsDataLoading} />
                    <Table
                        count={complaintsData?.data.result.totalCount}
                        isLoading={complaintsDataLoading}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        onPageChange={onPageChange}
                        head={userRatesHead}
                        result={rowPerPage}
                        rowsData={rowData}
                        isClickable={false}
                        page={currentPage}
                    ></Table>
                </CustomTableDiv>
            </CustomComplaintContainer>
        </DashboardLayout>
    )
}

export default Complaint
