import { Mutation, Query } from '../types'
import { useBaseMutation, useBaseQuery } from '../_Base'

import { createNotification, getAllNotifications } from 'services/be-api/notification/endpoints'

import { CreateNotificationModel, GetAllNotificationsServiceResponse, GetNotificationsVariables } from 'services/be-api/notification/types'

export const useCreateNotification: Mutation<CreateNotificationModel, void> = () => {
    return useBaseMutation({
        service: createNotification,
        onSuccess: {
            messageDisplay: true,
            message: 'Bildirim Başarıyla Oluşturuldu.',
        },
        onLoading: {
            messageDisplay: true,
            message: 'Bildirim Oluşturuluyor.',
        },
        onError: {
            messageDisplay: true,
        },
    })
}

export const useGetAllNotifications: Query<GetNotificationsVariables, GetAllNotificationsServiceResponse> = (params) => {
    return useBaseQuery({
        queryKeys: ['user', { params: params.queryKeys }],
        service: getAllNotifications,
        onSuccess: {
            messageDisplay: false,
            message: 'Succesfully, get all users',
        },
        onLoading: {
            messageDisplay: false,
            message: 'Loading',
        },
        onError: {
            messageDisplay: false,
        },
        enabled: params.enabled,
    })
}
