import { GetUserRatesResponse, GetUserRatesVariables, UserRatesVariables } from 'services/be-api/user-rate/types'
import { useBaseQuery } from '../_Base'
import { Mutation, Query } from '../types'
import { acceptUserRate, deleteUserRate, getAllUserRates, rejectUserRate } from 'services/be-api/user-rate/endpoints'
import { useBaseMutation } from '../_Base'

export const useGetAllUserRates: Query<GetUserRatesVariables, GetUserRatesResponse> = (params) =>
    useBaseQuery({
        queryKeys: ['companies', { params: params.queryKeys }],
        service: getAllUserRates,
        onSuccess: {
            messageDisplay: false,
            message: 'Successful.',
        },
        onLoading: {
            messageDisplay: false,
            message: 'Loading',
        },
        onError: {
            messageDisplay: false,
        },
        enabled: params.enabled,
    })

export const useAcceptRate: Mutation<UserRatesVariables, any> = () => {
    return useBaseMutation({
        service: acceptUserRate,
        onSuccess: {
            messageDisplay: true,
            message: 'Yorumu başarıyla yayınladınız',
        },
        onLoading: {
            messageDisplay: true,
            message: 'Loading',
        },
        onError: {
            messageDisplay: true,
        },
    })
}

export const useRejectRate: Mutation<UserRatesVariables, any> = () => {
    return useBaseMutation({
        service: rejectUserRate,
        onSuccess: {
            messageDisplay: true,
            message: 'Yorumu başarıyla kaldırdınız',
        },
        onLoading: {
            messageDisplay: true,
            message: 'Loading',
        },
        onError: {
            messageDisplay: true,
        },
    })
}

export const useDeleteRate: Mutation<UserRatesVariables, any> = () => {
    return useBaseMutation({
        service: deleteUserRate,
        onSuccess: {
            messageDisplay: true,
            message: 'Yorumu başarıyla sildiniz',
        },
        onLoading: {
            messageDisplay: true,
            message: 'Loading',
        },
        onError: {
            messageDisplay: true,
        },
    })
}
