import React, { FC, useState } from 'react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { Divider } from '@mui/material'
import AddBoxIcon from '@mui/icons-material/AddBox'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'

import CheckboxCard from './CheckboxCard/CheckboxCard'
import Button from 'components/Buttons/Button/Button'
import TextField from 'components/Input/TextField/TextField'
import { ButtonsSide, CoInputContent, CustomInputMask, ErrorMessage, ErrorMessageControler, FormContainer } from '../AddCompany.styled'
import { BankSection } from './CompanyAuthorizedInformation.styled'
import { ValueOf } from 'models/common/types/ValueOf'

import { AddCompanyProps, BankAccountModel, CompanyAuthorizedInformationFormValues } from './type'
import { colors } from 'styles/color'

const CompanyAuthorizedInformation: FC<AddCompanyProps> = ({ value, onChange, handleNext, handleBack }) => {
    const [radioValue, setRadioValue] = useState<string>('')

    const validationSchema = Yup.object().shape({
        role: Yup.string().required('Bu alan zorunludur.'),
        authorizedNameSurname: Yup.string()
            .required('Bu alan zorunludur.')
            .max(64, 'Maksimum 64 karakter olabilir')
            .matches(/^(?!\s)/, 'Kullanıcı İsim Soyisim alanında başta boşluk olamaz.')
            .matches(/(\S+\s)+/, 'Kullanıcı İsim Soyisim alanında en az 2 kelime olmalı.')
            .matches(/(\S+\s){1}/, 'Kullanıcı İsim Soyisim alanında yazılar arasında sadece 1 boşluk olmalı.')
            .matches(/\S+$/, 'Kullanıcı İsim Soyisim alanında sonda boşluk olmamalıdır.'), // /^(?!\s)(\S+)(?:\s\S+)+$/ çoklu regex çalışmazsa (gözümden bir şey kaçtıysa) çalışan alternatif regex
        telNumber: Yup.string()
            .required('Bu alan zorunludur.')
            .test('is-valid-telNumber', 'Geçersiz telefon numarası', (value) => {
                const lengthOfNumbers = value.replace(/_/g, '').length
                return lengthOfNumbers === 17
            }),
        eMail: Yup.string().max(64, 'Maksimum 64 karakter olabilir').email('Geçersiz eMail adresi'),
        reference: Yup.string().required('Bu alan zorunludur.').max(255, 'Maksimum 255 karakter girebilirsiniz'),
    })

    const onSubmit = (values: CompanyAuthorizedInformationFormValues) => {
        if (radioValue === 'satıcı') onChange({ ...values, bankAccounts: value.bankAccounts })
        else onChange({ ...values, bankAccounts: [{ bankName: '', ibanNumber: '', nameSurname: '' }] })

        handleNext()
    }

    const addBankAccount = () => {
        let existBankAccounts = value.bankAccounts
        existBankAccounts = [...existBankAccounts, { bankName: '', ibanNumber: '', nameSurname: '' }]
        const newValue: typeof value = { ...value, bankAccounts: existBankAccounts }
        onChange(newValue)
    }

    const onDeleteBankAccount = (index: number) => {
        let existBankAccounts = value.bankAccounts
        existBankAccounts = existBankAccounts.filter((_child, i) => i !== index)
        const newValue: typeof value = { ...value, bankAccounts: existBankAccounts }
        onChange(newValue)
    }

    const onChangeBankAccountValue = (index: number, key: keyof BankAccountModel, val: ValueOf<BankAccountModel>) => {
        let existBankAccounts = value.bankAccounts
        existBankAccounts[index][key] = val
        const newValue: typeof value = { ...value, bankAccounts: existBankAccounts }
        onChange(newValue)
    }

    const isBankAccountsInvalid = () => {
        const check = value.bankAccounts.some((bankAccount) => {
            if (bankAccount.bankName.length > 0 && bankAccount.ibanNumber.replaceAll(' ', '').replaceAll('_', '').length === 26 && bankAccount.nameSurname.length > 0) return false
            else if (bankAccount.bankName === '' && bankAccount?.ibanNumber?.replaceAll(' ', '').replaceAll('_', '').length === 0 && bankAccount.nameSurname === '') return false
            else return true
        })
        return radioValue === 'satıcı' && value.bankAccounts.length >= 0 && check
    }
    const isNewBankAccountsInvalid = () => {
        const check = value.bankAccounts.some((bankAccount) => {
            if (bankAccount.bankName.length > 0 && bankAccount.ibanNumber.replaceAll(' ', '').replaceAll('_', '').length === 26 && bankAccount.nameSurname.length > 0) return false
            else return true
        })
        return radioValue === 'satıcı' && value.bankAccounts.length >= 0 && check
    }

    return (
        <div>
            <Formik initialValues={value} validationSchema={validationSchema} onSubmit={onSubmit} validateOnBlur>
                {({ isSubmitting, values, handleChange, handleSubmit, isValid, handleBlur }) => {
                    return (
                        <Form>
                            <FormContainer>
                                <CheckboxCard radioValue={radioValue} setRadioValue={setRadioValue} handleChange={handleChange} val={values.role} />
                                <ErrorMessageControler>
                                    <TextField
                                        name="authorizedNameSurname"
                                        type="text"
                                        placeholder="Yetkili İsim Soyisim"
                                        onChange={(e, changeEvent) => handleChange(changeEvent)}
                                        value={values.authorizedNameSurname}
                                        onBlur={handleBlur}
                                        width="50%"
                                    />
                                    <ErrorMessage name="authorizedNameSurname" component={'div'} />
                                </ErrorMessageControler>
                                <CoInputContent>
                                    <ErrorMessageControler>
                                        <CustomInputMask
                                            name="telNumber"
                                            mask="+\90 999 999 99 99"
                                            placeholder="Telefon No"
                                            onChange={(changeEvent) => handleChange(changeEvent)}
                                            value={values.telNumber}
                                            onBlur={handleBlur}
                                        />
                                        <ErrorMessage name="telNumber" component={'div'} />
                                    </ErrorMessageControler>
                                    <ErrorMessageControler>
                                        <TextField
                                            name="eMail"
                                            type="email"
                                            placeholder="Email (Opsiyonel)"
                                            onChange={(e, changeEvent) => handleChange(changeEvent)}
                                            value={values.eMail}
                                            onBlur={handleBlur}
                                            expand
                                        />
                                        <ErrorMessage name="eMail" component={'div'} />
                                    </ErrorMessageControler>
                                </CoInputContent>

                                <ErrorMessageControler>
                                    <TextField
                                        multiline
                                        rows={2}
                                        name="reference"
                                        type="text"
                                        placeholder="Referanslar"
                                        onChange={(e, changeEvent) => handleChange(changeEvent)}
                                        value={values.reference}
                                        onBlur={handleBlur}
                                        expand
                                    />
                                    <ErrorMessage name="reference" component={'div'} />
                                </ErrorMessageControler>
                                {radioValue === 'satıcı' && (
                                    <BankSection>
                                        <Divider sx={{ marginTop: '20px' }}></Divider>
                                        {value.bankAccounts?.map((bank, index) => (
                                            <div key={index}>
                                                <CoInputContent sx={{ marginBottom: '20px' }}>
                                                    <ErrorMessageControler>
                                                        <TextField
                                                            name="nameSurname"
                                                            placeholder="Adı Soyadı"
                                                            type="text"
                                                            onChange={(e) => onChangeBankAccountValue(index, 'nameSurname', e as string)}
                                                            value={bank.nameSurname}
                                                            width="100%"
                                                            onBlur={handleBlur}
                                                            inputProps={{ maxLength: 64 }}
                                                        />
                                                        <ErrorMessage name="nameSurname" component={'div'} />
                                                    </ErrorMessageControler>
                                                    <ErrorMessageControler>
                                                        <CustomInputMask
                                                            name="ibanNumber"
                                                            placeholder="IBAN Numarası"
                                                            mask="\TR99 9999 9999 9999 9999 9999 99"
                                                            onChange={(e) => onChangeBankAccountValue(index, 'ibanNumber', e.target.value as string)}
                                                            value={bank.ibanNumber}
                                                            width="100%"
                                                            onBlur={handleBlur}
                                                        />
                                                        <ErrorMessage name="ibanNumber" component={'div'} />
                                                    </ErrorMessageControler>
                                                </CoInputContent>
                                                <CoInputContent sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <ErrorMessageControler>
                                                        <TextField
                                                            name="bankName"
                                                            type="text"
                                                            placeholder="Banka Adı"
                                                            onChange={(e) => onChangeBankAccountValue(index, 'bankName', e as string)}
                                                            value={bank.bankName}
                                                            width="50%"
                                                            onBlur={handleBlur}
                                                            inputProps={{ maxLength: 64 }}
                                                        />
                                                        <ErrorMessage name="bankName" component={'div'} />
                                                    </ErrorMessageControler>
                                                    {value.bankAccounts.length - 1 === index ? (
                                                        <Button
                                                            disabled={isNewBankAccountsInvalid()}
                                                            width="50%"
                                                            color="secondary"
                                                            startIcon={<AddBoxIcon />}
                                                            variant="outlined"
                                                            onClick={addBankAccount}
                                                        >
                                                            BAŞKA BANKA EKLE{' '}
                                                        </Button>
                                                    ) : (
                                                        <DeleteOutlinedIcon
                                                            sx={{
                                                                color: colors.error.errorAlternativeLight,
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={() => onDeleteBankAccount(index)}
                                                        />
                                                    )}
                                                </CoInputContent>
                                            </div>
                                        ))}
                                    </BankSection>
                                )}
                            </FormContainer>

                            <ButtonsSide>
                                <Button id="next" onClick={handleSubmit} disabled={isSubmitting || !isValid || isBankAccountsInvalid()}>
                                    İleri
                                </Button>
                                <Button variant="outlined" color="secondary" onClick={handleBack}>
                                    Geri
                                </Button>
                            </ButtonsSide>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}

export default CompanyAuthorizedInformation
