import React from 'react'
import { CustomBoxColumn, CustomCardPaper } from './index.styled'
import { Badge } from '@mui/material'
import Typography from 'components/Typography/Typography'
import { colors } from 'styles/color'
import { ptr } from 'utils/helpers'

interface NotificationProps {
    icon: React.ReactNode
    backgroundColor: string
    color: string
    name: string
    countValue: number
}

const Cards = ({ icon, color, backgroundColor, name, countValue }: NotificationProps) => {
    return (
        <div>
            <CustomCardPaper>
                <Badge
                    sx={{
                        backgroundColor: backgroundColor,
                        color: color,
                        borderRadius: '8px',
                        height: ptr(40),
                        width: ptr(40),
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    {icon}
                </Badge>
                <CustomBoxColumn>
                    <Typography color={colors.text.secondaryTextLight} variant="body-small-default">
                        {name}
                    </Typography>
                    <Typography variant="h6-medium">{countValue ? countValue : 0}</Typography>
                </CustomBoxColumn>
            </CustomCardPaper>
        </div>
    )
}

export default Cards
