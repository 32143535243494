import { styled } from '@mui/material'
import InputMask from 'react-input-mask'
import { ErrorMessage as CustomErrorMessage } from 'formik'
import { colors } from 'styles/color'
import { ptr } from 'utils/helpers'

export const StepperContainer = styled('div')((props) => ({
    backgroundColor: colors.white.default,
    display: 'flex',
    padding: '24px',
    margin: '24px',
    boxShadow: '0px 2px 10px rgba(76, 78, 100, 0.22)',
    gap: '1.5rem',
}))
export const StepperForm = styled('div')((props) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
}))
export const StepperContent = styled('div')((props) => ({}))

export const FormContainer = styled('div')((props) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
}))
export const CoInputContent = styled('div')((props) => ({
    display: 'flex',
    alignItems: 'flex-start',
    gap: '20px',
}))
export const ErrorMessageControler = styled('div')((props) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
}))
export const ButtonsSide = styled('div')((props) => ({
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    maxWidth: '100%',
    marginTop: '1rem',
}))
export const CustomInputMask = styled(InputMask)({
    borderRadius: '4px',
    height: ptr(55),
    padding: '0 16.5px',
    border: `1px solid #0000003b`,
    ' &:hover': {
        outline: 'none',
        border: `1px solid ${colors.black.default}`,
    },
    ' &:focus': {
        outline: 'none',
        border: `2px solid ${colors.primary.primaryMain}`,
    },
    '&::placeholder': {
        color: colors.text.disabledTextLight,
    },
})
export const ErrorMessage = styled(CustomErrorMessage)((props) => ({
    color: colors.text.secondaryTextLight,
    fontSize: '14px',
}))
