import { ProfileNotification } from '../../../../services/be-api/users/types'
import { GridColDef } from '@mui/x-data-grid'

export const profileNotificationExportOptions = [
    {
        key: 'BİLDİRİM',
        label: 'BILDIRIM',
        onRender: (item: string, element: ProfileNotification) => {
            return `${element?.title}`
        },
    },
    {
        key: 'İÇERİK',
        label: 'ICERIK',
        onRender: (item: string, element: ProfileNotification) => {
            return `${element?.text}`
        },
    },
    {
        key: 'BİLDİRİM SAATİ',
        label: 'BILDIRIM SAATI',
        onRender: (item: string, element: ProfileNotification) => {
            return `${element?.creationTime}`
        },
    },
]

export const profileNotificationTableHead: GridColDef[] = [
    { field: 'type', headerName: 'BİLDİRİM', width: 70, flex: 1 },
    { field: 'content', headerName: 'İÇERİK', width: 130, flex: 1 },
    { field: 'createdAt', headerName: 'BİLDİRİM SAATİ', width: 130, flex: 1 },
]
