import { GridColDef } from '@mui/x-data-grid'
import { RequestModel } from '../../services/be-api/requests/types'
import { ERequestStatus } from './index.enum'

export const requestStatusOptions = Object.entries(ERequestStatus).map((status) => ({ label: status[1], value: status[0] }))

export const requestTableHead: GridColDef[] = [
    { field: 'user', headerName: 'KULLANICI', width: 220 },
    { field: 'vehicleMakeName', headerName: 'MARKA', width: 140 },
    { field: 'vehicleModelName', headerName: 'MODEL', width: 120 },
    { field: 'workCategoryName', headerName: 'PARÇA', width: 190 },
    { field: 'workSubCategoryName', headerName: 'ALT KATEGORİ', width: 190 },
    { field: 'status', headerName: 'DURUM', width: 190 },
    { field: 'offerCount', headerName: 'TEKLİF', width: 80 },
    { field: 'creationTime', headerName: 'OLUŞTURMA TARİHİ', width: 160 },
    { field: 'menu', headerName: 'AKSİYON' },
]

export const requestOfferTableHead: GridColDef[] = [
    { field: 'user', headerName: 'TEKLİFİ VEREN' },
    { field: 'amount', headerName: 'TEKLİF' },
    { field: 'status', headerName: 'DURUM' },
]

export const exportOptions = [
    {
        key: 'KULLANICI',
        label: 'KULLANICI',
        onRender: (item: string, element: RequestModel) => {
            return `${element?.user.fullName}`
        },
    },
    {
        key: 'MARKA',
        label: 'MARKA',
        onRender: (item: string, element: RequestModel) => {
            return `${element?.vehicleMakeName}`
        },
    },
    {
        key: 'MODEL',
        label: 'MODEL',
        onRender: (item: string, element: RequestModel) => {
            return `${element?.vehicleModelName}`
        },
    },
    {
        key: 'PARÇA',
        label: 'PARÇA',
        onRender: (item: string, element: RequestModel) => {
            return `${element?.workCategoryName}`
        },
    },
    {
        key: 'ALT KATEGORI',
        label: 'ALT KATEGORI',
        onRender: (item: string, element: RequestModel) => {
            return `${element?.workSubCategoryName}`
        },
    },
    {
        key: 'DURUM',
        label: 'DURUM',
        onRender: (item: string, element: RequestModel) => {
            return `${element?.status}`
        },
    },
    {
        key: 'TEKLIF',
        label: 'TEKLIF',
        onRender: (item: string, element: RequestModel) => {
            return `${element?.offerCount}`
        },
    },
]
