import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import { Box, Divider } from '@mui/material'
import { CheckCircle, Feed } from '@mui/icons-material'

import {
    AvatarImageDiv,
    BankAccountDiv,
    BankAcoountsDiv,
    CoArrayDiv,
    CustomArrayDiv,
    CustomBadge,
    CustomCardBox,
    CustomCardContainer,
    CustomCompanyFirstBox,
    CustomCompanyFourthBox,
    CustomCompanyProfileBox,
    CustomCompanyProfileContainer,
    CustomCompanyProfileTableDiv,
    CustomCompanySecondBox,
    CustomCompanyThirdBox,
    CustomDetailsBox,
    CustomDetailsDiv,
    CustomRoleDiv,
    RateDiv,
    RatePoint,
    TextAlignCenterDiv,
} from './index.styled'
import { useActiveUser, useDeactiveUser, useGetUserProfile, useGetUserProfileCount, useUserStatusVerify } from 'utils/hooks/queries/Users'
import { GridColDef } from '@mui/x-data-grid'
import Typography from 'components/Typography/Typography'
import { colors } from 'styles/color'
import { ptr } from 'utils/helpers'
import Button from 'components/Buttons/Button/Button'
import Modal from 'components/Modal/Modal'
import Avatar from 'components/Avatar/Avatar'
import UserDemands from './demands'
import { GetUserProfileCountModel, UserProfileModel } from 'services/be-api/users/types'
import { EUserRoles } from 'utils/constants'
import LinearProgress from 'components/LinearProgress/LinearProgress'
import { Conversation, Diamond, ShippingBox } from 'assets/Icons'

export const companiesHead: GridColDef[] = [
    { field: 'user', headerName: 'KULLANICI', width: 110 },
    { field: 'phone', headerName: 'TELEFON NUMARASI', width: 110 },
    { field: 'position', headerName: 'POSİTİON', width: 110 },
    { field: 'status', headerName: 'DURUM', width: 110 },
]
const DEFAULT_MAKES_LIMIT = 10
const DEFAULT_LIMIT = 2

const Profile = () => {
    const [userProfile, setUserProfile] = useState<UserProfileModel>({} as UserProfileModel)
    const [countUser, setCountUser] = useState<GetUserProfileCountModel>()
    const [open, setOpen] = useState(false)
    const { mutate: deactiveUser, isSuccess: isSuccessDeactive } = useDeactiveUser()
    const { mutate: activeUser, isSuccess: isSuccessActive } = useActiveUser()
    const { mutate: verifyUser, isSuccess: isSuccessVerify } = useUserStatusVerify()

    const [vehicleMakesLimit, setVehicleMakesLimit] = useState(DEFAULT_MAKES_LIMIT)
    const [vehicleTypesLimit, setVehicleTypesLimit] = useState(DEFAULT_LIMIT)
    const [workCategoryLimit, setWorkCategoryLimit] = useState(DEFAULT_LIMIT)
    const [bankAccountLimit, setBankAccountLimit] = useState(DEFAULT_LIMIT)

    const navigate = useNavigate()

    const { id } = useParams()

    const { data, isSuccess, refetch } = useGetUserProfile({
        queryKeys: {
            id: Number(id),
        },
    })

    const { data: countUserData, isSuccess: countUserSuccess } = useGetUserProfileCount({
        queryKeys: {
            id: Number(id),
        },
    })

    const handleToUpdate = (id: number) => {
        navigate(`/users/${id}/update`)
    }

    const handleToggle = () => {
        setOpen(!open)
    }
    const handleModalConfirm = (id: string) => {
        if (userProfile.isActive) {
            deactiveUser({ id: Number(id) })
        } else if (!userProfile.isActive) {
            activeUser({ id: Number(id) })
        }
        setOpen(!open)
    }
    const verifyUserHandler = (id: string) => {
        if (userProfile.status === 'Beklemede') verifyUser({ id: Number(id) })
    }

    useEffect(() => {
        if (isSuccess && data?.data.result) {
            refetch()
            setUserProfile(data.data.result)
        }
    }, [isSuccess, data?.data.result, userProfile.isActive, refetch, isSuccessDeactive, isSuccessActive, isSuccessVerify])

    useEffect(() => {
        if (countUserData && countUserSuccess) {
            setCountUser(countUserData.data.result)
        }
    }, [countUserData, countUserSuccess])

    return (
        <div>
            <CustomCompanyProfileContainer>
                <CustomCompanyProfileTableDiv>
                    <CustomCompanyProfileBox>
                        <CustomCompanyFirstBox>
                            {!userProfile.avatar ? (
                                userProfile.fullName && <Avatar name={userProfile?.fullName} width={120} height={120} fontSize={'18px'}></Avatar>
                            ) : (
                                <AvatarImageDiv style={{ backgroundImage: `url(${userProfile.avatar})` }} />
                            )}

                            <TextAlignCenterDiv>
                                <RateDiv>
                                    <Typography variant="h6-medium">{userProfile.fullName}</Typography>
                                    <RatePoint> {userProfile.userRateAvg}</RatePoint>
                                </RateDiv>
                                <Typography variant="body-normal-default">{userProfile.company?.name}</Typography>
                            </TextAlignCenterDiv>

                            {userProfile.role === EUserRoles.BOTH ? (
                                <CustomRoleDiv sx={{ gap: ptr(16) }}>
                                    <CustomRoleDiv color="green">
                                        <LocalOfferIcon sx={{ color: colors.success.successMain, fontSize: '1rem' }} />
                                        <Typography variant={'body-small-default'} color={colors.success.successMain}>
                                            Satıcı
                                        </Typography>
                                    </CustomRoleDiv>
                                    <CustomRoleDiv color="blue">
                                        {' '}
                                        {/* bu kısımdaki colorlar props olarak stylde değişiyor*/}
                                        <ShoppingBasketIcon sx={{ color: colors.info.infoMain, fontSize: '1rem' }} />
                                        <Typography variant={'body-small-default'} color={colors.info.infoMain}>
                                            Alıcı
                                        </Typography>
                                    </CustomRoleDiv>
                                </CustomRoleDiv>
                            ) : userProfile.role === EUserRoles.SELLER ? (
                                <CustomRoleDiv color="green">
                                    <LocalOfferIcon sx={{ color: colors.success.successMain, fontSize: '1rem' }} />
                                    <Typography variant={'body-small-default'} color={colors.success.successMain}>
                                        Satıcı
                                    </Typography>
                                </CustomRoleDiv>
                            ) : (
                                <CustomRoleDiv color="blue">
                                    <ShoppingBasketIcon sx={{ color: colors.info.infoMain, fontSize: '1rem' }} />
                                    <Typography variant={'body-small-default'} color={colors.info.infoMain}>
                                        Alıcı
                                    </Typography>
                                </CustomRoleDiv>
                            )}
                        </CustomCompanyFirstBox>
                        {userProfile.role !== EUserRoles.BUYER && (
                            <CustomCardContainer>
                                <CustomCardBox>
                                    <ShippingBox height={32} width={32} />
                                    <div style={{ width: '100%' }}>
                                        <LinearProgress variant="determinate" value={parseInt(userProfile.shipmentAvg!) * 10} />
                                    </div>
                                    <Typography>{userProfile.shipmentAvg}</Typography>
                                </CustomCardBox>
                                <CustomCardBox>
                                    <Diamond height={32} width={32} />
                                    <div style={{ width: '100%' }}>
                                        <LinearProgress variant="determinate" value={parseInt(userProfile.qualityAvg!) * 10} />
                                    </div>
                                    <Typography>{userProfile.qualityAvg}</Typography>
                                </CustomCardBox>
                                <CustomCardBox>
                                    <Conversation height={32} width={32} />
                                    <div style={{ width: '100%' }}>
                                        <LinearProgress variant="determinate" value={parseInt(userProfile.communicationAvg!) * 10} />
                                    </div>
                                    <Typography>{userProfile.communicationAvg}</Typography>
                                </CustomCardBox>
                            </CustomCardContainer>
                        )}
                        {userProfile.role === EUserRoles.BOTH ? (
                            <CustomCompanySecondBox>
                                <div>
                                    <UserDemands userCount={countUser?.offerCount} name={'Verilmiş Teklif'} icon={<Feed style={{ color: colors.white.greyBorder }} />} />
                                    <UserDemands userCount={countUser?.demandCount} name={'Açılmış Talep'} icon={<Feed style={{ color: colors.white.greyBorder }} />} />
                                </div>
                                <div>
                                    <UserDemands userCount={countUser?.soldCount} name={'Satılan Ürün'} icon={<CheckCircle style={{ color: colors.white.greyBorder }} />} />
                                    <UserDemands userCount={countUser?.boughtCount} name={'Alınan Ürün'} icon={<CheckCircle style={{ color: colors.white.greyBorder }} />} />
                                </div>
                            </CustomCompanySecondBox>
                        ) : userProfile.role === EUserRoles.SELLER ? (
                            <CustomCompanySecondBox>
                                <UserDemands userCount={countUser?.offerCount} name={'Verilmiş Teklif'} icon={<Feed style={{ color: colors.white.greyBorder }} />} />
                                <UserDemands userCount={countUser?.soldCount} name={'Yapılmış Satış'} icon={<CheckCircle style={{ color: colors.white.greyBorder }} />} />
                            </CustomCompanySecondBox>
                        ) : (
                            <CustomCompanySecondBox>
                                <UserDemands userCount={countUser?.demandCount} name={'Açılmış Talep'} icon={<Feed style={{ color: colors.white.greyBorder }} />} />
                                <UserDemands userCount={countUser?.boughtCount} name={'Yapılmış Alışveriş'} icon={<CheckCircle style={{ color: colors.white.greyBorder }} />} />
                            </CustomCompanySecondBox>
                        )}
                        <CustomCompanyThirdBox>
                            <CustomDetailsDiv>
                                <CustomDetailsBox sx={{ marginBottom: ptr(8) }}>
                                    <Typography variant="h6-medium">Detaylar</Typography>
                                </CustomDetailsBox>
                                <Divider orientation="horizontal"></Divider>
                                <CustomDetailsBox>
                                    <Typography color={colors.text.primaryTextLight} variant="body-small-medium">
                                        Telefon :
                                    </Typography>
                                    <Typography color={colors.text.secondaryTextLight} variant="body-small-default">
                                        {userProfile.phoneNumber?.replace(/(\d{2})(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5')}
                                    </Typography>
                                </CustomDetailsBox>
                                <CustomDetailsBox>
                                    <Typography color={colors.text.primaryTextLight} variant="body-small-medium">
                                        Email :
                                    </Typography>
                                    <Typography color={colors.text.secondaryTextLight} variant="body-small-default">
                                        {!userProfile.emailAddress?.includes('@cikmaburada.com') ? userProfile.emailAddress : ' -'}
                                    </Typography>
                                </CustomDetailsBox>{' '}
                                <CustomDetailsBox>
                                    <Typography color={colors.text.primaryTextLight} variant="body-small-medium">
                                        Pozisyon :
                                    </Typography>
                                    <Typography color={colors.text.secondaryTextLight} variant="body-small-default">
                                        {userProfile.position}
                                    </Typography>
                                </CustomDetailsBox>{' '}
                                <CustomDetailsBox>
                                    <Typography color={colors.text.primaryTextLight} variant="body-small-medium">
                                        Durum :
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            textAlign: 'center',
                                            gap: ptr(30),
                                        }}
                                    >
                                        <CustomBadge
                                            style={{
                                                backgroundColor: userProfile.status === 'Beklemede' ? '#fff3db' : userProfile.status === 'Aktif' ? '#dbf8c9' : '#E5EBEC',
                                                padding: '0px 10px',
                                                width: 'fit-content',
                                            }}
                                        >
                                            <Typography variant="body-small-default" color={userProfile.status === 'Beklemede' ? '#FDB528' : userProfile.status === 'Aktif' ? '#72E128' : '#5578AD'}>
                                                {userProfile.status}
                                            </Typography>
                                        </CustomBadge>
                                        {userProfile.status === 'Beklemede' && (
                                            <Button onClick={() => verifyUserHandler(id!)} size="small" color="success">
                                                Onayla
                                            </Button>
                                        )}
                                    </Box>
                                </CustomDetailsBox>{' '}
                                <CustomDetailsBox sx={{ margin: `${ptr(5)} 0` }}>
                                    <Typography variant="h6-medium">Çalışma Alanları</Typography>
                                </CustomDetailsBox>
                                <Divider orientation="horizontal"></Divider>
                                <CoArrayDiv>
                                    <CustomArrayDiv>
                                        <Typography color={colors.text.primaryTextLight} variant="body-small-medium">
                                            Çalıştığı Kategori
                                        </Typography>

                                        {userProfile.workCategories?.slice(0, workCategoryLimit).map((category: any, index: number) => (
                                            <Typography key={index} color={colors.text.secondaryTextLight} variant="body-small-default">
                                                {category.workCategoryName}
                                            </Typography>
                                        ))}
                                        {userProfile.workCategories?.length <= DEFAULT_LIMIT ? (
                                            ''
                                        ) : userProfile.workCategories?.length > workCategoryLimit ? (
                                            <Typography color={colors.secondary.secondaryMain} variant="body-normal-semibold" onClick={() => setWorkCategoryLimit(userProfile.workCategories?.length)}>
                                                Tümünü Göster
                                            </Typography>
                                        ) : (
                                            <Typography color={colors.secondary.secondaryMain} variant="body-normal-semibold" onClick={() => setWorkCategoryLimit(DEFAULT_LIMIT)}>
                                                Daha Az Göster
                                            </Typography>
                                        )}
                                    </CustomArrayDiv>
                                    <CustomArrayDiv>
                                        <Typography color={colors.text.primaryTextLight} variant="body-small-medium">
                                            Vasıta Tipi
                                        </Typography>

                                        {userProfile.vehicleTypes?.slice(0, vehicleTypesLimit).map((category: any, index: number) => (
                                            <Typography key={index} color={colors.text.secondaryTextLight} variant="body-small-default">
                                                {category.vehicleTypeName}
                                            </Typography>
                                        ))}
                                        {userProfile.vehicleTypes?.length <= DEFAULT_LIMIT ? (
                                            ''
                                        ) : userProfile.vehicleTypes?.length > vehicleTypesLimit ? (
                                            <Typography color={colors.secondary.secondaryMain} variant="body-normal-semibold" onClick={() => setVehicleTypesLimit(userProfile.vehicleTypes?.length)}>
                                                Tümünü Göster
                                            </Typography>
                                        ) : (
                                            <Typography color={colors.secondary.secondaryMain} variant="body-normal-semibold" onClick={() => setVehicleTypesLimit(DEFAULT_LIMIT)}>
                                                Daha Az Göster
                                            </Typography>
                                        )}
                                    </CustomArrayDiv>
                                </CoArrayDiv>
                                <Typography color={colors.text.primaryTextLight} variant="body-small-medium">
                                    Çalıştığı Markalar
                                </Typography>
                                <CustomArrayDiv>
                                    {userProfile.vehicleMakes?.slice(0, vehicleMakesLimit).map((category: any, index: number) => (
                                        <Typography key={index} color={colors.text.secondaryTextLight} variant="body-small-default">
                                            {category.vehicleMakeName}
                                        </Typography>
                                    ))}
                                    {userProfile.vehicleMakes?.length <= DEFAULT_MAKES_LIMIT ? (
                                        ''
                                    ) : userProfile.vehicleMakes?.length > vehicleMakesLimit ? (
                                        <Typography color={colors.secondary.secondaryMain} variant="body-normal-semibold" onClick={() => setVehicleMakesLimit(userProfile.vehicleMakes?.length)}>
                                            Tümünü Göster
                                        </Typography>
                                    ) : (
                                        <Typography color={colors.secondary.secondaryMain} variant="body-normal-semibold" onClick={() => setVehicleMakesLimit(DEFAULT_MAKES_LIMIT)}>
                                            Daha Az Göster
                                        </Typography>
                                    )}
                                </CustomArrayDiv>
                                {userProfile.role !== 'Alıcı' && userProfile.bankAccounts && (
                                    <div>
                                        <CustomDetailsBox sx={{ margin: `${ptr(5)} 0` }}>
                                            <Typography variant="h6-medium">Banka Bilgileri</Typography>
                                        </CustomDetailsBox>
                                        <Divider orientation="horizontal"></Divider>
                                        {userProfile.bankAccounts &&
                                            JSON.parse(userProfile.bankAccounts)
                                                ?.slice(0, bankAccountLimit)
                                                .map((account: any, index: number) => (
                                                    <BankAcoountsDiv key={index}>
                                                        <Typography width={ptr(40)} color={colors.text.primaryTextLight} variant="body-small-medium">
                                                            {account.bankName}
                                                        </Typography>
                                                        <BankAccountDiv>
                                                            <Typography color={colors.text.secondaryTextLight} variant="body-small-default">
                                                                {account.nameSurname}
                                                            </Typography>
                                                            <Typography color={colors.text.secondaryTextLight} variant="body-small-default">
                                                                {account.ibanNumber}
                                                            </Typography>
                                                        </BankAccountDiv>
                                                    </BankAcoountsDiv>
                                                ))}
                                        {userProfile.bankAccounts && JSON.parse(userProfile.bankAccounts)?.length <= DEFAULT_LIMIT ? (
                                            ''
                                        ) : JSON.parse(userProfile.bankAccounts)?.length > bankAccountLimit ? (
                                            <Typography color={colors.secondary.secondaryMain} variant="body-normal-semibold" onClick={() => setBankAccountLimit(userProfile.bankAccounts?.length)}>
                                                Tümünü Göster
                                            </Typography>
                                        ) : (
                                            <Typography color={colors.secondary.secondaryMain} variant="body-normal-semibold" onClick={() => setBankAccountLimit(DEFAULT_LIMIT)}>
                                                Daha Az Göster
                                            </Typography>
                                        )}
                                    </div>
                                )}
                                <CustomDetailsBox sx={{ margin: `${ptr(5)} 0` }}>
                                    <Typography variant="h6-medium">Referanslar</Typography>
                                </CustomDetailsBox>
                                <Divider orientation="horizontal"></Divider>
                                <Typography color={colors.text.secondaryTextLight} variant="body-small-default">
                                    {userProfile.reference}
                                </Typography>
                            </CustomDetailsDiv>
                        </CustomCompanyThirdBox>
                        <CustomCompanyFourthBox>
                            {userProfile.isActive && <Button onClick={() => handleToUpdate(Number(id))}>DÜZENLE</Button>}
                            {userProfile.isActive ? (
                                <Button onClick={() => handleToggle()} variant="outlined" colorsx={colors.error.errorMain} borderColor={colors.error.errorMain}>
                                    ASKIYA AL
                                </Button>
                            ) : (
                                <Button onClick={() => handleToggle()} color="success">
                                    AKTIVE ET
                                </Button>
                            )}
                        </CustomCompanyFourthBox>
                    </CustomCompanyProfileBox>
                </CustomCompanyProfileTableDiv>
                <Modal
                    open={open}
                    onClose={() => handleToggle()}
                    onConfirm={() => handleModalConfirm(id!)}
                    header={userProfile.isActive ? 'Kullanıcıyı Askıya Almak Üzeresiniz!' : 'Kullanıcıyı Aktif Etmek Üzeresiniz'}
                    subheader={
                        userProfile.isActive
                            ? 'Kullanıcıyı askıya aldığınızda kullanıcıyı sistemden atmış oluyorsunuz. Yine de işleme devam etmek istiyor musunuz?'
                            : 'Kullanıcıyı aktive etmek istiyor musunuz?'
                    }
                ></Modal>
            </CustomCompanyProfileContainer>
        </div>
    )
}

export default Profile
