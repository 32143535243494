import React from 'react'
import { Div1, Div2, NoDataPictures, NoDataPicturesWithText, Picture, Picture2 } from './index.styled'
import Typography from 'components/Typography/Typography'

import ShoppingBagPNG from '../../../assets/Images/Dashboard/shoppingBag.png'
import ShoppingCartPNG from '../../../assets/Images/Dashboard/shoppingCart.png'
import { colors } from 'styles/color'
const NoDataMessage = () => {
    return (
        <NoDataPicturesWithText>
            <NoDataPictures>
                <Div1>
                    <Picture src={ShoppingBagPNG} alt="Alışveriş Çantası" />
                </Div1>
                <Div2>
                    <Picture2 src={ShoppingCartPNG} alt="Alışveriş Sepeti" />
                </Div2>
            </NoDataPictures>
            <Typography color={colors.text.disabledTextLight}>Veri Bulunamadı</Typography>
        </NoDataPicturesWithText>
    )
}

export default NoDataMessage
