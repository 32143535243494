import React, { FC } from 'react'
import { ModalProps } from './types'
import { OrderStatusFilter } from '../../../services/be-api/orders/types'
import Typography from '../../../components/Typography/Typography'
import { colors } from '../../../styles/color'
import { Box } from '@mui/material'

const Status: FC<ModalProps> = ({ status }) => {
    switch (status) {
        case OrderStatusFilter.PENDING:
            return (
                <Typography>
                    Siparişin durumu <Typography color={colors.primary.primaryMain}>Hazırlanıyor</Typography> olarak güncellenecektir.
                </Typography>
            )
        case OrderStatusFilter.INPREPARATIO:
            return (
                <Typography>
                    Siparişin durumu <Typography color={colors.primary.primaryMain}>Gönderildi</Typography> olarak güncellenecektir.
                </Typography>
            )
        case OrderStatusFilter.SHIPPING:
            return (
                <Typography>
                    Siparişin durumu <Typography color={colors.primary.primaryMain}>Teslim Edildi</Typography> olarak güncellenecektir.
                </Typography>
            )
        case OrderStatusFilter.CANCELED:
            return (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography color={colors.text.secondaryTextLight}>
                        Siparişin durumu <span style={{ color: colors.primary.primaryMain }}>İptal Edildi</span> olarak güncellenecektir.
                    </Typography>
                    <Typography color={colors.text.secondaryTextLight}>Bu işlem geri alınamaz ve üzerinde değişiklik yapılamaz.</Typography>
                </Box>
            )
        default:
            return <></>
    }
}

export default Status
