import { GridColDef } from '@mui/x-data-grid'
import { ECampaingStatus, ESortingStatus } from './index.interface'
import { SpecialOffer } from '../../services/be-api/products/types'

export const productTableHead: GridColDef[] = [
    { field: 'code', headerName: 'ÜRÜN KODU', width: 140 },
    { field: 'name', headerName: 'ÜRÜN İSMİ', width: 140 },
    { field: 'information', headerName: 'ÜRÜN AÇIKLAMASI', width: 200 },
    { field: 'price', headerName: 'LİSTE FİYATI', width: 100 },
    { field: 'campaignPrice', headerName: 'İNDİRİMLİ FİYATI', width: 100 },
    { field: 'stock', headerName: 'STOK', width: 100 },
    { field: 'actions', headerName: 'AKSİYON', width: 80 },
]

export const productSubTableHead: GridColDef[] = [
    { field: 'date', headerName: 'TARİH', width: 140 },
    { field: 'user', headerName: 'ALICI', width: 140 },
    { field: 'amount', headerName: 'ADET', width: 200 },
    { field: 'status', headerName: 'DURUM', width: 100 },
]

export const campaignStatusOptions = Object.entries(ECampaingStatus).map((status) => ({ label: status[1], value: status[0] }))

export const campaingSortingOptions = Object.entries(ESortingStatus).map((status) => ({ label: status[1], value: status[0] }))

export const productExportOptions = [
    {
        key: 'URUN KODU',
        label: 'URUN KODU',
        onRender: (item: string, element: SpecialOffer) => {
            return element.productCode
        },
    },
    {
        key: 'URUN ISMI',
        label: 'URUN ISMI',
        onRender: (item: string, element: SpecialOffer) => {
            return element.productName
        },
    },
    {
        key: 'URUN ACIKLAMASI',
        label: 'URUN ACIKLAMASI',
        onRender: (item: string, element: SpecialOffer) => {
            return element.productDescription
        },
    },
    {
        key: 'LISTE FIYATI',
        label: 'LISTE FIYATI',
        onRender: (item: string, element: SpecialOffer) => {
            return `${element.productPrice} TL`
        },
    },
    {
        key: 'INDIRIMLI FIYATI',
        label: 'INDIRIMLI FIYATI',
        onRender: (item: string, element: SpecialOffer) => {
            return `${element.productDiscountedPrice} TL`
        },
    },
    {
        key: 'STOK',
        label: 'STOK',
        onRender: (item: string, element: SpecialOffer) => {
            return element.productStock
        },
    },
]
