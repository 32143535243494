import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'

import { Divider } from '@mui/material'
import AddBoxIcon from '@mui/icons-material/AddBox'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'

import TextField from 'components/Input/TextField/TextField'
import Button from 'components/Buttons/Button/Button'
import { ButtonsSide, CoInputContent, CustomInputMask, ErrorMessage, ErrorMessageControler, FormContainer } from '../index.styled'
import { BankSection } from './index.styled'
import CheckboxCard from './CheckboxCard'
import Spinner from 'components/Spinner'

import { CompanyModel } from 'services/be-api/companies/types'
import { colors } from 'styles/color'
import { BankAccountModel, UserInfoFormValues, UserInfoUpdateProps } from './type'
import AutocompleteContainer from 'components/Input/AutoComplete/Autocomplete'
import { ValueOf } from 'models/common/types/ValueOf'

const UserInfo: React.FunctionComponent<UserInfoUpdateProps> = ({
    radioValue,
    setRadioValue,
    value,
    onChange,
    handleNext,
    handleBack,
    companiesData,
    companiesSuccess,
    setSelectedId,
    companiesLoading,
}) => {
    const [companies, setCompanies] = useState<CompanyModel[]>([])
    const [companyOptions, setCompanyOptions] = useState<{ value: number; label: string }[]>([])

    useEffect(() => {
        if (companiesSuccess && companiesData?.data.result) setCompanies(companiesData?.data.result.companies)
    }, [companiesSuccess, companiesData])

    useEffect(() => {
        setCompanyOptions(companies.map((company) => ({ value: company.id, label: company.name })))
    }, [companies])

    const validationSchema = Yup.object({
        role: Yup.string().required('Bu alan zorunludur.'),
        name: Yup.string().required('Bu alan zorunludur.'),
        fullName: Yup.string()
            .required('Bu alan zorunludur.')
            .max(100, 'Maksimum 100 karakter olabilir')
            .matches(/^(?!\s)/, 'Kullanıcı İsim Soyisim alanında başta boşluk olamaz.')
            .matches(/(\S+\s)+/, 'Kullanıcı İsim Soyisim alanında en az 2 kelime olmalı.')
            .matches(/(\S+\s){1}/, 'Kullanıcı İsim Soyisim alanında yazılar arasında sadece 1 boşluk olmalı.')
            .matches(/\S+$/, 'Kullanıcı İsim Soyisim alanında sonda boşluk olmamalıdır.'), // /^(?!\s)(\S+)(?:\s\S+)+$/ çoklu regex çalışmazsa (gözümden bir şey kaçtıysa) çalışan alternatif regex
        phoneNumber: Yup.string()
            .required('Bu alan zorunludur.')
            .test('is-valid-phoneNumber', 'Geçersiz telefon numarası', (value) => {
                const lengthOfNumbers = value.replace(/_/g, '').length

                return lengthOfNumbers === 17 || lengthOfNumbers === 13
            }),
        emailAddress: Yup.string().nullable(),
        reference: Yup.string().required('Bu alan zorunludur.').max(255, 'Maksimum 255 karakter girebilirsiniz'),
        nameSurname: Yup.string(),
        ibanNumber: Yup.string().max(26, '26 hane girilmesi zorunludur').min(26, '26 hane girilmesi zorunludur'),
        bankName: Yup.string(),
    })

    const onSubmit = (values: UserInfoFormValues) => {
        if (radioValue !== 'Alıcı') onChange({ ...values, bankAccounts: value.bankAccounts })
        else onChange({ ...values, bankAccounts: [{ bankName: '', ibanNumber: '', nameSurname: '' }] })

        handleNext()
    }

    const addBankAccount = () => {
        let existBankAccounts = value.bankAccounts
        existBankAccounts = [...existBankAccounts, { bankName: '', ibanNumber: '', nameSurname: '' }]
        const newValue: typeof value = { ...value, bankAccounts: existBankAccounts }
        onChange(newValue)
    }

    const onDeleteBankAccount = (index: number) => {
        let existBankAccounts = value.bankAccounts
        existBankAccounts = existBankAccounts.filter((_child, i) => i !== index)

        const newValue: typeof value = { ...value, bankAccounts: existBankAccounts }

        onChange(newValue)
    }

    const onChangeBankAccountValue = (index: number, key: keyof BankAccountModel, val: ValueOf<BankAccountModel>) => {
        let existBankAccounts = value.bankAccounts
        existBankAccounts[index][key] = val
        const newValue: typeof value = { ...value, bankAccounts: existBankAccounts }

        onChange(newValue)
    }
    const isEnabledNewBankAccount = () => (value.bankAccounts.length > 0 ? Object.values(value.bankAccounts.at(-1)!).every((child) => child) : false)

    const isBankAccountEmptyOrFull = () => {
        if ((value.role && value.role === 'Satıcı') || value.role === 'Satıcı & Alıcı') {
            return value.bankAccounts.length > 0 && checkProperties(value.bankAccounts).every((child) => child === false)
        } else {
            return true
        }
    }

    const ibanIsValid = () => {
        if ((value.role && value.role === 'Satıcı') || value.role === 'Satıcı & Alıcı') {
            if (value.bankAccounts.length === 1) {
                return value.bankAccounts[0].ibanNumber?.replaceAll(' ', '').includes('_')
            } else {
                return value.bankAccounts.some((child) => child.ibanNumber?.replaceAll(' ', '').includes('_'))
            }
        } else {
            return false
        }
    }

    function checkProperties(array: BankAccountModel[]) {
        return array.map((item) => {
            if (item.bankName === '' && item.ibanNumber === '' && item.nameSurname === '') {
                return false
            }
            if (item.bankName !== '' && item.ibanNumber !== '' && item.nameSurname !== '') {
                return false
            }
            return true
        })
    }

    return (
        <Formik initialValues={value} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize validateOnBlur>
            {({ isSubmitting, values, handleChange, handleSubmit, isValid, handleBlur }) => (
                <Form>
                    <Spinner open={companiesLoading} />
                    <FormContainer>
                        <CheckboxCard radioValue={radioValue} setRadioValue={setRadioValue} handleChange={handleChange} val={values?.role} />
                        <CoInputContent>
                            <ErrorMessageControler>
                                <AutocompleteContainer
                                    width="100%"
                                    size="medium"
                                    selectedValue={values.name}
                                    name="name"
                                    options={companyOptions}
                                    onChange={(id, changeEvent) => {
                                        handleChange(changeEvent)
                                        setSelectedId(Number(id ? id : companies.map((c) => c.id)))
                                    }}
                                ></AutocompleteContainer>

                                <ErrorMessage name="name" component={'div'} />
                            </ErrorMessageControler>

                            <ErrorMessageControler>
                                <TextField
                                    name="fullName"
                                    placeholder="Kullanıcı İsim Soyisim"
                                    onChange={(e, changeEvent) => handleChange(changeEvent)}
                                    value={values?.fullName}
                                    onBlur={handleBlur}
                                    expand
                                />
                                <ErrorMessage name="fullName" component={'div'} />
                            </ErrorMessageControler>
                        </CoInputContent>

                        <CoInputContent>
                            <ErrorMessageControler>
                                <CustomInputMask
                                    name="phoneNumber"
                                    mask="+\90 999 999 99 99"
                                    placeholder="Telefon No"
                                    onChange={(changeEvent) => handleChange(changeEvent)}
                                    value={values?.phoneNumber}
                                    onBlur={handleBlur}
                                />
                                <ErrorMessage name="phoneNumber" component={'div'} />
                            </ErrorMessageControler>

                            <ErrorMessageControler>
                                <TextField
                                    name="emailAddress"
                                    type="email"
                                    placeholder="Email (Opsiyonel)"
                                    onChange={(e, changeEvent) => handleChange(changeEvent)}
                                    value={values?.emailAddress}
                                    onBlur={handleBlur}
                                    expand
                                />
                                <ErrorMessage name="emailAddress" component={'div'} />
                            </ErrorMessageControler>
                        </CoInputContent>

                        <ErrorMessageControler>
                            <TextField
                                multiline
                                rows={2}
                                name="reference"
                                type="text"
                                placeholder="Referanslar"
                                onChange={(e, changeEvent) => handleChange(changeEvent)}
                                value={values?.reference}
                                onBlur={handleBlur}
                                expand
                            />
                            <ErrorMessage name="reference" component={'div'} />
                        </ErrorMessageControler>
                    </FormContainer>

                    {radioValue !== 'Alıcı' && (
                        <BankSection>
                            <Divider sx={{ marginTop: '20px' }}></Divider>
                            {value?.bankAccounts?.map((bank, index) => (
                                <div key={index}>
                                    <CoInputContent sx={{ marginBottom: '20px' }}>
                                        <ErrorMessageControler>
                                            <TextField
                                                name="nameSurname"
                                                placeholder="Adı Soyadı"
                                                type="text"
                                                onChange={(e, changeEvent) => onChangeBankAccountValue(index, 'nameSurname', e as string)}
                                                value={bank.nameSurname}
                                                inputProps={{ maxLength: 64 }}
                                                width="100%"
                                            />
                                            <ErrorMessage name="nameSurname" component={'div'} />
                                        </ErrorMessageControler>
                                        <ErrorMessageControler>
                                            <CustomInputMask
                                                name="ibanNumber"
                                                placeholder="IBAN Numarası"
                                                mask="\TR99 9999 9999 9999 9999 9999 99"
                                                onChange={(e) => onChangeBankAccountValue(index, 'ibanNumber', e.target.value as string)}
                                                value={bank.ibanNumber}
                                                width="100%"
                                                onBlur={handleBlur}
                                            />
                                            <ErrorMessage name="ibanNumber" component={'div'} />
                                        </ErrorMessageControler>
                                    </CoInputContent>
                                    <CoInputContent sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                                        <TextField
                                            name="bankName"
                                            type="text"
                                            placeholder="Banka Adı"
                                            onChange={(e, changeEvent) => onChangeBankAccountValue(index, 'bankName', e as string)}
                                            value={bank.bankName}
                                            inputProps={{ maxLength: 64 }}
                                            width="50%"
                                        />
                                        {value.bankAccounts.length - 1 === index ? (
                                            <Button disabled={!isEnabledNewBankAccount()} width="211px" color="secondary" startIcon={<AddBoxIcon />} variant="outlined" onClick={addBankAccount}>
                                                BAŞKA BANKA EKLE{' '}
                                            </Button>
                                        ) : (
                                            <DeleteOutlinedIcon sx={{ color: colors.error.errorAlternativeLight }} onClick={() => onDeleteBankAccount(index)} />
                                        )}
                                    </CoInputContent>
                                </div>
                            ))}
                            <div>
                                {value.bankAccounts.length === 0 && (
                                    <Button width="211px" color="secondary" startIcon={<AddBoxIcon />} variant="outlined" onClick={addBankAccount}>
                                        BAŞKA BANKA EKLE{' '}
                                    </Button>
                                )}
                            </div>
                        </BankSection>
                    )}
                    <ButtonsSide>
                        <Button id="next" onClick={handleSubmit} disabled={!isBankAccountEmptyOrFull() || ibanIsValid()}>
                            İleri
                        </Button>
                        <Button variant="outlined" color="secondary" onClick={handleBack}>
                            İptal
                        </Button>
                    </ButtonsSide>
                </Form>
            )}
        </Formik>
    )
}

export default UserInfo
