import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import DashboardLayout from '../../../layouts/Dashboard/DashboardLayout'
import {
    RemoveRequestButton,
    RequestDetailContainer,
    RequestDetailInformation,
    RequestDetailOfferSearch,
    RequestDetailTableContainer,
    RequestDetailTableFilter,
    RequestDetailTableHeader,
} from './index.styled'
import Typography from '../../../components/Typography/Typography'
import { Box, ImageList, ImageListItem } from '@mui/material'
import { CancelPresentation, ChevronRight, HistoryToggleOff, HourglassEmpty } from '@mui/icons-material'
import { getKey, getValue, handleFilter, ptr } from '../../../utils/helpers'
import { colors } from '../../../styles/color'
import React, { useEffect, useState } from 'react'
import AutocompleteContainer from '../../../components/Input/AutoComplete/Autocomplete'
import { EOfferStatus, offerStatusOptions, requestDetailTable } from './contstant'
import Table from '../../../components/Table'
import { TableBodyRowData } from '../../../components/Table/types'
import { useAdminCancelOffer, useOffersByRequestId, useRequestDetail, useWithdrawRequest } from '../../../utils/hooks/queries/Requests/useRequest'
import { useNavigate, useParams } from 'react-router-dom'
import { RequestDetailResponse, RequestOffer } from '../../../services/be-api/requests/types'
import Spinner from 'components/Spinner'
import List from '../../../components/List/List'
import Modal from '../../../components/Modal/Modal'
import TextField from '../../../components/Input/TextField/TextField'
import Tooltip from '../../../components/Tooltip/Tooltip'
import { CancelOffer } from '../types'
import dayjs from 'dayjs'
import NotFound from 'pages/notfound'
import { FormProps } from './types'
import { ErrorMessage } from 'pages/companies/AddCompanies/AddCompany.styled'
import PriceText from '../../../components/PriceText'

const RequestDetail = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const [currentPage, setCurrentPage] = useState(0)
    const [rowPerPage, setRowPerPage] = useState(10)
    const [filterText, setFilterText] = useState<string>('')
    const [offerStatus, setOfferStatus] = useState<string>('')
    const [offers, setOffers] = useState<RequestOffer[]>([])
    const [detail, setDetail] = useState<RequestDetailResponse | null>(null)
    const [isWitdrawRequest, setIsWithdrawRequest] = useState<boolean>(false)
    const [isCancelOffer, setIsCancelOffer] = useState<boolean>(false)
    const [cancelOfferData, setCancelOfferData] = useState<CancelOffer>({} as CancelOffer)
    const { data: requestDetail, isLoading: isLoadingRequestDetail, isError, refetch: refetchRequestDetail } = useRequestDetail({ queryKeys: { id } })
    const {
        data: offersData,
        isLoading: isLoadingOfferData,
        refetch,
    } = useOffersByRequestId({
        queryKeys: {
            id,
            Filter: filterText || null,
            Status: getKey(EOfferStatus, offerStatus) || null,
        },
    })

    const { mutate: withdrawRequest, isSuccess: isSuccessWithdrawRequest } = useWithdrawRequest()
    const { mutate: adminCancelOffer, isSuccess: adminCancelOfferSuccess } = useAdminCancelOffer()

    useEffect(() => {
        if (offersData?.data.result) {
            setOffers(offersData.data.result.offers)
        }
    }, [offersData?.data.result])

    useEffect(() => {
        if (requestDetail?.data.result) {
            setDetail(requestDetail.data.result)
        }
    }, [requestDetail])

    useEffect(() => {
        refetch()
    }, [adminCancelOfferSuccess, refetch])

    useEffect(() => {
        refetchRequestDetail()
    }, [isSuccessWithdrawRequest, refetchRequestDetail])

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowPerPage(parseInt(event.target.value, 10))
        setCurrentPage(0)
    }

    const onPageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setCurrentPage(newPage)
    }

    const handleTo = (mainPath: string, id: number) => navigate(`/${mainPath}/${id}`)

    const initialValues: FormProps = {
        rejectReason: '',
    }

    const validationSchema = Yup.object({
        rejectReason: Yup.string().required('Bu alan zorunludur.').max(255, 'Maksimum 255 karakter olabilir'),
    })
    const onSubmit = (value: { rejectReason: string }) => {
        withdrawRequest({ rejectReason: value.rejectReason, id: Number(id) })
        setIsWithdrawRequest(false)
    }
    // const filteredRequestDetailTable = (isUnpublished: boolean | undefined) => {
    //     if (isUnpublished) return requestDetailTable.filter((element) => element.field !== 'actions')
    //     else return requestDetailTable
    // }  // menü kısmını kaldırmak için

    const rowData: TableBodyRowData[] = offers.map((offer) => {
        return {
            user: (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="body-normal-semibold" onClick={() => handleTo('users', offer.user.id)}>
                        {offer.user.fullName}
                    </Typography>
                    <Typography color={colors.text.secondaryTextLight} variant="body-normal-default" onClick={() => handleTo('companies', offer.company.id)}>
                        {offer.company.name}
                    </Typography>
                </Box>
            ),
            status: (
                <Tooltip name={offer.rejectReason}>
                    <Typography>{offer.status}</Typography>
                </Tooltip>
            ),
            part: <Typography>{detail?.workCategoryName}</Typography>,
            partCategory: <Typography>{offer.condition}</Typography>,
            offer: (
                <Typography>
                    <PriceText price={offer.amount} />
                </Typography>
            ),
            creationTime: <Typography variant="body-small-default">{dayjs(offer.creationTime).format('YYYY-MM-DD HH:mm')}</Typography>,

            menu: (
                <List
                    listDetails={[
                        {
                            name: 'Teklifi Iptal Et',
                            icon: <CancelPresentation style={{ color: colors.error.errorMain }} />,
                            onClick: () => {
                                setCancelOfferData((prevState) => ({ ...prevState, id: offer.id }))
                                setIsCancelOffer(true)
                            },
                            disabled: !!offer.rejectReason || !detail?.isPublished,
                        },
                    ]}
                    width="230px"
                    minWidth="inherit"
                    gap="10px"
                    listPadding="15px"
                    itemButtonPadding="0px"
                    ListContainerPadding="0px 10px"
                    ListTextPadding="5px 0px"
                    backgroundColor={colors.white.default}
                    boxShadow="0px 5px 5px -3px #4C4E6424, 0px 8px 10px 1px #4C4E6424, 0px 3px 14px 2px #4C4E6433"
                    isTableActions={true}
                />
            ),
        }
    })
    return (
        <DashboardLayout>
            <div style={{ minHeight: '100vh' }}>
                <Spinner open={isLoadingRequestDetail} />
                {!isLoadingRequestDetail && !isError && (
                    <RequestDetailContainer>
                        <RequestDetailInformation>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: ptr(12) }}>
                                    <Box sx={{ display: 'flex', gap: ptr(8) }}>
                                        <Typography color={colors.text.secondaryTextLight}>Talepler /</Typography>
                                        <Typography size={20} color={colors.text.primaryTextLight}>
                                            Talepler Detayı
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', gap: ptr(10), alignItems: 'center' }}>
                                        <HistoryToggleOff fontSize={'small'} sx={{ color: colors.primary.primaryMain }} />
                                        <Typography color={colors.text.primaryTextLight}>Bu parça {detail?.status.toLowerCase()}.</Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                    <Typography color={colors.text.primaryTextLight} size={14}>
                                        {detail?.isPublished ? 'Kalan Süre' : 'Oluşturulma Zamanı'}
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            color: colors.text.disabledTextLight,
                                            fontSize: ptr(14),
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        {detail?.isPublished ? (
                                            <>
                                                <HourglassEmpty fontSize={'small'} /> {detail?.timeLeft} Saat
                                            </>
                                        ) : (
                                            dayjs(detail?.creationTime).format('YYYY-MM-DD HH:mm')
                                        )}
                                    </Box>
                                </Box>
                            </Box>

                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: ptr(8) }}>
                                    <Box sx={{ display: 'flex', gap: ptr(12) }}>
                                        <Typography size={14} color={colors.text.primaryTextLight}>
                                            Şu araca ait:
                                        </Typography>
                                        <Box sx={{ display: 'flex', color: colors.primary.primaryMain, borderBottom: `1px solid ${colors.primary.primaryMain}` }}>
                                            {detail?.vehicleMakeName}
                                            <ChevronRight sx={{ color: colors.action.actionActiveLight }} />
                                            {detail?.vehicleModelName}
                                            <ChevronRight sx={{ color: colors.action.actionActiveLight }} />
                                            {detail?.year}
                                        </Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', color: colors.action.actionActiveLight }}>
                                        {detail?.vehicleTypeName}
                                        <ChevronRight sx={{ color: colors.text.primaryTextLight }} />
                                        {detail?.workCategoryName}
                                        <ChevronRight sx={{ color: colors.text.primaryTextLight }} />
                                        {detail?.workSubCategoryName}
                                    </Box>
                                </Box>
                                <Box sx={{ display: 'flex', gap: ptr(8) }}>
                                    <Typography size={14} color={colors.text.primaryTextLight}>
                                        Şase Numarası:
                                    </Typography>
                                    <Typography size={12} color={colors.text.primaryTextLight}>
                                        {detail?.vin}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box sx={{ wordBreak: 'break-word', display: 'flex', flexDirection: 'column', gap: ptr(10) }}>
                                <Typography size={20} color={colors.text.primaryTextLight} weight={500}>
                                    Detay
                                </Typography>
                                <Typography size={14}>{detail?.description}</Typography>

                                {detail?.photos && (
                                    <ImageList cols={5} gap={12}>
                                        {detail.photos.map((photo) => (
                                            <ImageListItem>
                                                <img src={photo.photoUrl} alt={photo.photoUrl} style={{ borderRadius: ptr(6) }} loading="lazy" width={ptr(200)} height={ptr(180)} />
                                            </ImageListItem>
                                        ))}
                                    </ImageList>
                                )}
                                <RemoveRequestButton
                                    variant="outlined"
                                    onClick={() => {
                                        setIsWithdrawRequest(true)
                                    }}
                                    disabled={!detail?.isPublished}
                                >
                                    Talebi Yayından Kaldır
                                </RemoveRequestButton>
                            </Box>
                        </RequestDetailInformation>
                        <RequestDetailTableContainer>
                            <RequestDetailTableHeader>
                                <Typography size={20} weight={500}>
                                    Gelen Teklifler
                                </Typography>
                                <RequestDetailTableFilter>
                                    <RequestDetailOfferSearch onChange={(e) => handleFilter(e, setFilterText)} defaultValue={filterText} placeholder="Teklif Ara" />
                                    <AutocompleteContainer
                                        width="100%"
                                        placeholder="Teklif Durumu"
                                        onChange={(e) => setOfferStatus(getValue(EOfferStatus, e as string))}
                                        options={offerStatusOptions}
                                        selectedValue={offerStatus}
                                    />
                                </RequestDetailTableFilter>
                            </RequestDetailTableHeader>
                            <Table
                                count={1}
                                isLoading={false}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                onPageChange={onPageChange}
                                head={requestDetailTable}
                                result={rowPerPage}
                                rowsData={rowData}
                                isClickable={false}
                                page={currentPage}
                                menu={true}
                            />
                        </RequestDetailTableContainer>
                        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} validateOnBlur enableReinitialize>
                            {({ isSubmitting, values, handleChange, handleSubmit, isValid, handleBlur }) => (
                                <Modal
                                    open={isWitdrawRequest}
                                    onClose={() => setIsWithdrawRequest(false)}
                                    onConfirm={() => {
                                        handleSubmit()
                                    }}
                                    header="Talebi Yayından Kaldırmak Üzeresiniz!"
                                    subheader={``}
                                    disabled={isSubmitting || !isValid || values.rejectReason === ''}
                                >
                                    <Form style={{ width: '100%' }}>
                                        <TextField
                                            name="rejectReason"
                                            multiline={true}
                                            onBlur={handleBlur}
                                            value={values.rejectReason}
                                            width="100%"
                                            placeholder="Lütfen talebi yayından kaldırma sebebinizi açıklayın..."
                                            rows={5}
                                            onChange={(e, changeEvent) => handleChange(changeEvent)}
                                            expand
                                        />
                                        <ErrorMessage name="rejectReason" component={'div'} />
                                    </Form>
                                </Modal>
                            )}
                        </Formik>
                        <Modal
                            open={isCancelOffer}
                            onClose={() => setIsCancelOffer(false)}
                            onConfirm={() => {
                                adminCancelOffer(cancelOfferData)
                                setIsCancelOffer(false)
                                setCancelOfferData({} as CancelOffer)
                            }}
                            header="Teklifi İptal Etmek Üzeresiniz!"
                            subheader={``}
                            disabled={!cancelOfferData?.rejectReason}
                        >
                            <TextField
                                multiline={true}
                                width="100%"
                                placeholder="Lütfen teklifi iptal etme sebebinizi açıklayın...."
                                rows={5}
                                onChange={(e) => setCancelOfferData((prevState) => ({ ...prevState, rejectReason: e as string, id: cancelOfferData.id }))}
                            />
                        </Modal>

                        <Spinner open={isLoadingOfferData || isLoadingRequestDetail} />
                    </RequestDetailContainer>
                )}
                {!isLoadingRequestDetail && isError && <NotFound item={'Talep'} />}
            </div>
        </DashboardLayout>
    )
}

export default RequestDetail
