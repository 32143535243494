import React from 'react'
import { CustomBreadcrumbs, CustomLink } from './Breadcrumbs.styled'
import { BreadcrumbsProps, LinkProps } from './types'
import { useNavigate } from 'react-router-dom'

export const BreadcrumbsLink: React.FunctionComponent<LinkProps> = ({ icon, text, url, navigate, isLast }) => {
    return (
        <CustomLink underline={isLast ? 'none' : 'hover'} sx={{ cursor: isLast ? 'initial' : 'pointer' }} onClick={() => !isLast && navigate(`/${url}`)}>
            {icon} {text}
        </CustomLink>
    )
}

export const Breadcrumbs: React.FunctionComponent<BreadcrumbsProps> = ({ links }) => {
    const navigate = useNavigate()

    return (
        <CustomBreadcrumbs>
            {links.map((link: LinkProps, index) => (
                <BreadcrumbsLink isLast={index === links.length - 1} key={link.url} text={link.text} icon={link.icon} url={link.url} navigate={navigate} />
            ))}
        </CustomBreadcrumbs>
    )
}
