import styled from '@emotion/styled'

export const DashboardContainer = styled('div')({
    fontFamily: 'Inter',
    display: 'flex',
})
export const DashboardMain = styled('div')({
    width: '100%',
})
export const DashboardContent = styled('div')({
    marginLeft: '260px',
})
