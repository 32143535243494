import { styled } from '@mui/material'
import { ptr } from 'utils/helpers'
import { colors } from 'styles/color'

export const CustomCompanyProileContainer = styled('div')({
    height: '100vh',
    marginTop: ptr(44),
    marginRight: ptr(24),
})

export const StepperContainer = styled('div')((props) => ({
    backgroundColor: colors.white.default,
    display: 'flex',
    padding: '24px',
    margin: '24px',
    boxShadow: '0px 2px 10px rgba(76, 78, 100, 0.22)',
    gap: '1.5rem',
    width: '100%',
}))
export const StepperForm = styled('div')((props) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
}))
export const StepperContent = styled('div')((props) => ({}))
export const FormContainer = styled('div')((props) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
}))
export const CoInputContent = styled('div')((props) => ({
    display: 'flex',
    alignItems: 'flex-start',
    gap: '20px',
}))

export const CoManagerNameContent = styled('div')((props) => ({
    display: 'flex',
    alignItems: 'flex-start',
    width: '50%',
    padding: '0px 10px 0px 0px',
}))
export const ErrorMessageControler = styled('div')((props) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
}))
export const ButtonsSide = styled('div')((props) => ({
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    maxWidth: '100%',
    marginTop: '1rem',
}))
