export const GET_CURRENT_USER = 'services/app/Session/GetCurrentLoginInformations'
export const GET_USER_PROFILE = (id: number) => `users/${id}/profile`
export const GET_USERS_ALL = 'admin/users'
export const GET_USER_PROFILE_COUNT = (id: number) => `/admin/users/${id}/with-counts`
export const POST_CURRENT_USER = 'admin/users'
export const UPDATE_USER_ROLE = (id: number) => `admin/users/${id}/buyer-status`
export const PUT_DEACTIVATE_USER = (id: number) => `/admin/users/${id}/de-activate`
export const PUT_ACTIVE_USER = (id: number) => `/admin/users/${id}/activate`
export const PUT_UPDATE_USER = '/admin/users/'
export const GET_USER_PROFILE_NOTIFIATION = (id: number) => `/admin/user/${id}/notification`
export const GET_USER_PROFILE_CHAT = (id: number) => `/admin/conversation/${id}`
export const GET_USER_PROFILE_CHAT_DETAIL = (id: number) => `/admin/conversation/${id}/history`
export const PUT_USER_STATUS_VERIFY = (id: number) => `/admin/users/${id}/confirm`
