import DashboardLayout from 'layouts/Dashboard/DashboardLayout'
import { useEffect, useState } from 'react'
import {
    CustomBadge,
    CustomCompanyFirstBox,
    CustomCompanyFourthBox,
    CustomCompanyProfileBox,
    CustomCompanyProfileContainer,
    CustomCompanyProfileDiv,
    CustomCompanyProfileTableDiv,
    CustomCompanySecondBox,
    CustomCompanyThirdBox,
    CustomDetailsBox,
    CustomDetailsDiv,
    CustomTableHeadBox,
} from './index.styled'
import { useActiveCompany, useDeactiveCompany, useGetCompanyProfile, useGetCompanyProfileCount } from 'utils/hooks/queries/Companies'
import { CompanyModel, CompanyProfileCountModel } from 'services/be-api/companies/types'
import { GridColDef } from '@mui/x-data-grid'
import { Box, Divider } from '@mui/material'
import Typography from 'components/Typography/Typography'
import { colors } from 'styles/color'
import { TableBodyRowData } from 'components/Table/types'
import { useNavigate, useParams } from 'react-router-dom'
import Table from 'components/Table'
import { CheckCircle, Feed, LocalOffer, Verified } from '@mui/icons-material'
import { ptr } from 'utils/helpers'
import Button from 'components/Buttons/Button/Button'
import Modal from 'components/Modal/Modal'
import Spinner from 'components/Spinner'
import NotFound from 'pages/notfound'

export const companiesHead: GridColDef[] = [
    { field: 'user', headerName: 'KULLANICI', width: 110 },
    { field: 'phone', headerName: 'TELEFON NUMARASI', width: 110 },
    { field: 'position', headerName: 'POSİTİON', width: 110 },
    { field: 'status', headerName: 'DURUM', width: 110 },
]

const CompanyProfile = () => {
    const [companyProfile, setCompanyProfile] = useState<CompanyModel>({} as CompanyModel)
    const [companyCount, setCompanyCount] = useState<CompanyProfileCountModel>()
    const [currentPage, setCurrentPage] = useState(0)
    const [rowPerPage, setRowPerPage] = useState(10)
    const [open, setOpen] = useState(false)
    const { mutate: deactiveCompany, isSuccess: isSuccessDeactive } = useDeactiveCompany()
    const { mutate: activeCompany, isSuccess: isSuccessActive } = useActiveCompany()

    const navigate = useNavigate()

    const { id } = useParams()

    const { data, isSuccess, isLoading, refetch, isError } = useGetCompanyProfile({
        queryKeys: {
            maxResultCount: rowPerPage,
            skipCount: rowPerPage * currentPage,
            id: Number(id),
        },
    })

    const { data: companyCountData, isSuccess: companyCountSuccess } = useGetCompanyProfileCount({
        queryKeys: {
            id: Number(id),
        },
    })

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowPerPage(parseInt(event.target.value, 10))
        setCurrentPage(0)
    }

    const onPageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setCurrentPage(newPage)
    }

    const handleTo = (id: number, role: string) => {
        navigate(`/${role}/${id}`)
    }

    const handleToUpdate = (id: number) => {
        navigate(`/companies/${id}/update`)
    }

    const handleToggle = () => {
        setOpen(!open)
    }

    const handleModalConfirm = (id: string) => {
        if (companyProfile.isActive) {
            deactiveCompany({ id: Number(id) })
        } else if (!companyProfile.isActive) {
            activeCompany({ id: Number(id) })
        }
        setOpen(!open)
    }

    useEffect(() => {
        if (isSuccess && data?.data.result) {
            refetch()
            setCompanyProfile(data.data.result)
        }
    }, [isSuccess, refetch, data?.data.result, isSuccessDeactive, isSuccessActive])

    useEffect(() => {
        if (companyCountData && companyCountSuccess) {
            setCompanyCount(companyCountData.data.result)
        }
    }, [companyCountData, companyCountSuccess])

    const rowData: TableBodyRowData[] = (companyProfile.users ?? []).map((p) => {
        return {
            user: (
                <Box>
                    <Typography color={colors.primary.primaryMain} variant="body-normal-semibold" onClick={() => handleTo(p.id!, 'users')}>
                        {p.name} {p.surname}
                    </Typography>
                </Box>
            ),

            phone: (
                <Box>
                    <Typography color={colors.text.secondaryTextLight} variant="body-normal-default">
                        {p.phoneNumber} {p.isPhoneNumberConfirmed && <Verified style={{ color: colors.success.successMain }} />}
                    </Typography>
                </Box>
            ),
            position: (
                <Box>
                    <Typography variant="body-normal-default">{p.position}</Typography>
                </Box>
            ),
            status: (
                <Box>
                    <CustomBadge style={{ backgroundColor: p.status === 'Beklemede' ? '#fff3db' : p.status === 'Aktif' ? '#dbf8c9' : '#E5EBEC' }}>
                        <Typography width={'100%'} variant="body-smaller-default" color={p.status === 'Beklemede' ? '#FDB528' : p.status === 'Aktif' ? '#72E128' : '#5578AD'}>
                            {p.status}
                        </Typography>
                    </CustomBadge>
                </Box>
            ),
        }
    })

    return (
        <DashboardLayout>
            <Spinner open={isLoading} />
            {!isLoading && !isError && (
                <CustomCompanyProfileContainer>
                    <CustomCompanyProfileDiv>
                        <CustomCompanyProfileBox>
                            <CustomCompanyFirstBox sx={{ textAlign: 'center' }}>
                                <Typography variant="h6-medium">{companyProfile.name}</Typography>
                                <Typography variant="body-normal-default">
                                    {companyProfile.manager?.name} {companyProfile.manager?.surname}
                                </Typography>
                            </CustomCompanyFirstBox>
                            <CustomCompanySecondBox>
                                <CustomCompanySecondBox style={{ gap: ptr(8) }}>
                                    <Box
                                        sx={{
                                            textAlign: 'start',
                                            display: 'flex',
                                            width: ptr(44),
                                            height: ptr(44),
                                        }}
                                    >
                                        <CustomBadge style={{ background: colors.white.greyLight }}>
                                            <Feed style={{ color: colors.white.greyBorder }} />
                                        </CustomBadge>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            textAlign: 'start',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <Typography variant="h5-default">{companyCount?.totalDemandCount}</Typography>
                                        <Typography variant="body-smaller-default">Toplam Talep</Typography>
                                    </Box>
                                </CustomCompanySecondBox>
                                <CustomCompanySecondBox style={{ gap: ptr(8) }}>
                                    <Box
                                        sx={{
                                            textAlign: 'start',
                                            display: 'flex',
                                            width: ptr(44),
                                            height: ptr(44),
                                        }}
                                    >
                                        <CustomBadge style={{ background: colors.white.greyLight }}>
                                            <CheckCircle style={{ color: colors.white.greyBorder }} />
                                        </CustomBadge>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            textAlign: 'start',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <Typography variant="h5-default">{companyCount?.totalPurchasedDemandCount}</Typography>
                                        <Typography variant="body-smaller-default">Toplam Alışveriş</Typography>
                                    </Box>
                                </CustomCompanySecondBox>
                            </CustomCompanySecondBox>
                            <CustomCompanySecondBox>
                                <CustomCompanySecondBox style={{ gap: ptr(8) }}>
                                    <Box
                                        sx={{
                                            textAlign: 'start',
                                            display: 'flex',
                                            width: ptr(44),
                                            height: ptr(44),
                                        }}
                                    >
                                        <CustomBadge style={{ background: colors.white.greyLight }}>
                                            <LocalOffer style={{ color: colors.white.greyBorder }} />
                                        </CustomBadge>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            textAlign: 'start',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <Typography variant="h5-default">{companyCount?.totalPurchasedDemandCount}</Typography>
                                        <Typography variant="body-smaller-default">Toplam Teklif</Typography>
                                    </Box>
                                </CustomCompanySecondBox>
                            </CustomCompanySecondBox>
                            <CustomCompanyThirdBox>
                                <CustomDetailsDiv>
                                    <CustomDetailsBox sx={{ marginBottom: ptr(8) }}>
                                        <Typography variant="h6-medium">Detaylar</Typography>
                                    </CustomDetailsBox>
                                    <Divider orientation="horizontal"></Divider>
                                    <CustomDetailsBox>
                                        <Typography color={colors.text.primaryTextLight} variant="body-small-medium">
                                            Vergi No :
                                        </Typography>
                                        <Typography color={colors.text.secondaryTextLight} variant="body-small-default">
                                            {companyProfile.taxNumber}
                                        </Typography>
                                    </CustomDetailsBox>
                                    <CustomDetailsBox>
                                        <Typography color={colors.text.primaryTextLight} variant="body-small-medium">
                                            İl ve İlçe :
                                        </Typography>
                                        <Typography color={colors.text.secondaryTextLight} variant="body-small-default">
                                            {companyProfile.city} / {companyProfile.district}{' '}
                                        </Typography>
                                    </CustomDetailsBox>{' '}
                                    <CustomDetailsBox>
                                        <Typography color={colors.text.primaryTextLight} variant="body-small-medium">
                                            Adres :
                                        </Typography>
                                        <Typography color={colors.text.secondaryTextLight} variant="body-small-default">
                                            {companyProfile.address}
                                        </Typography>
                                    </CustomDetailsBox>{' '}
                                    <CustomDetailsBox>
                                        <Typography color={colors.text.primaryTextLight} variant="body-small-medium">
                                            Durum :
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                textAlign: 'center',
                                                gap: ptr(60),
                                            }}
                                        >
                                            <CustomBadge
                                                style={{
                                                    backgroundColor: companyProfile.status === 'Aktif' ? '#dbf8c9' : '#E5EBEC',
                                                    padding: '0px 10px',
                                                    width: 'fit-content',
                                                }}
                                            >
                                                <Typography variant="body-small-default" color={companyProfile.status === 'Aktif' ? '#72E128' : '#5578AD'}>
                                                    {companyProfile.status}
                                                </Typography>
                                            </CustomBadge>
                                        </Box>
                                    </CustomDetailsBox>{' '}
                                </CustomDetailsDiv>
                            </CustomCompanyThirdBox>
                            <CustomCompanyFourthBox>
                                <Button onClick={() => handleToUpdate(Number(id))}>DÜZENLE</Button>
                                <Button onClick={() => handleToggle()} variant="outlined" colorsx={colors.error.errorMain} borderColor={colors.error.errorMain}>
                                    {companyProfile.isActive ? 'ASKIYA AL' : 'AKTIVE ET'}
                                </Button>
                            </CustomCompanyFourthBox>
                        </CustomCompanyProfileBox>
                    </CustomCompanyProfileDiv>
                    <CustomCompanyProfileTableDiv style={{ width: '100%' }}>
                        <CustomTableHeadBox>
                            <Typography variant="h6-medium">Firma Kullanıcı Listesi</Typography>
                            <Button width="fit-content" padding={ptr(10)} onClick={() => navigate(`/users/new?companyId=${companyProfile.id}`)}>
                                Firmaya Çalışan Ekle
                            </Button>
                        </CustomTableHeadBox>
                        <Table
                            count={companyProfile.users?.length}
                            isLoading={isLoading}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            onPageChange={onPageChange}
                            head={companiesHead}
                            result={rowPerPage}
                            rowsData={rowData}
                            isClickable={false}
                            page={currentPage}
                        ></Table>
                        <Modal
                            open={open}
                            onClose={() => handleToggle()}
                            onConfirm={() => handleModalConfirm(id!)}
                            header={companyProfile.isActive ? 'Firmayı Askıya Almak Üzeresiniz!' : 'Firmayı Aktif Etmek Üzeresiniz'}
                            subheader={
                                companyProfile.isActive
                                    ? 'Firmayı askıya aldığınızda o firmanın tüm kullanıcılarını sistemden atmış oluyorsunuz. Yine de işleme devam etmek istiyor musunuz?'
                                    : 'Firmayı aktif ettiginizde o firmanın tüm kullanıcılarını aktif etmiş oluyorsunuz. Yine de işleme devam etmek istiyor musunuz?'
                            }
                        ></Modal>
                    </CustomCompanyProfileTableDiv>
                </CustomCompanyProfileContainer>
            )}
            {!isLoading && isError && <NotFound item={'Firma'} />}
        </DashboardLayout>
    )
}

export default CompanyProfile
