import React, { useEffect, useState } from 'react'
import { GridColDef } from '@mui/x-data-grid'

import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs'
import DashboardLayout from 'layouts/Dashboard/DashboardLayout'
import { CustomFilterDiv, CustomHeadDiv, CustomTableDiv, PopularityContainer } from '../index.styled'
import { endDateConverter, ptr, startDateConverter } from 'utils/helpers'
import Table from 'components/Table/Table'
import { TableBodyRowData } from 'components/Table/types'
import Typography from 'components/Typography/Typography'
import { Box } from '@mui/material'
import DatePicker from 'components/DateRangePicker/DateRangePicker'
import { useLocation } from 'react-router-dom'
import Button from 'components/Buttons/Button/Button'
import { IosShare } from '@mui/icons-material'
import { colors } from 'styles/color'
import ExportDropdown from 'components/ExportDropdown'
import Spinner from 'components/Spinner'
import { useGetAllTopBuyerSpecialOffer } from 'utils/hooks/queries/Dashboard/useDashboard'
import { TopBuyerSpecialOfferItem } from 'services/be-api/dashboard/types'
import SearchInput from '../../../components/Input/SearchInput/SearchInput'

export const exportOptions = [
    {
        key: 'ALICI',
        label: 'ALICI',
        onRender: (item: string, element: TopBuyerSpecialOfferItem) => {
            return `${element?.fullName}`
        },
    },
    {
        key: 'TOPLAM ALIS ADEDI',
        label: 'TOPLAM ALIS ADEDI',
        onRender: (item: string, element: TopBuyerSpecialOfferItem) => {
            return `${element?.totalRequestCount}`
        },
    },
    {
        key: 'TOPLAM ALIS MIKTARI',
        label: 'TOPLAM ALIS MIKTARI',
        onRender: (item: string, element: TopBuyerSpecialOfferItem) => {
            return `${element?.totalPurchasePrice}`
        },
    },
]

const TopCampaignBuyerDetail = () => {
    const [exportArray, setExportArray] = useState<Array<TopBuyerSpecialOfferItem>>([])
    const [buyerSpecial, setBuyerSpecial] = useState<Array<TopBuyerSpecialOfferItem>>([])
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [exportToggle, setExportToggle] = useState(false)
    const [currentPage, setCurrentPage] = useState(0)
    const [rowPerPage, setRowPerPage] = useState(10)
    const [filterText, setFilterText] = useState('')
    const location = useLocation()
    const selectedDate = location.state
    const {
        data: topBuyerSpecialOfferData,
        isSuccess: topBuyerSpecialOfferSuccess,
        isLoading: topBuyerSpecialOfferLoading,
    } = useGetAllTopBuyerSpecialOffer({
        queryKeys: {
            startDate: startDate.length ? startDateConverter(startDate) : null,
            endDate: endDate.length ? endDateConverter(endDate) : null,
            skipCount: currentPage * rowPerPage || null,
            maxResultCount: rowPerPage,
            filter: filterText,
        },
    })

    const {
        data: topBuyerSpecialOfferDataExport,
        isSuccess: topBuyerSpecialOfferSuccessExport,
        refetch: topBuyerSpecialOfferRefetchExport,
    } = useGetAllTopBuyerSpecialOffer({
        queryKeys: {
            startDate: startDate.length ? startDateConverter(startDate) : null,
            endDate: endDate.length ? endDateConverter(endDate) : null,
            skipCount: null,
            maxResultCount: 1000,
            filter: filterText,
        },
        enabled: false,
    })

    useEffect(() => {
        if (topBuyerSpecialOfferData && topBuyerSpecialOfferSuccess) {
            setBuyerSpecial(topBuyerSpecialOfferData?.data.result.items)
        }
    }, [topBuyerSpecialOfferData, topBuyerSpecialOfferSuccess])

    useEffect(() => {
        if (topBuyerSpecialOfferDataExport && topBuyerSpecialOfferSuccessExport) {
            setExportArray(topBuyerSpecialOfferDataExport?.data.result.items)
        }
    }, [topBuyerSpecialOfferDataExport, topBuyerSpecialOfferSuccessExport])

    useEffect(() => {
        if (selectedDate) {
            setStartDate(selectedDate.startDate)
            setEndDate(selectedDate.endDate)
        }
    }, [selectedDate])

    const breadcrumbLinks = [
        { text: 'Dashboard', url: '' },
        { text: 'Kampanyalar', url: '' },
        { text: 'Top Kampanya Alıcı Detay', url: '/popular-campaings-category-details', isLast: true },
    ]
    const productTableHead: GridColDef[] = [
        { field: 'name', headerName: 'ALICI', width: 150 },
        { field: 'totalPurchase', headerName: 'TOPLAM ALIŞ ADEDİ', width: 150 },
        { field: 'totalPurchaseAmount', headerName: 'TOPLAM ALIŞ MİKTARI', width: 150 },
    ]

    const rowData: TableBodyRowData[] = buyerSpecial.map((data) => {
        return {
            name: (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        flexDirection: 'column',
                    }}
                >
                    <Typography variant="body-normal-semibold">{data.fullName}</Typography>
                    <Typography color={colors.text.secondaryTextLight} variant="body-smaller-default">
                        {data.companyName} 666
                    </Typography>
                </Box>
            ),
            totalPurchase: <Typography variant="body-normal-default"> {data.totalRequestCount}</Typography>,
            totalPurchaseAmount: <Typography variant="body-normal-default"> {data.totalPurchasePrice}</Typography>,
        }
    })

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowPerPage(parseInt(event.target.value, 10))
        setCurrentPage(0)
    }

    const onPageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setCurrentPage(newPage)
    }

    const handleSelectDate = (sDate: string, eDate: string) => {
        setStartDate(sDate)
        setEndDate(eDate)
    }

    const handleExport = (exportToggle: boolean, set: React.Dispatch<React.SetStateAction<boolean>>) => {
        set(!exportToggle)
        topBuyerSpecialOfferRefetchExport()
    }

    return (
        <DashboardLayout>
            <PopularityContainer>
                <Breadcrumbs links={breadcrumbLinks} />
                <CustomTableDiv>
                    <CustomHeadDiv style={{ height: ptr(81) }}>
                        <Button
                            startIcon={<IosShare />}
                            onClick={() => handleExport(exportToggle, setExportToggle)}
                            variant="outlined"
                            colorsx={colors.secondary.secondaryAlternativeLight}
                            borderColor={colors.secondary.secondaryAlternativeLight}
                            padding={ptr(16)}
                        >
                            <ExportDropdown<TopBuyerSpecialOfferItem>
                                array={exportArray}
                                fileName={'export'}
                                title={''}
                                sheetName={''}
                                headers={exportOptions}
                                open={exportToggle}
                                buttonHeader={'Export'}
                                isSuccess={topBuyerSpecialOfferSuccessExport}
                            />
                        </Button>
                        <CustomFilterDiv>
                            <CustomFilterDiv style={{ gap: '20px' }}>
                                <SearchInput
                                    height={ptr(51)}
                                    value={filterText}
                                    onChange={(val) => {
                                        setFilterText(val)
                                        if (currentPage !== 0) setCurrentPage(0)
                                    }}
                                    placeholder="Firma veya Kullanıcı Ara"
                                />
                            </CustomFilterDiv>
                            <Box width={'20%'}>
                                <DatePicker
                                    setEndDate={setEndDate}
                                    setStartDate={setStartDate}
                                    startDate={startDate}
                                    endDate={endDate}
                                    value={`${startDate} - ${endDate}`}
                                    width={'100%'}
                                    placeholder={'Tarihe Göre Filtrele'}
                                    onSelectDate={({ startDate, endDate }) => {
                                        handleSelectDate(startDate, endDate)
                                        if (currentPage !== 0) setCurrentPage(0)
                                    }}
                                />
                            </Box>
                        </CustomFilterDiv>
                    </CustomHeadDiv>

                    <div>
                        <Spinner open={topBuyerSpecialOfferLoading} />
                        <Table
                            count={topBuyerSpecialOfferData?.data.result.totalCount}
                            isLoading={false}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            onPageChange={onPageChange}
                            head={productTableHead}
                            result={rowPerPage}
                            rowsData={rowData}
                            isClickable={false}
                            page={currentPage}
                        ></Table>
                    </div>
                </CustomTableDiv>
            </PopularityContainer>
        </DashboardLayout>
    )
}

export default TopCampaignBuyerDetail
