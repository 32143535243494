import { useEffect, useState } from 'react'
import { downloadExcel } from 'react-export-table-to-excel'
//@ts-ignore
import { CSVLink } from 'react-csv'
import { jsPDF } from 'jspdf'
import { get } from 'lodash'
import 'jspdf-autotable'
import { MenuItem, Popover } from '@mui/material'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import { IExportDropdownProps } from './types'
import { ptr } from 'utils/helpers'

const ExportDropdown = <T extends unknown>({ className, headers, fileName, array, title, sheetName, open, buttonHeader, isSuccess, ...other }: IExportDropdownProps<T>) => {
    const [newDownloadArray, setNewDownloadArray] = useState<any>([])

    const arrayConvert = (array: any) => {
        const tempData: any = []
        array.forEach((element: any) => {
            const tempObject: any = {}
            headers.forEach((e: any) => {
                if (e.onRender) {
                    const value = get(element, e.key)
                    tempObject[e.key.replace('.', '')] = e.onRender(value, element)
                } else {
                    const value = get(element, e.key)
                    tempObject[e.key.replace('.', '')] = value
                }
            })
            tempData.push(tempObject)
        })
        setNewDownloadArray(tempData)
    }

    useEffect(() => {
        if (Array.isArray(array)) {
            arrayConvert(array)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [array])

    function handleDownloadExcel() {
        downloadExcel({
            fileName: fileName,
            sheet: sheetName,
            tablePayload: {
                header: headers.map((e: any) => e.label),
                body: newDownloadArray,
            },
        })
    }

    const exportPDF = () => {
        const unit = 'pt'
        const size = 'A4' // Use A1, A2, A3 or A4
        const orientation = 'landscape' // portrait or landscape

        const doc = new jsPDF(orientation, unit, size)
        doc.setFontSize(10)

        let content = {
            styles: { fontFamily: 'Montserrat', fontStyle: 'normal' },
            startY: 50,
            head: [headers.map((e: any) => e.label)],
            body: newDownloadArray.map((e: any) => {
                const A = headers
                    .map((e2: any) => e[e2.key.replace('.', '')])
                    .map((x: any) => {
                        if (typeof x === 'string') {
                            return x
                                .replaceAll('ğ', 'g')
                                .replaceAll('İ', 'I')
                                .replaceAll('Ö', 'O')
                                .replaceAll('ö', 'o')
                                .replaceAll('ü', 'u')
                                .replaceAll('Ü', 'U')
                                .replaceAll('Ğ', 'G')
                                .replaceAll('ı', 'i')
                                .replaceAll('Ç', 'C')
                                .replaceAll('ç', 'c')
                                .replaceAll('Ş', 'S')
                                .replaceAll('ş', 's')
                        } else {
                            return x
                        }
                    })
                return A
            }),
        }

        //@ts-ignore
        doc.autoTable(content)
        doc.text(title, 10, 40)
        doc.save(`${fileName}.pdf`)
    }

    return (
        <PopupState variant="popover" popupId="demo-popup-popover">
            {(popupState) => (
                <div>
                    <p {...bindTrigger(popupState)}>{buttonHeader}</p>
                    <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        style={{ left: ptr(-20) }}
                    >
                        <MenuItem disabled={!isSuccess} className="w-100">
                            <CSVLink
                                data={newDownloadArray}
                                separator={';'}
                                headers={headers.map((e: any) => e.key.replace('.', ''))}
                                filename={`${fileName}.csv`}
                                tag="span"
                                style={{ color: 'unset' }}
                            >
                                Csv
                            </CSVLink>
                        </MenuItem>
                        <MenuItem disabled={!isSuccess} className="w-100" onClick={() => handleDownloadExcel()}>
                            <span style={{ textDecoration: 'underline' }} className="align-middle">
                                Excel
                            </span>
                        </MenuItem>
                        <MenuItem
                            disabled={!isSuccess}
                            className="w-100"
                            onClick={() => {
                                exportPDF()
                            }}
                        >
                            <span style={{ textDecoration: 'underline' }} className="align-middle">
                                PDF
                            </span>
                        </MenuItem>
                    </Popover>
                </div>
            )}
        </PopupState>
    )
}

export default ExportDropdown
