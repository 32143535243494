import { CustomHeadDiv, CustomTableDiv } from '../../index.styled'
import { ptr } from '../../../../utils/helpers'
import Button from '../../../../components/Buttons/Button/Button'
import { IosShare } from '@mui/icons-material'
import ExportDropdown from '../../../../components/ExportDropdown'
import Table from '../../../../components/Table'
import { useEffect, useState } from 'react'
import { TableBodyRowData } from '../../../../components/Table/types'
import Typography from '../../../../components/Typography/Typography'
import { useParams } from 'react-router-dom'
import { useGetProfileNotification } from '../../../../utils/hooks/queries/Users'
import { ProfileNotification } from '../../../../services/be-api/users/types'
import { profileNotificationExportOptions, profileNotificationTableHead } from './constants'
import { CustomExportBox } from 'pages/notifications/index.styled'

const Notification = () => {
    const { id } = useParams()
    const [exportToggle, setExportToggle] = useState(false)
    const [currentPage, setCurrentPage] = useState(0)
    const [rowPerPage, setRowPerPage] = useState(10)
    const [notifications, setNotifications] = useState<ProfileNotification[]>([])
    const [exportArray, setExportArray] = useState<ProfileNotification[]>([])

    const { data: notificationsData, isLoading: isNotificationsDataLoading } = useGetProfileNotification({
        queryKeys: {
            id,
            maxResultCount: rowPerPage,
            skipCount: currentPage * rowPerPage || null,
        },
    })
    const {
        data: notificationsDataExport,
        isSuccess: isNotificationsDataSuccessExport,
        refetch: isNotificationsRefetchExport,
    } = useGetProfileNotification({
        queryKeys: {
            id,
            maxResultCount: rowPerPage,
            skipCount: currentPage * rowPerPage || null,
        },
    })

    useEffect(() => {
        if (notificationsDataExport && notificationsDataExport?.data.result) {
            setExportArray(notificationsDataExport.data.result.items)
        }
    }, [notificationsDataExport, isNotificationsDataSuccessExport])

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowPerPage(parseInt(event.target.value, 10))
        setCurrentPage(0)
    }

    const onPageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setCurrentPage(newPage)
    }

    useEffect(() => {
        if (notificationsData?.data.result) setNotifications(notificationsData.data.result.items)
    }, [notificationsData])

    const handleExport = (exportToggle: boolean, set: React.Dispatch<React.SetStateAction<boolean>>) => {
        set(!exportToggle)
        isNotificationsRefetchExport()
    }

    const rowData: TableBodyRowData[] =
        notifications &&
        notifications?.map((notification) => {
            return {
                type: (
                    <Typography size={16} weight={600}>
                        {notification.title}
                    </Typography>
                ),
                content: (
                    <Typography size={16} weight={400}>
                        {notification.text}
                    </Typography>
                ),
                createdAt: (
                    <Typography size={14} weight={400}>
                        {notification.creationTime}
                    </Typography>
                ),
            }
        })

    return (
        <>
            <CustomTableDiv>
                <CustomHeadDiv style={{ height: ptr(54) }}>
                    <CustomExportBox>
                        <Button startIcon={<IosShare />} onClick={() => handleExport(exportToggle, setExportToggle)} variant="outlined" color={'secondary'} padding={ptr(16)}>
                            <ExportDropdown<ProfileNotification>
                                array={exportArray}
                                fileName={'notifications'}
                                title={''}
                                sheetName={''}
                                headers={profileNotificationExportOptions}
                                open={exportToggle}
                                buttonHeader={'Export'}
                                isSuccess={isNotificationsDataSuccessExport}
                            />
                        </Button>
                        <Typography variant="body-smaller-default">Maksimum 1000 veri</Typography>
                    </CustomExportBox>
                </CustomHeadDiv>
                <Table
                    count={notificationsData?.data.result.totalCount}
                    isLoading={isNotificationsDataLoading}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    onPageChange={onPageChange}
                    head={profileNotificationTableHead}
                    result={rowPerPage}
                    rowsData={rowData}
                    isClickable={false}
                    page={currentPage}
                ></Table>
            </CustomTableDiv>
        </>
    )
}

export default Notification
