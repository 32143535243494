import { GridColDef } from '@mui/x-data-grid'
import { Order, OrderStatusFilter, OrderTypeFilter } from '../../services/be-api/orders/types'
import dayjs from 'dayjs'

export const offerTableHead: GridColDef[] = [
    { field: 'orderTime', headerName: 'SİPARİŞ ZAMANI', width: 160 },
    { field: 'bankTransactionCode', headerName: 'SİPARİŞ KODU', width: 140 },
    { field: 'orderUser', headerName: 'SİPARİŞ VEREN', width: 200 },
    { field: 'productInfo', headerName: 'ÜRÜN BİLGİLERİ', width: 150 },
    { field: 'price', headerName: 'TOPLAM FİYAT', width: 150 },
    { field: 'amount', headerName: 'ADET', width: 50 },
    { field: 'productStatus', headerName: 'ÜRÜN DURUMU', width: 30 },
]

export const orderStatusOptions = Object.values(OrderStatusFilter).map((status) => ({ label: status, value: status }))
export const orderTypeOptions = Object.values(OrderTypeFilter).map((type) => ({ label: type, value: type }))

export const orderExportOptions = [
    {
        key: 'SIPARIS ZAMANI',
        label: 'SIPARIS ZAMANI',
        onRender: (item: string, element: Order) => {
            return `${dayjs(element.creationTime).format('YYYY-MM-DD, HH:mm')}`
        },
    },
    {
        key: 'SIPARIS KODU',
        label: 'SIPARIS KODU',
        onRender: (item: string, element: Order) => {
            return element.bankTransactionCode
        },
    },
    {
        key: 'SIPARIS VEREN',
        label: 'SIPARIS VEREN',
        onRender: (item: string, element: Order) => {
            return `${element.user?.company?.name}`
        },
    },
    {
        key: 'PARÇA',
        label: 'PARÇA',
        onRender: (item: string, element: Order) => {
            return `${element.specialOffer.productDescription}`
        },
    },
    {
        key: 'URUN BILGILERI',
        label: 'URUN BILGILERI',
        onRender: (item: string, element: Order) => {
            return `${element.specialOffer.productPrice}`
        },
    },
    {
        key: 'TOPLAM FIYAT',
        label: 'TOPLAM FIYAT',
        onRender: (item: string, element: Order) => {
            return `${element.specialOffer.productStock}`
        },
    },
    {
        key: 'URUN DURUMU',
        label: 'URUN DURUMU',
        onRender: (item: string, element: Order) => {
            return `${element.status}`
        },
    },
]

export const orderTransactionHistoryTableHead: GridColDef[] = [
    { field: 'updateDate', headerName: 'GÜNCELLEME TARİHİ', width: 100 },
    { field: 'status', headerName: 'DURUM', width: 100 },
]
