import usersServices from 'services/be-api/users'

import {
    GetAllUsersServiceResponse,
    GetUserServiceResponse,
    GetUsersVariables,
    UpdateUserRoleModel,
    CreateUserModel,
    UpdateUserVariables,
    UpdateUserResponse,
    GetUserProfileVariables,
    GetUserProfileResponse,
    GetUserProfileCountVariables,
    GetUserProfileCountResponse,
    GetUserProfileNotificationVariables,
    GetUserProfileNotificationResponse,
    GetUserProfileChatVariables,
    GetUserProfileChatResponse,
    GetUserProfileChatDetailVariables,
    GetUserProfileChatDetailResponse,
    GetUserActivateVariables,
    GetUserModelActivateServiceResponse,
} from 'services/be-api/users/types'

import { useBaseMutation, useBaseQuery } from '../_Base'
import { Mutation, Query } from '../types'
import {
    activeUser,
    deactiveUser,
    getUserProfile,
    getUserProfileChatDetail,
    getUserProfileChats,
    getUserProfileCount,
    getUserProfileNotification,
    putUserStatusVerify,
    updateUser,
    updateUserRole,
} from '../../../../services/be-api/users/endpoints'
import { RequestParams } from '../../../../services/types'

export const useGetUser: Query<any, GetUserServiceResponse> = ({ enabled = true }) =>
    useBaseQuery({
        queryKeys: ['user'],
        service: usersServices.getUser,
        onSuccess: {
            messageDisplay: false,
            message: 'Successful, get user detail.',
        },
        onLoading: {
            messageDisplay: false,
            message: 'Loading',
        },
        onError: {
            messageDisplay: false,
        },
        enabled,
    })
export const useCreateUser: Mutation<CreateUserModel, void> = () => {
    return useBaseMutation({
        service: usersServices.createUser,
        onSuccess: {
            messageDisplay: true,
            message: 'Kullanıcı Başarıyla Oluşturuldu.',
        },
        onLoading: {
            messageDisplay: true,
            message: 'Kullanıcı Oluşturuluyor',
        },
        onError: {
            messageDisplay: true,
        },
    })
}

export const useGetAllUsers: Query<GetUsersVariables, GetAllUsersServiceResponse> = (params) => {
    return useBaseQuery({
        queryKeys: ['user', { params: params.queryKeys }],
        service: usersServices.getAllUser,
        onSuccess: {
            messageDisplay: false,
            message: 'Succesfully, get all users',
        },
        onLoading: {
            messageDisplay: false,
            message: 'Loading',
        },
        onError: {
            messageDisplay: false,
        },
        enabled: params.enabled,
    })
}

export const useChangeUserRole: Mutation<UpdateUserRoleModel, void> = () => {
    return useBaseMutation({
        service: updateUserRole,
        onSuccess: {
            messageDisplay: true,
            message: 'Change user role',
        },
        onLoading: {
            messageDisplay: false,
            message: 'Loading',
        },
        onError: {
            messageDisplay: true,
        },
    })
}
export const useGetUserProfile: Query<GetUserProfileVariables, GetUserProfileResponse> = (params) =>
    useBaseQuery({
        queryKeys: ['users-profile', { params: params.queryKeys }],
        service: getUserProfile,
        onSuccess: {
            messageDisplay: false,
            message: 'Successful.',
        },
        onLoading: {
            messageDisplay: false,
            message: 'Loading',
        },
        onError: {
            messageDisplay: false,
        },
    })

export const useDeactiveUser: Mutation<GetUserActivateVariables, GetUserModelActivateServiceResponse> = () => {
    return useBaseMutation({
        service: deactiveUser,
        onSuccess: {
            messageDisplay: true,
            message: 'Kullanıcı başarıyla askıya alındı.',
        },
        onLoading: {
            messageDisplay: true,
            message: 'Loading',
        },
        onError: {
            messageDisplay: true,
        },
    })
}

export const useActiveUser: Mutation<GetUserActivateVariables, GetUserModelActivateServiceResponse> = () => {
    return useBaseMutation({
        service: activeUser,
        onSuccess: {
            messageDisplay: true,
            message: 'Kullanıcı başarıyla aktif edildi.',
        },
        onLoading: {
            messageDisplay: true,
            message: 'Loading',
        },
        onError: {
            messageDisplay: true,
        },
    })
}
export const useUserStatusVerify: Mutation<GetUserActivateVariables, GetUserModelActivateServiceResponse> = () => {
    return useBaseMutation({
        service: putUserStatusVerify,
        onSuccess: {
            messageDisplay: true,
            message: 'Kullanıcı başarıyla onaylandı.',
        },
        onLoading: {
            messageDisplay: true,
            message: 'Loading',
        },
        onError: {
            messageDisplay: true,
        },
    })
}

export const useUpdateUser: Mutation<UpdateUserVariables, UpdateUserResponse> = () => {
    return useBaseMutation({
        service: updateUser,
        onSuccess: {
            messageDisplay: true,
            message: 'Kullanıcı başarıyla güncellendi.',
        },
        onLoading: {
            messageDisplay: true,
            message: 'Loading',
        },
        onError: {
            messageDisplay: true,
        },
    })
}

export const useGetUserProfileCount: Query<GetUserProfileCountVariables, GetUserProfileCountResponse> = (params) =>
    useBaseQuery({
        queryKeys: ['companies-profile', { params: params.queryKeys }],
        service: getUserProfileCount,
        onSuccess: {
            messageDisplay: false,
            message: 'Successful.',
        },
        onLoading: {
            messageDisplay: false,
            message: 'Loading',
        },
        onError: {
            messageDisplay: false,
        },
    })

export const useGetProfileNotification: Query<RequestParams<GetUserProfileNotificationVariables>, GetUserProfileNotificationResponse> = (params) => {
    return useBaseQuery({
        queryKeys: ['user_profile_notifications', { params: params.queryKeys }],
        service: getUserProfileNotification,
        onSuccess: {
            messageDisplay: false,
            message: 'Successful.',
        },
        onLoading: {
            messageDisplay: false,
            message: 'Loading',
        },
        onError: {
            messageDisplay: false,
        },
    })
}

export const useGetProfileChats: Query<RequestParams<GetUserProfileChatVariables>, GetUserProfileChatResponse> = (params) => {
    return useBaseQuery({
        queryKeys: ['user_profile_chats', { params: params.queryKeys }],
        service: getUserProfileChats,
        onSuccess: {
            messageDisplay: false,
            message: 'Successful.',
        },
        onLoading: {
            messageDisplay: false,
            message: 'Loading',
        },
        onError: {
            messageDisplay: false,
        },
    })
}

export const useGetProfileChatDetail: Query<RequestParams<GetUserProfileChatDetailVariables>, GetUserProfileChatDetailResponse> = (params) => {
    return useBaseQuery({
        queryKeys: ['user_profile_chat_detail', { params: params.queryKeys }],
        service: getUserProfileChatDetail,
        onSuccess: {
            messageDisplay: false,
            message: 'Successful.',
        },
        onLoading: {
            messageDisplay: false,
            message: 'Loading',
        },
        onError: {
            messageDisplay: false,
        },
    })
}
