import { Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'

import DashboardLayout from '../../layouts/Dashboard/DashboardLayout'
import { CustomCardDiv, CustomFilterBox } from '../users/index.styled'
import { Badge, Box } from '@mui/material'
import { colors } from '../../styles/color'
import { endDateConverter, getKey, getValue, ptr, startDateConverter } from '../../utils/helpers'
import { Block, CheckCircle, DeleteOutline, IosShare, TextSnippet, VisibilityOutlined } from '@mui/icons-material'
import Typography from '../../components/Typography/Typography'
import AutocompleteContainer from '../../components/Input/AutoComplete/Autocomplete'
import { exportOptions, requestOfferTableHead, requestStatusOptions, requestTableHead } from './constants'
import React, { FC, useEffect, useState } from 'react'
import {
    ErrorMessage,
    ErrorMessageControler,
    RequestsContainer,
    RequestsFilterSection,
    RequestStatus,
    RequestTableContainer,
    RequestTableFilterContainer,
    RequestTableHead,
    StatisticCard,
    StatisticCardInfo,
    Statistics,
} from './index.styled'
import { useOffersByRequestId, useRequests, useRequestStatisticCount, useWithdrawRequest } from '../../utils/hooks/queries/Requests/useRequest'
import { RequestModel, RequestOffer, RequestStatistics } from '../../services/be-api/requests/types'
import { useGetVehicleMakes, useGetWorkCategory, useGetWorkSubCategory } from '../../utils/hooks/queries/LookUp'
import { BaseLookupModel } from '../../services/be-api/lookup/types'
import ExportDropdown from '../../components/ExportDropdown'
import Button from '../../components/Buttons/Button/Button'
import Table from '../../components/Table'
import { TableBodyRowData } from '../../components/Table/types'
import List from '../../components/List/List'
import DatePicker from '../../components/DateRangePicker/DateRangePicker'
import { SubOfferTableProps, WithdrawOffer } from './types'
import { useLocation, useNavigate } from 'react-router-dom'
import TextField from '../../components/Input/TextField/TextField'
import Modal from '../../components/Modal/Modal'
import { ERequestStatus } from './index.enum'
import Spinner from '../../components/Spinner'
import dayjs from 'dayjs'
import SearchInput from '../../components/Input/SearchInput/SearchInput'
import { FormProps } from './requestDetail/types'
import PriceText from '../../components/PriceText'
import { CustomExportBox } from 'pages/notifications/index.styled'

const SubOfferTable: FC<SubOfferTableProps> = ({ id }) => {
    const [offers, setOffers] = useState<RequestOffer[]>([])
    const { data: offersData, isLoading } = useOffersByRequestId({ queryKeys: { id } })

    useEffect(() => {
        if (offersData?.data.result) {
            setOffers(offersData.data.result.offers)
        }
    }, [offersData])

    let rowData: TableBodyRowData[] = offers?.map((offer: RequestOffer) => {
        return {
            user: (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography weight={600} color={colors.text.primaryTextLight}>
                        {offer.user.fullName}
                    </Typography>
                    <Typography weight={400} color={colors.text.secondaryTextLight}>
                        {offer.company.name}
                    </Typography>
                </Box>
            ),
            amount: (
                <Typography>
                    <PriceText price={offer.amount} />
                </Typography>
            ),
            status: <Typography>{offer.status}</Typography>,
        }
    })

    return (
        <>
            <Table count={offers.length} isLoading={isLoading} head={requestOfferTableHead} rowsData={rowData} isClickable={true} pagination={false} />
            <Spinner open={isLoading} />
        </>
    )
}

const Demands = () => {
    const location = useLocation()
    const selectedDate = location.state
    const navigate = useNavigate()
    const [currentPage, setCurrentPage] = useState(0)
    const [rowPerPage, setRowPerPage] = useState(10)
    const [startDate, setStartDate] = useState<string>('')
    const [endDate, setEndDate] = useState<string>('')
    const [requestStatistics, setRequestStatistics] = useState<RequestStatistics>()
    const [vehicleMakes, setVehicleMakes] = useState<BaseLookupModel[]>()
    const [workCategories, setWorkCategories] = useState<BaseLookupModel[]>()
    const [workSubCategories, setWorkSubCategories] = useState<BaseLookupModel[]>()
    const [vehicleMake, setVehicleMake] = useState<string | null>(null)
    const [workCategory, setWorkCategory] = useState<string | null>(null)
    const [workSubCategory, setWorkSubCategory] = useState<string | null>(null)
    const [requestStatus, setRequestStatus] = useState<string>('')
    const [requests, setRequests] = useState<RequestModel[]>([])
    const [exportArray, setExportArray] = useState<RequestModel[]>([])
    const [filterText, setFilterText] = useState<string>('')
    const [withDrawRequest, setWithdrawRequest] = useState<WithdrawOffer>({} as WithdrawOffer)
    const [isWithdrawRequestModal, setIsWithdrawRequestModal] = useState<boolean>(false)
    const [exportToggle, setExportToggle] = useState(false)

    const handleSelectDate = (sDate: string, eDate: string) => {
        setStartDate(sDate)
        setEndDate(eDate)
    }

    const { data: requestStatisticsData } = useRequestStatisticCount({
        queryKeys: {
            Filter: filterText || null,
            WorkCategoryName: workCategory || null,
            WorkSubCategoryName: workSubCategory || null,
            VehicleMakeName: vehicleMake || null,
            Status: getKey(ERequestStatus, requestStatus) || null,
            StartDate: startDate.length ? startDateConverter(startDate) : null,
            EndDate: endDate.length ? endDateConverter(endDate) : null,
            SkipCount: currentPage * rowPerPage,
            MaxResultCount: rowPerPage,
        },
    })
    const { data: vehicleMakesData } = useGetVehicleMakes({ queryKeys: {} })
    const { data: workCategoryData } = useGetWorkCategory({ queryKeys: {} })
    const { data: workSubCategoryData, refetch: workSubCategoryRefetch } = useGetWorkSubCategory({ queryKeys: { category: workCategory }, enabled: false })
    const {
        data: requestsData,
        isLoading: isLoadingRequests,
        refetch: refetchRequest,
    } = useRequests({
        queryKeys: {
            Filter: filterText || null,
            WorkCategoryName: workCategory || null,
            WorkSubCategoryName: workSubCategory || null,
            VehicleMakeName: vehicleMake || null,
            Status: getKey(ERequestStatus, requestStatus) || null,
            StartDate: startDate.length ? startDateConverter(startDate) : null,
            EndDate: endDate.length ? endDateConverter(endDate) : null,
            SkipCount: currentPage * rowPerPage,
            MaxResultCount: rowPerPage,
        },
    })
    const {
        data: requestsDataExport,
        isSuccess: isSuccessRequestsExport,
        refetch: refetchRequestExport,
    } = useRequests({
        queryKeys: {
            Filter: filterText || null,
            WorkCategoryName: workCategory || null,
            WorkSubCategoryName: workSubCategory || null,
            VehicleMakeName: vehicleMake || null,
            Status: getKey(ERequestStatus, requestStatus) || null,
            StartDate: startDate.length ? startDateConverter(startDate) : null,
            EndDate: endDate.length ? endDateConverter(endDate) : null,
            MaxResultCount: 1000,
        },
    })

    const { mutate: withdrawRequest, isSuccess: isWithdrawRequestSuccess } = useWithdrawRequest()

    useEffect(() => {
        if (selectedDate) {
            setStartDate(selectedDate.startDate)
            setEndDate(selectedDate.endDate)
        }
    }, [selectedDate])

    useEffect(() => {
        refetchRequest()
    }, [isWithdrawRequestSuccess, refetchRequest])

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowPerPage(parseInt(event.target.value, 10))
        setCurrentPage(0)
    }

    const onPageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setCurrentPage(newPage)
    }

    useEffect(() => {
        if (requestStatisticsData?.data.result) setRequestStatistics(requestStatisticsData?.data.result)
    }, [requestStatisticsData])

    useEffect(() => {
        if (vehicleMakesData?.data.result) setVehicleMakes(vehicleMakesData?.data.result)
    }, [vehicleMakesData])

    useEffect(() => {
        if (workCategoryData?.data.result) setWorkCategories(workCategoryData?.data.result)
    }, [workCategoryData])

    useEffect(() => {
        if (workSubCategoryData?.data.result) setWorkSubCategories(workSubCategoryData?.data.result)
    }, [workCategory, workSubCategoryData])

    useEffect(() => {
        if (requestsData?.data.result) setRequests(requestsData?.data.result.items)
    }, [requestsData])

    useEffect(() => {
        if (isSuccessRequestsExport && requestsDataExport?.data.result) {
            setExportArray(requestsDataExport.data.result.items)
        }
    }, [requestsDataExport, isSuccessRequestsExport])

    useEffect(() => {
        setWorkSubCategories([])
        setWorkSubCategory('')
        if (workCategory !== '') workSubCategoryRefetch()
    }, [workCategory, workSubCategoryRefetch])

    const handleTo = (mainPath: string, id: number) => {
        navigate(`/${mainPath}/${id}`)
    }

    const initialValues: FormProps = {
        rejectReason: '',
    }

    const validationSchema = Yup.object({
        rejectReason: Yup.string().required('Bu alan zorunludur.').max(255, 'Maksimum 255 karakter olabilir'),
    })

    const onSubmit = (value: any, { resetForm }: FormikHelpers<FormProps>) => {
        withdrawRequest(withDrawRequest)
        setIsWithdrawRequestModal(false)
        refetchRequest()
        resetForm()
    }

    const handleExport = (exportToggle: boolean, set: React.Dispatch<React.SetStateAction<boolean>>) => {
        set(!exportToggle)
        refetchRequestExport()
    }

    const rowData: TableBodyRowData[] = requests?.map((request) => {
        const isUnpublishedByAdmin = request.status.includes(ERequestStatus.UN_PUBLISHED_BY_ADMIN)
        return {
            selectableId: request.id,
            user: (
                <Box>
                    <Box>
                        <Typography variant="body-normal-semibold" onClick={() => handleTo('users', request.user.id)}>
                            {request.user.fullName}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography color={colors.text.secondaryTextLight} variant="body-normal-default" onClick={() => handleTo('companies', request.company.id)}>
                            {request.company.name}
                        </Typography>
                    </Box>
                </Box>
            ),
            vehicleMakeName: <Typography>{request.vehicleMakeName}</Typography>,
            vehicleModelName: <Typography>{request.vehicleModelName}</Typography>,
            workCategoryName: <Typography>{request.workCategoryName}</Typography>,
            workSubCategoryName: (
                <Box>
                    <Typography>{request.workSubCategoryName}</Typography>
                </Box>
            ),
            status: (
                <RequestStatus
                    sx={{
                        backgroundColor:
                            request.status === 'Kapandı'
                                ? colors.error.errorOutlinedHoverBg
                                : request.status === 'Alışveriş Gerçekleşti'
                                ? colors.success.successOutlinedHoverBg
                                : colors.warning.warningOutlinedHoverBg,
                    }}
                >
                    <Typography
                        size={13}
                        color={request.status === 'Kapandı' ? colors.error.errorMain : request.status === 'Alışveriş Gerçekleşti' ? colors.success.successMain : colors.warning.warningMain}
                    >
                        {request.status}
                    </Typography>
                </RequestStatus>
            ),
            offerCount: (
                <Box>
                    <Typography size={16} color={colors.warning.warningMain}>
                        {request.offerCount}
                    </Typography>
                </Box>
            ),
            creationTime: <Typography variant="body-small-default">{dayjs(request.creationTime).format('YYYY-MM-DD HH:mm')}</Typography>,
            menu: (
                <List
                    listDetails={[
                        {
                            name: 'Görüntüle',
                            icon: <VisibilityOutlined style={{ color: colors.action.actionActiveLight }} />,
                            onClick: () => handleTo('requests', request.id),
                        },

                        ...(!isUnpublishedByAdmin
                            ? [
                                  {
                                      name: 'Yayından Kaldır',
                                      icon: <DeleteOutline style={{ color: colors.action.actionActiveLight }} />,
                                      onClick: () => {
                                          setWithdrawRequest((prevState) => ({ ...prevState, id: request.id }))
                                          setIsWithdrawRequestModal(true)
                                      },
                                      disabled: !!request.unPublishReason,
                                  },
                              ]
                            : []),
                    ]}
                    width="230px"
                    minWidth="inherit"
                    gap="10px"
                    listPadding="15px"
                    itemButtonPadding="0px"
                    ListContainerPadding="0px 10px"
                    ListTextPadding="5px 0px"
                    backgroundColor={colors.white.default}
                    boxShadow="0px 5px 5px -3px #4C4E6424, 0px 8px 10px 1px #4C4E6424, 0px 3px 14px 2px #4C4E6433"
                    isTableActions={true}
                />
            ),
            detail: (
                <Box>
                    <SubOfferTable id={request.id} />
                </Box>
            ),
        }
    })

    return (
        <DashboardLayout>
            <RequestsContainer>
                <Statistics>
                    <StatisticCard>
                        <Badge
                            sx={{
                                background: colors.secondary.secondaryOutlinedHoverBg,
                                color: colors.secondary.secondaryMain,
                                borderRadius: '8px',
                                height: ptr(40),
                                width: ptr(40),
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <TextSnippet fontSize="medium" />
                        </Badge>
                        <StatisticCardInfo>
                            <Typography color={colors.text.secondaryTextLight} variant="body-small-default">
                                Toplam Oluşturulmuş Talep
                            </Typography>
                            <Typography variant="h6-medium">{requestStatistics?.totalCount}</Typography>
                        </StatisticCardInfo>
                    </StatisticCard>
                    <StatisticCard>
                        <Badge
                            sx={{
                                background: colors.warning.warningOutlinedHoverBg,
                                color: colors.warning.warningMain,
                                borderRadius: '8px',
                                height: ptr(40),
                                width: ptr(40),
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <TextSnippet fontSize="medium" />
                        </Badge>
                        <StatisticCardInfo>
                            <Typography color={colors.text.secondaryTextLight} variant="body-small-default">
                                Toplam Aktif Talep
                            </Typography>
                            <Typography variant="h6-medium">{requestStatistics?.totalPublishedCount}</Typography>
                        </StatisticCardInfo>
                    </StatisticCard>
                    <StatisticCard>
                        <Badge
                            sx={{
                                background: colors.error.errorOutlinedHoverBg,
                                color: colors.error.errorMain,
                                borderRadius: '8px',
                                height: ptr(40),
                                width: ptr(40),
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Block fontSize="medium" />
                        </Badge>
                        <StatisticCardInfo>
                            <Typography color={colors.text.secondaryTextLight} variant="body-small-default">
                                Toplam Kapanan Talep
                            </Typography>
                            <Typography variant="h6-medium">{requestStatistics?.totalUnPublishedCount}</Typography>
                        </StatisticCardInfo>
                    </StatisticCard>
                    <StatisticCard>
                        <Badge
                            sx={{
                                background: colors.success.SuccessLightBg,
                                color: colors.success.successMain,
                                borderRadius: '8px',
                                height: ptr(40),
                                width: ptr(40),
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <CheckCircle fontSize="medium" />
                        </Badge>
                        <StatisticCardInfo>
                            <Typography color={colors.text.secondaryTextLight} variant="body-small-default">
                                Toplam Yapılan Alışveriş
                            </Typography>
                            <Typography variant="h6-medium">{requestStatistics?.totalPurchased}</Typography>
                        </StatisticCardInfo>
                    </StatisticCard>
                </Statistics>
                <RequestsFilterSection>
                    <CustomFilterBox>
                        <Typography variant="h6-medium">Filtreleyerek Ara</Typography>
                        <CustomCardDiv>
                            <Box width={'30%'}>
                                <AutocompleteContainer
                                    width="100%"
                                    label="Marka Seç"
                                    onChange={(e) => {
                                        setVehicleMake(e as string)
                                        if (currentPage !== 0) setCurrentPage(0)
                                    }}
                                    options={vehicleMakes?.map((vehicle) => ({ label: vehicle.name, value: vehicle.name }))}
                                    selectedValue={vehicleMake}
                                />
                            </Box>
                            <Box width={'30%'}>
                                <AutocompleteContainer
                                    width="100%"
                                    label="Parça Seç"
                                    options={workCategories?.map((category) => ({
                                        label: category.name,
                                        value: category.name,
                                    }))}
                                    onChange={(e) => {
                                        setWorkCategory(e as string)
                                        if (currentPage !== 0) setCurrentPage(0)
                                    }}
                                    selectedValue={workCategory}
                                />
                            </Box>
                            <Box width={'30%'}>
                                <AutocompleteContainer
                                    width="100%"
                                    label="Alt Kategori Seç"
                                    onChange={(e) => {
                                        setWorkSubCategory(e as string)
                                        if (currentPage !== 0) setCurrentPage(0)
                                    }}
                                    options={workSubCategories?.map((subCategory) => ({
                                        label: subCategory.name,
                                        value: subCategory.name,
                                    }))}
                                    selectedValue={workSubCategory}
                                    disabled={!workCategory}
                                />
                            </Box>
                            <Box width={'30%'}>
                                <AutocompleteContainer
                                    width="100%"
                                    label="Talep Durumu Seç"
                                    onChange={(e) => {
                                        setRequestStatus(getValue(ERequestStatus, e as string))
                                        if (currentPage !== 0) setCurrentPage(0)
                                    }}
                                    options={requestStatusOptions}
                                    selectedValue={requestStatus}
                                />
                            </Box>
                        </CustomCardDiv>
                    </CustomFilterBox>
                </RequestsFilterSection>
                <RequestTableContainer>
                    <RequestTableHead>
                        <CustomExportBox>
                            <Button
                                startIcon={<IosShare />}
                                onClick={() => handleExport(exportToggle, setExportToggle)}
                                variant="outlined"
                                colorsx={colors.secondary.secondaryAlternativeLight}
                                borderColor={colors.secondary.secondaryAlternativeLight}
                                padding={ptr(16)}
                            >
                                <ExportDropdown<RequestModel>
                                    array={exportArray}
                                    fileName={'Talepler'}
                                    title={''}
                                    sheetName={''}
                                    headers={exportOptions}
                                    open={exportToggle}
                                    buttonHeader={'Export'}
                                    isSuccess={isSuccessRequestsExport}
                                />
                            </Button>
                            <Typography variant="body-smaller-default">Maksimum 1000 veri</Typography>
                        </CustomExportBox>
                        <RequestTableFilterContainer>
                            <SearchInput
                                fullHeight
                                fullWidth
                                value={filterText}
                                onChange={(val) => {
                                    setFilterText(val)
                                    if (currentPage !== 0) setCurrentPage(0)
                                }}
                                placeholder="Talep Ara"
                            />
                            <DatePicker
                                setEndDate={setEndDate}
                                setStartDate={setStartDate}
                                startDate={startDate}
                                endDate={endDate}
                                value={`${startDate} - ${endDate}`}
                                width={'100%'}
                                placeholder={'Tarihe Göre Filtrele'}
                                onSelectDate={({ startDate, endDate }) => {
                                    handleSelectDate(startDate, endDate)
                                    if (currentPage !== 0) setCurrentPage(0)
                                }}
                            />
                        </RequestTableFilterContainer>
                    </RequestTableHead>
                    <Table
                        count={requestsData?.data.result.totalCount}
                        isLoading={isLoadingRequests}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        onPageChange={onPageChange}
                        head={requestTableHead}
                        result={rowPerPage}
                        rowsData={rowData}
                        isClickable={false}
                        page={currentPage}
                        menu={true}
                    />
                </RequestTableContainer>
            </RequestsContainer>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} validateOnBlur enableReinitialize>
                {({ isSubmitting, values, handleChange, handleSubmit, isValid, handleBlur, resetForm }) => (
                    <Modal
                        open={isWithdrawRequestModal}
                        onClose={() => {
                            setIsWithdrawRequestModal(false)
                            resetForm()
                        }}
                        onConfirm={() => {
                            handleSubmit()
                        }}
                        header="Talebi Yayından Kaldırmak Üzeresiniz!"
                        subheader={``}
                        disabled={isSubmitting || !isValid || values.rejectReason === ''}
                    >
                        <ErrorMessageControler>
                            <TextField
                                name="rejectReason"
                                multiline={true}
                                width="100%"
                                onBlur={handleBlur}
                                value={values.rejectReason}
                                placeholder="Lütfen talebi yayından kaldırma sebebinizi açıklayın..."
                                rows={5}
                                onChange={(e, changeEvent) => handleChange(changeEvent)}
                            />
                            <ErrorMessage name="rejectReason" component={'div'} />
                        </ErrorMessageControler>

                        <Spinner open={isLoadingRequests && startDate.length > 1} />
                    </Modal>
                )}
            </Formik>
        </DashboardLayout>
    )
}

export default Demands
