import React, { FC, useState } from 'react'

import * as Yup from 'yup'
import { Form, Formik } from 'formik'

import { AddProductCategoryProps, ProductCategoryFormValues } from './type'
import Button from 'components/Buttons/Button/Button'
import CheckBox from 'components/Buttons/Checkbox/Checkbox'
import { BottomCheckboxConteiner, BottomCheckboxContent, CheckboxContainer, TopCheckboxConteiner, TopCheckboxsConteiner } from './index.styled'

import Typography from 'components/Typography/Typography'
import { ButtonsSide, ErrorMessage } from '../index.styled'
import { Box } from '@mui/material'
import { ArrowBack, ArrowForward } from '@mui/icons-material'
import { handleFilter } from '../../../../utils/helpers'
import { ProductSearchInput } from '../../index.styled'

const ProductCategoryInformation: FC<AddProductCategoryProps> = ({ handleNext, handleBack, onChange, value, vehicleMakes, vehicleTypes, workCategories, companies }) => {
    const [filterText, setFilterText] = useState<string>('')
    const validationSchema = Yup.object({
        workCategory: Yup.string().required('Bu alan zorunludur.'),
        vehicleType: Yup.string().required('Bu alan zorunludur.'),
        vehicleMake: Yup.string().required('Bu alan zorunludur.'),
        blockedCompanies: Yup.array().nullable(),
    })

    const onSubmit = (values: ProductCategoryFormValues) => {
        onChange({ ...values })
        handleNext()
    }

    return (
        <Formik initialValues={value} validationSchema={validationSchema} onSubmit={onSubmit} validateOnBlur enableReinitialize>
            {({ values, handleSubmit, handleBlur, setFieldValue }) => (
                <Form>
                    <CheckboxContainer>
                        <TopCheckboxsConteiner>
                            <TopCheckboxConteiner>
                                <Typography variant="body-small-semibold">Kategoriler</Typography>
                                {workCategories.map((option, index) => (
                                    <div onBlur={handleBlur} key={index}>
                                        <CheckBox
                                            size="small"
                                            name={`workCategory`}
                                            checked={values.workCategory === option}
                                            label={option}
                                            value={option}
                                            onChange={(e, checked) => {
                                                if (checked) {
                                                    setFieldValue('workCategory', option)
                                                } else setFieldValue('workCategory', '')
                                            }}
                                        />
                                    </div>
                                ))}
                                <ErrorMessage name="workCategory" component={'div'} />
                            </TopCheckboxConteiner>
                            <TopCheckboxConteiner>
                                <Typography variant="body-small-semibold">Vasıta Tipi</Typography>
                                {vehicleTypes.map((option, index) => (
                                    <div onBlur={handleBlur} key={index}>
                                        <CheckBox
                                            size="small"
                                            name={`vehicleType`}
                                            checked={values.vehicleType === option}
                                            label={option}
                                            value={option}
                                            onChange={(e, checked) => {
                                                if (checked) {
                                                    setFieldValue('vehicleType', option)
                                                } else setFieldValue('vehicleType', '')
                                            }}
                                        />
                                    </div>
                                ))}
                                <ErrorMessage name="vehicleType" component={'div'} />
                            </TopCheckboxConteiner>
                        </TopCheckboxsConteiner>
                        <BottomCheckboxConteiner className="BottomCheckboxConteiner">
                            <Typography variant="body-small-semibold">Markalar</Typography>
                            <div>
                                <BottomCheckboxContent className="BottomCheckboxContent">
                                    {vehicleMakes.map((option, index) => (
                                        <span onBlur={handleBlur} key={index}>
                                            <CheckBox
                                                size="small"
                                                name={`vehicleMake`}
                                                checked={values.vehicleMake === option}
                                                label={option}
                                                value={option}
                                                onChange={(e, checked) => {
                                                    if (checked) {
                                                        setFieldValue('vehicleMake', option)
                                                    } else setFieldValue('vehicleMake', '')
                                                }}
                                            />
                                        </span>
                                    ))}
                                </BottomCheckboxContent>
                                <ErrorMessage name="vehicleMake" component={'div'} />
                            </div>
                        </BottomCheckboxConteiner>
                        <BottomCheckboxConteiner className="BottomCheckboxConteiner">
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography variant="body-small-semibold">Bloklu Firmalar</Typography>
                                <ProductSearchInput onChange={(e) => handleFilter(e, setFilterText)} defaultValue={filterText} placeholder="Firma veya Kullanıcı Ara" />
                            </Box>
                            <div>
                                <BottomCheckboxContent className="BottomCheckboxContent">
                                    {companies
                                        .filter((company) => company.name.toLowerCase().includes(filterText.toLowerCase()))
                                        .map((option, index) => (
                                            <span onBlur={handleBlur} key={index}>
                                                <CheckBox
                                                    size="small"
                                                    name={`blockedCompanies`}
                                                    checked={!!values.blockedCompanies.find((blockedCompany) => blockedCompany.id === option.id)}
                                                    label={option.name}
                                                    value={option.name}
                                                    onChange={(e, checked) => {
                                                        if (checked) {
                                                            onChange({ ...values, blockedCompanies: [...values.blockedCompanies, { ...option }] })
                                                            setFieldValue('blockedCompanies', [...values.blockedCompanies, { ...option }])
                                                        } else {
                                                            const filteredBlockedCompanies = values.blockedCompanies.filter((blockedCompany) => blockedCompany.id !== option.id)
                                                            onChange({ ...values, blockedCompanies: filteredBlockedCompanies })
                                                        }
                                                    }}
                                                />
                                            </span>
                                        ))}
                                </BottomCheckboxContent>
                                <ErrorMessage name="blockedCompanies" component={'div'} />
                            </div>
                        </BottomCheckboxConteiner>
                    </CheckboxContainer>
                    <ButtonsSide>
                        <Button id="next" onClick={handleSubmit} disabled={false} endIcon={<ArrowForward />}>
                            İleri
                        </Button>
                        <Button variant="outlined" color="secondary" onClick={handleBack} startIcon={<ArrowBack />}>
                            Geri
                        </Button>
                    </ButtonsSide>
                </Form>
            )}
        </Formik>
    )
}

export default ProductCategoryInformation
