import { Typography } from '@mui/material'

import NotFoundImage from '../../assets/Images/NotFound.svg'

import { Container, CustomButton, CustomTextBox } from './index.styled'
import BottomImage from 'components/bottomImage'

import './index.styled'
import { NotFoundProps } from './types'

const NotFound: React.FunctionComponent<NotFoundProps> = (props) => {
    return (
        <>
            <Container>
                <CustomTextBox>
                    <Typography sx={{ color: 'rgba(76, 78, 100, 0.87)', fontSize: '96px', fontWeight: '500' }}>404</Typography>
                    <Typography sx={{ color: 'rgba(76, 78, 100, 0.87)', fontSize: '24px', fontWeight: '600' }}> {props.item ? props.item : 'Sayfa'} Bulunamadı ⚠️</Typography>
                    <Typography sx={{ color: 'rgba(76, 78, 100, 0.6)', fontSize: '14px', fontWeight: '400' }}>
                        aradığın {props.item ? `${props.item.toLocaleLowerCase()}` : 'sayfa'} bulunamadı
                    </Typography>
                </CustomTextBox>
                <img style={{ zIndex: '999' }} width={230} height={500} src={NotFoundImage} alt="notFound" />
                <CustomButton href="/" variant="contained">
                    ANASAYFAYA DÖN
                </CustomButton>
            </Container>
            <BottomImage type="Blue" />
        </>
    )
}

export default NotFound
