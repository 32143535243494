import DashboardLayout from '../../layouts/Dashboard/DashboardLayout'
import { Container, CustomInputMask, ErrorMessageContainer, FormContainer, FormHeader, FormItemContainer, FormLine, PasswordRule, PasswordRuleTitle } from './admin.styled'
import TextField from '../../components/Input/TextField/TextField'
import Button from '../../components/Buttons/Button/Button'
import { ptr } from '../../utils/helpers'
import { Formik } from 'formik'
import * as Yup from 'yup'

import React, { useEffect, useState } from 'react'
import { useGetAdmin, useUpdateAdmin, useUpdateAdminPassword, useUpdateAdminPortal } from '../../utils/hooks/queries/Admin'
import { IPassword, UpdateAdminInfoVariables } from '../../services/be-api/admin/types'
import { BankAccount } from '../../services/be-api/companies/types'
import Modal from '../../components/Modal/Modal'
import { IconButton } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { IBAN_REGEX } from 'utils/constants'

const Admin = () => {
    const profileValidationSchema = Yup.object({
        name: Yup.string().required('Bu alan zorunludur').max(64),
        surname: Yup.string().required('Bu alan zorunludur').max(64),
        emailAddress: Yup.string().email('Lütfen geçerli bir mail adresi giriniz').required('Bu alan zorunludur'),
    })

    const passwordValidationSchema = Yup.object({
        currentPassword: Yup.string().required('Bu alan zorunludur'),
        newPassword: Yup.string()
            .required('Bu alan zorunludur')
            .min(8, 'Şifreniz minimum 8 karakter içermelidir')
            .max(32, 'Şifreniz maximum 32 karakter içermelidir')
            .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/, 'Yeni şifre minimum 8 karakter, en az 1 büyük karakter ile 1 küçük harf ve 1 rakam içermelidir')
            .notOneOf([Yup.ref('currentPassword')], 'Lütfen aktif olarak kullandığınız şifreden farklı bir şifre giriniz'),
        newPasswordAgain: Yup.string()
            .required('Bu alan zorunludur')
            .max(32, 'Şifreniz maximum 32 karakter içermelidir')
            .oneOf([Yup.ref('newPassword')], 'Lütfen yeni şifre ve yeni şifre tekrarla alanlarını aynı giriniz')
            .notOneOf([Yup.ref('currentPassword')], 'Lütfen aktif olarak kullandığınız şifreden farklı bir şifre giriniz'),
    })

    const bankAccountValidationSchema = Yup.object({
        nameSurname: Yup.string().required('Bu alan zorunludur.').max(64),
        bankName: Yup.string().required('Bu alan zorunludur.').max(64),
        ibanNumber: Yup.string().nonNullable().required('Bu alan zorunludur.').matches(IBAN_REGEX, 'Lütfen geçerli bir iban giriniz'),
    })

    const initialPasswords = { newPassword: '', newPasswordAgain: '', currentPassword: '' } as IPassword
    const [adminProfileData, setAdminProfileData] = useState<UpdateAdminInfoVariables>({} as UpdateAdminInfoVariables)
    const [bankAccounts, setBankAccounts] = useState<BankAccount[]>([{} as BankAccount])
    const [confirmationModalVisible, setConfirmationModalVisible] = useState<boolean>(false)
    const [updateFn, setUpdateFn] = useState<{ data: any; fn: Function }>()
    const [isShowCurrentPassword, setIsShowCurrentPassword] = useState<boolean>(false)
    const [isShowNewPassword, setIsShowNewPassword] = useState<boolean>(false)
    const [isShowNewAgainPassword, setIsShowNewAgainPassword] = useState<boolean>(false)
    const { data: adminData } = useGetAdmin({ queryKeys: {} })
    const { mutate: updatePassword } = useUpdateAdminPassword()
    const { mutate: updateAdminInfo } = useUpdateAdmin()
    const { mutate: updateAdminPortal } = useUpdateAdminPortal()

    useEffect(() => {
        if (adminData?.data) {
            setAdminProfileData(adminData?.data.result)
            if (adminData?.data.result.bankAccounts) {
                const bankAccounts = JSON.parse(adminData?.data.result.bankAccounts)
                if (Array.isArray(bankAccounts)) setBankAccounts(bankAccounts)
                else setBankAccounts([bankAccounts ?? ({} as BankAccount)])
            }
        }
    }, [adminData])

    return (
        <DashboardLayout>
            <Container>
                <Formik
                    initialValues={adminProfileData}
                    validationSchema={profileValidationSchema}
                    onSubmit={(data) => {
                        setUpdateFn({
                            data,
                            fn: updateAdminInfo,
                        })
                        setConfirmationModalVisible(true)
                    }}
                    enableReinitialize
                    validateOnBlur
                >
                    {({ values, handleChange, handleSubmit, isValid, handleBlur }) => {
                        return (
                            <FormContainer>
                                <FormHeader>Profil Bilgileri</FormHeader>
                                <FormLine>
                                    <FormItemContainer>
                                        <TextField value={values.name} name="name" placeholder="Admin İsmi" width="100%" onChange={(e, k) => handleChange(k)} onBlur={handleBlur} />
                                        <ErrorMessageContainer name="name" component="div" />
                                    </FormItemContainer>
                                    <FormItemContainer>
                                        <TextField value={values.surname} name="surname" placeholder="Admin Soyismi" width="100%" onChange={(e, k) => handleChange(k)} onBlur={handleBlur} />
                                        <ErrorMessageContainer name="surname" component="div" />
                                    </FormItemContainer>
                                    <FormItemContainer>
                                        <TextField
                                            value={values.emailAddress}
                                            name="emailAddress"
                                            placeholder="admin@gmail.com"
                                            width="100%"
                                            onChange={(e, k) => handleChange(k)}
                                            onBlur={handleBlur}
                                        />
                                        <ErrorMessageContainer name="emailAddress" component="div" />
                                    </FormItemContainer>
                                </FormLine>
                                <FormLine sx={{ gap: ptr(16) }}>
                                    <Button id="next" onClick={handleSubmit} disabled={!isValid}>
                                        KAYDET
                                    </Button>
                                </FormLine>
                            </FormContainer>
                        )
                    }}
                </Formik>

                <Formik
                    initialValues={initialPasswords}
                    validationSchema={passwordValidationSchema}
                    onSubmit={(data) => {
                        setUpdateFn({
                            data,
                            fn: updatePassword,
                        })
                        setConfirmationModalVisible(true)
                    }}
                    validateOnBlur
                    enableReinitialize
                    validateOnMount
                >
                    {({ values, handleChange, handleSubmit, isValid, handleBlur, isSubmitting, resetForm }) => {
                        return (
                            <FormContainer>
                                <FormHeader>Şifre Değiştir</FormHeader>
                                <FormLine>
                                    <FormItemContainer>
                                        <TextField
                                            value={values.currentPassword}
                                            type={isShowCurrentPassword ? 'text' : 'password'}
                                            name="currentPassword"
                                            placeholder="Mevcut Şifre"
                                            width="49%"
                                            onChange={(value, event) => handleChange(event)}
                                            onBlur={handleBlur}
                                            endAdornment={
                                                <IconButton onClick={() => setIsShowCurrentPassword(!isShowCurrentPassword)}>{isShowCurrentPassword ? <VisibilityOff /> : <Visibility />}</IconButton>
                                            }
                                        />
                                        <ErrorMessageContainer name="currentPassword" component="div" />
                                    </FormItemContainer>
                                </FormLine>
                                <FormLine>
                                    <FormItemContainer>
                                        <TextField
                                            value={values.newPassword}
                                            type={isShowNewPassword ? 'text' : 'password'}
                                            name="newPassword"
                                            placeholder="Yeni Şifre"
                                            width="100%"
                                            onChange={(value, event) => handleChange(event)}
                                            onBlur={handleBlur}
                                            endAdornment={<IconButton onClick={() => setIsShowNewPassword(!isShowNewPassword)}>{isShowNewPassword ? <VisibilityOff /> : <Visibility />}</IconButton>}
                                        />
                                        <ErrorMessageContainer name="newPassword" component="div" />
                                    </FormItemContainer>
                                    <FormItemContainer>
                                        <TextField
                                            value={values.newPasswordAgain}
                                            type={isShowNewAgainPassword ? 'text' : 'password'}
                                            name="newPasswordAgain"
                                            placeholder="Yeni Şifre Tekrarla"
                                            width="100%"
                                            onChange={(value, event) => handleChange(event)}
                                            onBlur={handleBlur}
                                            endAdornment={
                                                <IconButton onClick={() => setIsShowNewAgainPassword(!isShowNewAgainPassword)}>
                                                    {isShowNewAgainPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            }
                                        />
                                        <ErrorMessageContainer name="newPasswordAgain" component="div" />
                                    </FormItemContainer>
                                </FormLine>
                                <FormLine>
                                    <PasswordRuleTitle>
                                        Şifre Gereksinimleri
                                        <PasswordRule>Minimum 8 karakter</PasswordRule>
                                        <PasswordRule>En az bir büyük ve bir küçük harf</PasswordRule>
                                        <PasswordRule>En az bir adet rakam</PasswordRule>
                                    </PasswordRuleTitle>
                                </FormLine>
                                <FormLine sx={{ gap: ptr(16) }}>
                                    <Button type="submit" onClick={handleSubmit} disabled={!isValid || isSubmitting}>
                                        KAYDET
                                    </Button>
                                    <Button type="reset" variant="outlined" color="secondary" onClick={() => resetForm()}>
                                        İPTAL
                                    </Button>
                                </FormLine>
                            </FormContainer>
                        )
                    }}
                </Formik>
                <Formik
                    initialValues={bankAccounts[0]}
                    validationSchema={bankAccountValidationSchema}
                    onSubmit={(data) => {
                        setUpdateFn({
                            data: { bankAccounts: data },
                            fn: updateAdminPortal,
                        })
                        setConfirmationModalVisible(true)
                    }}
                    enableReinitialize
                    validateOnBlur
                    validateOnMount
                >
                    {(props) => {
                        const { handleChange, handleSubmit, values, isSubmitting, handleBlur, isValid } = props
                        return (
                            <FormContainer>
                                <FormHeader>Banka Bilgileri</FormHeader>
                                <FormLine>
                                    <FormItemContainer>
                                        <TextField name="bankName" value={values?.bankName} placeholder="Banka Adı" width="49%" onChange={(value, event) => handleChange(event)} onBlur={handleBlur} />
                                        <ErrorMessageContainer name="bankName" component="div" />
                                    </FormItemContainer>
                                </FormLine>
                                <FormLine>
                                    <FormItemContainer>
                                        <TextField
                                            value={values?.nameSurname}
                                            name="nameSurname"
                                            placeholder="Hesap Adı"
                                            width="100%"
                                            onChange={(value, event) => handleChange(event)}
                                            onBlur={handleBlur}
                                        />
                                        <ErrorMessageContainer name="nameSurname" component="div" />
                                    </FormItemContainer>
                                    <FormItemContainer>
                                        <CustomInputMask
                                            name="ibanNumber"
                                            mask="TR 9999 9999 9999 9999 9999 9999"
                                            placeholder="IBAN"
                                            onChange={(changeEvent) => handleChange(changeEvent)}
                                            value={values?.ibanNumber}
                                            onBlur={handleBlur}
                                        />
                                        <ErrorMessageContainer name="ibanNumber" component="div" />
                                    </FormItemContainer>
                                </FormLine>
                                <FormLine sx={{ gap: ptr(16) }}>
                                    <Button id="next" onClick={handleSubmit} disabled={!isValid || isSubmitting}>
                                        KAYDET
                                    </Button>
                                </FormLine>
                            </FormContainer>
                        )
                    }}
                </Formik>
            </Container>
            <Modal
                open={confirmationModalVisible}
                onClose={() => setConfirmationModalVisible(false)}
                onConfirm={() => {
                    updateFn?.fn(updateFn.data)
                    setConfirmationModalVisible(false)
                }}
                header="Admin Bilgileri Güncelleme"
                subheader={`Admin bilgilerini güncellemek istiyor musunuz?`}
            />
        </DashboardLayout>
    )
}

export default Admin
