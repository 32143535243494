import styled from '@emotion/styled'
import { ptr } from 'utils/helpers'
import { Box, Button, TextField } from '@mui/material'
import { colors } from 'styles/color'

export const CustomNotificationsContainer = styled('div')({
    marginTop: ptr(44),
    marginRight: ptr(24),
    marginLeft: ptr(24),
})

export const SelectPageTypes = styled('div')({
    display: 'flex',
    width: '100%',
    padding: '4px 0 12px 0', // padding bottom değişirse SelectPageType top: "", da değişmeli
    boxShadow: ' 0px 2px 10px rgba(76, 78, 100, 0.22)',
})
export const SelectPageType = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    top: '12px',
    width: '50%',
    cursor: 'pointer',
})

export const CustomCardBox = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    textAlign: 'center',
    alignItems: 'center',
    marginBottom: ptr(24),
    gap: ptr(16),
    height: ptr(120),
})

export const CustomCardDiv = styled('div')({
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-between',
})

export const CustomTableDiv = styled('div')({
    boxShadow: ' 0px 2px 10px rgba(76, 78, 100, 0.22)',
    marginBottom: ptr(24),
})

export const CustomFilterBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-around',
    height: ptr(216),
    gap: ptr(16),
    padding: ptr(20),
    background: colors.white.default,
})

export const CustomHeadDiv = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    textAlign: 'center',
    alignItems: 'center',
    background: colors.white.default,
    padding: '0px 20px',
})
export const CustomFilterDiv = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'right',
    gap: ptr(24),
    textAlign: 'center',
    height: ptr(80),
    alignItems: 'center',
})

export const ModalInputs = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: ptr(16),
})

export const CustomExportBox = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    gap: ptr(16),
})

export const CustomSearchInput = styled(TextField)({
    height: '40px',
    '& > div': {
        height: '40px',
        fontWeight: '300',
        paddingRight: '12px',
        fontSize: '16px',
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: colors.primary.primaryMain,
        },
    },
})

export const AddUserButton = styled(Button)({
    paddingLeft: ptr(22),
    paddingRight: ptr(22),
    backgroundColor: colors.primary.primaryMain,
    ' &:hover': {
        backgroundColor: colors.primary.primaryContainedHoverBg,
    },
    color: colors.white.default,
})
