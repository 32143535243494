import React, { FC, useEffect, useState } from 'react'
import SummaryImg from '../../../../assets/Images/Summary.svg'
import Button from 'components/Buttons/Button/Button'
import { Content, ContentBox, GeneralContainer, Key, KeyContent, LastCheck, LeftSide, ProductImageItemImg, RigtSide, SummaryContainer, SummaryPhoto } from './index.styled'
import { ButtonsSide } from '../index.styled'
import { UpdateProductProps } from './types'
import { colors } from '../../../../styles/color'
import Typography from '../../../../components/Typography/Typography'
import { Box, ImageList } from '@mui/material'
import { ArrowBack, ArrowForward } from '@mui/icons-material'
import { CompanyModel } from '../../../../services/be-api/companies/types'
import { UpdateSpecialOfferData } from '../../../../services/be-api/products/types'
import { useUpdateSpecialOffer } from '../../../../utils/hooks/queries/Products'
import { useNavigate } from 'react-router-dom'
import Spinner from '../../../../components/Spinner'
import PriceText from '../../../../components/PriceText'

const Summary: FC<UpdateProductProps> = ({ handleBack, product, productCategory, companiesData, specialOfferId }) => {
    const navigate = useNavigate()

    const [company, setCompany] = useState<CompanyModel>({} as CompanyModel)
    const [isClicked, setIsClicked] = useState(false)
    const { mutate: updateSpecialOffer, isLoading, isSuccess } = useUpdateSpecialOffer()

    const onSubmit = () => {
        setIsClicked(true)
        const data: UpdateSpecialOfferData = {
            companyId: product.companyId,
            productName: product.productName,
            productDescription: product.productInfo,
            productPhotos: JSON.stringify(product.productImages),
            ownerIban: product.ownerIban,
            ownerBankName: product.ownerBankName,
            ownerFullName: product.ownerFullName,
            productPrice: Number(product.price),
            productDiscountedPrice: Number(product.campaignPrice),
            productStock: product.stockAmount,
            vehicleMake: productCategory.vehicleMake,
            vehicleType: productCategory.vehicleType,
            workCategory: productCategory.workCategory,
            restrictedCompanies: productCategory.blockedCompanies.map((blockedCompany) => ({ companyId: blockedCompany.id })),
        }
        updateSpecialOffer({ id: Number(specialOfferId), data })
    }

    useEffect(() => {
        const company = companiesData.find((company) => company.id === product.companyId)
        if (company) setCompany(company)
    }, [companiesData, product.companyId])

    useEffect(() => {
        if (isSuccess) navigate('/products')
    }, [isSuccess, navigate])

    return (
        <GeneralContainer>
            <Spinner open={isLoading} />
            <SummaryContainer>
                <LeftSide>
                    <LastCheck>
                        <Typography variant="h5-medium">Neredeyse Tamam! 🚀</Typography>
                        <Typography>Oluşturmak istediğin kampanyaya ait bilgileri kontrol ettikten sonra onaylayabilirsin.</Typography>
                    </LastCheck>

                    <Typography variant="body-normal-semibold" color={colors.text.primaryTextLight}>
                        Kullanıcı Bilgileri
                    </Typography>
                    <Box>
                        <KeyContent>
                            <ContentBox>
                                <Key>Firma Adı</Key>
                            </ContentBox>
                            <ContentBox>
                                <Content>{company.name}</Content>
                            </ContentBox>
                        </KeyContent>
                        <KeyContent>
                            <ContentBox>
                                <Key>Ürün İsmi</Key>
                            </ContentBox>
                            <ContentBox>
                                <Content>{product.productName}</Content>
                            </ContentBox>
                        </KeyContent>
                        <KeyContent>
                            <ContentBox>
                                <Key>Liste Fiyatı</Key>
                            </ContentBox>
                            <ContentBox>
                                <Content>
                                    <PriceText price={product.price} />
                                </Content>
                            </ContentBox>
                        </KeyContent>
                        <KeyContent>
                            <ContentBox>
                                <Key>İndirimli Fiyat</Key>
                            </ContentBox>
                            <ContentBox>
                                <Content>
                                    <PriceText price={product.campaignPrice} />
                                </Content>
                            </ContentBox>
                        </KeyContent>
                        <KeyContent>
                            <ContentBox>
                                <Key>Stok</Key>
                            </ContentBox>
                            <ContentBox>
                                <Content>{product.stockAmount}</Content>
                            </ContentBox>
                        </KeyContent>
                        <KeyContent>
                            <ContentBox>
                                <Key>Ürün Açıklaması</Key>
                            </ContentBox>
                            <ContentBox>
                                <Content>{product.productInfo}</Content>
                            </ContentBox>
                        </KeyContent>
                        <KeyContent sx={{ alignItems: 'flex-start' }}>
                            <ContentBox>
                                <Key>Ürün Fotoğrafı</Key>
                            </ContentBox>
                            <ContentBox>
                                <ImageList cols={5} gap={3}>
                                    {product.productImages?.map((photo, index: number) => (
                                        <SummaryPhoto>
                                            <ProductImageItemImg src={photo} alt={`${product.productName}_${index}`} />
                                        </SummaryPhoto>
                                    ))}
                                </ImageList>
                            </ContentBox>
                        </KeyContent>
                        <KeyContent sx={{ alignItems: 'flex-end' }}>
                            <ContentBox>
                                <Key>Hesap Sahibi Banka IBAN</Key>
                            </ContentBox>
                            <ContentBox>
                                <Content>
                                    {product.ownerFullName}, {product.ownerBankName}, {product.ownerIban}
                                </Content>
                            </ContentBox>
                        </KeyContent>
                    </Box>

                    <Box>
                        <Typography>Ürün Kategori Bilgisi</Typography>
                        <KeyContent>
                            <ContentBox>
                                <Key>Kategori</Key>
                            </ContentBox>
                            <ContentBox>
                                <Content>{productCategory.workCategory}</Content>
                            </ContentBox>
                        </KeyContent>
                        <KeyContent>
                            <ContentBox>
                                <Key>Vasıta Tipi</Key>
                            </ContentBox>
                            <ContentBox>
                                <Content>{productCategory.vehicleType}</Content>
                            </ContentBox>
                        </KeyContent>
                        <KeyContent>
                            <ContentBox>
                                <Key>Markalar</Key>
                            </ContentBox>
                            <ContentBox>
                                <Content>{productCategory.vehicleMake}</Content>
                            </ContentBox>
                        </KeyContent>
                        <KeyContent>
                            <ContentBox>
                                <Key>Bloklu Firmalar</Key>
                            </ContentBox>
                            <ContentBox>
                                <Content>{productCategory.blockedCompanies.map((blockedCompany) => blockedCompany.name).join(' / ')}</Content>
                            </ContentBox>
                        </KeyContent>
                    </Box>
                </LeftSide>
                <RigtSide>
                    <img width={115} height={310} src={SummaryImg} alt="Elinde Tabet Tutan Adam" />
                </RigtSide>
            </SummaryContainer>
            <ButtonsSide>
                <Button width="fit-content" padding={'0px 20px'} onClick={onSubmit} color={'success'} endIcon={<ArrowForward />} disabled={isClicked}>
                    Güncelle
                </Button>
                <Button variant="outlined" color="secondary" onClick={handleBack} startIcon={<ArrowBack />}>
                    Geri
                </Button>
            </ButtonsSide>
        </GeneralContainer>
    )
}

export default Summary
