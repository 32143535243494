import { Request } from '../../_base'
import { GET_ORDER_HISTORY, GET_ORDERS, PUT_DELIVERED_ORDER, PUT_ORDERS_UPDATE } from './constants'
import { RequestParams, Service } from '../../types'
import { GetOrderHistoryVariables, OrderFilterVariables, OrderStatusFilter, UpdateOrderStatusVariables } from './types'

export const getOrders: Service<RequestParams<OrderFilterVariables>> = ({ queryKey }) => {
    const { params } = queryKey[1]
    return Request.get(GET_ORDERS, params)
}

export const updateOrderStatus: Service<UpdateOrderStatusVariables> = ({ id, status, data }) => {
    switch (status) {
        case OrderStatusFilter.CANCELED:
            return Request.put(PUT_ORDERS_UPDATE(id, 'cancel'), data, {})
        case OrderStatusFilter.DELIVERED:
            return Request.put(PUT_DELIVERED_ORDER(id), {}, {})
        case OrderStatusFilter.INPREPARATIO:
            return Request.put(PUT_ORDERS_UPDATE(id, 'in-preparation'), {}, {})
        case OrderStatusFilter.SHIPPING:
            return Request.put(PUT_ORDERS_UPDATE(id, 'shipped'), data, {})
    }
}

export const getOrderHistory: Service<RequestParams<GetOrderHistoryVariables>> = ({ queryKey }) => {
    const { params } = queryKey[1]
    return Request.get(GET_ORDER_HISTORY(params.id), {})
}
