import { styled } from '@mui/material'

export const BottomImageDiv = styled('div')({
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    width: '100vw',
})
export const CustomImg = styled('img')({
    width: '100%',
    height: '100%',
})
