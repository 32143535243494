import React, { useEffect, useState } from 'react'

import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs'
import DashboardLayout from 'layouts/Dashboard/DashboardLayout'
import Table from 'components/Table/Table'
import Spinner from 'components/Spinner'
import Button from 'components/Buttons/Button/Button'
import ExportDropdown from 'components/ExportDropdown'
import Typography from 'components/Typography/Typography'
import { TableBodyRowData } from 'components/Table/types'
import AutocompleteContainer from 'components/Input/AutoComplete/Autocomplete'
import { CustomCardDiv, CustomExportBox, CustomFilterBox, CustomFilterDiv, CustomHeadDiv, CustomTableDiv, PopularityContainer } from '../index.styled'

import { ptr } from 'utils/helpers'
import { useGetWorkCategory } from 'utils/hooks/queries/LookUp'
import { useGetCompanies } from 'utils/hooks/queries/Companies'
import { useGetAllTopSpecialOffer } from 'utils/hooks/queries/Dashboard/useDashboard'

import { IosShare } from '@mui/icons-material'
import { Box } from '@mui/material'
import { colors } from 'styles/color'

import { SpecialOffer } from 'services/be-api/dashboard/types'
import { CompanyModel } from 'services/be-api/companies/types'
import { breadcrumbLinks, topSpecailOfferTableHead, topSpecialOffersExportOptions } from './constants'
import SearchInput from '../../../components/Input/SearchInput/SearchInput'

const TopCampaignDetail = () => {
    const [topSpecialOffer, setTopSpecialOffer] = useState<SpecialOffer[]>([])
    const [allCompanies, setAllCompanies] = useState<Array<CompanyModel>>([])
    const [exportArray, setExportArray] = useState<SpecialOffer[]>([])

    const [exportToggle, setExportToggle] = useState(false)
    // lookups
    const { data: workCategoriesData } = useGetWorkCategory({ queryKeys: {}, enabled: true })
    const [workCategories, setWorkCategories] = useState<{ value: string; label: string }[]>([])

    //filter
    const [filterText, setFilterText] = useState('')
    const [currentPage, setCurrentPage] = useState(0)
    const [rowPerPage, setRowPerPage] = useState(10)
    const [selectedWorkCategory, setSelectedWorkCategory] = useState('')

    const {
        data: topSpecialOfferData,
        isSuccess: isTopSpecialOfferSuccess,
        isLoading: isTopSpecialOfferLoading,
    } = useGetAllTopSpecialOffer({
        queryKeys: {
            workCategoryName: selectedWorkCategory || null,
            filter: filterText || null,
            maxResultCount: rowPerPage,
            skipCount: currentPage * rowPerPage || null,
        },
    })
    const {
        data: topSpecialOfferDataExport,
        isSuccess: isTopSpecialOfferSuccessExport,
        refetch: isTopSpecialOfferRefetchExport,
    } = useGetAllTopSpecialOffer({
        queryKeys: {
            workCategoryName: selectedWorkCategory || null,
            filter: filterText || null,
            maxResultCount: 1000,
            skipCount: currentPage * rowPerPage || null,
        },
        enabled: false,
    })
    const { data: companiesData, isSuccess: companiesSuccess } = useGetCompanies({
        queryKeys: {
            maxResultCount: 1000,
        },
    })

    useEffect(() => {
        if (isTopSpecialOfferSuccessExport && topSpecialOfferDataExport?.data.result) {
            setExportArray(topSpecialOfferDataExport.data.result.items)
        }
    }, [topSpecialOfferDataExport, isTopSpecialOfferSuccessExport])

    useEffect(() => {
        if (isTopSpecialOfferSuccess && topSpecialOfferData?.data) {
            setTopSpecialOffer(topSpecialOfferData?.data.result.items)
        }
    }, [isTopSpecialOfferSuccess, topSpecialOfferData])

    useEffect(() => {
        if (companiesSuccess && companiesData?.data.result.companies) {
            setAllCompanies(companiesData?.data.result.companies)
        }
    }, [companiesData, companiesSuccess])

    useEffect(() => {
        setWorkCategories(workCategoriesData?.data?.result?.map((child) => ({ value: child.name, label: child.name })) ?? [])
    }, [workCategoriesData])

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowPerPage(parseInt(event.target.value, 10))
        setCurrentPage(0)
    }
    const onPageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setCurrentPage(newPage)
    }

    const handleExport = (exportToggle: boolean, set: React.Dispatch<React.SetStateAction<boolean>>) => {
        set(!exportToggle)
        isTopSpecialOfferRefetchExport()
    }

    const rowData: TableBodyRowData[] = topSpecialOffer.map((data) => {
        let productPhotos
        if (data.productPhotos && Array.isArray(JSON.parse(data.productPhotos))) productPhotos = JSON.parse(data.productPhotos)

        return {
            selectableId: data.id,
            code: <Typography color={colors.primary.primaryMain}> {data.productCode}</Typography>,
            name: (
                <Box sx={{ display: 'flex', gap: ptr(30), alignItems: 'center' }}>
                    <Box
                        sx={{
                            height: '80px',
                            width: '80px',
                            backgroundColor: 'black',
                            borderRadius: '4px',
                        }}
                    >
                        <img height="100%" width="100%" src={productPhotos[0] || ''} alt={'Ürün'} />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="body-normal-semibold" color={colors.text.primaryTextLight}>
                            {data.productName}
                        </Typography>
                        <Box>
                            <Typography color={colors.text.secondaryTextLight}>{allCompanies.map((company) => company.id === data.id && company.name)}</Typography>
                        </Box>
                    </Box>
                </Box>
            ),
            information: (
                <Typography color={colors.text.secondaryTextLight} variant="body-small-default">
                    {data.productDescription}
                </Typography>
            ),
            purchasedStock: <Typography> {data.purchasedStock}</Typography>,
            remainingStock: <Typography color={colors.primary.primaryMain}> {data.remainingStock}</Typography>,
            salesCount: <Typography variant="body-normal-semibold"> {data.purchasedPrice}</Typography>,
        }
    })

    return (
        <DashboardLayout>
            <PopularityContainer>
                <Breadcrumbs links={breadcrumbLinks} />
                <CustomTableDiv>
                    <CustomFilterBox>
                        <Typography variant="h6-medium">Filtreleyerek Ara</Typography>
                        <CustomCardDiv>
                            <Box width={'30%'}>
                                <AutocompleteContainer
                                    width="100%"
                                    placeholder="Ana Kategori"
                                    options={workCategories}
                                    onChange={(e) => {
                                        setSelectedWorkCategory(e as string)
                                        if (currentPage !== 0) setCurrentPage(0)
                                    }}
                                />
                            </Box>
                        </CustomCardDiv>
                    </CustomFilterBox>
                </CustomTableDiv>
                <CustomTableDiv>
                    <CustomHeadDiv style={{ height: ptr(81) }}>
                        <CustomExportBox>
                            <Button
                                startIcon={<IosShare />}
                                onClick={() => handleExport(exportToggle, setExportToggle)}
                                variant="outlined"
                                colorsx={colors.secondary.secondaryAlternativeLight}
                                borderColor={colors.secondary.secondaryAlternativeLight}
                                padding={ptr(16)}
                            >
                                <ExportDropdown<SpecialOffer>
                                    array={exportArray}
                                    fileName={'Top Kampanya Detayı Tablo'}
                                    title={''}
                                    sheetName={''}
                                    headers={topSpecialOffersExportOptions}
                                    open={exportToggle}
                                    buttonHeader={'Export'}
                                    isSuccess={isTopSpecialOfferSuccessExport}
                                />
                            </Button>
                            <Typography variant="body-smaller-default">Maksimum 1000 veri</Typography>
                        </CustomExportBox>
                        <CustomFilterDiv>
                            <SearchInput
                                value={filterText}
                                onChange={(val) => {
                                    setFilterText(val)
                                    if (currentPage !== 0) setCurrentPage(0)
                                }}
                                placeholder="Kampanya Ara"
                            />
                        </CustomFilterDiv>
                    </CustomHeadDiv>
                    <div>
                        <Spinner open={isTopSpecialOfferLoading} />
                        <Table
                            count={topSpecialOfferData?.data.result.totalCount}
                            isLoading={isTopSpecialOfferLoading}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            onPageChange={onPageChange}
                            head={topSpecailOfferTableHead}
                            result={rowPerPage}
                            rowsData={rowData}
                            isClickable={false}
                            page={currentPage}
                        ></Table>
                    </div>
                </CustomTableDiv>
            </PopularityContainer>
        </DashboardLayout>
    )
}

export default TopCampaignDetail
