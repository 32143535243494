import { ImageListItem, styled } from '@mui/material'
import { colors } from 'styles/color'
import { ptr } from '../../../../utils/helpers'

export const GeneralContainer = styled('div')(() => ({}))
export const SummaryContainer = styled('div')(() => ({
    display: 'flex',
    width: '100%',
}))
export const LeftSide = styled('div')(() => ({
    width: '70%',
    display: 'flex',
    flexDirection: 'column',
    gap: ptr(16),
}))
export const RigtSide = styled('div')(() => ({
    width: '30%',
    display: 'flex',
    alignItems: 'flex-end',
}))
export const LastCheck = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
}))

export const KeyContent = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    marginTop: '8px',
    gap: '16px',
}))

export const Key = styled('div')(() => ({
    color: colors.text.secondaryTextLight,
    fontSize: '1rem',
    fontWeight: 600,
    width: ptr(130),
}))

export const Content = styled('div')(() => ({
    display: 'inline-block',
    color: colors.text.secondaryTextLight,
    fontSize: '1rem',
    fontWeight: 400,
}))
export const RoleContent = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    padding: '3px 5px 0 5px',
    borderRadius: '4px',
}))

export const ContentBox = styled('div')(() => ({}))

export const SummaryPhoto = styled(ImageListItem)({
    height: `${ptr(60)} !important`,
    width: ptr(66),
})

export const ProductImageItemImg = styled('img')({
    height: '100%',
    width: '100%',
    objectFit: 'contain',
    borderRadius: ptr(4),
})
