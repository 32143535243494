import React, { useEffect, useState } from 'react'
import { CustomAppRateContainer, CustomAutocompleteAppRate, CustomCardDiv, CustomExportBox, CustomFilterBox, CustomHeadDiv, CustomTableDiv } from './index.styled'
import DashboardLayout from 'layouts/Dashboard/DashboardLayout'
import Typography from 'components/Typography/Typography'
import { Box, Rating, TextField } from '@mui/material'
import DatePicker from 'components/DateRangePicker/DateRangePicker'
import Button from 'components/Buttons/Button/Button'
import { IosShare } from '@mui/icons-material'
import { endDateConverter, ptr, startDateConverter } from 'utils/helpers'
import { colors } from 'styles/color'
import ExportDropdown from 'components/ExportDropdown'
import Spinner from 'components/Spinner'
import Table from 'components/Table'
import { useGetAppRates } from 'utils/hooks/queries/AppRate'
import { AppRateModel } from 'services/be-api/app-rate/types'
import { GridColDef } from '@mui/x-data-grid'
import dayjs from 'dayjs'
import { TableBodyRowData } from 'components/Table/types'
import { useLocation, useNavigate } from 'react-router-dom'
import SearchInput from '../../components/Input/SearchInput/SearchInput'

export interface AppRateProps {
    selectedStartDate?: string
    selectedEndDate?: string
}

export const appRateHead: GridColDef[] = [
    { field: 'userName', headerName: 'PUANLAYAN', width: 80 },
    { field: 'rating', headerName: 'PUANI', width: 80 },
    { field: 'comment', headerName: 'YORUM', width: 550 },
    { field: 'creationTime', headerName: 'PUANLAMA TARİHİ', width: 80 },
]

export const rateOption = [
    {
        value: '1',
        label: '1',
    },
    {
        value: '2',
        label: '2',
    },
    {
        value: '3',
        label: '3',
    },
    {
        value: '4',
        label: '4',
    },
    {
        value: '5',
        label: '5',
    },
]

export const exportOptions = [
    {
        key: 'PUANLAYAN',
        label: 'PUANLAYAN',
        onRender: (item: string, element: AppRateModel) => {
            return `${element?.user.name}/${element?.user.surname}`
        },
    },
    {
        key: 'PUANI',
        label: 'PUANI',
        onRender: (item: string, element: AppRateModel) => {
            return `${element.rating}`
        },
    },
    {
        key: 'YORUM',
        label: 'YORUM',
        onRender: (item: string, element: AppRateModel) => {
            return `${element.comment}`
        },
    },
    {
        key: 'PUANLAMA TARIHI',
        label: 'PUANLAMA TARIHI',
        onRender: (item: string, element: AppRateModel) => {
            return `${dayjs(element?.creationTime).format('DD.MM.YYYY')}`
        },
    },
]

const AppRate = () => {
    const location = useLocation()
    const selectedDate = location.state
    const [exportArray, setExportArray] = useState<Array<AppRateModel>>([])
    const [appRate, setAppRate] = useState<Array<AppRateModel>>([])
    const [selectedRate, setSelectedRate] = useState<string[]>([])
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const navigate = useNavigate()
    const [exportToggle, setExportToggle] = useState(false)
    const [filterText, setFilterText] = useState('')
    const [currentPage, setCurrentPage] = useState(0)
    const [rowPerPage, setRowPerPage] = useState(10)
    const {
        data: appRateData,
        isLoading: appRateLoading,
        isSuccess: appRateSuccess,
    } = useGetAppRates({
        queryKeys: {
            maxResultCount: rowPerPage,
            skipCount: currentPage * rowPerPage || null,
            filter: filterText || null,
            rating: selectedRate?.toString() || null,
            startDate: startDate.length ? startDateConverter(startDate) : null,
            endDate: endDate.length ? endDateConverter(endDate) : null,
        },
    })

    const {
        data: appRateDataExport,
        isSuccess: appRateDataSuccessExport,
        refetch: appRateExportRefetch,
    } = useGetAppRates({
        queryKeys: {
            maxResultCount: 1000,
            skipCount: null,
            filter: filterText,
            rating: selectedRate.toString(),
            startDate: startDate.length ? startDateConverter(startDate) : null,
            endDate: endDate.length ? endDateConverter(endDate) : null,
        },
        enabled: false,
    })

    useEffect(() => {
        if (selectedDate) {
            setStartDate(selectedDate.startDate)
            setEndDate(selectedDate.endDate)
        }
    }, [selectedDate])

    useEffect(() => {
        if (appRateDataSuccessExport && appRateDataExport?.data.result) {
            setExportArray(appRateDataExport.data.result.items)
        }
    }, [appRateDataExport, appRateDataSuccessExport])

    useEffect(() => {
        if (appRateSuccess && appRateData) {
            setAppRate(appRateData.data.result.items)
        }
    }, [appRateData, appRateSuccess])

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowPerPage(parseInt(event.target.value, 10))
        setCurrentPage(0)
    }

    const onPageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setCurrentPage(newPage)
    }

    const handleSelectDate = (sDate: string, eDate: string) => {
        setStartDate(sDate)
        setEndDate(eDate)
    }

    const handleTo = (id: number, role: string) => {
        navigate(`/${role}/${id}`)
    }

    const handleExport = (exportToggle: boolean, set: React.Dispatch<React.SetStateAction<boolean>>) => {
        set(!exportToggle)
        appRateExportRefetch()
    }

    const rowData: TableBodyRowData[] = appRate.map((p) => {
        return {
            userName: (
                <Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="body-normal-semibold" onClick={() => handleTo(p.userId, 'users')}>
                            {p.user.name} {p.user.surname}{' '}
                        </Typography>
                        <Typography color={colors.text.secondaryTextLight} variant="body-normal-default">
                            {p?.user.company?.name}
                        </Typography>
                    </Box>
                </Box>
            ),

            rating: (
                <Box>
                    <Box>
                        <Rating value={p.rating} readOnly />
                    </Box>
                </Box>
            ),
            comment: (
                <Box sx={{ wordBreak: 'break-word' }}>
                    <Typography variant="body-normal-default">{p.comment}</Typography>
                </Box>
            ),
            creationTime: (
                <Box>
                    <Typography width={'100%'} variant="body-smaller-default">
                        {dayjs(p.creationTime).format('DD.MM.YYYY')}
                    </Typography>
                </Box>
            ),
        }
    })

    return (
        <DashboardLayout>
            <CustomAppRateContainer>
                <CustomTableDiv>
                    <CustomFilterBox>
                        <Typography variant="h6-medium">Puanlamaları Filtrele</Typography>
                        <CustomCardDiv>
                            <Box sx={{ width: '30%' }} display={'flex'} alignItems={'center'} flexDirection={'row'}>
                                <CustomAutocompleteAppRate
                                    multiple
                                    options={rateOption}
                                    getOptionLabel={(option: any) => {
                                        const typedOption = option as { value: string; label: string }
                                        return typedOption.label ?? ''
                                    }}
                                    defaultValue={[]}
                                    renderInput={(params) => <TextField {...params} variant="outlined" label="Yıldız Seç" />}
                                    style={{
                                        width: '100%',
                                        backgroundColor: 'transparent',
                                        height: '100%',
                                        borderRadius: ptr(4),
                                        color: `${colors.primary.primaryMain} !important`,
                                    }}
                                    onChange={(e, value: any) => {
                                        setSelectedRate(value.map((v: any) => v.value))
                                        if (currentPage !== 0) setCurrentPage(0)
                                    }}
                                />
                            </Box>
                            <Box sx={{ width: '30%' }}>
                                <SearchInput
                                    fullHeight
                                    fullWidth
                                    value={filterText}
                                    onChange={(val) => {
                                        setFilterText(val)
                                        if (currentPage !== 0) setCurrentPage(0)
                                    }}
                                    placeholder="Puanlama Ara"
                                />
                            </Box>
                            <Box sx={{ width: '30%' }}>
                                <DatePicker
                                    setEndDate={setEndDate}
                                    setStartDate={setStartDate}
                                    startDate={startDate}
                                    endDate={endDate}
                                    value={`${startDate} - ${endDate}`}
                                    width={'100%'}
                                    placeholder={'Tarihe Göre Filtrele'}
                                    onSelectDate={({ startDate, endDate }) => {
                                        handleSelectDate(startDate, endDate)
                                        if (currentPage !== 0) setCurrentPage(0)
                                    }}
                                />
                            </Box>
                        </CustomCardDiv>
                    </CustomFilterBox>
                </CustomTableDiv>
                <CustomTableDiv>
                    <CustomHeadDiv style={{ height: ptr(81) }}>
                        <CustomExportBox>
                            <Button
                                startIcon={<IosShare />}
                                onClick={() => handleExport(exportToggle, setExportToggle)}
                                variant="outlined"
                                colorsx={colors.secondary.secondaryAlternativeLight}
                                borderColor={colors.secondary.secondaryAlternativeLight}
                                padding={ptr(16)}
                            >
                                <ExportDropdown<AppRateModel>
                                    array={exportArray}
                                    fileName={'export'}
                                    title={''}
                                    sheetName={''}
                                    headers={exportOptions}
                                    open={exportToggle}
                                    buttonHeader={'Export'}
                                    isSuccess={appRateDataSuccessExport}
                                />
                            </Button>
                            <Typography variant="body-smaller-default">Maksimum 1000 veri</Typography>
                        </CustomExportBox>
                    </CustomHeadDiv>
                    <Spinner open={appRateLoading} />
                    <Table
                        count={appRateData?.data.result.totalCount}
                        isLoading={appRateLoading}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        onPageChange={onPageChange}
                        head={appRateHead}
                        result={rowPerPage}
                        rowsData={rowData}
                        isClickable={false}
                        page={currentPage}
                    ></Table>
                </CustomTableDiv>
            </CustomAppRateContainer>
        </DashboardLayout>
    )
}

export default AppRate
