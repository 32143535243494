import React, { useEffect } from 'react'
import { BankContent, Content, ContentBox, GeneralContainer, Key, KeyContent, LastCheck, LeftSide, RigtSide, SummaryContainer, Title } from './Summary.styled'
import { AddCompanyProps } from './type'
import Button from 'components/Buttons/Button/Button'
import Typography from 'components/Typography/Typography'
import SummaryImg from '../../../../assets/Images/Summary.svg'
import { useCreateCompany } from 'utils/hooks/queries/Companies'
import { ButtonsSide } from '../AddCompany.styled'
import { parseNameSurname } from 'utils/helpers'
import { CreateCompanyModel } from 'services/be-api/companies/types'
import { useNavigate } from 'react-router-dom'
import Spinner from '../../../../components/Spinner'

const Summary = ({ handleBack, companyInfo, authorizedInfo, subscriptionInfo }: AddCompanyProps) => {
    const { mutate: reSend, isSuccess, isLoading } = useCreateCompany()
    const navigate = useNavigate()

    const onSubmit = () => {
        let authorized = parseNameSurname(authorizedInfo.authorizedNameSurname)
        const inputDTO: CreateCompanyModel = {
            company: {
                name: companyInfo.companiesName,
                taxNumber: companyInfo.taxNumber.toString(),
                city: companyInfo.city,
                district: companyInfo.district,
                address: companyInfo.address,
            },
            user: {
                name: authorized.name,
                surname: authorized.surname,
                emailAddress: authorizedInfo.eMail ?? null,
                reference: authorizedInfo.reference,
                phoneNumber: authorizedInfo.telNumber.replaceAll(' ', ''),
                isSeller: authorizedInfo.role === 'satıcı',
                isBuyer: authorizedInfo.role !== 'satıcı',
                vehicleTypeNames: subscriptionInfo.vehicleTypes,
                vehicleMakeNames: subscriptionInfo.vehicleMakes,
                workCategoryNames: subscriptionInfo.workCategories,
                position: 'Yönetici',
                bankAccounts: authorizedInfo.bankAccounts.length ? JSON.stringify(authorizedInfo.bankAccounts) : '[]',
            },
        }

        reSend(inputDTO)
    }
    useEffect(() => {
        if (isSuccess) {
            navigate('/companies')
        }
    }, [isSuccess, navigate])

    return (
        <GeneralContainer>
            <Spinner open={isLoading} />
            <SummaryContainer>
                <LeftSide>
                    <LastCheck>
                        <Typography variant="h5-medium">Neredeyse Tamam! 🚀</Typography>
                        <Typography>Oluşturmak istediğin firmaya ait bilgileri kontrol ettikten sonra onaylayabilirsin.</Typography>
                    </LastCheck>

                    <Title>Firma Bilgileri</Title>
                    <KeyContent>
                        <ContentBox>
                            <Key>Firma Adı</Key>
                        </ContentBox>
                        <ContentBox>
                            <Content>{companyInfo.companiesName}</Content>
                        </ContentBox>
                    </KeyContent>
                    <KeyContent>
                        <ContentBox>
                            <Key>Vergi No</Key>
                        </ContentBox>
                        <ContentBox>
                            <Content>{companyInfo.taxNumber}</Content>
                        </ContentBox>
                    </KeyContent>
                    <KeyContent>
                        <ContentBox>
                            <Key>İl</Key>
                        </ContentBox>
                        <ContentBox>
                            <Content>{companyInfo.city}</Content>
                        </ContentBox>
                    </KeyContent>
                    <KeyContent>
                        <ContentBox>
                            <Key>İlçe</Key>
                        </ContentBox>
                        <ContentBox>
                            <Content>{companyInfo.district}</Content>
                        </ContentBox>
                    </KeyContent>
                    <KeyContent>
                        <ContentBox>
                            <Key>Adres</Key>
                        </ContentBox>
                        <ContentBox>
                            <Content>{companyInfo.address}</Content>
                        </ContentBox>
                    </KeyContent>

                    <Title>Firma Yetkili Bilgileri</Title>
                    <KeyContent>
                        <ContentBox>
                            <Key>Yetkili Adı</Key>
                        </ContentBox>
                        <ContentBox>
                            <Content>{authorizedInfo.authorizedNameSurname}</Content>
                        </ContentBox>
                    </KeyContent>
                    <KeyContent>
                        <ContentBox>
                            <Key>Telefon No</Key>
                        </ContentBox>
                        <ContentBox>
                            <Content>{authorizedInfo.telNumber}</Content>
                        </ContentBox>
                    </KeyContent>
                    <KeyContent>
                        <ContentBox>
                            <Key>Email</Key>
                        </ContentBox>
                        <ContentBox>
                            <Content>{authorizedInfo.eMail ? authorizedInfo.eMail : '-'}</Content>
                        </ContentBox>
                    </KeyContent>
                    <KeyContent>
                        <ContentBox>
                            <Key>Referanslar</Key>
                        </ContentBox>
                        <ContentBox>
                            <Content>{authorizedInfo.reference}</Content>
                        </ContentBox>
                    </KeyContent>

                    <Title>Abonelik Bilgisi</Title>
                    <KeyContent>
                        <ContentBox>
                            <Key>Çalıştığı Kategori</Key>{' '}
                        </ContentBox>
                        <ContentBox>
                            {subscriptionInfo.workCategories.map((option, index) => (
                                <Content sx={{ marginRight: '10px' }}>{option + (subscriptionInfo.workCategories.length - 1 !== index && ',')}</Content>
                            ))}{' '}
                        </ContentBox>
                    </KeyContent>
                    <KeyContent>
                        <ContentBox>
                            <Key>Vasıta Tipi</Key>{' '}
                        </ContentBox>
                        <ContentBox>
                            {subscriptionInfo.vehicleTypes.map((option, index) => (
                                <Content sx={{ marginRight: '10px' }}>{option + (subscriptionInfo.vehicleTypes.length - 1 !== index && ',')}</Content>
                            ))}
                        </ContentBox>
                    </KeyContent>
                    <KeyContent>
                        <ContentBox>
                            <Key>Çalıştığı Markalar</Key>{' '}
                        </ContentBox>
                        <ContentBox>
                            {subscriptionInfo.vehicleMakes.map((option, index) => (
                                <Content sx={{ marginRight: '10px' }}>{option + (subscriptionInfo.vehicleMakes.length - 1 !== index && ',')} </Content>
                            ))}
                        </ContentBox>
                    </KeyContent>
                </LeftSide>
                <RigtSide>
                    <img width={115} height={310} src={SummaryImg} alt="Elinde Tabet Tutan Adam" />
                    {authorizedInfo.bankAccounts.map((account, index) => account.bankName !== '' && index === 0 && <Title>Banka Bilgileri</Title>)}
                    {authorizedInfo.bankAccounts.map((account) => (
                        <KeyContent sx={{ alignItems: 'flex-start' }}>
                            <ContentBox>
                                <Key>{account.bankName}</Key>
                            </ContentBox>
                            <ContentBox>
                                <BankContent>
                                    <Content>{account.nameSurname}</Content>
                                    <Content>{account.ibanNumber}</Content>
                                </BankContent>
                            </ContentBox>
                        </KeyContent>
                    ))}
                </RigtSide>
            </SummaryContainer>
            <ButtonsSide>
                <Button onClick={onSubmit} disabled={isLoading} color={'success'}>
                    Oluştur
                </Button>
                <Button variant="outlined" color="secondary" onClick={handleBack}>
                    Geri
                </Button>
            </ButtonsSide>
        </GeneralContainer>
    )
}

export default Summary
