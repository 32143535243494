import { Mutation, Query } from '../types'
import { useBaseMutation, useBaseQuery } from '../_Base'
import productsService from '../../../../services/be-api/products'
import { RequestParams } from '../../../../services/types'
import {
    CreateSpecialOffer,
    GetSpecialOfferOrders,
    SpecialOfferDetailVariables,
    SpecialOfferFilter,
    SpecialOfferResponse,
    UpdateSpecialOffer,
    UpdateSpecialOfferVariables,
} from '../../../../services/be-api/products/types'

export const useSpecialOffer: Query<RequestParams<SpecialOfferFilter>, SpecialOfferResponse> = (params) => {
    return useBaseQuery({
        queryKeys: ['special_offer', { params: params.queryKeys }],
        service: productsService.getProductSpecialOffer,
        onSuccess: {
            messageDisplay: false,
            message: 'Successful, get products',
        },
        onLoading: {
            messageDisplay: false,
            message: 'Loading',
        },
        onError: {
            messageDisplay: false,
        },
    })
}

export const useCreateSpecialOffer: Mutation<CreateSpecialOffer, void> = () => {
    return useBaseMutation({
        service: productsService.createProductSpecialOffer,
        onSuccess: {
            messageDisplay: true,
            message: 'Ürün Başarı ile Eklenmiştir',
        },
        onLoading: {
            messageDisplay: false,
            message: 'Loading',
        },
        onError: {
            messageDisplay: false,
        },
    })
}

export const useUpdateSpecialOfferStatus: Mutation<UpdateSpecialOfferVariables, any> = () => {
    return useBaseMutation({
        service: productsService.updateSpecialOfferStatus,
        onSuccess: {
            messageDisplay: false,
            message: 'Successful, update special offer status',
        },
        onLoading: {
            messageDisplay: false,
            message: 'Loading',
        },
        onError: {
            messageDisplay: false,
        },
    })
}

export const useGetSpecialOfferOrders: Query<RequestParams<GetSpecialOfferOrders>, any> = (params) => {
    return useBaseQuery({
        queryKeys: ['special_offer_orders', { params: params.queryKeys }],
        service: productsService.getSpecialOfferOrders,
        onSuccess: {
            messageDisplay: false,
            message: 'Successful, get special offer orders',
        },
        onLoading: {
            messageDisplay: false,
            message: 'Loading',
        },
        onError: {
            messageDisplay: false,
        },
    })
}

export const useSpecialOfferDetail: Query<RequestParams<SpecialOfferDetailVariables>, any> = (params) => {
    return useBaseQuery({
        queryKeys: ['special_offer_detail', { params: params.queryKeys }],
        service: productsService.getSpecialOfferDetail,
        onSuccess: {
            messageDisplay: false,
            message: 'Successful, get special offer orders',
        },
        onLoading: {
            messageDisplay: false,
            message: 'Loading',
        },
        onError: {
            messageDisplay: false,
        },
    })
}

export const useUpdateSpecialOffer: Mutation<UpdateSpecialOffer, void> = () => {
    return useBaseMutation({
        service: productsService.updateSpecialOffer,
        onSuccess: {
            messageDisplay: true,
            message: 'Ürün Başarıyla Güncellendi',
        },
        onLoading: {
            messageDisplay: true,
            message: 'Loading',
        },
        onError: {
            messageDisplay: true,
        },
    })
}
