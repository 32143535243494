import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import useAuthStore from 'context/auth-store'
import { useLogin } from '../../utils/hooks/queries/Auth'
import siteLogo from '../../assets/Images/cikmaBuradaLogo.svg'

import Button from 'components/Buttons/Button/Button'
import BottomImage from 'components/bottomImage'
import PasswordInput from 'components/Input/PasswordInput/PasswordInput'
import Typography from 'components/Typography/Typography'
import TextField from 'components/Input/TextField/TextField'
import { Form } from 'components/Form'

import { CustomContainer, CustomPaper, CustomTextBox } from './index.styled'
import { LoginFormValues } from './types'

const LoginPage: React.FunctionComponent = () => {
    const { mutate, data, isSuccess, isError } = useLogin()
    const { setAccessToken, accessToken } = useAuthStore()
    const navigate = useNavigate()

    useEffect(() => {
        if (data?.data.result.accessToken) {
            setAccessToken(data?.data.result.accessToken)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessToken, data?.data.result.accessToken])

    useEffect(() => {
        if (accessToken && isSuccess) {
            setTimeout(() => {
                navigate('/')
            }, 300)
        }
    }, [accessToken, isSuccess, navigate])

    // useEffect(() => {
    //     if (localStorage.getItem('authStore')) {
    //         navigate('/')
    //     }
    // }, [navigate])

    const schema = yup
        .object()
        .shape({
            userNameOrEmailAddress: yup.string().email('E-mail formatında olmalıdır!').required('E-mail alanı zorunludur!').max(256, 'E-mail max 256 karakter olmalıdır!'),
            password: yup.string().min(4, 'Şifre min 4 karakter olmalıdır!').max(12, 'Şifre max 12 karakter olmalıdır!').required('Şifre alanı zorunludur!'),
        })
        .required()

    const {
        handleSubmit,
        control,
        formState: { errors, isValid, isSubmitting, isSubmitted },
    } = useForm<LoginFormValues>({
        resolver: yupResolver(schema),
        defaultValues: {
            userNameOrEmailAddress: '',
            password: '',
        },
    })
    const handleLogin = async (e: LoginFormValues) => {
        mutate({ data: e })
    }

    return (
        <CustomContainer>
            <CustomPaper>
                <img width={130} height={130} src={siteLogo} alt="cıkmaBurada" />
                <CustomTextBox>
                    <Typography color="rgba(76, 78, 100, 0.87)" variant="h5-semibold">
                        ÇıkmaKolay'a Hoş Geldin 👋🏻
                    </Typography>
                    <Typography color="rgba(76, 78, 100, 0.6)" variant="body-small-default">
                        Sisteme kayıtlı mail adresin ve şifrenle giriş yapabilirsin.
                    </Typography>
                </CustomTextBox>
                <form style={{ width: '100%' }} onSubmit={handleSubmit(handleLogin)}>
                    <Form gap="large">
                        <Controller
                            name="userNameOrEmailAddress"
                            control={control}
                            rules={{
                                required: true,
                                maxLength: 256,
                            }}
                            render={({ field }) => <TextField expand label="E-mail" onError={errors.userNameOrEmailAddress} {...field} />}
                        />
                        <Controller name="password" control={control} render={({ field }) => <PasswordInput expand onError={errors.password} inputProps={{ ...field }} />} />
                        <Button type="submit" disabled={!isValid || isSubmitting || (!isError && isSubmitted)} width={'100%'} variant="contained">
                            GİRİŞ YAP
                        </Button>
                    </Form>
                </form>
            </CustomPaper>
            <BottomImage />
        </CustomContainer>
    )
}

export default LoginPage
