import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'

import Typography from 'components/Typography/Typography'
import { useUpdateUser } from 'utils/hooks/queries/Users'
import Button from 'components/Buttons/Button/Button'
import { BankContent, Content, ContentBox, GeneralContainer, Key, KeyContent, LastCheck, LeftSide, RightSide, RoleContent, SummaryContainer, Title } from './index.styled'
import { ButtonsSide } from '../index.styled'

import { parseNameSurname } from 'utils/helpers'
import { UpdateCompanyProps } from './type'
import { UpdateUserVariables } from 'services/be-api/users/types'
import { colors } from 'styles/color'
import SummarPng from '../../../../assets/Images/UpdateSummary.png'
import Spinner from '../../../../components/Spinner'
import { useGetCompanyWithId } from 'utils/hooks/queries/Companies'

const Summary: React.FunctionComponent<UpdateCompanyProps> = ({ radioValue, userId, handleBack, userInfo, subscriptionInfo, selectedId }) => {
    const { mutate: reSend, isSuccess, isLoading } = useUpdateUser()
    const [companyName, setCompanyName] = useState('')
    const {
        data,
        isSuccess: companySuccess,
        isLoading: companyLoading,
    } = useGetCompanyWithId({
        queryKeys: {
            id: selectedId,
        },
    })

    const navigate = useNavigate()

    const onSubmit = () => {
        let user = parseNameSurname(userInfo.fullName)
        const inputDTO: UpdateUserVariables = {
            id: userId,
            name: user.name,
            surname: user.surname,
            emailAddress: userInfo.emailAddress ? userInfo.emailAddress : null,
            reference: userInfo.reference,
            isBuyer: radioValue.includes('Alıcı'),
            isSeller: radioValue.includes('Satıcı'),
            companyId: selectedId || userInfo.companyId,
            vehicleTypeNames: subscriptionInfo.vehicleTypes,
            vehicleMakeNames: subscriptionInfo.vehicleMakes,
            workCategoryNames: subscriptionInfo.workCategories,
            position: userInfo.position,
            bankAccounts: JSON.stringify(userInfo.bankAccounts),
            avatar: userInfo.avatar,
            phoneNumber: userInfo.phoneNumber.replaceAll(' ', ''),
        }
        reSend(inputDTO)
    }

    useEffect(() => {
        isSuccess && navigate('/users')
    }, [isSuccess, navigate])

    useEffect(() => {
        if (data && companySuccess) {
            setCompanyName(data.data.result.name)
        }
    }, [companySuccess, data])

    return (
        <GeneralContainer>
            <Spinner open={isLoading || companyLoading} />
            <SummaryContainer>
                <LeftSide>
                    <LastCheck>
                        <Typography variant="h5-medium">Neredeyse Tamam! 🚀</Typography>
                        <Typography>Oluşturmak istediğin kullanıcıya ait bilgileri kontrol ettikten sonra onaylayabilirsin.</Typography>
                    </LastCheck>

                    <Title>Kullanıcı Bilgileri</Title>
                    <KeyContent>
                        <ContentBox>
                            <Key>Firma İsmi</Key>
                        </ContentBox>
                        <ContentBox>
                            <Content>{companyName}</Content>
                        </ContentBox>
                    </KeyContent>
                    <KeyContent>
                        <ContentBox>
                            <Key>Kullanıcı Tipi</Key>
                        </ContentBox>
                        <ContentBox>
                            <RoleContent
                                style={{
                                    backgroundColor: radioValue === 'Satıcı' ? colors.success.successMain : colors.info.infoMain,
                                    background:
                                        radioValue === 'Satıcı'
                                            ? `linear-gradient(0deg, rgba(255, 255, 255, 0.88), rgba(255, 255, 255, 0.88)), #72E128`
                                            : radioValue === 'Alıcı'
                                            ? 'linear-gradient(0deg, rgba(255, 255, 255, 0.88), rgba(255, 255, 255, 0.88)), #26C6F9'
                                            : '',
                                }}
                            >
                                <Content>
                                    {radioValue === 'Satıcı' ? (
                                        <LocalOfferIcon sx={{ color: colors.success.successMain, fontSize: '1rem' }} />
                                    ) : radioValue === 'Alıcı' ? (
                                        <ShoppingBasketIcon sx={{ color: colors.info.infoMain, fontSize: '1rem' }} />
                                    ) : (
                                        radioValue === 'Satıcı & Alıcı'
                                    )}
                                </Content>
                                <Content>
                                    {radioValue ? (
                                        radioValue === 'Satıcı & Alıcı' ? (
                                            <>
                                                <Content
                                                    sx={{
                                                        background: `linear-gradient(0deg, rgba(255, 255, 255, 0.88), rgba(255, 255, 255, 0.88)), #72E128`,
                                                        color: colors.success.successMain,
                                                        marginRight: '5px',
                                                        marginLeft: '5px',
                                                    }}
                                                >
                                                    {' '}
                                                    <LocalOfferIcon sx={{ color: colors.success.successMain, fontSize: '1rem' }} /> Satıcı
                                                </Content>
                                                <Content
                                                    sx={{
                                                        background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.88), rgba(255, 255, 255, 0.88)), #26C6F9',
                                                        color: colors.info.infoMain,
                                                        marginRight: '5px',
                                                        marginLeft: '5px',
                                                    }}
                                                >
                                                    <ShoppingBasketIcon sx={{ color: colors.info.infoMain, fontSize: '1rem' }} /> Alıcı
                                                </Content>
                                            </>
                                        ) : (
                                            <span style={{ color: radioValue === 'Satıcı' ? colors.success.successMain : colors.info.infoMain }}>
                                                {radioValue.charAt(0).toUpperCase() + radioValue.slice(1)}
                                            </span>
                                        )
                                    ) : (
                                        ''
                                    )}
                                </Content>
                            </RoleContent>
                        </ContentBox>
                    </KeyContent>
                    <KeyContent>
                        <ContentBox>
                            <Key>Kullanıcı İsmi</Key>
                        </ContentBox>
                        <ContentBox>
                            <Content>{userInfo.fullName}</Content>
                        </ContentBox>
                    </KeyContent>
                    <KeyContent>
                        <ContentBox>
                            <Key>Telefon No</Key>
                        </ContentBox>
                        <ContentBox>
                            <Content>{userInfo.phoneNumber}</Content>
                        </ContentBox>
                    </KeyContent>
                    <KeyContent>
                        <ContentBox>
                            <Key>Email</Key>
                        </ContentBox>
                        <ContentBox>
                            <Content>{userInfo.emailAddress ? userInfo.emailAddress : ''}</Content>
                        </ContentBox>
                    </KeyContent>
                    <KeyContent>
                        <ContentBox>
                            <Key>Referanslar</Key>
                        </ContentBox>
                        <ContentBox>
                            <Content>{userInfo.reference}</Content>
                        </ContentBox>
                    </KeyContent>

                    {userInfo.bankAccounts.map((account, index) => account.bankName !== '' && index === 0 && <Title>Banka Bilgileri</Title>)}
                    {userInfo.bankAccounts.map((account, index) => (
                        <KeyContent key={index} sx={{ alignItems: 'flex-start' }}>
                            <ContentBox>
                                <Key>{account.bankName}</Key>
                            </ContentBox>
                            <ContentBox>
                                <BankContent>
                                    <Content>{account.nameSurname}</Content>
                                    <Content>{account.ibanNumber}</Content>
                                </BankContent>
                            </ContentBox>
                        </KeyContent>
                    ))}

                    <Title>Abonelik Bilgisi</Title>
                    <KeyContent>
                        <ContentBox>
                            <Key>Çalıştığı Kategori</Key>{' '}
                        </ContentBox>
                        <ContentBox>
                            {subscriptionInfo.workCategories.map((option, index) => (
                                <Content key={index} sx={{ marginRight: '10px' }}>
                                    {option + (subscriptionInfo.workCategories.length - 1 !== index && ',')}
                                </Content>
                            ))}{' '}
                        </ContentBox>
                    </KeyContent>
                    <KeyContent>
                        <ContentBox>
                            <Key>Vasıta Tipi</Key>{' '}
                        </ContentBox>
                        <ContentBox>
                            {subscriptionInfo.vehicleTypes.map((option, index) => (
                                <Content key={index} sx={{ marginRight: '10px' }}>
                                    {option + (subscriptionInfo.vehicleTypes.length - 1 !== index && ',')}
                                </Content>
                            ))}
                        </ContentBox>
                    </KeyContent>
                    <KeyContent>
                        <ContentBox>
                            <Key>Çalıştığı Markalar</Key>{' '}
                        </ContentBox>
                        <ContentBox>
                            {subscriptionInfo.vehicleMakes.map((option, index) => (
                                <Content key={index} sx={{ marginRight: '10px' }}>
                                    {option + (subscriptionInfo.vehicleMakes.length - 1 !== index && ',')}
                                </Content>
                            ))}
                        </ContentBox>
                    </KeyContent>
                </LeftSide>
                <RightSide>
                    <img width={115} height={310} src={SummarPng} alt="Elinde Tabet Tutan Adam" />
                </RightSide>
            </SummaryContainer>
            <ButtonsSide>
                <Button onClick={onSubmit} disabled={isLoading} color={'success'}>
                    Güncelle
                </Button>
                <Button variant="outlined" color="secondary" onClick={handleBack}>
                    Geri
                </Button>
            </ButtonsSide>
        </GeneralContainer>
    )
}

export default Summary
